import styled from "styled-components";

import { etGrayDark, etWhite } from "../../shared/colors";
import { getEarthLines } from "../../shared/helpers/getEarthLines";
import { getUonOverlayColor } from "../../shared/helpers/protect-page/getUonOverlayColor";

namespace sc {
  export const Wrapper = styled.div`
    position: relative;
  `;

  export const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 7;
    cursor: pointer;
  `;

  export const EarthLines = styled.div<{ uonCount: number }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 93%;
    ${(props) =>
      `background-image: url(${getEarthLines(
        props.uonCount,
        props.theme.isWebpSupported,
        true,
      )});`}
  `;

  export const Container = styled.div`
    font-family: Roboto;
    width: 328px;
    height: 328px;
    background-color: ${etWhite};
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 2px 0 rgba(0, 0, 0, 0.1);
    color: ${etGrayDark};
    position: relative;
    overflow: hidden;
    padding: 12px;
    cursor: pointer;
  `;

  export const Overlay = styled.div<{ uonReserveSize: number }>`
    position: absolute;
    ${(props) =>
      `background-color: ${getUonOverlayColor(props.uonReserveSize)};`};
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    pointer-events: none;
  `;

  export const UonReserveBackground = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-items: stretch;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 304px;
    margin-bottom: 0;
  `;

  export const SquareM2 = styled.div`
    width: 280px;
    height: 280px;
    max-width: 280px;
    max-height: 280px;
    position: relative;
    z-index: 6;
  `;

  export const UonM2Logo = styled.img`
    width: 280px;
    height: 280px;
    max-width: 280px;
    max-height: 280px;
  `;

  export const UonReserveTop = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: start;
    width: 125px;
    height: 76px;
    position: absolute;
    z-index: 4;
    top: 15px;
    left: 5px;
    color: ${etWhite};
  `;

  export const UonReserveCount = styled.span<{
    isEndWithOne: boolean;
    uonCount: number;
  }>`
    font-family: Roboto Condensed;
    height: 100%;
    line-height: 48px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    padding: 0px 7px 0 5px;
    font-size: 65px;
    margin-right: ${(props) =>
      props.isEndWithOne && props.uonCount < 1000 ? "-8px" : 0};
  `;

  export const UonReserveTitle = styled.p`
    font-family: Roboto Condensed;
    width: 100px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.03;
  `;

  export const UonReserveTitle1 = styled.span`
    font-family: Roboto Condensed;
    font-weight: bold;
    font-size: 22px;
    line-height: 1;
    margin-top: -5px;

    display: block;
    text-transform: lowercase;
  `;

  export const TopRightStar = styled.img`
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    display: block;
    position: absolute;
    z-index: 4;
    top: 15px;
    right: 15px;
  `;

  export const TopRightText = styled.p`
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-transform: uppercase;
    color: ${etWhite};
    font-size: 12px;
    position: absolute;
    top: 43px;
    right: 16px;
    margin: 0;
  `;

  export const CardContentContainer = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `;

  export const Coordinate = styled.span`
    font-family: Roboto Condensed;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  `;
}

export default sc;
