/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from "mobx-react-lite";

import { Currency, UonSize } from "../../stores/DonateStore/DonateStore";
import { ILeaderboardModel } from "../../stores/LeaderBoardModel";
import { LazyModalType, ModalType } from "../../stores/ModalStore";
import { SectionDavidAttenbrough } from "../ProtectV202208/SectionDavidAttenbrough/SectionDavidAttenbrough";
import { SectionFiveV202208 } from "../ProtectV202208/SectionFiveV202208/SectionFiveV202208";
import { SectionFour } from "../ProtectV202208/SectionFour/SectionFour";
import { SectionMoreAbout } from "../ProtectV202208/SectionMoreAbout/SectionMoreAbout";
import { SectionOneBodyV202208 } from "../ProtectV202208/SectionOneV202208/SectionOneBodyV202208";
import { SectionOneBodyWithEnableOneFlow } from "../ProtectV202208/SectionOneV202208/SectionOneBodyWithEnableOneFlow";
import { SectionOneV202208 } from "../ProtectV202208/SectionOneV202208/SectionOneV202208";
import {
  SectionSevenDriver,
  SectionSevenV202208,
} from "../ProtectV202208/SectionSevenV202208/SectionSevenV202208";
import { SectionThreeV202208 } from "../ProtectV202208/SectionThreeV202208/SectionThreeV202208";
import { SectionTwoV202208 } from "../ProtectV202208/SectionTwo/SectionTwoV202208";
import sc from "./LayoutProtect.styled";
import { NpoLogoKey } from "./SectionFive";
import { SectionTenDeckDriver } from "./SectionTen";

export interface FeatureFlaggingProtectPageDriver {
  readonly counterFlapEffectDuration: number;
  readonly counterFlipEffectSetInterval: number;
  readonly counterFlipEffectTimeReroll: number;
  readonly enableOneFlow: boolean;
}

export interface ProtectPageDriver {
  readonly protectCount: number;
  readonly lastNameProtector: string;
  readonly staticDecks: SectionTenDeckDriver[];
  readonly isPlayingMovie: boolean;
  readonly loadingProtectedCount: boolean;
  readonly sectionSixDeckPathString: string;
  readonly isLoadingReserve: boolean;

  readonly isSectionTwoSubSectionActivated: boolean;

  readonly isAutoPlayVimeo: boolean;
  readonly isShowAdditionalText: boolean;
  readonly visibleNpoLogo: Array<{ src: string; key: NpoLogoKey }>;
  readonly visibleNpoProtectV202208: Array<{
    key: NpoLogoKey;
    src: string;
    size: { w: string; h: string };
  }>;
  readonly isGroupUnpublished: boolean;
  readonly currency: Currency;

  setIsShowAdditionalText(): void;

  openModal(modalTypes: ModalType | LazyModalType): void;
  updateUonSize(size: UonSize): void;
  back(): void;
  updateIsPlayingMovie(isPlayingMovie: boolean): void;
  toggleSectionTwoSubSection(): void;
  openSubscribeOneFlowModal(dataCy: string): void;
}

export interface PropsLayoutProtect {
  driver: ProtectPageDriver;
  leaderboard: ILeaderboardModel;
  deviceDriver: {
    isWebPSupported: boolean;
  };
  featureFlaggingDriver: FeatureFlaggingProtectPageDriver;
  isOverlayActive: boolean;
  closeOverlay?: () => void;

  driverMenu: { isCardJoiningOpen: boolean; isMenuAppOpen: boolean };
  sectionSevenDriver: SectionSevenDriver;
}

export const LayoutProtectV202208 = observer((props: PropsLayoutProtect) => {
  const stateDriver = {
    loading: props.driver.loadingProtectedCount,
  };
  const pathDriver = {
    sectionSixDeckPathString: props.driver.sectionSixDeckPathString,
  };

  return (
    <sc.Container
      isOverlayActive={props.isOverlayActive}
      isCardJoiningOpen={props.driverMenu.isCardJoiningOpen}
      isMenuAppOpen={props.driverMenu.isMenuAppOpen}
    >
      <SectionOneV202208
        enableOneFlow={props.featureFlaggingDriver.enableOneFlow}
        renderSectionOneBody={
          props.featureFlaggingDriver.enableOneFlow
            ? () => (
                <SectionOneBodyWithEnableOneFlow
                  leaderboard={props.leaderboard}
                  driver={props.driver}
                  stateDriver={stateDriver}
                  flags={props.featureFlaggingDriver}
                />
              )
            : () => (
                <SectionOneBodyV202208
                  leaderboard={props.leaderboard}
                  driver={props.driver}
                  stateDriver={stateDriver}
                  flags={props.featureFlaggingDriver}
                />
              )
        }
      />
      <SectionTwoV202208 driver={props.driver} />
      <SectionThreeV202208 driver={props.driver} />
      <SectionFour driver={props.driver} decks={props.driver.staticDecks} />
      <SectionFiveV202208 />
      <SectionSevenV202208 driver={props.sectionSevenDriver} />
      {/* <SectionProtectAndGive /> */}
      <SectionDavidAttenbrough pathDriver={pathDriver} />
      <SectionMoreAbout />
    </sc.Container>
  );
});
