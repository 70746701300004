import { observer } from "mobx-react-lite";
import React from "react";

import ReportCard from "-!svg-react-loader?name=ReportCard!./assets/report-card.svg";

import { etRegentGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import scUpdate from "./CardSimpleContext_Update.styled";

interface IProps {
  onReport(): void;
  isHovering?: boolean;
}

export const ContextMenuCardReport = observer(function (props: IProps) {
  const { t } = useTranslation("ContextMenu");

  return (
    <scUpdate.ContextMenuRow
      data-cy="CardMenuItem-ReportCard"
      className={props.isHovering ? "hover" : ""}
      onClick={(e) => {
        e.stopPropagation();
        props.onReport();
      }}
    >
      <ReportCard fill={etRegentGray} />
      <scUpdate.ContextMenuItemBlue>
        {t("context-menu.card-menu.buttons.report")}
      </scUpdate.ContextMenuItemBlue>
    </scUpdate.ContextMenuRow>
  );
});
