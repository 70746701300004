import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { useTranslation } from "../../../shared/translate/NextI18next";
import TheBigWhyDeck from "../../LayoutProtect/img/protect-section-six-play.png";
import scLayoutProtect from "../../LayoutProtect/LayoutProtect.styled";
import { LazyImage } from "../../LazyImage/LazyImage";
import sc from "./SectionDavidAttenbrough.styled";

export interface PathDriver {
  readonly sectionSixDeckPathString: string;
}

interface IProps {
  pathDriver: PathDriver;
}

export const SectionDavidAttenbrough = observer((props: IProps) => {
  const { t } = useTranslation("Protect");
  const { t: tGeneral } = useTranslation("General");

  return (
    <scLayoutProtect.Section
      data-cy="SectionDavidAttenbrough"
      className="visual-reg"
    >
      <sc.Wrapper>
        <sc.Container>
          <sc.ColLeft data-cy="SectionDavidAttenbrough-Card">
            <Link
              href="/[vanityName]/[deckName]/[cardId]"
              as={`/${props.pathDriver.sectionSixDeckPathString}`}
              passHref
              legacyBehavior
            >
              <sc.TheBigWhyCard>
                <LazyImage
                  src={TheBigWhyDeck}
                  width="100%"
                  maxWidth="100%"
                  height="100%"
                  maxHeight="100%"
                  alt="the big why deck"
                />
              </sc.TheBigWhyCard>
            </Link>
          </sc.ColLeft>
          <sc.ColRight>
            <sc.Title data-cy="SectionDavidAttenbrough-Title">
              “{t("protect.section-david.title")}”
            </sc.Title>
            <sc.SubTitle data-cy="SectionDavidAttenbrough-SubTitle">
              {t("protect.section-david.sir")} David Attenborough
            </sc.SubTitle>
            <sc.Buttons>
              <scLayoutProtect.ButtonLinkPrimary
                href="#sectionTwo"
                data-cy="SectionDavidAttenbrough-StartProtecting"
              >
                {tGeneral("general.start-protecting")}
              </scLayoutProtect.ButtonLinkPrimary>
            </sc.Buttons>
          </sc.ColRight>
        </sc.Container>
      </sc.Wrapper>
    </scLayoutProtect.Section>
  );
});
