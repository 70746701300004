import styled from "styled-components";

import {
  etBlack,
  etGray,
  etGrayBold,
  etGreen,
  etWhite,
} from "../../shared/colors";

namespace sc {
  export const Container = styled.div`
    width: 100%;
  `;
  export const ContentContainer = styled.div`
    width: 328px;
    max-width: 328px;
    height: 496px;
    max-height: 496px;
    background-color: ${etWhite};
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  `;
  export const IconWrapper = styled.div`
    margin: 12px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  `;

  export const Title = styled.p`
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
  `;
  export const PaymentDetailWrapper = styled.div`
    width: 100%;
    height: 198px;
    max-height: 198px;
    margin-top: 36px;
  `;
  export const PaymentTable = styled.table`
    width: 100%;
    height: 90%;
  `;
  export const TBody = styled.tbody`
    width: 100%;
  `;
  export const Tr = styled.tr`
    &.bordered {
      border-bottom: 1px solid ${etGrayBold};
    }
  `;

  export const Th = styled.th`
    font-family: Roboto Condensed;
    font-size: 12px;
    font-weight: normal;
    color: ${etBlack};
    height: 15px;
    line-height: 15px;
    margin-top: 5px;

    &.textBold {
      font-weight: bold;
    }
    &.grayText {
      color: ${etGray};
    }
  `;

  export const ThLeft = styled(Th)`
    float: left;
  `;
  export const ThRight = styled(Th)`
    float: right;
  `;
  export const Describe = styled.div`
    width: 100%;
    margin-top: 19px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  `;
  export const GreenText = styled.p`
    color: ${etGreen};
    margin: 0;
    font-weight: bold;
  `;
  export const GrayText = styled.p`
    margin: 0;
    width: 103%;
  `;
}

export default sc;
