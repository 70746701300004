import styled from "styled-components";

import {
  etBlack,
  etLicoriceBlue,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const Container = styled.div`
    width: 328px;
    height: 496px;
    max-width: 328px;
    max-height: 496px;

    background-color: ${etWhite};
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);

    padding: 12px;

    position: relative;
  `;

  export const Body = styled.div`
    height: 472px;
    width: 304px;
    max-height: 472px;
    max-width: 304px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    position: relative;
  `;

  export const BackgroundFrame = styled.div`
    height: 472px;
    width: 304px;
    max-height: 472px;
    max-width: 304px;

    background: url(${require("../../assets/img/card-removed-content.png")})
      center center no-repeat;

    position: absolute;
    z-index: 0;
  `;

  export const Content = styled.div`
    height: 118px;
    width: 304px;
    max-height: 118px;
    max-width: 304px;

    background-color: ${etSolitudeLight};
    border-radius: 0px;
    margin: auto 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    z-index: 1;
  `;

  export const Text = styled.div`
    font-family: Roboto;
    font-size: 14px;

    color: ${etLicoriceBlue};

    letter-spacing: 0px;
    text-align: center;
  `;

  export const TextHead = styled(Text)`
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  `;

  export const ContextWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 8px;
  `;

  export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: ${zIndex.CARDITEM_BLACK_OVERLAY};
  `;
}

export default sc;
