import styled from "styled-components";

import { IconSvgContainer } from "../../components/IconSvg/IconSvg.styled";
import {
  etBlack,
  etBrightLightBlue,
  etRed,
  etRegentGray,
  etSolitudeLight,
  etVoteRed,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";

namespace sc {
  export const ContextMenuItemBlue = styled.span`
    margin-left: 13px;
  `;
  export const ContextMenuItemRed = styled.span``;

  export const ContextMenuItemRedInInit = styled.span`
    color: ${etVoteRed};
  `;
  export const ContextMenuRow = styled.div<{ hoverColor: string }>`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    color: ${etBlack};

    border-radius: 4px;
    border: 1px solid ${etSolitudeLight};
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    width: 190px;
    margin-bottom: 12px;
    padding: 12px;

    color: ${etRegentGray};
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;

    :hover {
      background-color: ${transparentize(etBrightLightBlue, 0.1)};
      border-color: ${transparentize(etBrightLightBlue, 0.1)};
    }

    :hover > ${ContextMenuItemBlue} {
      color: ${etBrightLightBlue};
    }

    :hover > ${ContextMenuItemRed} {
      color: ${etRed};
    }

    :hover > ${IconSvgContainer} {
      fill: ${(props) => props.hoverColor};
    }

    &:last-child {
      margin-bottom: 0;
    }
  `;
  export const ContextMenuRowReportItem = styled(ContextMenuRow)`
    margin-bottom: 0px;
  `;

  export const ContextMenuRowReport = styled.div`
    height: fit-content;
    min-height: fit-content;
    max-height: fit-content;
    width: fit-content;
    border: none;
    display: grid;
    grid-gap: 12px;
  `;

  export const ContextMenuRowReorder = styled(ContextMenuRow)`
    padding: 0;
    ${ContextMenuItemBlue} {
      margin: 0;
    }
    ${IconSvgContainer} {
      margin-left: 8px;
      margin-right: 10px;
    }
  `;

  export const ContextMenuRowEdit = styled(ContextMenuRow)`
    padding: 0;
    ${ContextMenuItemBlue} {
      margin: 0;
    }
    ${IconSvgContainer} {
      margin-left: 10px;
      margin-right: 10px;
    }
  `;

  export const ContextMenuRowDelete = styled(ContextMenuRow)`
    padding: 0;
    ${ContextMenuItemBlue} {
      margin: 0;
    }
    ${IconSvgContainer} {
      margin-left: 11px;
      margin-right: 10px;
    }
  `;
}

export default sc;
