import styled, { css } from "styled-components";

import {
  largeScreenLowerLimit,
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  xLargeScreenLowerLimit,
  xmediumScreenLowerLimit,
} from "../../shared/breakpoints";
import { etBlack, etBrightLightBlue, etWhite } from "../../shared/colors";

export namespace sc {
  export const Container = styled.div<{ isAboutOverlay: boolean }>`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;

    height: 496px;
    max-height: 496px;
    background-color: ${etWhite};
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    padding-top: 24px;
    margin-top: 24px;
    border-radius: 8px;
    @media (min-width: ${xLargeScreenLowerLimit}) {
      width: calc(328px * 4 + 24px * 3);
      max-width: calc(328px * 4 + 24px * 3);
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      width: calc(328px * 3 + 24px * 2);
      max-width: calc(328px * 3 + 24px * 2);
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: calc(328px * 2 + 24px);
      max-width: calc(328px * 2 + 24px);
      padding-top: 0px;
      margin-top: 24px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      min-width: 328px;
      max-width: 328px;
    }
  `;

  export const ContentWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 96px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: calc(328px * 2 + 24px);
      margin-top: 64px;

      align-items: center;
      flex-direction: column;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-top: 85px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      flex-direction: column;
      margin-top: 82px;
      align-items: center;
    }
  `;

  export const BannerLogo = styled.div<{ logo: string }>`
    height: 180px;
    max-height: 180px;
    width: 192px;
    max-width: 192px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${props.logo})`};

    @media (max-width: ${mediumScreenUpperLimit}) {
      height: 125px;
      max-height: 125px;
      width: 144px;
      max-width: 144px;

      margin-bottom: 33px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      height: 94px;
      max-height: 94px;
      width: 108px;
      max-width: 108px;
    }
  `;

  export const Content = styled.div`
    font-family: Roboto;
    color: ${etBlack};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 40px;
    @media (max-width: ${largeScreenUpperLimit}) {
      padding-left: 40px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      padding-left: unset;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      padding-left: 0;
      align-items: center;
    }
  `;

  export const Title = styled.p<{ isNPOPage: boolean }>`
    color: ${etBlack};
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 65px;
    font-weight: bold;

    margin-bottom: 36px;

    width: 812px;
    max-width: 812px;

    white-space: break-spaces;

    > span > span {
      font-weight: bold;
      display: block;
      text-transform: capitalize;
    }

    ${(props) =>
      props.isNPOPage &&
      `
        margin-bottom: 13px;
        width: 832px;
        max-width: 832p;
        line-height: 50px;
      `}

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 50px;
      line-height: 55px;

      margin-bottom: 13px;
      width: 707px;
      max-width: 707px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      font-size: 40px;
      line-height: 45px;
      text-align: center;

      width: 610px;
      max-width: 610px;
      padding: 0 25px;
      margin-bottom: 23px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      font-size: 30px;
      line-height: 35px;

      width: 100%;
      max-width: 100%;

      margin-bottom: 25px;
      padding: 0;
    }
  `;

  export const Subtitle = styled.p`
    width: 840px;
    max-width: 840px;
    margin-bottom: 36px;

    font-family: "Roboto Condensed";
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 30px;
    color: ${etBlack};
    white-space: break-spaces;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      width: 650px;
      max-width: 650px;
      margin-bottom: 20px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      width: 610px;
      max-width: 610px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  `;

  export const ButtonsWrapper = styled.div<{ aboutPage: boolean }>`
    width: 354px;
    max-width: 354px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${largeScreenLowerLimit}) {
      justify-content: center;
      width: 100%;
      max-width: 100%;
    }
  `;

  const StyleButton = css`
    height: 40px;
    max-height: 40px;
    border-radius: 20px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: condensed;
    text-transform: uppercase;
    text-align: center;
    line-height: 40px;

    &:hover {
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const BlueBtn = styled.a<{ aboutPage: boolean }>`
    ${StyleButton}
    width: 190px;
    max-width: 190px;

    background-color: ${etBrightLightBlue};
    border: 1px solid ${etBrightLightBlue};

    color: ${etWhite};

    &:hover {
      color: ${etWhite};
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      height: 40px;
      max-height: 40px;
    }
  `;

  export const ProtectLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    font-size: 16px;
    font-family: Roboto Condensed;
    text-transform: uppercase;
    color: ${etWhite};
    background-color: ${etBrightLightBlue};
    border: none;
    border-radius: 24px;

    width: 180px;
    height: 40px;
    cursor: pointer;

    &:hover {
      color: ${etWhite};
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;
  export const CloseButton = styled.div<{ display: "none" | "block" }>`
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;
    z-index: 1;
    display: ${(props) => props.display};
  `;
}
