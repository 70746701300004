import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { etWhite } from "../../shared/colors";
import { ModalType } from "../../stores/ModalStore";
import { IconSvg } from "../IconSvg/IconSvg";
import { OverlayDriver } from "./LayoutError";
import sc from "./LayoutErrorCustom.styled";

export interface LayoutErrorUnauthorizedDriver {
  openModal(modalType: ModalType): void;
}

interface IProps {
  driver: LayoutErrorUnauthorizedDriver;
  overlayDriver: OverlayDriver;
}

export const LayoutErrorUnauthorized = observer((props: IProps) => {
  return (
    <sc.Wrapper
      className="visual-reg"
      data-cy="LayoutErrorUnauthorized"
      position={props.overlayDriver.isOverlayActive ? "fixed" : "relative"}
    >
      <sc.BackgroundBlur data-cy="LayoutError-Background" />
      <sc.Overlay data-cy="LayoutError-Overlay" />
      <sc.Container>
        <sc.ColLeft>
          <sc.ErrorImage data-cy="LayoutError-ErrorImage">
            <IconSvg
              width="281px"
              height="250px"
              padding="0"
              icon="unauthorised-error"
              pointer
              color={etWhite}
            />
          </sc.ErrorImage>
        </sc.ColLeft>

        <sc.ColRight>
          <sc.StatusCode data-cy="LayoutError-StatusCode">
            Error 401 : No Authorization Found
          </sc.StatusCode>
          <sc.ErrorMessage data-cy="LayoutError-ErrorMessage">
            Sorry, this page is locked.
          </sc.ErrorMessage>
          <sc.ErrorTitle data-cy="LayoutError-ErrorTitle">
            Like the planet, we’re protecting this page and can’t give <br />
            you access unless you Join EarthToday (and log in)!
          </sc.ErrorTitle>
          <sc.ErrorDescription data-cy="LayoutError-ErrorDescription">
            Please{" "}
            <sc.Link
              onClick={(e) => {
                e.preventDefault();
                props.driver.openModal("loginForm");
              }}
            >
              log in
            </sc.Link>{" "}
            or{" "}
            <sc.Link
              onClick={(e) => {
                e.preventDefault();
                props.driver.openModal("signupForm");
              }}
            >
              sign up
            </sc.Link>{" "}
            {
              "to gain access. Or continue stalking us anonymously on our home page, we don’t mind the attention."
            }
          </sc.ErrorDescription>

          <sc.LinkNavContainer data-cy="LayoutError-Nav">
            <Link
              href="/naturereserves"
              as="/naturereserves"
              passHref
              legacyBehavior
            >
              <sc.LinkNavItem>Protect m² »</sc.LinkNavItem>
            </Link>
            <Link
              href="https://support.earthtoday.com"
              as="https://support.earthtoday.com/"
              passHref
              legacyBehavior
            >
              <sc.LinkNavItem target="_blank">Support »</sc.LinkNavItem>
            </Link>
            <Link
              href="https://support.earthtoday.com/en/collections/3529132-introduction"
              as="https://support.earthtoday.com/en/collections/3529132-introduction"
              passHref
              legacyBehavior
            >
              <sc.LinkNavItem target="_blank">FAQ »</sc.LinkNavItem>
            </Link>
          </sc.LinkNavContainer>
        </sc.ColRight>
      </sc.Container>
    </sc.Wrapper>
  );
});
