export const cuberoot = function (n) {
  return Math.pow(n, 1 / 3);
};

export const frac = function (n) {
  return n - Math.floor(n);
};
export const nFrac = function (n) {
  return n - Math.round(n);
};

export const deg2rad = function (deg) {
  return (deg * Math.PI) / 180;
};

export const w = function (Y) {
  return Math.max(1, Math.floor(1546 * Math.sin(deg2rad((Y + 0.5) / 24))));
};

export const latlon2XYxy = function (lat, lon) {
  const X = Math.floor((lon + 180) * 24);
  const Y = Math.floor((lat + 90) * 24);
  const x = Math.floor(w(Y) * frac((lon + 180) * 24));
  const y = Math.floor(1546 * frac((lat + 90) * 24));

  return [X, Y, x, y];
};

export const latlon2XYxyMeter = function (lat, lon) {
  const X = Math.floor((lon + 180) * 24);
  const Y = Math.floor((lat + 90) * 24);
  const x = Math.floor(w(Y) * frac((lon + 180) * 24));
  const y = Math.floor(1546 * frac((lat + 90) * 24));

  const desigX = frac(Math.floor(w(Y) * frac((lon + 180) * 24) * 3) / 3);
  const desigY = frac(Math.floor(1546 * frac((lat + 90) * 24) * 3) / 3);

  return [X, Y, x, y, desigX, desigY];
};

export const latlon2XYxyFacCustom = function (lat, lon, factor) {
  const X = Math.floor((lon + 180) * 24);
  const Y = Math.floor((lat + 90) * 24);
  const x = Math.floor((w(Y) * frac((lon + 180) * 24)) / factor) * factor;
  const y = Math.floor((1546 * frac((lat + 90) * 24)) / factor) * factor;

  return [X, Y, x, y];
};

export const XYxy2latlon = function (X, Y, x, y) {
  let lat = (Y + (y + 0.5) / 1546) / 24 - 90;
  let lon = (X + (x + 0.5) / w(Y)) / 24 - 180;

  lat = Math.floor(lat * 100000000) / 100000000;
  lon = Math.floor(lon * 100000000) / 100000000;

  return [lat, lon];
};

export function snapToGrid(lon: number, lat: number): number[] {
  const four = latlon2XYxyFacCustom(lat, lon, 6);
  const latlon = XYxy2latlon(four[0], four[1], four[2], four[3]);
  return [latlon[1], latlon[0]];
}
