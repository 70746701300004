import styled from "styled-components";

import {
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  xmediumScreenLowerLimit,
} from "../../../shared/breakpoints";
import { etBlack, etWhite } from "../../../shared/colors";

namespace sc {
  export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 50px;

    min-height: 700px;
    width: 100%;
    background-color: ${etBlack};

    @media (max-width: ${mediumScreenLowerLimit}) {
      padding: 83px 0;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      padding: 50px 0;
    }
  `;

  export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 1044px;
    z-index: 1;
    @media (max-width: ${mediumScreenUpperLimit}) {
      width: fit-content;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `;

  const Col = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
  `;

  export const ColLeft = styled(Col)`
    flex: 0 0 30%;
    cursor: pointer;

    @media (max-width: ${xmediumScreenLowerLimit}) {
      justify-content: center;
      align-items: center;
    }
  `;

  export const TheBigWhyCard = styled.a`
    width: 328px;
    height: 496px;
    position: relative;
  `;

  export const ColRight = styled(Col)`
    flex: 0 0 70%;
    margin-left: 192px;
    justify-content: center;
    align-items: flex-start;

    label {
      color: ${etWhite};
    }

    @media (max-width: 820px) {
      margin-left: 150px;
    }
  `;

  export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 42px;

    @media (max-width: ${xmediumScreenLowerLimit}) {
      > a {
        width: 211px;
      }
    }
  `;
  export const Title = styled.p`
    color: ${etWhite};
    font-family: Roboto;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 45px;
    max-width: 410px;

    margin: 0;
    margin-bottom: 10px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 289px;
      font-size: 25px;
      line-height: 35px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      margin-top: 52px;
    }
  `;

  export const SubTitle = styled.span`
    color: ${etWhite};
    font-family: Roboto Condensed;
    font-size: 24px;
    font-weight: normal;
    height: 26px;
    letter-spacing: 0px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;

    display: inline-block;
    text-align: left;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 20px;
      line-height: 24px;
    }
  `;
}

export default sc;
