import {
  etBrightLightBlue,
  etGray,
  etGreen,
  etLightBlue,
  etOrange,
  etPurpleUonCard,
  etRed,
  etYellowDeck,
} from "../../colors";

export function getUonOverlayColor(uonCount: number) {
  switch (uonCount) {
    case 1: {
      return etRed;
    }
    case 5: {
      return etOrange;
    }
    case 10: {
      return etYellowDeck;
    }
    case 20: {
      return etBrightLightBlue;
    }
    case 50: {
      return etGreen;
    }
    case 100: {
      return etLightBlue;
    }
    case 500: {
      return etPurpleUonCard;
    }
    case 1000: {
      return etRed;
    }
    default: {
      return etGray;
    }
  }
}
