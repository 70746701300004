import { observer } from "mobx-react-lite";
import React from "react";
import Slider from "react-slick";

import { useTranslation } from "../../shared/translate/NextI18next";
import { LazyModalType } from "../../stores/ModalStore";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import scLayoutProtect from "./LayoutProtect.styled";
import sc from "./SectionFive.styled";

interface Props {
  driver: {
    readonly visibleNpoLogo: Array<{ src: string; key: NpoLogoKey }>;
    openModal(modalTypes: LazyModalType): void;
  };
}

export enum NpoLogoKey {
  NatureNeedsHalf = "nature-needs-half",
  RewildingEurope = "nature-needs-half",
  FFI = "fauna-flora",
  WildFoundation = "wild-foundation",
  IFAW = "ifaw",
  RewildingArgentina = "rewilding-argentina",
  ARK = "ark",
  PeaceParksFoundation = "peace-parks-foundation",
  KarenMogensen = "karen-mogensen",
  RainForestTrust = "rainforest-trust",
  HojaNueva = "hoja-nueva",
  AsepalecoFoundation = "asepaleco-foundation",
}

export const SamplePrevArrow = (props) => {
  return (
    <sc.Left onClick={props.onClick} data-cy="SamplePrevArrow">
      <img src={require("../../assets/img/arrow-left.png")} alt="" />
    </sc.Left>
  );
};

export const SampleNextArrow = (props) => {
  return (
    <sc.Right onClick={props.onClick} data-cy="SampleNextArrow">
      <img src={require("../../assets/img/arrow-right.png")} alt="" />
    </sc.Right>
  );
};

export const SectionFive = observer((props: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    swipeToSlide: true,
    autoplaySpeed: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  const { t } = useTranslation("Protect");

  return (
    <scLayoutProtect.Section data-cy="SectionFive" className="visual-reg">
      <sc.Wrapper>
        <sc.Container>
          <sc.PartnersWrap>
            <sc.Slider>
              <Slider {...settings}>
                {props.driver.visibleNpoLogo.map((logo) => (
                  <sc.ItemLogo key={logo.key}>
                    <sc.PartnerLogo
                      data-cy="SectionFive-PartnerLogo"
                      src={logo.src}
                    />
                  </sc.ItemLogo>
                ))}
              </Slider>
            </sc.Slider>
          </sc.PartnersWrap>

          <sc.Title data-cy="SectionFive-Title">
            {t("protect.section-five.title")}
          </sc.Title>
          <scLayoutProtect.SubTitle data-cy="SectionFive-SubTitle">
            {t("protect.section-five.sub-title")}
          </scLayoutProtect.SubTitle>
          <scLayoutProtect.Text>
            <sc.Content data-cy="SectionFive-Content">
              <DangerouslyHTMLRenderer>
                {t("protect.section-five.description")}
              </DangerouslyHTMLRenderer>
            </sc.Content>
          </scLayoutProtect.Text>
          <sc.Button>
            <sc.ButtonLinkPrimary
              data-cy="SectionFive-SendEmail"
              onClick={(event) => {
                event.preventDefault();
                props.driver.openModal("contactPartner");
              }}
            >
              {t("protect.button.partner")}
            </sc.ButtonLinkPrimary>
          </sc.Button>
        </sc.Container>
      </sc.Wrapper>
    </scLayoutProtect.Section>
  );
});
