import { observer } from "mobx-react-lite";
import React from "react";

import { etRed, etVoteRed } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import scUpdate from "./CardSimpleContext_Update.styled";

interface IProps {
  onDelete(): void;
  disabled?: boolean;
  location?: string;
  isHovering?: boolean;
}

export const ContextMenuCardDelete = observer(function (props: IProps) {
  const { t } = useTranslation("ContextMenu");

  return (
    <scUpdate.ContextMenuRowDelete
      className={props.isHovering ? "hover" : ""}
      data-cy="CardMenuItem-Delete"
      hoverColor={etRed}
      disabled={props.disabled}
      onClick={(e) => {
        e.stopPropagation();
        props.onDelete();
      }}
    >
      <scUpdate.IconWrapper>
        <IconSvg
          icon="delete-card"
          color={etVoteRed}
          pointer
          hoverColor={etVoteRed}
          width="16px"
          height="16px"
          padding="0"
        />
      </scUpdate.IconWrapper>
      <scUpdate.ContextMenuItemRed location={props.location}>
        {t("context-menu.card-menu.buttons.delete")}
      </scUpdate.ContextMenuItemRed>
    </scUpdate.ContextMenuRowDelete>
  );
});
