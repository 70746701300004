import styled from "styled-components";

import {
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  smallScreenLowerLimit,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBlueLight,
  etBrightLightBlue,
  etDark,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";

namespace sc {
  export const Container = styled.div`
    grid-auto-columns: 328px;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, 328px);
    justify-content: center;
  `;

  export const GridItem = styled.div`
    position: relative;
    grid-column-start: 4 span;

    @media (min-width: ${smallScreenLowerLimit}) {
      grid-column: 1 span;
    }
    @media (min-width: ${mediumScreenLowerLimit}) {
      grid-column: 2 span;
    }
    @media (min-width: ${largeScreenLowerLimit}) {
      grid-column: 3 span;
    }
    @media (min-width: ${xLargeScreenLowerLimit}) {
      grid-column: 4 span;
    }
  `;

  export const Label = styled.div`
    color: ${etDark};
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    @media (max-width: ${mediumScreenLowerLimit}) {
      color: ${etBrightLightBlue};
      width: 100%;
      max-width: 100%;
    }
  `;

  export const Button = styled.button`
    color: ${etWhite};
    background-color: ${etBrightLightBlue};
    border-radius: 4px;
    height: 40px;
    max-height: 40px;
    width: 280px;
    max-width: 280px;
    margin-left: 16px;
    border: none;
    font-weight: 500;

    @media (max-width: ${smallScreenUpperLimit}) {
      margin-top: 16px;
    }

    &:hover {
      background-color: ${etBlueLight};
    }
  `;

  export const Spacing = styled.div<{
    marginTop: string;
    marginBottom: string;
  }>`
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${transparentize(etBrightLightBlue, 0.1)};
    border-radius: 8px;
    border: 1px solid ${etBrightLightBlue};
    color: ${etBrightLightBlue};
    min-height: 80px;
    padding: 0 24px;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};

    @media (max-width: ${mediumScreenLowerLimit}) {
      flex-flow: column;
      align-items: flex-start;
      text-align: center;
      background-color: ${transparentize(etBrightLightBlue, 0.1)};
      padding: 24px;
      ${Button} {
        margin-left: 0;
        width: 100%;
      }
    }
  `;

  export const Overlay = styled.div<{
    top: string;
    bottom: string;
  }>`
    position: absolute;
    z-index: 0;
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    left: 0;
    right: 0;

    background-color: ${etWhite};
    border-radius: 8px;

    @media (max-width: ${mediumScreenLowerLimit}) {
      background-color: ${etBlack};
    }
  `;
}

export { sc };
