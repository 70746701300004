/* eslint-disable no-unused-expressions */

import { observer } from "mobx-react-lite";
import React from "react";

import DoubleCard from "-!svg-react-loader?name=DoubleCard!./assets/double-card.svg";
import HeaderCard from "-!svg-react-loader?name=HeaderCard!./assets/header-card.svg";
import SingleCard from "-!svg-react-loader?name=SingleCard!./assets/single-card.svg";

import { etRegentGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import sc from "./CardSimpleContext_Update.styled";

interface Driver {
  onResizeCardSingle(): void;
  onResizeCardDouble(): void;
  onPinFeaturedCard(): void;
  readonly isCardSingleOptionActive: boolean;
  readonly isCardDoubleOptionActive: boolean;
  readonly isCardHeaderOptionActive: boolean;
  readonly isAutoplayedCard: boolean;
}
interface IProps {
  driver: Driver;
}

export default observer(function (props: IProps) {
  const { t } = useTranslation("ContextMenu");

  return (
    <>
      <sc.ResizeText data-cy="CardMenuItem-CardSize">
        {t("context-menu.card-menu.card-size")}
      </sc.ResizeText>
      <sc.ResizeLayout>
        <sc.ResizeOption
          data-cy="CardSimpleContext-MenuContext-Pin-Single"
          onClick={(e) => {
            e.stopPropagation();
            !props.driver.isCardSingleOptionActive &&
              props.driver.onResizeCardSingle();
          }}
        >
          <sc.ResizeBorder
            className={props.driver.isCardSingleOptionActive ? "active" : ""}
          >
            <sc.ResizeIcon data-cy="CardMenuItem-SizeSingle">
              <SingleCard fill={etRegentGray} />
            </sc.ResizeIcon>
          </sc.ResizeBorder>
          <sc.ContextMenuText data-cy="CardMenuItem-SizeSingle-Text">
            {t("context-menu.card-menu.single")}
          </sc.ContextMenuText>
        </sc.ResizeOption>

        <sc.ResizeOption
          onClick={(e) => {
            e.stopPropagation();
            !props.driver.isCardDoubleOptionActive &&
              props.driver.onResizeCardDouble();
          }}
        >
          <sc.ResizeBorder
            className={props.driver.isCardDoubleOptionActive ? "active" : ""}
          >
            <sc.ResizeIcon data-cy="CardMenuItem-SizeDouble">
              <DoubleCard fill={etRegentGray} />
            </sc.ResizeIcon>
          </sc.ResizeBorder>
          <sc.ContextMenuText data-cy="CardMenuItem-SizeDouble-Text">
            {t("context-menu.card-menu.double")}
          </sc.ContextMenuText>
        </sc.ResizeOption>

        <sc.ResizeOption
          data-cy="CardSimpleContext-MenuContext-Pin-Header"
          disabled={props.driver.isAutoplayedCard}
          onClick={(e) => {
            e.stopPropagation();
            !props.driver.isCardHeaderOptionActive &&
              props.driver.onPinFeaturedCard();
          }}
        >
          <sc.ResizeBorder
            className={props.driver.isCardHeaderOptionActive ? "active" : ""}
          >
            <sc.ResizeIcon data-cy="CardMenuItem-SizeHeader">
              <HeaderCard fill={etRegentGray} />
            </sc.ResizeIcon>
          </sc.ResizeBorder>
          <sc.ContextMenuText data-cy="CardMenuItem-SizeHeader-Text">
            {t("context-menu.card-menu.header")}
          </sc.ContextMenuText>
        </sc.ResizeOption>
      </sc.ResizeLayout>
    </>
  );
});
