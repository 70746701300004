import styled from "styled-components";

import {
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  mobileDeviceWidth,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import { etGray, etWhite } from "../../shared/colors";
import {
  getBGImgCardIntro,
  getBGImgCardIntro1col,
  getBGImgCardIntro2col,
  getBGImgCardIntro3col,
} from "../../shared/helpers/getItemSystemPage";

export namespace sc {
  export const Container = styled.div<{
    systemPage: string;
    textColor: string;
  }>`
    position: relative;
    height: 496px;
    max-height: 496px;
    width: calc(328px * 4 + 24px * 3);

    border-radius: 8px;

    overflow: hidden;

    color: ${(props) => props.textColor};
    background-color: ${etGray};
    ${(props) =>
      `background: url(${getBGImgCardIntro(
        props.systemPage,
        props.theme.isWebpSupported,
      )}) center center no-repeat;`}
    background-size: cover;

    margin-top: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 88px 0 90px 168px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      width: calc(328px * 3 + 24px * 2);
      ${(props) =>
        `background: url(${getBGImgCardIntro3col(
          props.systemPage,
          props.theme.isWebpSupported,
        )}) center center no-repeat;`}
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      width: calc(328px * 2 + 24px * 1);
      padding: unset;
      ${(props) =>
        `background: url(${getBGImgCardIntro2col(
          props.systemPage,
          props.theme.isWebpSupported,
        )}) center center no-repeat;`}
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 328px;
      max-width: 328px;

      ${(props) =>
        `background: url(${getBGImgCardIntro1col(
          props.systemPage,
          props.theme.isWebpSupported,
        )}) center center no-repeat;`}
    }

    @media (max-width: ${mobileDeviceWidth}) {
      margin-top: 12px;
    }
  `;
  export const WrapperContent = styled.div`
    width: 100%;
    max-width: 100%;
    height: 496px;
    max-height: 496px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${largeScreenLowerLimit}) {
      flex-direction: column;
      padding: 51px 0px 48px 0px;
    }
  `;
  export const Content = styled.div`
    font-family: Roboto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin-top: 68px;

    width: 100%;
    max-width: 100%;

    @media (max-width: 1700px) {
      margin-top: 0px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      margin-top: 21px;
      align-items: center;
    }
  `;

  export const Title = styled.div`
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 65px;

    margin-bottom: 29px;

    height: 130px;
    max-height: 130px;

    & > span:first-child {
      font-weight: bold;
      display: block;
      text-transform: capitalize;
    }

    @media (max-width: ${xLargeScreenLowerLimit}) {
      margin-bottom: 31px;
      font-size: 50px;
      line-height: 55px;

      height: 110px;
      max-height: 110px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 23px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      font-size: 30px;
      line-height: 35px;
      height: 70px;
      max-height: 70px;
    }
  `;
  export const Subtitle = styled.div<{
    isDeckNature: boolean;
  }>`
    max-height: 100px;
    height: 100px;
    width: 630px;
    max-width: 668px;

    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 32px;

    margin-bottom: 30px;
    padding-right: ${(props) => (props.isDeckNature ? "120px" : "0px")};

    @media (max-width: ${xLargeScreenLowerLimit}) {
      margin-bottom: 30px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      line-height: 30px;
      text-align: center;

      height: 120px;
      max-height: 120px;
      width: 612px;
      max-width: 612px;

      padding: unset;
      margin-bottom: 0;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      font-size: 16px;
      line-height: 22px;
      height: 132px;
      max-height: 132px;
      width: 328px;
      max-width: 328px;

      padding: 0 12px;
    }
  `;
  export const LearnMoreButton = styled.a`
    background-color: ${etWhite};
    border-radius: 20px;
    border: 1px solid ${etWhite};

    height: 40px;
    max-height: 40px;
    width: 166px;
    max-width: 166px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      color: ${etGray};
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: condensed;
      text-transform: uppercase;
      text-align: center;
      line-height: 20px;
    }

    &:hover {
      color: ${etGray};
      text-decoration: none;
    }
  `;

  export const WrapperLogo = styled.div`
    min-height: 157.5px;
    max-height: 157.5px;
    min-width: 147px;
    max-width: 147px;
    margin-right: 39px;
    position: relative;
    top: -40px;
    @media (max-width: 1700px) {
      top: -77px;
    }

    @media (max-width: ${xLargeScreenLowerLimit}) {
      height: 157px;
      max-height: 157px;
      width: 136px;
      max-width: 136px;
      margin-right: 26px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      position: initial;
      min-height: 76px;
      max-height: 76px;
      min-width: 76px;
      max-width: 76px;
      margin: unset;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      min-height: 87px;
      max-height: 87px;
      min-width: 75px;
      max-width: 75px;
    }
  `;
  export const WrapperButton = styled.div`
    height: 40px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${largeScreenLowerLimit}) {
      min-width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-top: 26px;
    }
  `;

  export const CloseButton = styled.div<{ color: string }>`
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;
    z-index: 1;

    svg > g > g {
      stroke: ${(props) => props.color};
    }
  `;
}
