import styled from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
} from "../../shared/breakpoints";
import { etLicoriceBlue, etWhite } from "../../shared/colors";

namespace sc {
  export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    max-width: 368px;
    @media (max-width: ${largeScreenUpperLimit}) {
      max-width: 306px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const ToolTipContainer = styled.div<{ isVisualTestRunning?: boolean }>`
    position: ${(props) =>
      props.isVisualTestRunning ? "relative" : "absolute"};
    top: ${(props) => (props.isVisualTestRunning ? "0" : "50px")};
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;

    ${(props) =>
      props.isVisualTestRunning && {
        visibility: "visible",
        opacity: "1",
      }}
  `;

  export const PlayButtonContainer = styled.a<{
    stroke: string;
    hoverColor: string;
    isVisualTestRunning?: boolean;
  }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25px;
    min-width: 25px;

    &:hover {
      ${ToolTipContainer} {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s, visibility 0.5s;
        border-radius: 5px;
        box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 25%);
      }
    }

    // For visual testing
    ${(props) =>
      props.isVisualTestRunning
        ? `${ToolTipContainer} {
      visibility: visible;
      opacity: 1;
      border-radius: 5px;
      box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 25%);
    }`
        : undefined}

    > div > svg > g > g {
      stroke: ${(props) => props.stroke};
    }

    &.active > div > svg > g > g {
      stroke: ${(props) => props.hoverColor};
      fill: ${(props) => props.hoverColor};
    }

    &:hover > div > svg > g > g {
      stroke: ${(props) => props.hoverColor};
      fill: ${(props) => props.hoverColor};
    }
  `;

  export const ChannelContent = styled.p`
    position: relative;
    display: inline-block;
    padding: 12px;
    margin-bottom: unset;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${etWhite};
    background-color: ${etLicoriceBlue};
    border-radius: 5px;

    white-space: nowrap;

    :before {
      content: "";
      position: absolute;
      margin: auto;
      height: 0px;
      width: 0px;
      top: -15px;
      left: 0px;
      right: 0;

      border-width: 11px;
      border-color: transparent transparent ${etLicoriceBlue} transparent;
      border-style: solid;
    }
  `;
}

export default sc;
