import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

export interface LayoutMobileMenuDriver {
  shouldRenderNewMobileMenu: boolean;
}
export interface LayoutMobileMenuProps {
  driver: LayoutMobileMenuDriver;
  renderMobileMenu: () => ReactNode;
  renderCardMenu: () => ReactNode;
}

export const LayoutMobileMenu = observer(function LayoutMobileMenu(
  props: LayoutMobileMenuProps,
) {
  const { shouldRenderNewMobileMenu } = props.driver;

  return (
    <>
      {shouldRenderNewMobileMenu
        ? props.renderMobileMenu()
        : props.renderCardMenu()}
    </>
  );
});
