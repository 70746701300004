/* eslint-disable import/no-default-export */
import { observer } from "mobx-react-lite";
import React from "react";

import { ModalType } from "../../stores/ModalStore";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";
import { ModalSendEmailLayout } from "./ModalSendEmailLayout";

type IProps = {
  openModal(modalTypes: ModalType): void;
};

export default observer(function ModalSendEmail(props: IProps) {
  const { openModal } = props;

  return (
    <ModalSendEmailLayout
      driver={{ openModal }}
      renderModalWrapper={(children) => (
        <ModalWrapper modalTypes="sendEmail">{children}</ModalWrapper>
      )}
    />
  );
});
