import { observer } from "mobx-react-lite";
import React from "react";

import { etRegentGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import scUpdate from "./CardSimpleContext_Update.styled";

interface IProps {
  onShare(): void;
  isCardDeleteOptionVisible?: boolean;
}

export const ContextMenuCardShare = observer(function ContextMenuCardShare(
  props: IProps,
) {
  const { t } = useTranslation("ContextMenu");

  return (
    <scUpdate.ContextMenuRow
      data-cy="CardMenuItem-ShareCard"
      onClick={(e) => {
        e.stopPropagation();
        props.onShare();
      }}
    >
      <IconSvg
        icon="share"
        color={etRegentGray}
        pointer
        width={props.isCardDeleteOptionVisible ? "15px" : "16px"}
        height={props.isCardDeleteOptionVisible ? "15px" : "16px"}
        padding="0"
      />
      <scUpdate.ContextMenuItemBlue>
        {t("context-menu.card-menu.buttons.share")}
      </scUpdate.ContextMenuItemBlue>
    </scUpdate.ContextMenuRow>
  );
});
