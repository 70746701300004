import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etGray,
  etGrayBold,
  etScarletRed,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const Container = styled.div<{ mLandscape?: boolean }>`
    position: relative;
    display: none;
    overflow-y: hidden;
    height: 64px;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: ${zIndex.NAVBAR};
    }
    :before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      height: 64px;
      width: calc(50% - 40px);
      z-index: -1;
      background-color: ${(props) => (props.mLandscape ? etBlack : etWhite)};
      @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
        background-color: ${(props) =>
          props.theme.isMobileDevice &&
          props.theme.themeStyle.defaultBackground};
      }
      // to detect mobile landscape
      @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
        background-color: ${etBlack};
      }
      /* ${(props) => props.mLandscape && `background-color: ${etBlack};`} */
    }
    :after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      background-color: ${etWhite};
      height: 64px;
      width: calc(50% - 40px);
      z-index: -1;
      @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
        background-color: ${(props) =>
          props.theme.isMobileDevice &&
          props.theme.themeStyle.defaultBackground};
      }
      // to detect mobile landscape
      @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
        background-color: ${etBlack};
      }
      ${(props) => props.mLandscape && `background-color: ${etBlack};`}
    }
  `;

  export const CircleCenter = styled.div`
    position: absolute;
    top: calc(-64px - 56px);
    left: 50%;
    transform: translateX(-50%);
    width: calc(80px + 64px * 2);
    height: calc(80px + 64px * 2);
    border-radius: 50%;
    border: 64px solid ${etWhite};
    background-color: transparent;
    z-index: -1;
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      border: 64px solid
        ${(props) =>
          props.theme.isMobileDevice
            ? props.theme.themeStyle.defaultBackground
            : etWhite};
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      border: 64px solid ${etBlack};
    }
    &.mLandscape {
      border: 64px solid ${etBlack};
    }
  `;

  export const NavbarTopContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `;

  export const Navbar = styled.nav`
    padding: 0 calc((50% - 40px) / 2);
    display: flex;
    width: 100%;
  `;

  export const Hamburger = styled.div<{
    rotate: string;
    isActive: boolean;
    name: "menu" | "play";
  }>`
    display: flex;
    align-items: center;
    user-select: none;
    height: 16px;
    width: 21px;
    border: solid ${etGray};
    border-width: 2px 0;
    cursor: pointer;
    > div {
      width: 100%;
      height: 0;
      border-top: 2px solid ${etGray};
    }
    transform: rotate(${(props) => props.rotate});
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      border-color: ${(props) =>
        props.isActive
          ? props.name === "menu"
            ? etScarletRed
            : etBrightLightBlue
          : etGrayBold};
      > div {
        border-top: 2px solid
          ${(props) =>
            props.isActive
              ? props.name === "menu"
                ? etScarletRed
                : etBrightLightBlue
              : etGrayBold};
      }
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      border-color: ${(props) =>
        props.isActive
          ? props.name === "menu"
            ? etScarletRed
            : etBrightLightBlue
          : props.theme.themeStyle.theNavbarBottomColor};
      > div {
        border-color: ${(props) =>
          props.isActive
            ? props.name === "menu"
              ? etScarletRed
              : etBrightLightBlue
            : props.theme.themeStyle.theNavbarBottomColor};
      }
      > div {
        border-top: 2px solid
          ${(props) =>
            props.isActive
              ? props.name === "menu"
                ? etScarletRed
                : etBrightLightBlue
              : props.theme.themeStyle.theNavbarBottomColor};
      }
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      border-color: ${(props) =>
        props.isActive
          ? props.name === "menu"
            ? etScarletRed
            : etBrightLightBlue
          : etSolitudeLight};
      > div {
        border-color: ${(props) =>
          props.isActive
            ? props.name === "menu"
              ? etScarletRed
              : etBrightLightBlue
            : etSolitudeLight};
      }
      > div {
        border-top: 2px solid
          ${(props) =>
            props.isActive
              ? props.name === "menu"
                ? etScarletRed
                : etBrightLightBlue
              : etSolitudeLight};
      }
    }
    &.mLandscape {
      border-color: ${(props) =>
        props.isActive
          ? props.name === "menu"
            ? etScarletRed
            : etBrightLightBlue
          : etSolitudeLight};
      > div {
        border-color: ${(props) =>
          props.isActive
            ? props.name === "menu"
              ? etScarletRed
              : etBrightLightBlue
            : etSolitudeLight};
      }
      > div {
        border-top: 2px solid
          ${(props) =>
            props.isActive
              ? props.name === "menu"
                ? etScarletRed
                : etBrightLightBlue
              : etSolitudeLight};
      }
    }
  `;

  export const ButtonText = styled.div<{
    isActive: boolean;
    name: "menu" | "play";
  }>`
    color: ${etGrayBold};
    font-family: "Roboto Condensed";
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 4px;
    @media screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      color: ${(props) =>
        props.isActive
          ? props.name === "menu"
            ? etScarletRed
            : etBrightLightBlue
          : etGrayBold};
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) =>
        props.isActive
          ? props.name === "menu"
            ? etScarletRed
            : etBrightLightBlue
          : props.theme.themeStyle.theNavbarBottomColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${(props) =>
        props.isActive
          ? props.name === "menu"
            ? etScarletRed
            : etBrightLightBlue
          : etSolitudeLight};
    }
  `;

  export const ButtonContainer = styled.div<{ name: "menu" | "play" }>`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      :hover {
        ${ButtonText} {
          color: ${(props) =>
            props.name === "menu" ? etScarletRed : etBrightLightBlue};
        }
        ${Hamburger} {
          border-color: ${(props) =>
            props.name === "menu" ? etScarletRed : etBrightLightBlue};
          > div {
            border-top: 2px solid
              ${(props) =>
                props.name === "menu" ? etScarletRed : etBrightLightBlue};
          }
        }
      }
    }
  `;
}

export default sc;
