import { useTranslation } from "../../shared/translate/NextI18next";
import { Currency } from "../../stores/DonateStore/DonateStore";
import { IconSvg } from "../IconSvg/IconSvg";
import { InfoBubble } from "../InfoBubble/InfoBubble";
import sc from "./ModalReceipt.style";

export interface ModalReceiptDriver {
  natureProtectionNumber: string;
  operationalCost: string;
  vat: string;
  totalPaid: string;
  currency: Currency;
}

interface IProps {
  driver: ModalReceiptDriver;
}

export const ModalReceipt = (props: IProps) => {
  const { t } = useTranslation("ModalReceipt");
  const { totalPaid, natureProtectionNumber, operationalCost, vat } =
    props.driver;

  return (
    <sc.Container>
      <sc.ContentContainer className="visual-reg">
        <sc.IconWrapper>
          <IconSvg
            icon="m2"
            padding="0"
            margin="0"
            width="60px"
            height="60px"
            dataCy="ModalReceipt-IconM2"
          />
        </sc.IconWrapper>
        <sc.Title data-cy="ModalReceipt-Title">
          {t("modal-receipt.title")}
          &nbsp;
          <InfoBubble driver={props.driver} />
        </sc.Title>
        <sc.PaymentDetailWrapper>
          <sc.PaymentTable data-cy="ModalReceipt-PaymentDetail">
            <sc.TBody>
              <sc.Tr className="bordered">
                <sc.ThLeft
                  data-cy="ModalReceipt-Description-Label"
                  className="grayText"
                >
                  {t("modal-receipt.desc")}
                </sc.ThLeft>
                <sc.ThRight
                  data-cy="ModalReceipt-Price-Label"
                  className="grayText"
                >
                  {t("modal-receipt.price")}
                </sc.ThRight>
              </sc.Tr>
              <sc.Tr>
                <sc.ThLeft
                  data-cy="ModalReceipt-Uon-Label"
                  className="textBold"
                >
                  {t("modal-receipt.1-uon")}
                </sc.ThLeft>
              </sc.Tr>
              <sc.Tr>
                <sc.ThLeft data-cy="ModalReceipt-Nature-Protection-Label">
                  {t("modal-receipt.nature-protection")}
                </sc.ThLeft>
                <sc.ThRight data-cy="ModalReceipt-Nature-Protection-Number">
                  €{natureProtectionNumber}
                </sc.ThRight>
              </sc.Tr>
              <sc.Tr>
                <sc.ThLeft data-cy="ModalReceipt-Operational-Label">
                  {t("modal-receipt.operational-costs")}
                </sc.ThLeft>
                <sc.ThRight data-cy="ModalReceipt-Operational-Codes-Number">
                  €{operationalCost}
                </sc.ThRight>
              </sc.Tr>
              <sc.Tr className="bordered">
                <sc.ThLeft data-cy="ModalReceipt-VAT-Label">VAT</sc.ThLeft>
                <sc.ThRight data-cy="ModalReceipt-VAT-Number">
                  €{vat}
                </sc.ThRight>
              </sc.Tr>
              <sc.Tr>
                <sc.ThLeft
                  data-cy="ModalReceipt-Total-Label"
                  className="textBold"
                >
                  {t("modal-receipt.total-paid")}
                </sc.ThLeft>
                <sc.ThRight
                  data-cy="ModalReceipt-Total-Number"
                  className="textBold"
                >
                  €{totalPaid}
                </sc.ThRight>
              </sc.Tr>
            </sc.TBody>
          </sc.PaymentTable>
        </sc.PaymentDetailWrapper>
        <sc.Describe data-cy="ModalReceipt-Describe">
          <sc.GreenText>{t("modal-receipt.green-txt")}</sc.GreenText>
          <sc.GrayText>{t("modal-receipt.gray-txt-1")}</sc.GrayText>
          <sc.GrayText>{t("modal-receipt.gray-txt-2")}</sc.GrayText>
          <sc.GrayText>{t("modal-receipt.gray-txt-3")}</sc.GrayText>
        </sc.Describe>
      </sc.ContentContainer>
    </sc.Container>
  );
};
