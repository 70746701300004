import styled from "styled-components";

import {
  etBlueFaded,
  etBrightLightBlue,
  etGray,
  etIceBlue,
  etShadowDarkBlueFaded,
} from "../../shared/colors";

export namespace sc {
  const pTag = styled.p`
    margin: 0;
    padding: 0;
    color: ${etGray};
  `;
  export const CardSizeContainer = styled.div`
    width: 100%;
  `;
  export const BlockTitle = styled.h4`
    margin-bottom: 7px;
    color: ${etGray};
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    height: 14px;
    letter-spacing: 0;
  `;
  export const CardSizeItems = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  `;
  export const CardSizeItem = styled.div<{ disabled: boolean }>`
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  `;
  export const IconBorder = styled.div<{
    disabled: boolean;
    shouldChangeIconColorWhenHover?: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    padding: 0;
    border: 1px solid ${etIceBlue};
    border-radius: 4px;
    box-shadow: none;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    & > div {
      cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    }

    ${(props) =>
      !props.disabled &&
      `
      &:hover {
      background-color: ${etBlueFaded};
      border-color: ${etBrightLightBlue};
      box-shadow: 0 4px 16px 0 ${etShadowDarkBlueFaded};

      svg {
        fill: ${etBrightLightBlue};
        ${
          !!props.shouldChangeIconColorWhenHover &&
          `path {
          fill: ${etBrightLightBlue};
        }`
        }
      }
    }
    `}
    &.active {
      background-color: ${etBlueFaded};
      border-color: ${etBrightLightBlue};
      box-shadow: 0 4px 16px 0 ${etShadowDarkBlueFaded};

      svg {
        fill: ${etBrightLightBlue};
        ${(props) =>
          !!props.shouldChangeIconColorWhenHover &&
          `path {
          fill: ${etBrightLightBlue};
        }`}
      }
    }
  `;
  export const CardSizeItemText = styled(pTag)`
    margin-top: 2px;
    width: 100%;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 10px;
    font-weight: normal;
    height: 11px;
    letter-spacing: 0;
    text-transform: uppercase;
  `;
}
