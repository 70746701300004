import { observer } from "mobx-react-lite";
import React from "react";

import { useRootStore } from "../../stores/rootStore";

export const ModalsRenderer = observer(function ModalsRenderer(props: {}) {
  const { modalStore } = useRootStore();

  return (
    <>
      {modalStore.lazyModals.map((modal) => (
        <React.Fragment key={modal.name}>{modal.component}</React.Fragment>
      ))}
    </>
  );
});
