import { SearchResponseDto } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";
import Highlighter from "react-highlight-words";

import { etBrightLightBlue } from "../../shared/colors";
import { buildResizedAwsImageRequest } from "../../shared/helpers/buildAwsImageRelated";
import sc from "./TheNavbarSearchResultItem.styled";

interface IProps {
  readonly searchInputValue: string;
  readonly item: SearchResponseDto;
  mLandscape?: boolean; // to customize snapshot in visual test

  searchResultFocusUpdate(b: boolean): void;
  searchInputFocusUpdate(b: boolean): void;
  turnMenuOff(): void;
  searchInputValueUpdate(s: string): void;
}

export const TheNavbarSearchResultItem = observer(
  function TheNavbarSearchResultItem(props: IProps) {
    return (
      <>
        {props.item.type === "deck" ? (
          <Link
            href="/[vanityName]/[deckName]"
            as={`/${props.item.path.join("/")}`}
            passHref
            legacyBehavior
          >
            <sc.Container
              className={props.mLandscape ? "mLandscape" : ""}
              onClick={() => {
                props.searchResultFocusUpdate(false);
                props.searchInputFocusUpdate(false);
                props.turnMenuOff();
                props.searchInputValueUpdate("");
              }}
            >
              {props.item.properties.image && (
                <sc.PreviewDeckImg
                  data-cy="SearchResultItem-PreviewDeckImg"
                  src={buildResizedAwsImageRequest(
                    props.item.properties.image,
                    {
                      width: 328,
                      height: 328,
                    },
                  )}
                />
              )}
              <sc.PreviewBody>
                <sc.Title
                  data-cy="SearchResultItem-DeckTitle"
                  className={props.mLandscape ? "mLandscape" : ""}
                >
                  <Highlighter
                    textToHighlight={props.item.properties.name}
                    autoEscape
                    searchWords={[props.searchInputValue]}
                  />
                </sc.Title>
                <sc.Description
                  data-cy="SearchResultItem-DeckDescription"
                  className={props.mLandscape ? "mLandscape" : ""}
                >
                  {props.item.properties.description}
                </sc.Description>
                <sc.CardCount data-cy="SearchResultItem-CardCount">
                  {props.item.properties.cards}
                  {props.item.properties.cards === 1 ? " Card" : " Cards"}
                </sc.CardCount>
              </sc.PreviewBody>
            </sc.Container>
          </Link>
        ) : (
          <Link
            href="/[vanityName]"
            as={`/${props.item.path.join("/")}`}
            passHref
            legacyBehavior
          >
            <sc.Container
              className={props.mLandscape ? "mLandscape" : ""}
              onClick={() => {
                props.searchResultFocusUpdate(false);
                props.searchInputFocusUpdate(false);
                props.turnMenuOff();
                props.searchInputValueUpdate("");
              }}
            >
              {props.item.properties.image && (
                <sc.PreviewUserImg
                  data-cy="SearchResultItem-PreviewUserImg"
                  src={buildResizedAwsImageRequest(
                    props.item.properties.image,
                    {
                      width: 328,
                      height: 328,
                    },
                  )}
                />
              )}
              <sc.PreviewBody>
                <sc.Title
                  data-cy="SearchResultItem-UserTitle"
                  className={props.mLandscape ? "mLandscape" : ""}
                >
                  <Highlighter
                    textToHighlight={props.item.properties.fullname}
                    autoEscape
                    searchWords={[props.searchInputValue]}
                    highlightStyle={{ color: etBrightLightBlue }}
                  />
                </sc.Title>
                <sc.Description
                  data-cy="SearchResultItem-UserDescription"
                  className={props.mLandscape ? "mLandscape" : ""}
                >
                  {props.item.properties.decks}{" "}
                  {props.item.properties.decks === 1 ? " Deck " : " Decks "}
                  with a total of
                  {` ${props.item.properties.followers}`}
                  {props.item.properties.followers === 1
                    ? " Follower"
                    : " Followers"}
                </sc.Description>
              </sc.PreviewBody>
            </sc.Container>
          </Link>
        )}
      </>
    );
  },
);
