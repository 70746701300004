import styled from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  smallScreenLowerLimit,
  smallScreenUpperLimit,
  xmediumScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBrightLightBlue,
  etLicoriceBlue,
  etNevadaGrey,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";

namespace sc {
  export const Wrapper = styled.div`
    display: flex;
    height: 700px;
    padding: 100px 0;
    background-color: ${etSolitudeLight};
    @media (max-width: ${mediumScreenLowerLimit}) {
      padding: 95px 0 90px;
    }
  `;

  export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 100%;

    label {
      color: ${etLicoriceBlue};
      text-align: center;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      padding: 0 15px;
    }
  `;

  export const Content = styled.p`
    margin: 0;
    margin-top: 34px;
    color: ${etNevadaGrey};
  `;

  export const PartnerLogo = styled.div<{ src: string }>`
    width: 152px;
    height: 82px;
    background: url(${(props) => props.src}) center center no-repeat;
    background-size: contain;

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-left: 0;
    }
  `;

  export const PartnersWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-height: 82px;
    max-height: 82px;
    margin-bottom: 66px;
  `;

  export const Button = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
  `;

  export const ButtonLinkPrimary = styled.a`
    font-family: Roboto;
    font-stretch: condensed;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    line-height: normal;
    text-align: center;

    border-radius: 23px;
    min-width: 210px;
    max-width: 210px;
    height: 40px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${etBrightLightBlue};
    color: ${etWhite};

    &:hover {
      text-decoration: none;
      color: ${etWhite};
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      min-width: 180px;
      max-width: 180px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-width: 210px;
      height: 40px;
    }
  `;

  export const Title = styled.label`
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-size: 60px;
    line-height: 1;
    letter-spacing: -0.5px;
    margin-bottom: 10px;
    text-align: left;
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 45px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      line-height: 50px;
      letter-spacing: -1px;
      width: 100%;
      height: 150px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      height: fit-content;
      margin-bottom: 12px;
    }
  `;

  export const Slider = styled.div`
    min-width: 890px;
    max-width: 890px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${largeScreenUpperLimit}) {
      max-width: 538px;
      min-width: 538px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      max-width: 360px;
      min-width: 360px;
    }

    @media (max-width: ${"414px"}) {
      max-width: 180px;
      min-width: 180px;
    }
  `;
  export const ItemLogo = styled.div`
    padding: 0 12px;
  `;
  export const Arrow = styled.div`
    width: 32px;
    height: 100px;
    position: absolute;
    top: -10px;
    bottom: 0;
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: ${smallScreenLowerLimit}) {
      top: 10px;
      img {
        width: 50%;
        height: 50%;
      }
    }
  `;

  export const Left = styled(Arrow)`
    left: -86px;

    @media (max-width: ${smallScreenUpperLimit}) {
      left: -56px;
    }
    @media (max-width: ${smallScreenLowerLimit}) {
      left: -39px;
    }
  `;

  export const Right = styled(Arrow)`
    right: -86px;

    @media (max-width: ${smallScreenUpperLimit}) {
      right: -56px;
    }
  `;
}

export default sc;
