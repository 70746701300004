import { observer } from "mobx-react-lite";
import React from "react";

import { ModalType } from "../../stores/ModalStore";
import { LayoutErrorBadRequest } from "./LayoutErrorBadRequest";
import { LayoutErrorDefault } from "./LayoutErrorDefault";
import { LayoutErrorInternalServer } from "./LayoutErrorInternalServer";
import { LayoutErrorNotFound } from "./LayoutErrorNotFound";
import { LayoutErrorUnauthorized } from "./LayoutErrorUnauthorized";

export interface ErrorLayoutDriver {
  openModal(modalType: ModalType): void;
}

export interface MobileMenuDriver {
  readonly shouldFixViewport: boolean;
}

export interface OverlayDriver {
  readonly isOverlayActive: boolean;
}
interface IProps {
  driver: ErrorLayoutDriver;
  overlayDriver: OverlayDriver;
  mobileMenuDriver: MobileMenuDriver;
  statusCode: number | null;
}

export const LayoutError = observer((props: IProps) => {
  if (!props.statusCode || props.mobileMenuDriver.shouldFixViewport)
    return <></>;

  switch (props.statusCode) {
    case 400: {
      return <LayoutErrorBadRequest overlayDriver={props.overlayDriver} />;
    }
    case 401: {
      return (
        <LayoutErrorUnauthorized
          driver={props.driver}
          overlayDriver={props.overlayDriver}
        />
      );
    }
    case 404: {
      return <LayoutErrorNotFound overlayDriver={props.overlayDriver} />;
    }
    case 500: {
      return <LayoutErrorInternalServer overlayDriver={props.overlayDriver} />;
    }
    default: {
      return <LayoutErrorDefault overlayDriver={props.overlayDriver} />;
    }
  }
});
