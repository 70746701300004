import { observer } from "mobx-react-lite";
import Slider from "react-slick";

import { CardDeckOverviewDriver } from "./CardDeckOverview";
import sc from "./CardDeckOverview.styled";

type IProps = {
  driver: CardDeckOverviewDriver;
  autoplaySlider?: boolean;
};
export const CardDeckOverviewBackgroundImage = observer((props: IProps) => {
  const { driver, autoplaySlider } = props;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoplaySlider !== false,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    fade: true,
    arrows: false,
  };

  if (!driver.isBackgroundSlider) {
    return (
      <sc.PreviewImageContainer
        deckSize={props.driver.renderSize}
        data-testid="CardDeckOverview-BackgroundImage"
        data-cy="CardDeckOverview-BackgroundImage"
      >
        <sc.PreviewImage
          deckSize={props.driver.renderSize}
          key={driver.deckCardPreviewImages[0].url}
          previewImage={driver.deckCardPreviewImages[0].url}
        />
      </sc.PreviewImageContainer>
    );
  }

  return (
    <Slider
      {...settings}
      data-testid="CardDeckOverview-SliderImages"
      data-cy="CardDeckOverview-SliderImages"
    >
      {driver.deckCardPreviewImages.map((previewImage) => {
        return previewImage.isProfileImage ? (
          <sc.PreviewImageProfileRepost
            renderSize={props.driver.renderSize}
            previewImage={previewImage.url}
          />
        ) : (
          <sc.PreviewImage
            data-cy="CardDeckOverviews-PreviewImage"
            deckSize={props.driver.renderSize}
            key={previewImage.url}
            previewImage={previewImage.url}
          />
        );
      })}
    </Slider>
  );
});
