import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { isBrowser } from "../../shared/helpers/isBrowser";
import { smoothVerticalScrolling } from "../../shared/helpers/smoothScrollSafari";
import { LeaderboardModel } from "../../stores/LeaderBoardModel";
import { ProtectStore } from "../../stores/ProtectStore";
import { RootStore } from "../../stores/rootStore";
import { SectionSevenStore } from "../../stores/SectionSevenStore";
import { LayoutProtectV202208 } from "../LayoutProtect/LayoutProtectV202208";
import { PageLoader } from "../PageLoader/PageLoader";
import { LayoutAbout } from "./LayoutAbout";
import sc from "./LayoutAbout.styled";

interface IProps {
  cookiePolicyRef: React.RefObject<HTMLDivElement>;
  driver: RootStore;
}

export const About = observer((props: IProps) => {
  const { mobileMenuStore } = props.driver;
  const [store] = useState(
    () =>
      new ProtectStore(
        props.driver.reserveApi,
        props.driver.followingApi,
        props.driver.protectPageApi,
        props.driver.deckDetailApi,
        props.driver.modalStore,
        props.driver.modalDeleteDeckStore,
        props.driver.autoplayDeckStore,
        props.driver.theMessageStore,
        props.driver.userSessionStore,
        props.driver.donateStore,
        props.driver.featureFlaggingStore,
        props.driver.previousRoute,
      ),
  );

  const leaderboard = new LeaderboardModel(props.driver.modalStore, store);
  const [sectionSevenStore] = useState(() => new SectionSevenStore());

  useEffect(() => {
    (async () => {
      if (isBrowser() && props.driver.overlayAboutStore.isOverlayActive) {
        await Promise.all([
          store.fetchPageDataServer(),
          store.fetchPageDataBrowser(),
        ]);
      }
    })();
  }, [props.driver.overlayAboutStore.isOverlayActive]);

  useEffect(() => {
    (async () => {
      const shouldRefreshWithToken =
        props.driver.overlayAboutStore.isOverlayActive && store.userId;
      if (shouldRefreshWithToken) {
        await store.fetchAllReserves();
      }
    })();
  }, [store.userId]);

  useEffect(() => {
    if (!props.driver.overlayAboutStore.isOverlayActive) {
      return;
    }
    // only run on browser
    store.subscribe();
    document.querySelectorAll("html")[0].style.scrollBehavior = "smooth";

    // safari, ios device not support scroll-behavior https://caniuse.com/?search=scroll-behavior
    if (
      !navigator.userAgent.toLowerCase().includes("chrome") &&
      navigator.userAgent.toLowerCase().indexOf("safari") > 0
    ) {
      const sectionTwoElement = document.querySelector("#sectionTwo");
      const anchors = document.querySelectorAll('a[href="#sectionTwo"]') as any;
      for (const anchor of anchors) {
        anchor.removeAttribute("href");
        anchor.addEventListener("click", () => {
          smoothVerticalScrolling(sectionTwoElement);
        });
      }
    }

    return () => {
      store.unsubscribe();
      document.querySelectorAll("html")[0].style.scrollBehavior = "auto";
    };
  }, [props.driver.overlayAboutStore.isOverlayActive]);

  useEffect(() => {
    const offsetHeight = props.cookiePolicyRef.current?.offsetHeight || 0;
    if (
      store.isPlayingMovie &&
      !props.driver.userSessionStore.hasAcceptedSocialCookies
    ) {
      window.scrollTo({ top: offsetHeight, behavior: "smooth" });
    }
  }, [store.isPlayingMovie]);
  const renderContent = () => {
    if (store.isPageLoading) {
      return (
        <sc.LoaderWrapper>
          <PageLoader />
        </sc.LoaderWrapper>
      );
    }
    return (
      <LayoutProtectV202208
        isOverlayActive={false}
        driver={store}
        leaderboard={leaderboard}
        featureFlaggingDriver={props.driver.featureFlaggingStore.flags}
        deviceDriver={props.driver.deviceStore}
        driverMenu={mobileMenuStore}
        closeOverlay={props.driver.overlayAboutStore.closeOverlay}
        sectionSevenDriver={sectionSevenStore}
      />
    );
  };

  return (
    <LayoutAbout
      driver={props.driver.overlayAboutStore}
      renderContent={renderContent}
    />
  );
});
