/* eslint-disable @typescript-eslint/ban-ts-comment */
import { observer } from "mobx-react-lite";
import dynamic, { LoaderComponent } from "next/dynamic";
import React, { useMemo } from "react";

import { ModalType } from "../../stores/ModalStore";
import { useRootStore } from "../../stores/rootStore";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";
import { TheLoader } from "../TheLoader/TheLoader";

// @ts-ignore
export const DynamicModalLoader = observer(function DynamicModalComponent<P>(
  props: P & {
    modalType: ModalType;
    loadComponent: () => LoaderComponent<P>;
  },
) {
  const store = useRootStore().modalStore;
  const isActive = store.activeModals.includes(props.modalType);
  const DynamicComponent = dynamic(props.loadComponent, {
    loading: () => (
      <ModalWrapper modalTypes={props.modalType}>
        <TheLoader />
      </ModalWrapper>
    ),
  });

  const memoComponent = useMemo(() => {
    return <DynamicComponent {...props} />;
  }, [isActive]);
  return isActive && memoComponent;
});
