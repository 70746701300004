/* eslint-disable unicorn/prefer-logical-operator-over-ternary */
import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mobileMaxHeight,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etGray,
  etGrayBold,
  etGrayDark,
  etLicoriceBlue,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";

namespace sc {
  export const NotificationItem = styled.a<{ isGroupInviteNoti: boolean }>`
    display: flex;
    width: 100%;
    padding: 12px;
    color: ${etGrayDark};
    border-radius: 4px;
    margin-bottom: 12px;
    background-color: ${etWhite};
    :hover {
      color: ${etGrayDark};
      text-decoration: none;
    }
    &.visited {
      border-color: ${etGrayBold}20;
    }
    :last-child {
      margin-bottom: 0;
    }
    border: 1px solid ${etBrightLightBlue};
    width: 327px;
    min-width: 327px;
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) => props.theme.themeStyle.defaultBackground};
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
    }
    &.mLandscape {
      background-color: ${etBlack};
    }
  `;
  export const NotificationItemPreview = styled.div<{ src: string | false }>`
    height: 42px;
    width: 42px;
    min-width: 42px;
    min-height: 42px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(${(props) =>
      props.src
        ? props.src
        : props.theme.isWebpSupported
        ? require("../../assets/img/default.webp")
        : require("../../assets/img/default.png")});
  `;
  export const NotificationItemBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-left: 12px;
    line-height: 20px;
  `;
  export const NotificationItemTitle = styled.div`
    font-size: 12px;
    color: ${etLicoriceBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    max-width: 255px;
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.notificationTitleColor};
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const NotificationItemText = styled.div`
    font-size: 10px;
    color: ${etGray};
  `;
  export const NotificationType = styled.span`
    color: ${etGray};
    font-family: Roboto Condensed;
    font-size: 12px;
    line-height: 13px;
    font-weight: normal;
    letter-spacing: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 255px;
    margin-top: 3px;

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.notificationTitleColor};
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;

  export const EventType = styled.span`
    font-weight: normal;
  `;

  export const ButtonsWrapper = styled.div`
    width: 100%;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
  `;
}

export default sc;
