import sc from "./PlayButtons.styled";

export type IProps = {
  isVisualTestRunning?: boolean;
  toolTipChannel: string;
};

export const ToolTip = (props: IProps) => {
  return (
    <sc.ToolTipContainer
      className="visual-reg"
      isVisualTestRunning={props.isVisualTestRunning}
    >
      <sc.ChannelContent data-cy="PlayButtons-CreationContent">
        {props.toolTipChannel}
      </sc.ChannelContent>
    </sc.ToolTipContainer>
  );
};
