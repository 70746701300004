import styled from "styled-components";

import {
  smallScreenLowerLimit,
  xmediumScreenLowerLimit,
} from "../../shared/breakpoints";
import { etWhite } from "../../shared/colors";

namespace sc {
  export const BackGround = styled.div<{
    isOverlayActive: boolean | undefined;
  }>`
    position: ${(props) => (props.isOverlayActive ? "fixed" : "relative")};
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    width: 100vw;
    min-height: 429px;
    height: calc(100vh - 208px);
    background-image: url(${require("../../assets/img/error-bg-x3.jpg")});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url(${require("../../assets/img/error-bg-x3.jpg")});
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      background-image: url(${require("../../assets/img/error-bg-x2.jpg")});
      background-size: cover;
    }

    @media (max-width: ${smallScreenLowerLimit}) {
      background-image: url(${require("../../assets/img/error-bg-x1.jpg")});
      background-size: cover;
    }
  `;
  export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${etWhite};
  `;
  export const ErrorImg = styled.div`
    min-width: 18.75rem;
    margin-right: 5%;
    min-height: 18.75rem;
    background-image: url(${require("../../assets/img/error-image.png")});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `;
  export const ErrorTitle = styled.div`
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
  `;
  export const ErrorText = styled.div`
    font-size: 1.5rem;
    margin-bottom: 1.1rem;
  `;
  export const ErrorInfo = styled.div`
    padding: 1rem;
  `;
}

export default sc;
