import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import { etRegentGray, etWhite } from "../../shared/colors";
import { DECK_NAME_MAX_LENGTH_SINGLE_SIZE } from "../../shared/constants";
import { getValidHttpUrl } from "../../shared/helpers/getValidHttpUrl";
import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";
import { CardSize, MasterLinkType } from "../../shared/models/Card";
import { CardBaseContentType } from "../../stores/CardBaseModel";
import { Button } from "../Button/Button";
import { ButtonCircle } from "../ButtonCircle/ButtonCircle";
import { CardItemMetaData, ICardItemContent } from "../CardItem/CardItem";
import CardItemLayout from "../CardItemLayout/CardItemLayout";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./CardItemMusic.styled";
import { CardItemMusicFooter } from "./CardItemMusicFooter";
import { CardItemMusicFooterAction } from "./CardItemMusicFooterAction";

export interface CardItemMusicDriver {
  readonly isCardDrafting: false;
  readonly cardType: MasterLinkType.MUSIC;
  readonly contentType: CardBaseContentType;
  readonly id: string;
  readonly metadata: CardItemMetaData | null;
  readonly cardContent: ICardItemContent | null;
  readonly vanityName: string;
  readonly curatorName: string;
  readonly comment: string;
  readonly deckName: string;
  readonly deckPathString: string;
  readonly categoryPrinciple: string;
  readonly updatedDate: string;
  readonly cardMusicPreviewImageBackground: string;
  readonly previewImage: string;
  readonly pathSharing: string;
  readonly shareActive: boolean;
  readonly starActive: boolean;
  readonly hover?: boolean;
  readonly contextMenuActive: boolean;
  readonly categoryColor: string;
  readonly isCardOwner: boolean;

  readonly isCardInFirstPosition?: boolean; // only card on homepage
  readonly isAutoplayedCard?: boolean;

  cardRepostIdUpdate(): void;
  toggleShareActive(): void;
  toggleStarActive(): void;
  openContextMenu(): void;
  closeContextMenu(): void;

  onStarCard(): void;
}

export interface LayoutDriver {
  readonly cardSize: CardSize;
}

interface IProps {
  driver: CardItemMusicDriver;
  layoutDriver: LayoutDriver;
  renderMenuContext(): ReactNode;
  renderSharing(): ReactNode;
  isCurrentUser?: boolean;
}

export const CardItemMusic = observer(function CardItemMusic(props: IProps) {
  const {
    driver,
    layoutDriver,
    renderMenuContext,
    renderSharing,
    isCurrentUser,
  } = props;

  const { cardContent } = driver;
  return (
    <CardItemLayout layoutDriver={layoutDriver}>
      <sc.Container
        data-cy="CardItemMusic-Container"
        bg={driver.cardMusicPreviewImageBackground}
        className={[
          driver.hover ? "hover" : "",
          layoutDriver.cardSize === CardSize.DOUBLE ? "double-size" : "",
        ].join(" ")}
      >
        <sc.ContainerContent isActive={driver.contextMenuActive}>
          {driver.contextMenuActive && <sc.BlackOverlay />}
          <sc.Header>
            {driver.deckPathString ? (
              <Link
                href="/[vanityName]/[deckName]"
                as={`/${driver.deckPathString}`}
                passHref
                legacyBehavior
              >
                <sc.HeaderTitle
                  data-cy="CardItemMusic-HeaderTitle"
                  title={driver.deckName}
                  iconColor={props.driver.categoryColor}
                  data-icon={`cat-${driver.categoryPrinciple}`}
                >
                  <IconSvg
                    data-cy="CardItemMusic-Icon"
                    icon={`cat-${driver.categoryPrinciple}`}
                    width="19px"
                    height="18px"
                    padding="0"
                    color={props.driver.categoryColor}
                  />
                  #
                  {truncateWithEllipsis(
                    driver.deckName,
                    DECK_NAME_MAX_LENGTH_SINGLE_SIZE,
                  )}
                </sc.HeaderTitle>
              </Link>
            ) : (
              <sc.HeaderTitle
                title={driver.deckName}
                iconColor={props.driver.categoryColor}
                data-icon={`cat-${driver.categoryPrinciple}`}
              >
                {truncateWithEllipsis(
                  driver.deckName,
                  DECK_NAME_MAX_LENGTH_SINGLE_SIZE,
                )}
              </sc.HeaderTitle>
            )}
            <sc.ContextMenuContainer>
              {renderMenuContext()}
            </sc.ContextMenuContainer>
          </sc.Header>
          <sc.OverlayLinkContainer data-cy="CardItem-OverlayLinkContainer">
            {driver.isCardInFirstPosition &&
            cardContent &&
            cardContent.content?.source?.url ? (
              <a
                data-cy="CardItemMusic-CardInFirstPosition"
                target="_blank"
                href={getValidHttpUrl(cardContent.content.url)}
              />
            ) : (
              <Link
                href="/[vanityName]/[deckName]/[cardId]"
                as={`/${driver.deckPathString}/${driver.id}`}
                passHref
              ></Link>
            )}
          </sc.OverlayLinkContainer>
          <sc.Wrap>
            {cardContent && cardContent.content?.source?.url ? (
              <sc.CoverContainer>
                <sc.PreviewImage
                  data-cy="CardItemMusic-PreviewImage"
                  src={driver.previewImage}
                >
                  <sc.OverlayCopy
                    data-cy="CardItemMusic-Overlay"
                    className={
                      layoutDriver.cardSize === CardSize.DOUBLE
                        ? "cardDouble-overlay"
                        : ""
                    }
                  />
                  <sc.OverlaySource data-cy="CardItemMusic-OverlaySource" />
                  <sc.CardSource
                    href={getValidHttpUrl(cardContent.content.url)}
                    target="_blank"
                    className={
                      layoutDriver.cardSize === CardSize.DOUBLE
                        ? "cardDouble-source"
                        : ""
                    }
                  >
                    {cardContent.content?.source.name}
                    <sc.ArrowLink>
                      <IconSvg
                        icon="carditem-link-v2"
                        width="11px"
                        height="11px"
                        padding="0"
                        margin="auto 0 auto 6px"
                        color={etWhite}
                        pointer
                      />
                    </sc.ArrowLink>
                  </sc.CardSource>
                </sc.PreviewImage>

                <sc.Body
                  data-cy="CardItemMusic-Body"
                  href={cardContent.content?.source.url}
                  target="_blank"
                >
                  <sc.BodyTitle
                    data-cy="CardItemMusic-BodyTitle"
                    isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
                  >
                    {cardContent.content.title}
                  </sc.BodyTitle>
                  <sc.BodyAuthor data-cy="CardItemMusic-BodyAuthor">
                    {cardContent.content.authors.length > 0 &&
                      cardContent.content.authors[0] &&
                      cardContent.content.authors[0].name}
                  </sc.BodyAuthor>
                </sc.Body>

                <sc.CoverOverlay>
                  <sc.OverlayActions
                    className={
                      layoutDriver.cardSize === CardSize.DOUBLE
                        ? "cardDouble-actions"
                        : ""
                    }
                  >
                    <sc.SaveButtonWrapper
                      isButtonHiden={driver.isCardOwner || !!isCurrentUser}
                    >
                      <Button
                        driver={{
                          dataCy: "CardItemMusic-ButtonSave",
                          type: "primary",
                          fontSize: "14px",
                          fontStretch: "condensed",
                          height: "32px",
                          width: "88px",
                          round: true,
                          onClick: driver.cardRepostIdUpdate,
                        }}
                      >
                        SAVE
                        <IconSvg
                          data-cy="CardMucsic-SaveIcon"
                          icon="save"
                          pointer
                          padding="0"
                          width="12px"
                          height="14px"
                          color={etWhite}
                        />
                      </Button>
                    </sc.SaveButtonWrapper>
                    <ButtonCircle
                      driver={{
                        margin: "0 8px",
                        type: "white",
                        dataCy: "CardItemMusic-ButtonShare",
                        onClick: () => {
                          driver.toggleShareActive();
                          driver.closeContextMenu();
                        },
                      }}
                    >
                      <IconSvg
                        data-cy="CardMusic-ShareIcon"
                        icon="share"
                        pointer
                        padding="0"
                        width="12px"
                        height="14px"
                        color={etRegentGray}
                      />
                    </ButtonCircle>
                  </sc.OverlayActions>
                </sc.CoverOverlay>
              </sc.CoverContainer>
            ) : (
              <sc.RemovedCardBody data-cy="RemovedCardBody">
                <sc.RemovedCardBodyTitle>
                  NO LONGER AVAILABLE
                </sc.RemovedCardBodyTitle>
                <sc.RemovedCardBodyText>
                  This card was deleted by its original curator
                </sc.RemovedCardBodyText>
              </sc.RemovedCardBody>
            )}
          </sc.Wrap>
          <CardItemMusicFooter
            data-cy="CardItemMusic-Footer"
            id={driver.id}
            isDoubleSize={layoutDriver.cardSize === CardSize.DOUBLE}
            comment={driver.comment}
            vanityName={driver.vanityName}
            curatorName={driver.curatorName}
            updatedDate={driver.updatedDate}
            deckPathString={driver.deckPathString}
            renderCardSimpleAction={() => (
              <CardItemMusicFooterAction driver={driver} />
            )}
          />
        </sc.ContainerContent>
        {renderSharing()}
      </sc.Container>
    </CardItemLayout>
  );
});
