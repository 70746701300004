import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { etGray, etGrayDark, etWhite } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./ContextMenu.styled";

export interface ContextMenuDriver {
  readonly active: boolean;
  onIconMoreClicked(): void;
  closeContextMenu(): void;
}

interface IProps {
  driver: ContextMenuDriver;
  renderContextMenuItem(): ReactNode;
}

export const ContextMenu = observer(function (props: IProps) {
  const { driver, renderContextMenuItem } = props;

  return (
    <>
      {driver.active && (
        <sc.Overlay
          onClick={driver.closeContextMenu}
          data-cy="CardSimpleContext-MenuContextLayout"
        />
      )}
      <sc.Container
        data-cy="CardSimpleContext-MenuContext"
        className="visual-reg"
      >
        <sc.ContextMenuContainer data-cy="CardSimpleContext-ContextMenuContainer">
          <IconSvg
            icon="more"
            pointer
            width="18px"
            height="18px"
            padding="0px"
            color={driver.active ? etWhite : etGray}
            hoverColor={driver.active ? etWhite : etGrayDark}
            onClick={driver.onIconMoreClicked}
          />

          <sc.ContextMenu
            display={driver.active ? "flex" : "none"}
            className="visual-reg-context-menu-profile"
          >
            {renderContextMenuItem()}
          </sc.ContextMenu>
        </sc.ContextMenuContainer>
      </sc.Container>
    </>
  );
});
