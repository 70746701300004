import styled from "styled-components";

import { mediumScreenUpperLimit } from "../../shared/breakpoints";
import { etWhite } from "../../shared/colors";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Button = styled.div<{
    height: string;
    width: string;
    backgroundColor: string;
    hoverIconColor: string;
    hoverBackgroundColor: string;
    hoverBorderColor: string;
    borderWidth: string;
    borderColor: string;
    mobileHeight: string;
    mobileWidth: string;
    margin: string;
    disabled?: boolean;
  }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${etWhite};
    border: solid ${(props) => props.borderColor};
    border-width: ${(props) => props.borderWidth};
    border-radius: 50%;
    height: ${(props) => props.height};
    max-height: ${(props) => props.height};
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
    background-color: ${(props) => props.backgroundColor};
    margin: ${(props) => props.margin};
    &:hover {
      border-color: ${(props) => props.hoverBorderColor};
      background-color: ${(props) => props.hoverBackgroundColor};
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
      text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
      ${IconSvgContainer} {
        fill: ${(props) => props.hoverIconColor};
      }
    }

    opacity: ${(props) => (props.disabled ? 0.65 : 1)};
    @media (max-width: ${mediumScreenUpperLimit}) {
      width: ${(props) => props.mobileWidth};
      height: ${(props) => props.mobileHeight};
      max-width: ${(props) => props.mobileWidth};
      max-height: ${(props) => props.mobileHeight};
    }
  `;

  export const ButtonLabel = styled.label<{ labelColor: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 50px;
    width: 120px;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    color: ${(props) => props.labelColor};
    &:hover {
      cursor: pointer;
    }
  `;
}
export default sc;
