import { observer } from "mobx-react-lite";

import scUpdate from "./CardSimpleContext_Update.styled";
import { isValidNumberInput } from "./isValidNumberInput";

interface IContextMenuCardOrderInputProps {
  sortNumber: string;
  onChanged(order: string): void;
  onBlured(): void;
}

export const ContextMenuCardOrderInput = observer(function (
  props: IContextMenuCardOrderInputProps,
) {
  return (
    <scUpdate.ContextMenuSortInput
      data-cy="ContextMenuItem-SortNumberInput"
      type="number"
      role="textbox"
      onBlur={() => props.onBlured()}
      onChange={(e) => props.onChanged(e.target.value)}
      onKeyDown={(e) => isValidNumberInput(e)}
      value={props.sortNumber}
    />
  );
});
