import styled from "styled-components";

import etLogo from "../../assets/svgs/et-com-logo.svg";
import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenLowerLimit,
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenLowerLimit,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etGrayLight,
  etNevadaGrey,
  etPaleSky,
  etSolitudeLight,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";

namespace sc {
  export const Container = styled.div<{
    isOverlayActive: boolean;
    isCardJoiningOpen: boolean;
    isMenuAppOpen: boolean;
  }>`
    position: ${(props) => (props.isOverlayActive ? "fixed" : "static")};
    z-index: 0;
    bottom: 0;

    display: ${(props) =>
      props.isCardJoiningOpen || props.isMenuAppOpen ? "none" : "flex"};
    justify-content: center;

    border-top: 1px solid ${etGrayLight};
    width: 100vw;
    padding-bottom: 33px;
    padding-top: 41px;
    background-color: ${etSolitudeLight};

    @media (max-width: ${mediumScreenUpperLimit}) {
      height: 540px;
      max-height: 540px;
      padding-bottom: 100px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      height: 850px;
      max-height: 850px;
      padding-bottom: 40px;
      padding-top: 34px;
      margin-bottom: 64px;

      bottom: ${(props) => (props.isOverlayActive ? "-65px" : "0")};
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      height: 910px;
      max-height: 910px;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.theFooterBackgroundColor};
      border-color: ${(props) =>
        props.theme.isMobileDevice
          ? transparentize(etSolitudeLight, 0.8)
          : etGrayLight};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
      border-color: ${transparentize(etSolitudeLight, 0.8)};
    }
    &.mLandscape {
      background-color: ${etBlack};
      border-color: ${transparentize(etSolitudeLight, 0.8)};
    }
  `;

  export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${etSolitudeLight};

    width: ${smallScreenLowerLimit};
    @media (max-width: ${mediumScreenLowerLimit}) {
      flex-direction: column;
      width: 100%;
      height: 425px;
      max-height: 425px;
    }
    @media (min-width: ${mediumScreenLowerLimit}) {
      justify-content: initial;
      justify-content: space-between;
      flex-direction: column;

      width: ${mediumScreenLowerLimit};
    }
    @media (min-width: ${largeScreenLowerLimit}) {
      width: ${largeScreenLowerLimit};
    }
    @media (min-width: ${xLargeScreenLowerLimit}) {
      width: ${xLargeScreenLowerLimit};
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.theFooterBackgroundColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
    }
    &.mLandscape {
      background-color: ${etBlack};
    }
  `;

  export const Col = styled.div`
    display: flex;
    justify-content: space-between;
  `;
  export const ColRight = styled(Col)`
    width: 35%;
    @media (max-width: ${largeScreenLowerLimit}) {
      width: 50%;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 100%;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      margin-top: 20px;
    }
  `;
  export const ColLeft = styled(Col)`
    width: 50%;
    @media (max-width: ${largeScreenLowerLimit}) {
      flex-direction: column-reverse;
      align-items: center;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 100%;
    }
  `;
  export const LogoText = styled.div`
    color: ${etNevadaGrey};
    margin-top: 7px;
    font-size: 16px;
    font-family: Roboto;
    line-height: 28px;

    @media (max-width: ${mediumScreenLowerLimit}) {
      text-align: center;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      height: 28px;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const Logo = styled.div`
    height: 40px;
    width: 40px;
    background: url(${etLogo}) center center no-repeat;
    background-size: contain;
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
    }
  `;
  export const LogoContainer = styled.div`
    margin-left: -105px;
    padding-top: 2px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      margin-left: -48px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      min-width: 142.25px;
    }
    @media (max-width: ${largeScreenLowerLimit}) {
      order: 1;
      margin-left: -50px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-left: unset;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      order: 4;
    }
  `;
  export const LogoContainerMobile = styled.div`
    display: none;
    @media (max-width: ${mediumScreenLowerLimit}) {
      text-align: center;
      order: 3;
      margin-top: 100px;

      display: block;
    }
  `;
  export const PoliciesContainer = styled.div`
    margin-left: 325px;
    @media (max-width: ${xLargeScreenLowerLimit}) {
      margin-left: 0;
    }
    @media (max-width: ${largeScreenLowerLimit}) {
      margin-left: -50px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-bottom: 34px;
      margin-left: unset;
    }
  `;
  export const MoreEarthTodayContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -55px;

    > a:nth-last-child(1) {
      padding-bottom: 0px;
    }
    @media (max-width: ${largeScreenLowerLimit}) {
      margin-left: -50px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-bottom: 34px;
      margin-left: unset;
    }
  `;
  export const AboutEarthTodayContainer = styled(MoreEarthTodayContainer)``;
  export const PolicyLink = styled.a`
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    font-family: Roboto;
    line-height: 16px;
    text-decoration: none;
    color: ${etNevadaGrey};
    padding: 6px 0;
    :hover {
      text-decoration: none;
      color: ${etBrightLightBlue};
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      text-align: center;
      padding: 2px 0;
      line-height: 1.75;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;

  export const PolicyLinkBottom = styled(PolicyLink)`
    width: fit-content;
  `;
  export const Title = styled.b`
    font-size: 16px;
    font-family: Roboto;
    line-height: 21px;
    padding-bottom: 6px;
    color: ${etPaleSky};
    @media (max-width: ${smallScreenUpperLimit}) {
      text-align: center;
      line-height: 1.75;
      padding: 0px;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const Policies = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: ${smallScreenUpperLimit}) {
      justify-content: initial;
      order: 1;
    }
  `;
  export const TextField = styled(PolicyLink)``;
  export const ContactLink = styled(PolicyLink)`
    color: ${etNevadaGrey};
    font-size: 16px;
    font-family: Roboto;
    line-height: 1.75;
    :hover {
      color: ${etBrightLightBlue};
    }
  `;

  export const Contact = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 120px;
    flex-direction: column;
    line-height: 1.75;
    margin-left: -90px;

    @media (max-width: ${largeScreenLowerLimit}) {
      min-width: 233px;
      max-width: 233px;
      margin-bottom: -30px;
      margin-left: -50px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-left: unset;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      align-items: center;
      justify-content: center;
      margin-right: 0%;
      height: inherit;
      order: 2;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      height: initial;
      padding-top: 0px;
      margin-bottom: 34px;
    }
  `;

  export const SocialContacts = styled.div`
    padding-top: 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      svg > g > g {
        fill: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
      }
      svg {
        fill: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
      }
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      svg {
        fill: ${etSolitudeLight};
      }
    }
    &.mLandscape {
      svg {
        fill: ${etSolitudeLight};
      }
    }
  `;

  export const SupportCentre = styled.a`
    font-size: 16px;
    color: ${etNevadaGrey};

    :hover {
      color: ${etBrightLightBlue};
      cursor: pointer;
      text-decoration: none;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const FAQ = styled(SupportCentre)`
    padding: 6px 0;
    line-height: 1;
    @media (max-width: ${smallScreenUpperLimit}) {
      text-align: center;
      line-height: 1.75;
    }
  `;

  export const PoliciesContainerBottom = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-left: -70px;

    min-width: 580px;
    max-width: 630px;
    min-height: 28px;
    max-height: 28px;

    color: ${etNevadaGrey};
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    & > span {
      color: ${etNevadaGrey};
      line-height: 10px;
      font-size: 16px;
      margin-right: 7px;
      margin-left: 7px;
      @media (max-width: ${smallScreenUpperLimit}) {
        display: none;
      }
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: fit-content;
      max-height: fit-content;
      min-width: 100%;
      max-width: 100%;
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      margin-left: -23px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-left: -80px;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      margin: 0 auto;
    }
  `;

  export const WrapperFooterTop = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: start;
    border-bottom: 1px solid ${etGrayLight};
    margin-bottom: 30px;
    padding-bottom: 41px;

    @media (max-width: ${largeScreenLowerLimit}) {
      display: grid;
      grid-template-columns: auto auto;
      grid-auto-rows: auto auto;
      row-gap: 34px;
      column-gap: 218px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: unset;
    }
  `;

  export const LogoV2 = styled.a`
    &:hover {
      svg > g > g {
        fill: ${etBrightLightBlue};
      }
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      &:hover {
        svg > g > g {
          fill: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
        }
      }
    }
  `;
}

export default sc;
