import { action, makeObservable, observable } from "mobx";

export class ContextMenuModel {
  @observable active: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound closeContextMenu(): void {
    this.active = false;
  }

  @action.bound openContextMenu(): void {
    this.active = true;
  }

  @action.bound onIconMoreClicked(): void {
    this.active = !this.active;
  }
}
