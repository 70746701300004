import {
  NotificationEventType,
  NotificationResponseDto,
} from "@earthtoday/contract";
import { getEventText } from "@earthtoday/fe-shared-code";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import { etGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { Button } from "../Button/Button";
import sc from "./NotificationItem.styled";

export interface NotificationItemDriver {
  isInvitationAccepted: boolean | null;
  isInvitationReplied: boolean;
  isLoadingReply: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  onMarkNotificationRead(notification: NotificationResponseDto): void;
  onMount(): void;
  formatLatestTime(
    fromDate: Date,
    notification: NotificationResponseDto,
  ): string;
  showNotificationsToggle(): void;
  turnMenuOff(): void;
  handleReplyGroupInvitation(
    b: boolean,
    notification: NotificationResponseDto,
  ): void;
  getGiftCollectedNotificationTitle(notification: NotificationResponseDto): {
    key: string;
    vars: {
      uonCount: number;
    };
  };
  getDeckAutoplayedNotificationTitle(
    notification: NotificationResponseDto,
  ): ReactNode;
}

interface IProps {
  driver: NotificationItemDriver;
  notification: NotificationResponseDto;
  bodyOverflowAuto(): void;
}

export const NotificationItem = observer((props: IProps) => {
  const { driver, notification } = props;
  const {
    path,
    event,
    type: notificationType,
    group,
    visited,
    initiators,
    imageUrl,
  } = notification;

  const { t } = useTranslation("Notification");

  const renderLinkWrapper = (children: ReactNode): ReactNode => {
    if (notification) {
      if (path && event !== NotificationEventType.GROUP_MEMBER_INVITED) {
        const href =
          path.length === 3
            ? "/[vanityName]/[deckName]/[cardId]"
            : "/[vanityName]/[deckName]";
        return (
          <Link href={href} as={`/${path.join("/")}`} passHref legacyBehavior>
            {children}
          </Link>
        );
      }
      return children;
    }
    return <></>;
  };

  const displayNotification = (): ReactNode => {
    if (event === NotificationEventType.GIFT_CODE_COLLECTED) {
      const item = driver.getGiftCollectedNotificationTitle(notification);
      return (
        <sc.EventType>
          <span data-testid="text-content">
            {" "}
            {t(item.key, { ...item.vars })}
          </span>
        </sc.EventType>
      );
    }

    if (event === NotificationEventType.AUTOPLAYED) {
      return (
        <sc.EventType>
          {driver.getDeckAutoplayedNotificationTitle(notification)}
        </sc.EventType>
      );
    }

    return (
      <sc.EventType>
        {" "}
        - {getEventText(event, t)}
        {event !== NotificationEventType.CONTENT_DELETED &&
        event !== NotificationEventType.GROUP_MEMBER_INVITED
          ? ` your ${notificationType.toLowerCase()}`
          : ""}
        {event === NotificationEventType.GROUP_MEMBER_INVITED ? ` admin` : ""}
      </sc.EventType>
    );
  };

  const renderButtons = (): ReactNode => (
    <sc.ButtonsWrapper>
      <Button
        driver={{
          type: "primary",
          dataCy: "CardCampaignCreate-createButton",
          width: "119px",
          height: "32px",
          fontStretch: "condensed",
          fontFamily: "Roboto Condensed",
          fontSize: "14px",
          mobileVisible: true,
          disabled: driver.isLoadingReply,
          onClick: () => {
            driver.handleReplyGroupInvitation(true, props.notification);
          },
        }}
      >
        {t("notification.button.accept-group")}
      </Button>

      <Button
        driver={{
          type: "outline-light",
          dataCy: "CardCampaignCreate-createButton",
          width: "119px",
          height: "32px",
          color: `${etGray}`,
          fontStretch: "condensed",
          fontFamily: "Roboto Condensed",
          fontSize: "14px",
          mobileVisible: true,
          disabled: driver.isLoadingReply,
          onClick: () => {
            driver.handleReplyGroupInvitation(false, props.notification);
          },
        }}
      >
        {t("notification.button.reject-group")}
      </Button>
    </sc.ButtonsWrapper>
  );

  return (
    <>
      {renderLinkWrapper(
        <sc.NotificationItem
          data-cy="TheNavbarNotification-Item"
          onClick={async () => {
            props.bodyOverflowAuto();
            driver.turnMenuOff();
            driver.showNotificationsToggle();
            await driver.onMarkNotificationRead(props.notification);
            driver.onMount();
          }}
          className={`${visited ? "visited" : ""} visual-reg ${
            driver.mLandscape ? "mLandscape" : ""
          }`}
          isGroupInviteNoti={
            event === NotificationEventType.GROUP_MEMBER_INVITED
          }
        >
          <sc.NotificationItemPreview
            data-cy="TheNavbarNotification-ItemPreview"
            src={imageUrl || false}
          />
          <sc.NotificationItemBody>
            <sc.NotificationItemTitle
              data-cy="TheNavbarNotification-ItemTitle"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {initiators[0]}
              {displayNotification()}
            </sc.NotificationItemTitle>

            <sc.NotificationType
              data-cy="TheNavbarNotification-NotificationType"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {event !== NotificationEventType.GROUP_MEMBER_INVITED &&
              event !== NotificationEventType.GIFT_CODE_COLLECTED &&
              event !== NotificationEventType.AUTOPLAYED
                ? `${group} - `
                : ""}
              {driver.formatLatestTime(new Date(), props.notification)}
            </sc.NotificationType>
            {event === NotificationEventType.GROUP_MEMBER_INVITED
              ? renderButtons()
              : null}
          </sc.NotificationItemBody>
        </sc.NotificationItem>,
      )}
    </>
  );
});
