import { observer } from "mobx-react-lite";
import Link from "next/link";
import { slugify } from "transliteration";

import { useTranslation } from "../../shared/translate/NextI18next";
import { getNavSecondaryTab, NavSecondaryTabItem } from "./getNavSecondaryTabs";
import { sc } from "./NavSecondaryTabs.styled";

interface IProps {
  location: "section-one" | "et-profile";
  enableOneFlow?: boolean;
}
export const NavSecondaryTabs = observer(function NavSecondaryTabs(
  props: IProps,
) {
  const { t } = useTranslation("SystemPageTabs");
  const { location } = props;
  const tabs = Object.values(NavSecondaryTabItem).filter((tab) => {
    if (location === "et-profile") {
      return tab !== NavSecondaryTabItem.ABOUT_EARTH_TODAY;
    }
    if (location === "section-one") {
      return true;
    }
  });

  const renderTabs = () => {
    return (
      <sc.TabContainer isEtProfile={location === "et-profile"}>
        {tabs.map((tab) => {
          const { width, height, label, href } = getNavSecondaryTab(tab, t);
          if (tab === NavSecondaryTabItem.FINANCIAL_STATEMENT) {
            return (
              <sc.Tab
                key={tab}
                data-cy={`NavSecondaryTabs-Tab-${slugify(tab)}`}
                width="84px"
                height={height}
                href={href}
                target="_blank"
                isEtProfile={location === "et-profile"}
              >
                {t("systempagetabs.financial-update")}
              </sc.Tab>
            );
          }

          if (tab === NavSecondaryTabItem.ABOUT_EARTH_TODAY) {
            return (
              <sc.Tab
                key={tab}
                data-cy={`NavSecondaryTabs-Tab-${slugify(tab)}`}
                width={width}
                height={height}
                href={href}
                target="_blank"
                isEtProfile={location === "et-profile"}
                enableOneFlow={props.enableOneFlow}
              >
                {label}
              </sc.Tab>
            );
          }
          return (
            <Link href={href} passHref key={tab} legacyBehavior>
              <sc.Tab
                data-cy={`NavSecondaryTabs-Tab-${slugify(tab)}`}
                width={width}
                height={height}
                isEtProfile={location === "et-profile"}
                enableOneFlow={props.enableOneFlow}
              >
                {label}
              </sc.Tab>
            </Link>
          );
        })}
      </sc.TabContainer>
    );
  };

  return location === "et-profile" ? (
    <sc.LayoutTabsEtProfile data-cy="NavSecondaryTabs-LayoutTabsEtProfile">
      {renderTabs()}
    </sc.LayoutTabsEtProfile>
  ) : (
    renderTabs()
  );
});
