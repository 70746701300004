import { observer } from "mobx-react-lite";
import React from "react";

import { etWhite } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { LazyModalType, ModalType } from "../../stores/ModalStore";
import { Button } from "../Button/Button";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./TheNavbarLinkItems.styled";

export interface TheNavbarLinkItemDriver {
  readonly isCurator: boolean;
  readonly userLoggedIn: boolean;
  openModalCreateCard(): void;
  openModal(modalType: ModalType | LazyModalType): void;
}

interface IProps {
  driver: TheNavbarLinkItemDriver;
}

export const TheNavbarLinkItems = observer(function TheNavbarLinkItems(
  props: IProps,
) {
  const { driver } = props;
  const { isCurator, userLoggedIn } = driver;
  const { t: tGeneral } = useTranslation("General");

  const renderButton = () => {
    if (!userLoggedIn) {
      return null;
    }

    return (
      <sc.Container className="navbar-nav">
        <Button
          driver={{
            type: "primary",
            dataCy: "TheNavbarLinkItems-createCardButton",
            width: "113px",
            fontStretch: "condensed",
            margin: "0 21px 0 0",
            round: true,
            mobileWidth: "70px",
            color: etWhite,
            fontWeight: "normal",
            fontFamily: "Roboto Condensed",
            disabled: !isCurator,
            onClick: () => props.driver.openModalCreateCard(),
          }}
        >
          <IconSvg
            icon="add"
            color={etWhite}
            pointer
            width="14px"
            height="14px"
            padding="0"
            margin="0 6.5px 0 0"
          />{" "}
          {tGeneral("general.nav-top-create-card")}
        </Button>
      </sc.Container>
    );
  };

  return <>{renderButton()}</>;
});
