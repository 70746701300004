/* eslint-disable jsx-a11y/no-autofocus */
import { SearchResponseDto } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import React from "react";
import { DebounceInput } from "react-debounce-input";

import { etBlue, etGray } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import { TheNavbarSearchResult } from "../TheNavbarSearchResult/TheNavbarSearchResult";
import { SearchResultTab } from "../TheNavbarTop/TheNavbarStore/TheNavbarStore";
import sc from "./SearchPanel.styled";

export interface SearchPanelDriver {
  readonly searchInputFocus: boolean;
  readonly searchInputValue: string;
  readonly searching: boolean;
  readonly searchResultsForCurrentTab: SearchResponseDto[];
  readonly searchResultFocus: boolean;
  readonly currentResultTabs: SearchResultTab;
  readonly userLoggedIn: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test

  searchResultFocusUpdate(b: boolean): void;
  searchInputFocusUpdate(b: boolean): void;
  onSearchInputChange(s: string): void;
  currentResultTabsUpdate(tab: SearchResultTab): void;
  turnMenuOff(): void;
  searchInputValueUpdate(s: string): void;
}

export const SearchPanel = observer(function SearchPanel(props: {
  driver: SearchPanelDriver;
  width?: string; // only use for storybook
}) {
  return (
    <sc.SearchContainer className="visual-reg" width={props.width}>
      {props.driver.searchInputFocus ? (
        <sc.SearchContainerOverlay
          onClick={() =>
            props.driver.searchInputFocusUpdate(!props.driver.searchInputFocus)
          }
          data-cy="SearchPanel-SearchContainerOverlay"
        />
      ) : null}

      <sc.SearchReserve
        userLoggedIn={props.driver.userLoggedIn}
        width={props.width}
        data-cy="SearchPanel-searchReserve"
        className={props.driver.mLandscape ? "mLandscape" : ""}
      >
        <sc.FormGroup
          borderColor={props.driver.searchInputFocus ? etBlue : "transparent"}
          className={props.driver.mLandscape ? "mLandscape" : ""}
          width={props.width}
        >
          <IconSvg
            icon="search"
            width="18px"
            height="18px"
            padding="0"
            margin="0"
            color={etGray}
            pointer={!!props.driver.searchInputValue}
          />
          <DebounceInput
            className="d-md-block d-lg-block"
            data-cy="SearchPanel-searchbox"
            value={props.driver.searchInputValue}
            debounceTimeout={300}
            onFocus={() => props.driver.searchInputFocusUpdate(true)}
            onChange={(e) => props.driver.onSearchInputChange(e.target.value)}
          />
        </sc.FormGroup>
        <sc.SearchResultsWrapper
          shouldShowResult={
            props.driver.searchInputFocus && !!props.driver.searchInputValue
          }
        >
          <TheNavbarSearchResult
            data-cy="SearchPanel-searchResult"
            driver={props.driver}
          />
        </sc.SearchResultsWrapper>
      </sc.SearchReserve>
    </sc.SearchContainer>
  );
});
