import Link from "next/link";

import { useTranslation } from "../../shared/translate/NextI18next";
import { AuthUserModel } from "../../stores/AuthUserModel";
import sc from "../TheNavbarControllers/TheNavbarControllers.styled";

export interface TheNavbarUserContextMenuDriver {
  readonly user: AuthUserModel | null;

  readonly shouldRenderCreateCard: boolean;
  openModalCreateCard(): void;

  readonly shouldRenderCreateActionCard: boolean;
  onCreateActionCardMenuItemClicked(): void;

  readonly shouldRenderCreateInfoCard: boolean;
  onCreateInfoCardMenuItemClicked(): void;

  readonly shouldRenderCreateCollectableCard: boolean;
  onCreateCollectableCardMenuItemClicked(): void;

  showMenuToggle(): void;
  onLogout(redirect: () => void): void;
  readonly hoveringMenuItem?:
    | "CREATE_CARD"
    | "CREATE_ACTION_CARD"
    | "CREATE_INFO_CARD"
    | "CREATE_COLLECTABLE_CARD"
    | "LOG_OUT"
    | "DEFAULT";
}

interface IProps {
  driver: TheNavbarUserContextMenuDriver;
  routerDriver: {
    push(url: string, undefined, options?: { shallow: boolean }): void;
  };
  enableNewProfilePage: boolean;
  enableGroup: boolean;
}

export const TheNavbarUserContextMenu = (props: IProps) => {
  const { t } = useTranslation("NavUserMenu");
  const {
    shouldRenderCreateCard,
    user,
    hoveringMenuItem,
    shouldRenderCreateActionCard,
    shouldRenderCreateInfoCard,
    shouldRenderCreateCollectableCard,
    showMenuToggle,
    onLogout,
    openModalCreateCard,
    onCreateActionCardMenuItemClicked,
    onCreateInfoCardMenuItemClicked,
    onCreateCollectableCardMenuItemClicked,
  } = props.driver;

  if (!user) return <></>;

  return (
    <sc.UserMenuContainer>
      <sc.UserMenuOverLay
        data-cy="TheNavbarControllers-UserMenuOverLay"
        onClick={showMenuToggle}
      />
      <sc.UserMenu
        className="visual-reg"
        data-cy="TheNavbarControllers-UserMenu"
        emailAddressVerified={user.emailAddressVerified}
      >
        {user.emailAddressVerified || props.enableNewProfilePage ? (
          <Link
            href="/[vanityName]"
            as={`/${user.vanityName}`}
            passHref
            legacyBehavior
          >
            <sc.UserMenuItem
              data-cy="TheNavbarControllers-MyProfile"
              onClick={() => showMenuToggle()}
            >
              {props.enableNewProfilePage
                ? t("user-menu.profile")
                : t("user-menu.my-profile")}
            </sc.UserMenuItem>
          </Link>
        ) : undefined}
        <Link
          href="/[vanityName]"
          as={`/${user.vanityName}?${
            props.enableNewProfilePage ? "tab=wallet" : "tab=your-square-meters"
          }`}
          passHref
          legacyBehavior
        >
          <sc.UserMenuItem
            data-cy="TheNavbarControllers-ProtectedMeters"
            onClick={() => showMenuToggle()}
          >
            {props.enableNewProfilePage ? (
              t("user-menu.wallet")
            ) : (
              <>{t("user-menu.protected-m2")}</>
            )}
          </sc.UserMenuItem>
        </Link>
        {props.enableNewProfilePage && user.isGroupToken && (
          <Link
            href="/[vanityName]"
            as={`/${user.vanityName}?tab=members`}
            passHref
            legacyBehavior
          >
            <sc.UserMenuItem
              onClick={() => showMenuToggle()}
              className={hoveringMenuItem === "DEFAULT" ? "hover" : ""}
              data-cy={`TheNavbarControllers-Members`}
            >
              <div>Members</div>
            </sc.UserMenuItem>
          </Link>
        )}
        {props.enableNewProfilePage &&
          !user.isGroupToken &&
          props.enableGroup && (
            <Link
              href="/[vanityName]"
              as={`/${user.vanityName}?tab=groups`}
              passHref
              legacyBehavior
            >
              <sc.UserMenuItem
                onClick={() => showMenuToggle()}
                className={hoveringMenuItem === "DEFAULT" ? "hover" : ""}
                data-cy={`TheNavbarControllers-Groups`}
              >
                <div>Groups</div>
              </sc.UserMenuItem>
            </Link>
          )}
        {user.emailAddressVerified || props.enableNewProfilePage ? (
          <Link
            href="/[vanityName]"
            as={`/${user.vanityName}?tab=archive`}
            passHref
            legacyBehavior
          >
            <sc.UserMenuItem
              onClick={() => showMenuToggle()}
              data-cy="TheNavbarControllers-Draft"
            >
              <div>{t("user-menu.archive")}</div>
              {props.enableNewProfilePage ? (
                <></>
              ) : (
                <sc.DraftCount data-cy="TheNavbarControllers-DraftCount">
                  {user.draftingCardsCount}
                </sc.DraftCount>
              )}
            </sc.UserMenuItem>
          </Link>
        ) : undefined}
        <Link href="/settings" passHref legacyBehavior>
          <sc.UserMenuItem
            data-cy="TheNavbarControllers-Settings"
            onClick={() => showMenuToggle()}
          >
            {t("user-menu.settings")}
          </sc.UserMenuItem>
        </Link>
        {shouldRenderCreateCard && (
          <>
            <sc.HR />
            <sc.UserMenuItemCreateCard
              data-cy="TheNavbarControllers-CreateCard"
              className={hoveringMenuItem === "CREATE_CARD" ? "hover" : ""}
              onClick={() => {
                showMenuToggle();
                openModalCreateCard();
              }}
            >
              {t("user-menu.create-card")}
            </sc.UserMenuItemCreateCard>
          </>
        )}
        {shouldRenderCreateActionCard && (
          <>
            <sc.HR />
            <sc.UserMenuItemCreateCard
              data-cy="TheNavbarControllers-CreateActionCard"
              className={
                hoveringMenuItem === "CREATE_ACTION_CARD" ? "hover" : ""
              }
              onClick={onCreateActionCardMenuItemClicked}
            >
              {t("user-menu.create-action-card")}
            </sc.UserMenuItemCreateCard>
          </>
        )}
        {shouldRenderCreateInfoCard && (
          <>
            <sc.HR />
            <sc.UserMenuItemCreateCard
              data-cy="TheNavbarControllers-CreateActionCard"
              className={hoveringMenuItem === "CREATE_INFO_CARD" ? "hover" : ""}
              onClick={onCreateInfoCardMenuItemClicked}
            >
              {t("user-menu.create-info-card")}
            </sc.UserMenuItemCreateCard>
          </>
        )}
        {shouldRenderCreateCollectableCard && (
          <>
            <sc.HR />
            <sc.UserMenuItemCreateCard
              data-cy="TheNavbarControllers-CreateCollectableCard"
              className={
                hoveringMenuItem === "CREATE_COLLECTABLE_CARD" ? "hover" : ""
              }
              onClick={onCreateCollectableCardMenuItemClicked}
            >
              {t("user-menu.create-collectible-card")}
            </sc.UserMenuItemCreateCard>
          </>
        )}
        <sc.HR />

        <sc.UserMenuItemLogout
          data-cy="TheNavbarControllers-logoutLink"
          className={hoveringMenuItem === "LOG_OUT" ? "hover" : ""}
          onClick={async () => {
            showMenuToggle();
            await onLogout(() =>
              props.routerDriver.push("/", undefined, { shallow: false }),
            );
          }}
        >
          {t("user-menu.logout")}
        </sc.UserMenuItemLogout>
      </sc.UserMenu>
    </sc.UserMenuContainer>
  );
};
