export enum MobileMenuItemId {
  SEARCH = "SEARCH",
  ABOUT = "ABOUT",
  COLLECT = "COLLECT",
  PROTECT = "PROTECT",
  PROFILE = "PROFILE",
  MY_UON = "MY_UON",
  MESSAGE = "MESSAGE",
  SETTING = "SETTING",
  GROUP = "GROUP",
  LOGOUT = "LOGOUT",
}
