/* eslint-disable import/no-default-export */
import { observer } from "mobx-react-lite";
import React from "react";

import { ModalType } from "../../stores/ModalStore";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";
import { ModalDeckReportLayout } from "./ModalDeckReportLayout";

interface IProps {
  openModal(modalTypes: ModalType): void;
}

export default observer(function (props: IProps) {
  return (
    <ModalDeckReportLayout
      driver={{ onClose: () => props.openModal("") }}
      renderModalWrapper={(children) => (
        <ModalWrapper modalTypes="deckReport" top="0">
          {children}
        </ModalWrapper>
      )}
    />
  );
});
