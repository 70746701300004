/* eslint-disable unicorn/no-array-push-push */
/* eslint-disable unicorn/consistent-function-scoping */
import { DeckCuratingResponseDto } from "@earthtoday/contract";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import Router from "next/router";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import ARK from "../components/LayoutProtect/img/ARK_natuur.png";
import AsepalecoFoundation from "../components/LayoutProtect/img/AsepalecoFoundation.png";
import FFI from "../components/LayoutProtect/img/FFI-logo.png";
import HojaNueva from "../components/LayoutProtect/img/hoja-nueva.png";
import Ifaw from "../components/LayoutProtect/img/Ifaw.png";
import Ifaw2 from "../components/LayoutProtect/img/ifaw2.png";
import KarenMogensen from "../components/LayoutProtect/img/KMR-Logo.png";
import NatureNeedsHalf from "../components/LayoutProtect/img/NatureNeedsHalf.png";
import PeaceParksFoundation from "../components/LayoutProtect/img/peace-parks-foundation.png";
import Peaceparks from "../components/LayoutProtect/img/Peaceparks.png";
import RainForestTrust from "../components/LayoutProtect/img/rainforest-trust.png";
import RewildingArgentina from "../components/LayoutProtect/img/RewildingArgentina.png";
import RewildingEurope from "../components/LayoutProtect/img/RewildingEurope.png";
import WildFoundation from "../components/LayoutProtect/img/WildFoundation.png";
import WildFoundation2 from "../components/LayoutProtect/img/WildFoundation2.png";
import { ProtectPageDriver } from "../components/LayoutProtect/LayoutProtectV202208";
import { NpoLogoKey } from "../components/LayoutProtect/SectionFive";
import { LeaderboardVisibleTab } from "../components/Leaderboard/LeaderboardContent";
import { IModalDeleteDeckStore } from "../components/ModalDeleteDeck/ModalDeleteDeckStore";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import {
  ErrorMessage,
  ITheMessageStore,
} from "../components/TheMessage/TheMessageStore";
import { DeckEditData, IDeckDetailApi } from "../shared/apis/DeckDetailApi";
import { FollowingApi } from "../shared/apis/FollowingApi";
import { ApiOption } from "../shared/apis/options/ApiOption";
import { IProtectPageApi } from "../shared/apis/ProtectPageAPI";
import { IReserveApi } from "../shared/apis/ReserveApi";
import { UnsubscribeFn } from "../shared/apis/UnsubscribeFn";
import { isProduction, urlCardTheBigWhy } from "../shared/env";
import { buildNewConsumers } from "../shared/helpers/buildNewConsumers";
import { translateAPIError } from "../shared/helpers/translateApiError";
import { CustomConsumer, IConsumer } from "../shared/models/Consumer";
import { ReserveResponse } from "../shared/models/Reserve";
import { AutoPlayDeckStore } from "./AutoplayDeckStore";
import CardDeckOverviewModel from "./CardDeckOverviewModel";
import { Currency, DonateStore, UonSize } from "./DonateStore/DonateStore";
import FeatureFlaggingStore from "./FeatureFlaggingStore";
import { IFollowingRequest } from "./FollowingStore";
import { LeaderTab } from "./LeaderBoardModel";
import {
  OneFlowScreen,
  OneFlowType,
} from "./ModalOneFlowPresenter/ModalOneFlowPresenter";
import { IModalStore, LazyModalType } from "./ModalStore";

export interface ProtectPageData {
  isPageLoading: boolean;
  fetchPageDataError: ErrorMessage;
  fetchLeaderboardRecentError: ErrorMessage;

  fetchLeaderboardTopError: ErrorMessage;
  fetchLeaderboardGoodCompanyRecentError: ErrorMessage;
  fetchLeaderboardGoodCompanyTopError: ErrorMessage;
  theBigWhyCardLink: string;
  staticDecks: DeckCuratingResponseDto[];
}
export class ProtectStore implements ProtectPageDriver {
  @observable fetchPageDataError: ErrorMessage = null;
  @observable isPageLoading: boolean = false;
  @observable visibleTabs: LeaderboardVisibleTab[] = [
    LeaderboardVisibleTab.RECENT,
    LeaderboardVisibleTab.TOP,
  ];

  @observable currentTabLeaderboard: LeaderTab = "recent";

  @observable currentTabGoodCompany: LeaderTab = "top"; // GoodCompany is only had the top
  @observable topConsumers: IObservableArray<CustomConsumer> =
    observable<CustomConsumer>([]);
  @observable recentConsumers: IObservableArray<CustomConsumer> =
    observable<CustomConsumer>([]);
  @observable recentConsumersGoodCompany: IObservableArray<IConsumer> =
    observable<IConsumer>([]);
  @observable topConsumersGoodCompany: IObservableArray<IConsumer> =
    observable<IConsumer>([]);
  @observable protectCount: number = 0;
  @observable loadingProtectedCount: boolean = false;
  @observable reserves: IObservableArray<ReserveResponse> =
    observable<ReserveResponse>([]);
  @observable theBigWhyCardLink: string = isProduction
    ? urlCardTheBigWhy
    : "earthtoday/thebigwhy/1674634377897783296";

  @observable fetchLeaderboardRecentError: ErrorMessage = null;
  @observable fetchLeaderboardTopError: ErrorMessage = null;
  @observable fetchLeaderboardGoodCompanyRecentError: ErrorMessage = null;
  @observable fetchLeaderboardGoodCompanyTopError: ErrorMessage = null;
  @observable fetchReserveCountDataError: ErrorMessage = null;
  @observable fetchStaticDecksDataError: ErrorMessage = null;
  @observable isPlayingMovie: boolean = false;
  @observable isOverlay: boolean = false;
  @observable isNoMoreGoodCompanyTop: boolean = false;
  @observable isNoMoreGoodCompanyRecent: boolean = false;
  @observable isLoadingReserve: boolean = false;
  @observable isSectionTwoSubSectionActivated: boolean = false;
  @observable isAutoPlayVimeo: boolean = true;
  @observable isShowAdditionalText: boolean = false;

  constructor(
    private reserveApi: IReserveApi,
    private followingApi: FollowingApi,
    private protectPageApi: IProtectPageApi,
    private deckDetailApi: IDeckDetailApi,
    private modalStore: IModalStore,
    private modalDeleteDeckStore: IModalDeleteDeckStore,
    private autoplayDeckStore: AutoPlayDeckStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private donateStore: DonateStore,
    private featureFlaggingStore: FeatureFlaggingStore,
    private previousRoute: string,
  ) {
    makeObservable(this);
  }

  public dehydrate(): ProtectPageData {
    return {
      fetchLeaderboardRecentError: this.fetchLeaderboardRecentError,
      fetchLeaderboardTopError: this.fetchLeaderboardTopError,
      fetchLeaderboardGoodCompanyRecentError:
        this.fetchLeaderboardGoodCompanyRecentError,
      fetchLeaderboardGoodCompanyTopError:
        this.fetchLeaderboardGoodCompanyTopError,
      fetchPageDataError: this.fetchPageDataError,
      isPageLoading: this.isPageLoading,
      theBigWhyCardLink: this.theBigWhyCardLink,
      staticDecks:
        this.staticDecks.map((staticDeck) => staticDeck.toJSON()) || [],
    };
  }

  @action.bound public hydrate(data: ProtectPageData): void {
    this.fetchLeaderboardRecentError = data.fetchLeaderboardRecentError;
    this.fetchLeaderboardTopError = data.fetchLeaderboardTopError;
    this.fetchLeaderboardGoodCompanyRecentError =
      data.fetchLeaderboardGoodCompanyRecentError;
    this.fetchLeaderboardGoodCompanyTopError =
      data.fetchLeaderboardGoodCompanyTopError;
    this.fetchPageDataError = data.fetchPageDataError;
    this.isPageLoading = data.isPageLoading;
    this.theBigWhyCardLink = data.theBigWhyCardLink;
    this.staticDecks.replace(
      data.staticDecks.map(
        (staticDeck) =>
          new CardDeckOverviewModel(
            this.modalStore,
            this.modalDeleteDeckStore,
            this.autoplayDeckStore,
            this.theMessageStore,
            this.userSessionStore,
            this,
            staticDeck,
            this.featureFlaggingStore,
            this.deckDetailApi,
          ),
      ),
    );
  }

  @computed get userId(): string {
    return this.userSessionStore.user?.id || "";
  }

  @action.bound updateLoadingReserve = (b: boolean): void => {
    this.loadingProtectedCount = b;
  };

  @action.bound fetchPageDataBrowser = flow(function* fetchPageDataBrowser(
    this: ProtectStore,
  ) {
    this.loadingProtectedCount = true;

    yield Promise.all([
      this.fetchRecentConsumers(),
      this.fetchTopConsumers(),
      // TODO: should active after confirm BE
      // this.fetchRecentConsumersGoodCompany(),
      // this.fetchTopConsumersGoodCompany(),
      this.fetchReserveCount(),
    ]);

    this.loadingProtectedCount = false;
  });

  @action.bound fetchPageDataServer = flow(function* fetchPageDataServer(
    this: ProtectStore,
  ) {
    yield this.fetchAllReserves();
  });

  @action.bound fetchReserveCount = flow(function* fetchReserveCount(
    this: ProtectStore,
  ) {
    try {
      this.protectCount = yield this.protectPageApi.fetchUonCount();

      if (this.fetchReserveCountDataError) {
        this.fetchReserveCountDataError = null;
      }
    } catch (error) {
      this.fetchReserveCountDataError = translateAPIError(error);
    }
  });

  @action.bound openModal(modalTypes: LazyModalType): Promise<void> {
    return this.modalStore.openModal(modalTypes);
  }

  @action.bound updateUonSize(size: UonSize, dataCy?: string): void {
    this.donateStore.updateTriggerPointDataCy(dataCy || "");
    this.donateStore.prepareProtectDonation(size);
  }

  @action.bound toggleSectionTwoSubSection(): void {
    this.isSectionTwoSubSectionActivated =
      !this.isSectionTwoSubSectionActivated;
  }

  @action.bound setIsShowAdditionalText(): void {
    this.isShowAdditionalText = true;
  }

  @action.bound back(): void {
    if (document.referrer) {
      Router.push("/", "/");
    } else if (this.previousRoute) {
      Router.back();
    } else {
      Router.push("/", "/");
    }
  }

  unsubscribeFns: UnsubscribeFn[] = [];

  @action.bound subscribe = (): void => {
    this.unsubscribeFns.push(
      this.protectPageApi.subscribeUonCount((error, count) => {
        const translatedError = error ? translateAPIError(error) : "";
        if (translatedError) {
          this.fetchReserveCountDataError = translatedError;
          this.theMessageStore.showMessage({
            typeMessage: "Error",
            title: "toast-message.general.error",
            content: translatedError,
          });
          return;
        }

        runInAction(() => {
          this.protectCount = count;
          this.fetchReserveCountDataError = null;
        });
      }),
    );

    this.unsubscribeFns.push(
      this.protectPageApi.subscribeGlobalLeaderboard(
        (error, globalLeaderboardUpdated: IConsumer[] | null) => {
          const translatedError = error ? translateAPIError(error) : "";
          if (translatedError) {
            this.fetchLeaderboardRecentError = translatedError;
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translatedError,
            });
            return;
          }

          runInAction(() => {
            if (globalLeaderboardUpdated) {
              this.updateRecentConsumers(globalLeaderboardUpdated);
            }
            this.fetchLeaderboardRecentError = null;
          });
        },
      ),
    );
  };

  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };

  @action.bound fetchRecentConsumers = flow(function* fetchRecentConsumers(
    this: ProtectStore,
  ) {
    try {
      const res = yield this.protectPageApi.fetchLeaderboardRecent();
      this.updateRecentConsumers(res);
      // eslint-disable-next-line no-unused-expressions
      this.fetchLeaderboardRecentError &&
        (this.fetchLeaderboardRecentError = null);
    } catch (error) {
      this.fetchLeaderboardRecentError = translateAPIError(error);
    }
  });

  @action.bound fetchTopConsumers = flow(function* fetchTopConsumers(
    this: ProtectStore,
  ) {
    try {
      const res = yield this.protectPageApi.fetchLeaderboardTop();
      this.updateTopConsumers(res);
      if (this.fetchLeaderboardTopError) this.fetchLeaderboardTopError = null;
    } catch (error) {
      this.fetchLeaderboardTopError = translateAPIError(error);
    }
  });
  @action.bound fetchRecentConsumersGoodCompany = flow(
    function* fetchRecentConsumersGoodCompany(this: ProtectStore, offset = 0) {
      try {
        const res = yield this.protectPageApi.fetchLeaderboardGoodCompanyRecent(
          offset,
        );

        if (Array.isArray(res) && res.length === 0) {
          this.isNoMoreGoodCompanyRecent = true;
        }

        const consumers = [...this.recentConsumersGoodCompany, ...res];

        this.updateRecentConsumersGoodCompany(consumers);

        if (this.fetchLeaderboardGoodCompanyRecentError)
          this.fetchLeaderboardGoodCompanyRecentError = null;
      } catch (error) {
        this.fetchLeaderboardGoodCompanyRecentError = translateAPIError(error);
      }
    },
  );

  @action.bound fetchTopConsumersGoodCompany = flow(
    function* fetchTopConsumersGoodCompany(this: ProtectStore, offset = 0) {
      try {
        const res = yield this.protectPageApi.fetchLeaderboardGoodCompanyTop(
          offset,
        );
        if (Array.isArray(res) && res.length === 0) {
          this.isNoMoreGoodCompanyTop = true;
        }

        const consumers = [...this.topConsumersGoodCompany, ...res];

        this.updateTopConsumersGoodCompany(consumers);

        if (this.fetchLeaderboardGoodCompanyTopError)
          this.fetchLeaderboardGoodCompanyTopError = null;
      } catch (error) {
        this.fetchLeaderboardGoodCompanyTopError = translateAPIError(error);
      }
    },
  );

  @computed get staticDecks(): IObservableArray<CardDeckOverviewModel> {
    if (this.reserves.length === 0)
      return observable<CardDeckOverviewModel>([]);

    const decksModel: CardDeckOverviewModel[] = [];
    for (const reserve of this.reserves) {
      if (!reserve.deck) {
        continue;
      }

      if (reserve.isDisabledProtect) {
        continue;
      }

      decksModel.push(
        new CardDeckOverviewModel(
          this.modalStore,
          this.modalDeleteDeckStore,
          this.autoplayDeckStore,
          this.theMessageStore,
          this.userSessionStore,
          this,
          reserve.deck,
          this.featureFlaggingStore,
          this.deckDetailApi,
        ),
      );
    }
    return observable<CardDeckOverviewModel>(decksModel);
  }

  @action.bound fetchAllReserves = flow(function* fetchAllReserves(
    this: ProtectStore,
    options?: ApiOption,
  ) {
    try {
      this.isLoadingReserve = true;
      const reserves: ReserveResponse[] =
        yield this.reserveApi.fetchAllReserves(options);

      this.reserves.replace(reserves);
    } catch (error) {
      this.fetchStaticDecksDataError = translateAPIError(error);
    } finally {
      this.isLoadingReserve = false;
    }
  });

  @action.bound updateTopConsumersGoodCompany = (
    consumers: IConsumer[],
  ): void => {
    this.topConsumersGoodCompany.replace(consumers);
  };

  @action.bound updateTopConsumers = (consumers: IConsumer[]): void => {
    const newConsumers = buildNewConsumers(consumers);
    this.topConsumers.replace(newConsumers);
  };

  @action.bound updateRecentConsumers = (consumers: IConsumer[]): void => {
    const newConsumers = buildNewConsumers(consumers);
    this.recentConsumers.replace(newConsumers);
  };

  updateRecentConsumersGoodCompany = (consumers: IConsumer[]): void => {
    this.recentConsumersGoodCompany.replace(consumers);
  };

  @action.bound updateCurrentTab = (tab: LeaderTab): void => {
    this.updateLoadingReserve(true);
    this.currentTabLeaderboard = tab;
    this.updateLoadingReserve(false);
  };

  updateCurrentTabGoodCompany = (tab: LeaderTab): void => {
    this.updateLoadingReserve(true);
    this.currentTabGoodCompany = tab;
    this.updateLoadingReserve(false);
  };

  @action.bound toggleFollowing = flow(function* toggleFollowing(
    this: ProtectStore,
    data: IFollowingRequest,
  ) {
    try {
      this.optimisticFollowing(this.staticDecks, data.deckId, data.following);
      const res = yield this.followingApi.toggleFollowingDecks(data);

      //refresh cache
      this.fetchAllReserves({ clearCache: true });

      this.optimisticFollowing(
        this.staticDecks,
        res.deckId,
        res.following,
        res.count,
      );
      return true;
    } catch (error) {
      this.optimisticFollowing(this.staticDecks, data.deckId, !data.following);
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound editDeck = flow(function* editDeck(
    this: ProtectStore,
    deckId: string,
    deckData: DeckEditData,
  ) {
    try {
      const result = yield* toFlowGeneratorFunction(
        this.deckDetailApi.editDeck,
      )(deckId, deckData);

      const i = this.staticDecks.findIndex((deck) => deck.id === deckId);
      if (i !== -1) {
        this.staticDecks[i].updateDeck(result.data);
      }
      this.modalStore.openModal("");
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action optimisticFollowing = (
    data: IObservableArray<CardDeckOverviewModel>,
    deckId: string,
    following: boolean,
    count?: number,
  ): void => {
    const foundIndex = data.findIndex((deck) => deck.id === deckId);
    if (foundIndex !== -1) {
      const newCounts =
        count === undefined
          ? following
            ? data[foundIndex].followingCounts + 1
            : data[foundIndex].followingCounts - 1
          : count;

      data[foundIndex].updateFollowCounts(newCounts, following);
    }
  };

  @computed get sectionSixDeckPathString(): string {
    return this.theBigWhyCardLink;
  }

  @computed get lastNameProtector(): string {
    if (this.recentConsumers.length === 0) {
      return "";
    }

    if (!this.recentConsumers[0]) {
      return "";
    }

    return this.recentConsumers[0].name;
  }
  @computed get currency(): Currency {
    return this.donateStore.currency;
  }

  @action.bound updateIsPlayingMovie(isPlayingMovie: boolean): void {
    this.isPlayingMovie = isPlayingMovie;
  }

  @computed get visibleNpoLogo() {
    const NPO_LOGO = [
      {
        key: NpoLogoKey.NatureNeedsHalf,
        src: NatureNeedsHalf,
      },
      {
        key: NpoLogoKey.RewildingEurope,
        src: RewildingEurope,
      },
      {
        key: NpoLogoKey.FFI,
        src: FFI,
      },
      {
        key: NpoLogoKey.WildFoundation,
        src: WildFoundation,
      },
      {
        key: NpoLogoKey.IFAW,
        src: Ifaw,
      },
      {
        key: NpoLogoKey.RewildingArgentina,
        src: RewildingArgentina,
      },
      {
        key: NpoLogoKey.ARK,
        src: ARK,
      },
      {
        key: NpoLogoKey.PeaceParksFoundation,
        src: PeaceParksFoundation,
      },
      {
        key: NpoLogoKey.KarenMogensen,
        src: KarenMogensen,
      },
      {
        key: NpoLogoKey.HojaNueva,
        src: HojaNueva,
      },
      {
        key: NpoLogoKey.RainForestTrust,
        src: RainForestTrust,
      },
    ];

    const excludedLogo =
      this.featureFlaggingStore.flags.excludedNpoLogo.split("_");

    return NPO_LOGO.filter((logo) => !excludedLogo.includes(logo.key));
  }

  @computed get visibleNpoProtectV202208() {
    const npoLogos = [
      {
        key: NpoLogoKey.IFAW,
        src: Ifaw2,
        size: {
          w: "94px",
          h: "38px",
        },
      },
      {
        key: NpoLogoKey.AsepalecoFoundation,
        src: AsepalecoFoundation,
        size: {
          w: "125px",
          h: "118px",
        },
      },
      {
        key: NpoLogoKey.RewildingArgentina,
        src: RewildingArgentina,
        size: {
          w: "200px",
          h: "65px",
        },
      },
      {
        key: NpoLogoKey.PeaceParksFoundation,
        src: Peaceparks,
        size: {
          w: "214px",
          h: "50px",
        },
      },
      {
        key: NpoLogoKey.WildFoundation,
        src: WildFoundation2,
        size: {
          w: "125px",
          h: "51px",
        },
      },
      {
        key: NpoLogoKey.HojaNueva,
        src: HojaNueva,
        size: {
          w: "150px",
          h: "65px",
        },
      },
      {
        key: NpoLogoKey.RainForestTrust,
        src: RainForestTrust,
        size: {
          w: "200px",
          h: "65px",
        },
      },
    ];

    const excludedLogo =
      this.featureFlaggingStore.flags.excludedNpoLogo.split("_");

    return npoLogos.filter((logo) => !excludedLogo.includes(logo.key));
  }

  @action.bound openSubscribeOneFlowModal(dataCy: string): void {
    this.donateStore.updateTriggerPointDataCy(dataCy);
    if (
      !this.featureFlaggingStore.flags.enableOneFlow ||
      this.userSessionStore.user?.isGroupUnpublished
    ) {
      return;
    }
    this.modalStore.openModal({
      name: "oneFlow",
      initialState: {
        currentScreen: OneFlowScreen.START,
        oneFlowType: OneFlowType.SUBSCRIBE,
        isConfirmSubscribe: true,
      },
    });
  }

  @computed get isGroupUnpublished(): boolean {
    return !!this.userSessionStore.user?.isGroupUnpublished;
  }
}
