import { observer, useLocalObservable } from "mobx-react-lite";
import Link from "next/link";
import React, { useEffect } from "react";

import {
  etBlue,
  etGreen,
  etOrange,
  etPurpleUonCard,
  etRed,
  etRegentGray,
  etYellow,
} from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./PlayButtons.styled";
import { ToolTip } from "./ToolTip";

interface PlayButtonsDriver {
  asPath: string;
}

interface IProps {
  driver: PlayButtonsDriver;
  isVisualTestRunning?: boolean;
  featureFlaggingDriver: {
    enablePlayPage: boolean;
  };
}

export const PlayButtons = observer(function PlayButtons(props: IProps) {
  const { t } = useTranslation("SystemPageTabs");

  const ToolTipChannel = {
    PEOPLE: `${t("systempagetabs.people-channel.name")} - ${t(
      "systempagetabs.people-channel.title",
    )}`,
    CREATION: `${t("systempagetabs.creation-channel.name")} - ${t(
      "systempagetabs.creation-channel.title",
    )}`,
    WORLD: `${t("systempagetabs.world-channel.name")} - ${t(
      "systempagetabs.world-channel.title",
    )}`,
    NATURE: `${t("systempagetabs.nature-channel.name")} - ${t(
      "systempagetabs.nature-channel.title",
    )}`,
    KEYS: `${t("systempagetabs.keys-channel.name")} - ${t(
      "systempagetabs.keys-channel.title",
    )}`,
    SPIRIT: `${t("systempagetabs.spirit-channel.name")} - ${t(
      "systempagetabs.spirit-channel.title",
    )}`,
    ETLOGO: `${t("systempagetabs.et-logo.tooltip")}`,
  };

  const { setPrinciple } = useLocalObservable(() => ({
    principle: "",
    setPrinciple(str: string) {
      this.principle = str;
    },
  }));

  const { asPath } = props.driver;

  useEffect(() => {
    setPrinciple(asPath.split("/")[2] || "");
  }, [asPath]);

  return (
    <sc.Container data-cy="PlayButtons-container">
      <Link href="/people" passHref legacyBehavior>
        <sc.PlayButtonContainer
          className={asPath === "/people" ? "active" : ""}
          data-cy="PlayButtons-People"
          stroke={etRegentGray}
          hoverColor={etRed}
          isVisualTestRunning={props.isVisualTestRunning}
        >
          <IconSvg
            icon="cat-people"
            color={etRegentGray}
            pointer
            width="25px"
            height="24px"
            padding="0"
          />
          <ToolTip toolTipChannel={ToolTipChannel.PEOPLE} />
        </sc.PlayButtonContainer>
      </Link>

      <Link href="/creation" passHref legacyBehavior>
        <sc.PlayButtonContainer
          className={asPath === "/creation" ? "active" : ""}
          data-cy="PlayButtons-Creation"
          stroke={etRegentGray}
          hoverColor={etOrange}
        >
          <IconSvg
            icon="cat-creation"
            color={etRegentGray}
            pointer
            width="25px"
            height="24px"
            padding="0"
          />
          <ToolTip toolTipChannel={ToolTipChannel.CREATION} />
        </sc.PlayButtonContainer>
      </Link>

      <Link href="/world" passHref legacyBehavior>
        <sc.PlayButtonContainer
          className={asPath === "/world" ? "active" : ""}
          data-cy="PlayButtons-World"
          stroke={etRegentGray}
          hoverColor={etYellow}
        >
          <IconSvg
            icon="cat-world"
            color={etRegentGray}
            pointer
            width="24px"
            height="24px"
            padding="0"
          />
          <ToolTip toolTipChannel={ToolTipChannel.WORLD} />
        </sc.PlayButtonContainer>
      </Link>

      <Link href="/earthtoday" passHref legacyBehavior>
        <sc.PlayButtonContainer
          data-cy="PlayButtons-Earthtoday"
          stroke=""
          hoverColor="transparent"
        >
          <IconSvg
            icon="et-com-logo"
            pointer
            width="40px"
            height="40px"
            padding="0"
          />
          <sc.ToolTipContainer>
            <sc.ChannelContent data-cy="PlayButtons-EtLogoContent">
              {ToolTipChannel.ETLOGO}
            </sc.ChannelContent>
          </sc.ToolTipContainer>
        </sc.PlayButtonContainer>
      </Link>

      <Link href="/nature" passHref legacyBehavior>
        <sc.PlayButtonContainer
          className={asPath === "/nature" ? "active" : ""}
          data-cy="PlayButtons-Nature"
          stroke={etRegentGray}
          hoverColor={etGreen}
        >
          <IconSvg
            icon="cat-nature"
            color={etRegentGray}
            pointer
            width="19px"
            height="19px"
            padding="0"
          />
          <ToolTip toolTipChannel={ToolTipChannel.NATURE} />
        </sc.PlayButtonContainer>
      </Link>

      <Link href="/keys" passHref legacyBehavior>
        <sc.PlayButtonContainer
          className={asPath === "/keys" ? "active" : ""}
          data-cy="PlayButtons-Keys"
          stroke={etRegentGray}
          hoverColor={etBlue}
        >
          <IconSvg
            icon="cat-keys"
            color={etRegentGray}
            pointer
            width="25px"
            height="24px"
            padding="0"
          />
          <ToolTip toolTipChannel={ToolTipChannel.KEYS} />
        </sc.PlayButtonContainer>
      </Link>

      <Link href="/spirit" passHref legacyBehavior>
        <sc.PlayButtonContainer
          className={asPath === "/spirit" ? "active" : ""}
          data-cy="PlayButtons-Spirit"
          stroke={etRegentGray}
          hoverColor={etPurpleUonCard}
        >
          <IconSvg
            icon="cat-spirit"
            color={etRegentGray}
            pointer
            width="24px"
            height="26px"
            padding="0"
          />
          <ToolTip toolTipChannel={ToolTipChannel.SPIRIT} />
        </sc.PlayButtonContainer>
      </Link>
    </sc.Container>
  );
});
