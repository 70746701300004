import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { NavSecondaryTabs } from "../../NavSecondaryTabs/NavSecondaryTabs";
import { sc } from "./SectionOneV202208.styled";

interface IProps {
  enableOneFlow: boolean;
  renderSectionOneBody(): ReactNode;
}
export const SectionOneV202208 = observer(function SectionOneV202208(
  props: IProps,
) {
  return (
    <sc.Section data-cy="SectionOneV202208" className="visual-reg">
      <NavSecondaryTabs
        location="section-one"
        enableOneFlow={props.enableOneFlow}
      />
      {props.renderSectionOneBody()}
    </sc.Section>
  );
});
