import { observer } from "mobx-react-lite";
import React from "react";

import { etRegentGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardSimpleContext_Update.styled";

interface IProps {
  onSave(): void;
  isCardDeleteOptionVisible?: boolean;
  disabled?: boolean;
}

export const ContextMenuCardSave = observer(function ContextMenuCardSave(
  props: IProps,
) {
  const { t } = useTranslation("ContextMenu");

  return (
    <sc.ContextMenuRow
      data-cy="CardMenuItem-SaveCard"
      disabled={props.disabled}
      onClick={(e) => {
        e.stopPropagation();
        props.onSave();
      }}
    >
      <IconSvg
        icon="save"
        color={etRegentGray}
        pointer={true}
        width={props.isCardDeleteOptionVisible ? "13px" : "20px"}
        height={props.isCardDeleteOptionVisible ? "15px" : "20px"}
        padding={props.isCardDeleteOptionVisible ? "0" : "2.5px 3.5px"}
      />
      <sc.ContextMenuItemBlue>
        {t("context-menu.card-menu.buttons.save")}
      </sc.ContextMenuItemBlue>
    </sc.ContextMenuRow>
  );
});
