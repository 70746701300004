import styled from "styled-components";

import { xmediumScreenLowerLimit } from "../../shared/breakpoints";
import { etGray, etHeatherBlue, etWhite } from "../../shared/colors";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const Container = styled.div`
    position: absolute;
    transition: 0.5s;
    width: 100%;
    left: 0;
    padding: 15px;
    background-color: ${etWhite};
    box-shadow: 0 16px 24px 0 rgba(42, 54, 66, 0.2);
    border-radius: 0 0 0 4px;
    z-index: 50;
    bottom: 0;

    transform: translate(0, 100%);
    visibility: hidden;
    &.active {
      visibility: visible;
      transform: translate(0, 0);
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      border-radius: 0 0 4px 4px;
    }
  `;

  export const CardSharingTitle = styled.h2`
    font-weight: 900;
    font-size: 25px;
    line-height: normal;
    margin: 0 0 10px;
    color: ${etHeatherBlue};
    text-align: left;
  `;

  export const Close = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 13px 13px 0 0;
    padding: 0;
  `;

  export const LogoRow = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 37px);
    grid-gap: 16px;
    margin-top: 16px;
    justify-content: center;
  `;

  export const CardLinkContainer = styled.div`
    width: 100%;
    text-align: center;
  `;

  export const CardLink = styled.span`
    font-size: 15px;
    line-height: 1.3;
    color: ${etGray};
    word-break: break-all;
  `;

  export const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: ${zIndex.CARDSHARING_OVERLAY};
  `;
}

export default sc;
