/* eslint-disable react/require-default-props */
import React from "react";

import { etDark, etHeatherBlue } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { sc } from "./CustomLoaderV2.styled";

export interface CustomLoaderV2Driver {
  text: string;
  subText: string;
  textColor?: string;
  spinnerColor?: string;
  bgColor?: string;
  isDisableAnimation?: boolean;
  borderRadius?: number;
  textFontSize?: number;
  subTextFontSize?: number;
  spinnerSize?: number;
  spacing?: number;
  stickNumber?: number;
  spinnerDuration?: number;
  position?: {
    top: string;
    left: string;
  };
}

interface CustomLoaderProps {
  driver: CustomLoaderV2Driver;
}

export const CustomLoaderV2 = (props: CustomLoaderProps) => {
  const {
    text,
    subText,
    isDisableAnimation = false,
    position = { top: "0", left: "0" },
    bgColor = "transparent",
    textColor = etDark,
    borderRadius = 0,
    spinnerColor = etHeatherBlue,
    spinnerSize = 40,
    spacing = 20,
    textFontSize = 18,
    subTextFontSize = 12,
    stickNumber = 24,
    spinnerDuration = 2.4,
  } = props.driver;
  const { t } = useTranslation("CustomLoader");

  return (
    <sc.Container
      data-cy="CustomLoader-Container"
      className="visual-reg"
      bgColor={bgColor}
      borderRadius={borderRadius}
    >
      <sc.Spinner
        data-cy="CustomLoader-Spinner"
        isDisableAnimation={isDisableAnimation}
        position={position}
        spinnerColor={spinnerColor}
        spinnerSize={spinnerSize}
        marginBottom={spacing}
        stickNumber={stickNumber}
        duration={spinnerDuration}
      >
        {Array.from({ length: stickNumber }, (_, i) => (
          <div key={i} />
        ))}
      </sc.Spinner>
      <sc.WrapperText>
        <sc.Text
          data-cy="CustomLoader-Text"
          textColor={textColor}
          fontSize={textFontSize}
        >
          {t(text)}
        </sc.Text>
        <sc.Subtext
          data-cy="CustomLoader-Subtext"
          textColor={textColor}
          fontSize={subTextFontSize}
        >
          {t(subText)}
        </sc.Subtext>
      </sc.WrapperText>
    </sc.Container>
  );
};
