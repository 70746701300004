import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import { etLightBlue, etWhite } from "../../shared/colors";

namespace sc {
  export const Wrapper = styled.div<{ position: "relative" | "fixed" }>`
    width: 100%;
    height: 100vh;

    position: ${(props) => props.position};
  `;
  export const BackgroundBlur = styled.div`
    width: 100%;
    height: 100%;

    background: url(${require("../../assets/img/SEA-OF-CARD.png")}) center
      center no-repeat;
    background-size: cover;

    filter: blur(3px);
    -webkit-filter: blur(3px);
    z-index: 2;
  `;

  export const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;

    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  `;
  export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    margin-top: -64px;

    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 3;

    transform: translate(-50%, -50%);

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      flex-flow: column;
      width: 80%;
    }
  `;

  export const StatusCode = styled.div`
    color: ${etWhite};
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;

    margin-bottom: 32px;
  `;

  export const ErrorMessage = styled.div`
    color: ${etWhite};

    font-size: 60px;
    font-weight: bold;

    letter-spacing: -0.5px;
    line-height: 65px;

    font-family: "Roboto";
    margin-bottom: 36px;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 44px;
      letter-spacing: -0.42px;
    }
  `;

  export const ErrorTitle = styled.div`
    color: ${etWhite};
    font-family: "Roboto Condensed";
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0px;

    margin-bottom: 32px;
    margin-top: -28px;
    text-transform: uppercase;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 18px;
    }
  `;

  export const ErrorDescription = styled.div`
    color: ${etWhite};
    font-family: "Roboto";
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;

    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 24px;
    }
  `;

  export const ErrorImage = styled.div`
    width: 100%;
    height: 100%;
  `;

  export const ErrorImageBackgroundSmall = styled.div<{ bg: string }>`
    height: 250px;
    width: 281px;
    background: url(${(props) => props.bg});
    background-size: contain;
    background-repeat: no-repeat;
  `;

  export const ErrorImageBackground = styled.div<{ bg: string }>`
    height: 300px;
    width: 300px;
    min-height: 300px;
    min-width: 300px;

    background: url(${(props) => props.bg});
    background-position-y: -35px;
    background-size: contain;
    background-repeat: no-repeat;
  `;

  export const ColLeft = styled.div`
    @media (max-width: ${smallScreenUpperLimit}) {
      display: none;
    }

    ${ErrorImage} {
      height: 250px;
      width: 281px;

      margin-right: 48px;
    }
  `;

  export const ColRight = styled.div`
    min-width: 680px;

    @media (max-width: 1024px) {
      min-width: unset;
    }
  `;

  export const Link = styled.a`
    text-decoration: underline;
    color: ${etWhite};

    &:hover {
      color: ${etLightBlue};
      cursor: pointer;
    }
  `;

  export const LinkNavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: fit-content;
  `;

  export const LinkNavItem = styled.a`
    color: ${etWhite};

    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;

    letter-spacing: 0px;
    line-height: 30px;
    height: 30px;

    margin-right: 37px;
    text-decoration: none;

    &:hover {
      color: #00a2ff;
      cursor: pointer;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      margin-right: 12px;
      font-size: 15px;
    }

    &:last-child {
      margin-right: 0;
    }
  `;

  export const BreakLine = styled.br`
    display: none;
    @media (max-width: ${smallScreenUpperLimit}) {
      display: block;
    }
  `;
}

export default sc;
