import { MobileSystemDeckTextAlignment } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import Slider from "react-slick";

import { abbreviateNumber } from "../../shared/helpers/abbreviateNumber";
import { DeckSize } from "../../stores/CardDeckOverviewModel";
import { sc } from "./DeckSystem.styled";

export interface DeckSystemDriver {
  id: string;
  formatedDeckName: string;
  description: string;
  logo: string;
  deckAuthor: string;
  textColor: string;
  textAlignment: MobileSystemDeckTextAlignment;

  deckCardPreviewImages: Array<{
    url: string;
    isProfileImage: boolean;
  }>;

  isBackgroundSlider?: boolean;
  isBackgroundSliderAutoplay?: boolean;
  contextMenuActive: boolean;
  renderSize: DeckSize;
  deckStatistic: {
    cardsCount: number;
    followersCount?: number;
  };
}

export interface IProps {
  driver: DeckSystemDriver;
  renderOverlay(): React.ReactNode;
  renderMenuContext(): React.ReactNode;
}

export const DeckSystem = observer((props: IProps) => {
  const { driver, renderMenuContext, renderOverlay } = props;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: driver.isBackgroundSliderAutoplay !== false,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    fade: true,
    arrows: false,
  };

  const { followersCount, cardsCount } = driver.deckStatistic;

  const statisticText = followersCount
    ? `${cardsCount} Cards - ${abbreviateNumber(followersCount)} Followers`
    : `${cardsCount} Cards`;

  return (
    <sc.Container
      deckSize={props.driver.renderSize}
      data-key={props.driver.id}
      key={props.driver.id}
      className="visual-reg"
      data-cy="DeckSystem-Container"
    >
      {renderMenuContext()}

      {driver.contextMenuActive && <sc.BlackOverlay />}
      <sc.Body
        data-cy="DeckSystem-ContainerBody"
        deckSize={props.driver.renderSize}
      >
        {renderOverlay()}
        {driver.isBackgroundSlider ? (
          <Slider {...settings}>
            {driver.deckCardPreviewImages.map((previewImage) => (
              <sc.PreviewImage
                deckSize={props.driver.renderSize}
                key={previewImage.url}
                previewImage={previewImage.url}
              />
            ))}
          </Slider>
        ) : (
          driver.deckCardPreviewImages[0]?.url && (
            <sc.PreviewImageContainer deckSize={props.driver.renderSize}>
              <sc.PreviewImage
                deckSize={props.driver.renderSize}
                data-cy="DeckSystem-PreviewImage"
                previewImage={driver.deckCardPreviewImages[0]?.url}
              />
            </sc.PreviewImageContainer>
          )
        )}

        <sc.Name
          color={driver.textColor}
          deckSize={props.driver.renderSize}
          textAlign={driver.textAlignment}
          data-cy="DeckSystem-FormatedDeckName"
          title={driver.formatedDeckName}
        >
          {driver.formatedDeckName}
        </sc.Name>

        <sc.Statistic
          textAlign={driver.textAlignment}
          color={driver.textColor}
          data-cy="DeckSystem-Statistic"
        >
          {statisticText}
        </sc.Statistic>
        <sc.Description
          textAlign={driver.textAlignment}
          color={driver.textColor}
          data-cy="DeckSystem-Description"
        >
          {driver.description}
        </sc.Description>
      </sc.Body>
      <sc.FooterContainer deckSize={props.driver.renderSize}>
        <sc.CuratorImageContainer deckSize={props.driver.renderSize}>
          <sc.CuratorImage
            deckSize={props.driver.renderSize}
            data-cy="DeckSystem-CuratorImage"
            logo={driver.logo}
          />
        </sc.CuratorImageContainer>
        <sc.FooterText
          data-cy="DeckSystem-FooterText"
          deckSize={props.driver.renderSize}
        >
          <sc.DeckBy>A deck by </sc.DeckBy>
          <sc.CuratorName
            data-cy="DeckSystem-CuratorName"
            title={driver.deckAuthor}
          >
            {driver.deckAuthor}
          </sc.CuratorName>
        </sc.FooterText>
      </sc.FooterContainer>
      <sc.SubCardContainer>
        <sc.SubCard1 />
        <sc.SubCard2 />
      </sc.SubCardContainer>
    </sc.Container>
  );
});
DeckSystem.displayName = "DeckSystem";
