import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etNevadaGrey,
  etSolitude,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";
import {
  CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE,
  CARD_HEIGHT_WITH_TITLE,
  CARD_HEIGHT_WITHOUT_TITLE,
} from "../../stores/CardBaseModel";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Header = styled.div`
    position: relative;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    padding: 0 12px;
    align-items: center;
    border-radius: 8px 8px 0 0;
    width: 100%;
  `;
  export const HeaderTitle = styled.a<{
    isCardSizeDouble: boolean;
    iconColor: string;
  }>`
    font-size: 14px;
    font-stretch: condensed;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${etNevadaGrey};
    width: ${(props) => (props.isCardSizeDouble ? "624px" : "272px")};
    height: 100%;
    display: flex;
    align-items: center;
    > ${IconSvgContainer} {
      margin-right: 6px;
      cursor: pointer;
    }
    :hover {
      color: ${etBlack};
    }

    > div > svg {
      height: 18px;
      width: 19px;
      > g > g {
        stroke: ${(props) => props.iconColor};
        fill: ${(props) => props.iconColor};
      }
    }
  `;
  export const ContextMenuContainer = styled.div`
    width: 32px;
    height: 44px;
  `;
  export const PreviewImageContainer = styled.div`
    position: relative;
  `;
  export const ContentContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `;

  export const IconPlayContainer = styled.div`
    position: absolute;
    width: 74px;
    height: 51px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${zIndex.CARDITEM_LINK};
  `;
  export const PreviewImage = styled.a<{
    src: string;
    isCardSizeDouble: boolean;
    hasTitle: boolean;
  }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE}px;
    height: ${(props) =>
      props.isCardSizeDouble && props.hasTitle
        ? `${CARD_HEIGHT_WITH_TITLE}px`
        : props.isCardSizeDouble && !props.hasTitle
        ? `${CARD_HEIGHT_WITHOUT_TITLE}px`
        : `${CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE}px`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props) => props.src});
    @media (max-width: ${smallScreenUpperLimit}) {
      height: ${CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE}px;
    }
  `;
  export const Body = styled.a<{
    isCardSizeDouble: boolean;
    hasTitle: boolean;
  }>`
    text-decoration: none;
    color: ${etWhite};
    background-color: ${etBlack};
    padding: ${(props) =>
      props.isCardSizeDouble ? "17px 161px 21px 12px" : "9px 21px 13px 12px"};
    line-height: 1.33;

    display: ${(props) =>
      props.isCardSizeDouble && !props.hasTitle ? "none" : "flex"};

    flex-direction: column;
    min-height: 86px;
    height: ${(props) =>
      props.isCardSizeDouble && props.hasTitle ? "86px" : "100px"};
    font-weight: 600;
    :hover {
      color: ${etWhite};
      text-decoration: none;
    }
  `;
  export const BodyText = styled.div<{ isCardSizeDouble: boolean }>`
    flex: 1;
    max-height: 78px;
    min-height: 78px;
    width: ${(props) => (props.isCardSizeDouble ? "483px" : "271px")};
    font-size: 18px;
    font-family: "Roboto Slab";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  `;
  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: ${zIndex.CARDITEM_BLACK_OVERLAY};
  `;

  export const RemovedCardBody = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    flex: 3;
    color: ${etBlack};
    background-color: ${etSolitude};
    padding: 1rem 1rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    max-height: 20.5rem;
    min-height: 8rem;
    font-weight: 600;
  `;
  export const RemovedCardBodyTitle = styled.div`
    font-size: 0.625rem;
    padding: 1rem 0;
    font-weight: bold;
  `;
  export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0.5;
    background-color: ${etBlack};
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const OverlayLinkContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    }
  `;
  export const OverlayContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    &::after {
      content: "";
      position: absolute;
      z-index: ${zIndex.OVERLAY_LINK};
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: ${etBlack};
    }
  `;
  export const OverlayContent = styled.div`
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
  export const ContainerButtonAction = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
    z-index: ${zIndex.ACTION_CONTAINER};
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const ContainerButtonIconLink = styled.div`
    margin: auto 0px auto 6px;
    background: transparent;
    visibility: hidden;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const ButtonLink = styled.a`
    display: block;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    height: 14px;
    font-size: 12px;
    color: ${etWhite};
    text-align: center;
    background-color: transparent;
    display: flex;
    align-items: center;
    :hover {
      text-decoration: none;
      color: ${etWhite};
    }
  `;
  export const CardSource = styled.button`
    position: absolute;
    bottom: 9px;
    left: 12px;
    z-index: ${zIndex.CARDITEM_LINK};
    width: 147px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    background: transparent;
  `;
  export const YoutubeIFrame = styled.iframe`
    flex: 3;
    border: none;
  `;
  export const ContainerBody = styled.div<{ contextMenuActive: boolean }>`
    position: relative;
    margin: 0 12px;
  `;

  export const CardLink = styled.div``;

  export const Gradient = styled.div`
    content: "";
    width: 100%;
    height: 44px;
    opacity: 0.6;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    position: absolute;
    bottom: 0;
    z-index: 0;
  `;
  export const Container = styled.div`
    position: relative;
    height: 100%;
    padding-bottom: 0;
    background-color: ${etWhite};
    color: ${etBlack};
    display: flex;
    flex-flow: column;
    :hover {
      ${ContainerButtonAction} {
        visibility: visible;
      }
      ${ContainerButtonIconLink} {
        visibility: visible;
      }
      ${Overlay} {
        visibility: visible;
      }
    }
  `;

  export const SaveButtonWrapper = styled.div<{ isButtonHiden: boolean }>`
    display: ${(props) => (props.isButtonHiden ? "none" : "block")};
  `;
}

export default sc;
