import styled from "styled-components";

import { smallScreenUpperLimit } from "../../shared/breakpoints";
import {
  etRegentGray,
  etShadowLighter,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Container = styled.div`
    font-family: Roboto Condensed;
    display: grid;
    grid-template-columns: repeat(3, minmax(82px, 140px));
    grid-gap: 12px;
    width: 100%;
  `;
  export const Item = styled.div`
    font-family: Roboto Condensed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background-color: ${transparentize(etSolitudeLight, 0.5)};
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    :hover {
      opacity: 1;
      color: ${etRegentGray};
      background-color: ${etWhite};
      box-shadow: 0 1px 1px 0 ${etShadowLighter}, 0 2px 3px 0 ${etShadowLighter};
      > ${IconSvgContainer} {
        fill: ${etRegentGray};
      }
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 12px;
      height: 31px;
      > ${IconSvgContainer} {
        display: none;
      }
    }
  `;
}

export default sc;
