import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useRouter } from "next/router";

import { etBrightLightBlue, etGray, etGreen } from "../../shared/colors";
import { LazyModalType } from "../../stores/ModalStore";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardMenu.styled";

export interface ICardMenuDriver {
  readonly isMenuAppOpen: boolean;
  readonly vanityName: string;
  readonly image: string;
  readonly isCurator: boolean;
  readonly isUserVerified: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  openLazyModal(modal: LazyModalType): void;
  onUserLogout(redirect?: () => void): void;
  toggleMenuApp(): void;
  updateScrollPermission(target: "tabs" | "profile", permission: boolean): void;
  turnMenuOff(): void;
  onCreateCollectableClicked(): void;
}

interface IProps {
  driver: ICardMenuDriver;
  featureFlagStore: {
    flags: {
      enableCollectibleUon: boolean;
    };
  };
}

export const CardMenu = observer(function CardMenu(props: IProps) {
  const router = useRouter();
  const { driver, featureFlagStore } = props;
  const {
    vanityName,
    image,
    isCurator,
    isMenuAppOpen,
    isUserVerified,
    mLandscape,
    openLazyModal,
    onUserLogout,
    toggleMenuApp,
    updateScrollPermission,
    turnMenuOff,
    onCreateCollectableClicked,
  } = driver;

  if (!isMenuAppOpen) {
    return <></>;
  }

  const renderButtonToDeckTab = () => {
    return (
      <sc.Option
        data-cy="CardMenu-Decks"
        onClick={() => {
          if (!isUserVerified) {
            return false;
          }
          toggleMenuApp();
          updateScrollPermission("tabs", true);
        }}
      >
        <sc.Circle isVerified={isUserVerified} mLandscape={mLandscape}>
          <IconSvg
            icon="menu-decks"
            width="24px"
            height="24px"
            margin="auto auto"
            padding="0"
            pointer
          />
        </sc.Circle>
        <sc.Text
          isVerified={isUserVerified}
          className={mLandscape ? "mLandscape" : ""}
        >
          DECKS
        </sc.Text>
      </sc.Option>
    );
  };

  const renderDeckLink = () => {
    if (!isUserVerified) {
      return renderButtonToDeckTab();
    }
    return (
      <Link href="/[vanityName]" as={`/${vanityName}?tab=decks`} legacyBehavior>
        {renderButtonToDeckTab()}
      </Link>
    );
  };

  return (
    <sc.Container data-cy="CardMenu-Container" className="visual-reg">
      <sc.Row>
        <sc.Option
          data-cy="CardMenu-Settings"
          onClick={() => {
            toggleMenuApp();
            router.push(`/settings`);
          }}
        >
          <sc.Circle mLandscape={mLandscape}>
            <IconSvg
              icon="menu-settings"
              width="26px"
              height="26px"
              margin="auto auto"
              padding="0"
              color={etGray}
              pointer
            />
          </sc.Circle>
          <sc.Text className={mLandscape ? "mLandscape" : ""}>SETTINGS</sc.Text>
        </sc.Option>

        <Link
          href="/[vanityName]/[deckName]"
          as="/earthtoday/aboutearthtoday"
          passHref
          legacyBehavior
        >
          <sc.Option
            data-cy="CardMenu-About"
            onClick={() => {
              toggleMenuApp();
            }}
          >
            <sc.Circle mLandscape={mLandscape}>
              <IconSvg
                icon="menu-about"
                width="28px"
                height="28px"
                margin="auto auto"
                padding="0"
                color={etGray}
                pointer
              />
            </sc.Circle>
            <sc.Text className={mLandscape ? "mLandscape" : ""}>ABOUT</sc.Text>
          </sc.Option>
        </Link>

        <sc.Option
          onClick={async () => {
            await onUserLogout(() => {
              router.push("/", undefined, { shallow: false });
            });
          }}
          data-cy="CardMenu-Logout"
        >
          <sc.Circle mLandscape={mLandscape}>
            <IconSvg
              icon="menu-logout"
              width="20px"
              height="20px"
              margin="auto auto"
              padding="0"
              color={etGray}
              pointer
            />
          </sc.Circle>
          <sc.Text className={mLandscape ? "mLandscape" : ""}>LOGOUT</sc.Text>
        </sc.Option>
      </sc.Row>
      <sc.Row>
        <Link
          href="/[vanityName]"
          as={`/${vanityName}?tab=your-square-meters`}
          passHref
          legacyBehavior
        >
          <sc.Option
            data-cy="CardMenu-Wallet"
            onClick={() => {
              toggleMenuApp();
              updateScrollPermission("tabs", true);
            }}
          >
            <sc.Circle mLandscape={mLandscape}>
              <IconSvg
                icon="menu-protected"
                width="24px"
                height="24px"
                margin="auto auto"
                padding="0"
                color={etGray}
                pointer
              />
            </sc.Circle>
            <sc.Text className={mLandscape ? "mLandscape" : ""}>WALLET</sc.Text>
          </sc.Option>
        </Link>

        <Link
          href="/[vanityName]"
          as={`/${vanityName}${
            isUserVerified ? "" : "?tab=your-square-meters"
          }`}
          passHref
          legacyBehavior
        >
          <sc.Option
            data-cy="CardMenu-Profile"
            onClick={() => {
              toggleMenuApp();
              updateScrollPermission("profile", true);
            }}
          >
            <sc.Circle mLandscape={mLandscape}>
              <sc.Profile src={image} />
            </sc.Circle>
            <sc.Text className={mLandscape ? "mLandscape" : ""}>
              PROFILE
            </sc.Text>
          </sc.Option>
        </Link>

        {renderDeckLink()}
      </sc.Row>

      <sc.Row>
        <sc.Option
          data-cy="CardMenu-Protect"
          onClick={() => {
            toggleMenuApp();
            router.push(`/naturereserves`);
          }}
        >
          <sc.CircleProtect bgColor={etGreen} iconColor={etGreen}>
            <IconSvg
              icon="menu-protected"
              width="24px"
              height="24px"
              margin="auto auto"
              padding="0"
              pointer
            />
          </sc.CircleProtect>
          <sc.Text className={mLandscape ? "mLandscape" : ""}>PROTECT</sc.Text>
        </sc.Option>
        {featureFlagStore.flags.enableCollectibleUon ? (
          <sc.Option
            data-cy="CardMenu-Collectible"
            onClick={() => {
              onCreateCollectableClicked();
            }}
          >
            <sc.CircleCreateCard
              isCurator={true}
              bgColor={etBrightLightBlue}
              iconColor={etBrightLightBlue}
              mLandscape={mLandscape}
            >
              <IconSvg
                icon="menu-create-card"
                width="24px"
                height="24px"
                margin="auto auto"
                padding="0"
                pointer
              />
            </sc.CircleCreateCard>
            <sc.Text
              data-cy="CardMenu-OptionCollectable-Text"
              className={mLandscape ? "mLandscape" : ""}
            >
              Collectible
            </sc.Text>
          </sc.Option>
        ) : (
          <sc.Option
            data-cy="CardMenu-Following"
            onClick={() => {
              toggleMenuApp();
              router.push(`/following`);
            }}
          >
            <sc.Circle className="menu-following" mLandscape={mLandscape}>
              <IconSvg
                icon="menu-following"
                width="26px"
                height="17px"
                margin="auto auto"
                padding="0"
                pointer
              />
            </sc.Circle>
            <sc.Text className={mLandscape ? "mLandscape" : ""}>
              FOLLOWING
            </sc.Text>
          </sc.Option>
        )}
        <sc.Option
          onClick={() => {
            isCurator && openLazyModal("cardCreate");
            turnMenuOff();
          }}
          data-cy="CardMenu-CreateCard"
        >
          <sc.CircleCreateCard
            className="create-card"
            isCurator={isCurator}
            bgColor={etBrightLightBlue}
            iconColor={etBrightLightBlue}
            mLandscape={mLandscape}
          >
            <IconSvg
              icon="menu-create-card"
              width="24px"
              height="24px"
              margin="auto auto"
              padding="0"
              pointer
            />
          </sc.CircleCreateCard>
          <sc.Text
            isCurator={isCurator}
            className={mLandscape ? "mLandscape" : ""}
          >
            CARD
          </sc.Text>
        </sc.Option>
      </sc.Row>
      <sc.RowSingle>
        <Link href="/[vanityName]" as="/earthtoday" passHref legacyBehavior>
          <sc.Option
            data-cy="CardMenu-Play"
            onClick={() => {
              toggleMenuApp();
            }}
          >
            <sc.CirclePlay bgColor="unset" iconColor="unset">
              <IconSvg
                icon="et-com-logo"
                width="60px"
                height="60px"
                margin="auto auto"
                padding="0"
                pointer
              />
            </sc.CirclePlay>
            <sc.Text className={mLandscape ? "mLandscape" : ""}>PLAY</sc.Text>
          </sc.Option>
        </Link>
      </sc.RowSingle>
    </sc.Container>
  );
});
