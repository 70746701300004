import styled from "styled-components";

import { etScarletRed } from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";

namespace sc {
  export const Container = styled.div`
    width: 100%;
    border-radius: 4px;
    border: solid 1px ${etScarletRed};
    background-color: ${transparentize(etScarletRed, 0.15)};
    padding: 8px 12px;
    margin: 0;

    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${etScarletRed};
  `;
}

export default sc;
