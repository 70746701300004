import styled from "styled-components";

import {
  etBlack,
  etBrightLightBlue,
  etGrayDark,
  etRed,
  etRegentGray,
  etShadowLighter,
  etVoteRed,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Container = styled.div`
    position: relative;
  `;

  export const ContextMenuContainer = styled.div<{
    menuColor: string;
    isContextMenuActive: boolean;
  }>`
    position: absolute;
    z-index: ${(props) => (props.isContextMenuActive ? 17 : 14)};
    line-height: 20px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;

    width: 40px;
    height: 30px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > ${IconSvgContainer} {
      fill: ${(props) => props.menuColor || etWhite};
    }
  `;
  export const ContextMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    min-width: 154px;
    padding: 12px;
    z-index: 2;
    background-color: ${etWhite};
    border-radius: 6px;
    box-shadow: 4px 24px 12px ${etShadowLighter};

    top: 24px;
    right: 10px;

    width: 214px;
    box-shadow: 0px 16px 24px 0px ${etGrayDark}20;
  `;

  export const ContextMenuItemBlue = styled.span`
    margin-left: 10px;
  `;
  export const ContextMenuItemRed = styled.span``;

  export const ContextMenuItemRedInInit = styled.span`
    color: ${etVoteRed};
    margin-left: 10px;
  `;
  export const IconContainer = styled.div`
    position: absolute;
    z-index: -1;
    opacity: 0.2;
    transform: scale(1.8);
    top: 5rem;
    right: -4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ::before {
      content: "";
      position: absolute;
      height: 92%;
      width: 92%;
      background-color: ${etWhite};
      border-radius: 1rem;
    }
  `;
  export const ContextMenuRow = styled.div<{ hoverColor: string }>`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    color: ${etBlack};
    > a {
      color: ${etBlack};
      text-decoration: none;
    }

    border-radius: 4px;
    border: 1px solid rgb(235, 238, 240);
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    width: 190px;
    margin-bottom: 12px;
    padding: 12px;

    color: ${etRegentGray};
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;

    :hover {
      background-color: ${transparentize(etBrightLightBlue, 0.1)};
      border-color: ${transparentize(etBrightLightBlue, 0.1)};
    }

    :hover ${ContextMenuItemBlue} {
      color: ${etBrightLightBlue};
    }

    :hover ${ContextMenuItemRed} {
      color: ${etRed};
    }

    :hover ${IconSvgContainer} {
      fill: ${(props) => props.hoverColor};
    }

    &:last-child {
      margin-bottom: 0;
    }
  `;

  export const IconWrapper = styled.div`
    width: 18px;
    max-width: 18px;
    display: flex;
    justify-content: center;
  `;

  export const ContextMenuRowReport = styled(ContextMenuRow)`
    ${ContextMenuItemBlue} {
      margin-left: 10px;
    }
  `;

  export const ContextMenuRowReorder = styled(ContextMenuRow)`
    ${IconSvgContainer} {
      margin-left: 8px;
      margin-right: 10px;
    }
  `;

  export const ContextMenuRowEdit = styled(ContextMenuRow)`
    ${IconSvgContainer} {
      margin-left: 10px;
      margin-right: 10px;
    }
  `;

  export const ContextMenuRowDelete = styled(ContextMenuRow)`
    border: 1px solid ${etVoteRed};
    ${IconSvgContainer} {
      margin-left: 11px;
      margin-right: 10px;
    }
    &:hover {
      background-color: ${etVoteRed};
      ${ContextMenuItemRedInInit} {
        color: ${etWhite};
      }
      ${IconSvgContainer} {
        fill: ${etWhite};
      }
    }
  `;

  export const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 17;
    cursor: default;
  `;
}

export default sc;
