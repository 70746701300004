import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { ModalType } from "../../stores/ModalStore";
import { LayoutModal } from "../LayoutModal/LayoutModal";
import sc from "./ModalSendEmail.styled";

interface Driver {
  openModal(modalTypes: ModalType): void;
}

interface IProps {
  driver: Driver;
  renderModalWrapper(children: ReactNode): ReactNode;
}

export const ModalSendEmailLayout = observer((props: IProps) => {
  const { driver } = props;

  return (
    <LayoutModal
      onClose={() => driver.openModal("")}
      renderModalWrapper={props.renderModalWrapper}
      renderModalContent={() => (
        <>
          <sc.Header>
            <sc.HeaderTitle data-cy="ModalSendEmail-HeaderTitle">
              Send us an email
            </sc.HeaderTitle>
          </sc.Header>
          <sc.Body data-cy="ModalSendEmail-Body">
            <div>
              We’d love to hear from you! Send us an email with comments,
              questions or concerns and we’ll do our best to get back to you
              quickly.
            </div>
            <sc.Contact data-cy="ModalSendEmail-Contact">
              <span>Contact us directly by emailing</span>
              <sc.MailtoLink
                data-cy="ModalSendEmail-MailtoLink"
                href="mailto:contact@earthtoday.com?subject=Mail-us"
              >
                contact@earthtoday.com
              </sc.MailtoLink>
            </sc.Contact>
          </sc.Body>
        </>
      )}
    />
  );
});
