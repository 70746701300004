import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { DeckSize } from "../../stores/CardDeckOverviewModel";
import sc from "./CardDeckOverview.styled";
import { CardDeckOverviewBackgroundImage } from "./CardDeckOverviewBackgroundImage";

export type CardDeckOverviewDriver = {
  readonly number: number;
  readonly deckCardPreviewImages: Array<{
    url: string;
    isProfileImage: boolean;
  }>;
  readonly description: string;
  readonly path: string[];
  readonly contextMenuActive: boolean;
  readonly shareActive: boolean;
  readonly isBackgroundSlider?: boolean;
  toggleShareActive(): void;
  readonly renderSize: DeckSize;
};
interface IProps {
  autoplaySlider?: boolean;
  driver: CardDeckOverviewDriver;
  renderDeckContent(): ReactNode;
  renderDeckFooter(): ReactNode;
  renderMenuContext(): ReactNode;
  renderOverlay(): ReactNode;
  renderSharing(): ReactNode;
}

export const CardDeckOverview = observer(function CardDeckOverview(
  props: IProps,
) {
  const {
    autoplaySlider,
    driver,
    renderDeckContent,
    renderDeckFooter,
    renderMenuContext,
    renderOverlay,
    renderSharing,
  } = props;

  return (
    <sc.Container
      deckSize={props.driver.renderSize}
      className="visual-reg"
      data-cy="CardDeckOverviews-Container"
    >
      {renderMenuContext()}
      {renderSharing()}
      {driver.shareActive && (
        <sc.SharingOverlay onClick={() => driver.toggleShareActive()} />
      )}
      {driver.contextMenuActive && <sc.BlackOverlay />}
      <sc.ContainerBody deckSize={props.driver.renderSize}>
        <CardDeckOverviewBackgroundImage
          driver={driver}
          autoplaySlider={autoplaySlider}
        />

        {driver.number === 0 ? undefined : (
          <sc.HeaderNumber data-cy="CardDeckOverview-HeaderNumber">
            {driver.number < 10 ? `0${driver.number}` : driver.number}
          </sc.HeaderNumber>
        )}

        {renderDeckContent()}
        {renderOverlay()}
      </sc.ContainerBody>
      {renderDeckFooter()}
      <sc.SubCardContainer>
        <sc.SubCard1 />
        <sc.SubCard2 />
      </sc.SubCardContainer>
    </sc.Container>
  );
});
