import { observer } from "mobx-react-lite";
import Link from "next/link";

import { branchIOHost, utmSourceValue } from "../../shared/env";
import {
  getBgColorSystemPage,
  getTextColorSystemPage,
} from "../../shared/helpers/getItemSystemPage";
import { useTranslation } from "../../shared/translate/NextI18next";
import {
  IWhiteBannerInfo,
  NamePage,
  NameSystemPage,
} from "../../stores/OverlaySystemPagePresenter";
import { SystemDeckParam } from "../../stores/SystemDeckStore";
import { sc } from "./SystemPagesTabs.styled";
export interface SystemPageTabsDriver {
  readonly systemPage: NameSystemPage;
  readonly isSystemDeck: boolean;
  readonly isShowBecomeBtn: boolean;
  readonly tabName: string;
  readonly whiteBannerInfo: IWhiteBannerInfo;
}
export interface IPropsSystemPageTabs {
  driver: SystemPageTabsDriver;
}

export const SystemPageTabs = observer(function SystemPageTabs(
  props: IPropsSystemPageTabs,
) {
  const { driver } = props;

  const { t } = useTranslation("SystemPageTabs");

  if (!driver.systemPage) {
    return <></>;
  }

  return (
    <sc.WrapperTabs
      className="visual-reg"
      height={
        driver.systemPage === NamePage.Today ||
        driver.systemPage === NamePage.AboutEarthToday
          ? "70px"
          : "69px"
      }
    >
      <sc.Container
        data-cy="SystemPageTabs-Container"
        className={
          driver.systemPage === NamePage.AboutEarthToday ? "about-page" : ""
        }
      >
        <sc.Tab
          data-cy="SystemPageTabs-CurrentTab"
          isActive={true}
          bgColor="transparent"
          isDisplayNone={false}
          className={
            driver.systemPage === NamePage.Today
              ? "today-page"
              : driver.isSystemDeck
              ? "system-deck"
              : ""
          }
        >
          {t(driver.tabName)}
        </sc.Tab>

        {driver.isShowBecomeBtn ? (
          <Link
            href={driver.whiteBannerInfo.blueBtnHref}
            target="_blank"
            passHref
            legacyBehavior
          >
            <sc.Tab
              data-cy="SystemPageTabs-ChannelPage-BecomeACurator"
              color={getTextColorSystemPage(driver.systemPage || "")}
              isActive={false}
              isDisplayNone={false}
              bgColor={getBgColorSystemPage(driver.systemPage || "")}
              className={
                driver.systemPage === NamePage.Today
                  ? "today-page"
                  : driver.isSystemDeck
                  ? "system-deck"
                  : ""
              }
            >
              {driver.whiteBannerInfo.blueBtnTitle}
            </sc.Tab>
          </Link>
        ) : null}

        {driver.systemPage !== NamePage.AboutEarthToday && (
          <Link
            href="/[vanityName]/[deckName]"
            as={`/earthtoday/aboutearthtoday?${utmSourceValue}`}
            passHref
            legacyBehavior
          >
            <sc.Tab
              data-cy="SystemPageTabs-AboutEarthToday"
              isActive={false}
              isDisplayNone={false}
              bgColor="transparent"
              className={
                driver.systemPage === NamePage.Today
                  ? "today-page"
                  : driver.isSystemDeck
                  ? "system-deck"
                  : ""
              }
            >
              <>{t("systempagetabs.about")}</>
            </sc.Tab>
          </Link>
        )}

        <sc.Tab
          href={branchIOHost + "/uonearth/protect"}
          data-cy="SystemPageTabs-Protect"
          isActive={false}
          isDisplayNone={driver.systemPage === SystemDeckParam.NPO}
          bgColor="transparent"
          className={
            driver.systemPage === NamePage.Today
              ? "today-page"
              : driver.isSystemDeck
              ? "system-deck"
              : ""
          }
        >
          {t("systempagetabs.protect")}
        </sc.Tab>
      </sc.Container>
    </sc.WrapperTabs>
  );
});
