/* eslint-disable unicorn/no-nested-ternary */
import styled from "styled-components";

import {
  etBlack,
  etBrightLightBlue,
  etGray,
  etGrayBold,
  etGrayDark,
  etRed,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";

export namespace sc {
  export const Note = styled.div`
    font-size: 10px;
    text-transform: uppercase;
    color: ${etGray};
    margin-top: 5px;
    margin-bottom: 0;
    line-height: 11px;
  `;

  export const ControlContainer = styled.div`
    margin-top: 12px;
  `;

  // Background Color
  export const ButtonBackgroundColor = styled.button<{
    backgroundColor: string;
    textColor: string;
  }>`
    width: 100%;
    max-height: 40px;
    min-height: 40px;
    border-radius: 4px;
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.backgroundColor};
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  export const BackgroundColorPickerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `;
  export const BackgroundColorPickerPopover = styled.div`
    position: absolute;
    z-index: 999;

    padding: 12px 12px 24px 12px;
    border-radius: 8px;
    border: 0px solid rgba(0, 0, 0, 0.25);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    background-color: #fff;
  `;
  export const BackgroundColorPickerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
  `;

  // Upload Content
  export const ButtonUploadContent = styled.button`
    width: 100%;
    max-height: 40px;
    min-height: 40px;
    border-radius: 4px;
    color: ${etWhite};
    background-color: ${etGray};
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const UploadContentBlock = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${transparentize(etBlack, 0.4)};
    z-index: 8;
  `;

  export const UploadContentCloseBtn = styled.div`
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;
  `;
  export const UploadContentTitle = styled.h2<{ color: string }>`
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    width: 235px;
    height: 24px;
    color: ${(props) => props.color};
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0px;
  `;
  export const UploadContentButtons = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
  `;
  export const ZipFileInput = styled.input``;
  const BaseButton = styled.button`
    height: 40px;
    width: 280px;
    border-radius: 4px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
  `;
  export const UploadButton = styled(BaseButton)`
    background-color: ${etBrightLightBlue};
    color: ${etWhite};
    border: none;
  `;
  export const UploadNote = styled(Note)<{ color: string }>`
    margin-top: 4px;
    margin-bottom: 12px;
    color: ${(props) => props.color};
  `;
  export const DeleteZipFileButton = styled(BaseButton)`
    background-color: ${etWhite};
    color: ${etRed};
    border: 1px solid ${etRed};

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  `;

  export const UploadContentPreviewFileName = styled.div`
    text-align: center;
  `;
  export const UploadContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 328px;
    height: 496px;
    background-color: ${etWhite};
    padding: 20px 24px 25px 24px;
    border-radius: 4px;
    box-shadow: 0px 16px 18px 0px ${transparentize(etGrayDark, 0.2)};
  `;

  // Open Card View checkbox
  export const WrapperOpenCardViewCheckbox = styled.div`
    display: flex;
    align-items: center;
  `;
  export const CheckBoxInput = styled.input`
    position: relative;
    cursor: pointer;
    appearance: none;
    border-radius: 4px;
    border: 1px solid ${etGrayBold};
    height: 20px;
    max-height: 20px;
    width: 20px;
    max-width: 20px;

    &:checked {
      border: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  export const CheckedInput = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    max-height: 20px;
    width: 20px;
    max-width: 20px;
    border: 1px solid ${etBrightLightBlue};
    border-radius: 4px;
    margin: 1px 0px 0px -20px;
    background-color: ${etBrightLightBlue};
  `;

  export const IconChecked = styled.img`
    background-color: ${etBrightLightBlue};
    width: 13px;
    height: 10px;
    cursor: pointer;
  `;
  export const CheckboxDesc = styled.label<{ disabled: boolean }>`
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};

    font-family: Roboto, sans-serif;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: ${etGray};
    line-height: 1.5;
    text-align: left;

    padding-left: 12px;
  `;
}
