import styled from "styled-components";

import zIndex from "../../shared/zIndex";

namespace sc {
  export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  `;
}

export default sc;
