/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
import { observer } from "mobx-react-lite";
import React from "react";

import EditCard from "-!svg-react-loader?name=EditCard!./assets/edit-card.svg";

import { etRegentGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import scUpdate from "./CardSimpleContext_Update.styled";

interface IProps {
  onEdit(): void;
  disabled?: boolean;
  isHovering?: boolean;
}

export default observer(function (props: IProps) {
  const { t } = useTranslation("ContextMenu");

  return (
    <scUpdate.ContextMenuRow
      className={props.isHovering ? "hover" : ""}
      data-cy="CardMenuItem-Edit"
      disabled={props.disabled}
      onClick={(event) => {
        event.stopPropagation();
        props.onEdit();
      }}
    >
      <EditCard fill={etRegentGray} />
      <scUpdate.ContextMenuItemBlue>
        {t("context-menu.card-menu.buttons.edit")}
      </scUpdate.ContextMenuItemBlue>
    </scUpdate.ContextMenuRow>
  );
});
