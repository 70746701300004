import { observer } from "mobx-react-lite";
import Image from "next/image";
import Link from "next/link";
import VimeoPlayer from "react-player/vimeo";

import { etBlack, etGreen, etWhite } from "../../shared/colors";
import { protectPageSectionOneNumberAnimation } from "../../shared/helpers/protectPageSectionOneNumberAnimation";
import { useTranslation } from "../../shared/translate/NextI18next";
import { CustomLoaderV2 } from "../CustomLoaderV2/CustomLoaderV2";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { DownloadAppButton } from "../DownloadAppButtons/DownloadAppButton";
import { FlyingMap, FlyingMapDriver } from "../FlyingMap/FlyingMap";
import { HomeHeaderCardCountAnimation } from "../HomeHeaderCardCounter/HomeHeaderCardCountAnimation";
import { IconSvg } from "../IconSvg/IconSvg";
import { TheLoader } from "../TheLoader/TheLoader";
import { QrCodeOverlayScreenState } from "../TheNavbarTop/TheNavbarStore/QrCodeOverlayPresenter";
import appLogo from "./assets/app-logo.png";
import qrCodeSample from "./assets/qr-code-sample.png";
import { sc } from "./QrCodeOverlay.styled";

export interface QrCodeOverlayDriver {
  state: QrCodeOverlayScreenState;
  shouldShowOverlay: boolean;
  flyingMapDriver?: FlyingMapDriver;

  protectedCount: number | null;
  shouldRenderVideoPlayer: boolean;
  onWatchVideoButtonClicked(): void;
  onVideoOverlayClicked(): void;
  onLoginForPartnerClicked(): void;

  isLoading: boolean;
  qrCodeUrl: string;
  isQrCodeExpired: boolean;
  onReloadBtnClicked(): void;
  onBackBtnClicked(): void;
  onLearnMoreBtnClicked(): void;
  onCloseButtonClicked(): void;
  disableLoaderAnimation?: boolean; // For visual test
}
interface IProps {
  driver: QrCodeOverlayDriver;
}

export const QrCodeOverlay = observer(function QRCodeOverlay(props: IProps) {
  const {
    state,
    shouldShowOverlay,
    flyingMapDriver,
    protectedCount,
    shouldRenderVideoPlayer,
    onWatchVideoButtonClicked,
    onVideoOverlayClicked,
    onLoginForPartnerClicked,
    isLoading,
    qrCodeUrl,
    isQrCodeExpired,
    onReloadBtnClicked,
    onBackBtnClicked,
    onLearnMoreBtnClicked,
    onCloseButtonClicked,
    disableLoaderAnimation,
  } = props.driver;
  const { t } = useTranslation("QrCodeOverlay");

  return shouldShowOverlay ? (
    <sc.FixedContainer className="visual-reg">
      <sc.PageOverlay />
      <sc.BackGroundMask>
        {flyingMapDriver && <FlyingMap driver={flyingMapDriver} />}
        <sc.BgOverlay />
      </sc.BackGroundMask>
      <sc.Container data-cy="QrCodeOverlay-Container">
        <sc.Content>
          {state === QrCodeOverlayScreenState.PRIMARY ? (
            <>
              <sc.DownloadAppCard>
                <sc.CounterBlock>
                  <sc.StartProtecting>
                    {t("qr-code-overlay-primary.start-protecting")}
                  </sc.StartProtecting>
                  <sc.CounterContainer>
                    <sc.CounterWrapper>
                      {protectedCount === null ? (
                        <TheLoader className="visual-reg-remove" />
                      ) : (
                        <>
                          {protectPageSectionOneNumberAnimation(
                            protectedCount,
                          ).map((count, i) => (
                            <HomeHeaderCardCountAnimation
                              dataCy={"LandingPage-CountNumber"}
                              flags={{ counterFlapEffectDuration: 0 }}
                              noAnimationCounter={true}
                              countNumbers={count.countNumbers}
                              lastNumber={count.lastNumber}
                              key={`${count.lastNumber}${i}`}
                              borderColor={etWhite}
                              fixedSize
                            />
                          ))}
                          <sc.CounterMeterLogo
                            data-cy="HomeHeaderCardCountAnimation-SquareMeterLogo"
                            src={require("../../assets/img/m2-white.png")}
                            alt="Home Header Card Counter m²"
                          />
                        </>
                      )}
                    </sc.CounterWrapper>
                    <sc.CounterDescription>
                      {t("qr-code-overlay-primary.meter-x-meter")}
                    </sc.CounterDescription>
                  </sc.CounterContainer>
                </sc.CounterBlock>

                <sc.DownloadAppBlock>
                  <sc.DownloadApp>
                    {t("qr-code-overlay-primary.download-app")}
                  </sc.DownloadApp>
                  <sc.DownloadAppButtons>
                    <DownloadAppButton
                      dataCy="NewVisitorOverlay-Download-btn-appstore"
                      store="app-store"
                      width={134}
                      height={40}
                      storeUrl="https://apps.apple.com/app/uon-earth/id6470965614"
                      buttonStyle="white"
                    />

                    <DownloadAppButton
                      dataCy="NewVisitorOverlay-Download-btn-googleplay"
                      store="google-play"
                      width={134}
                      height={40}
                      storeUrl="https://play.google.com/store/apps/details?id=uon.earth"
                      buttonStyle="white"
                    />
                  </sc.DownloadAppButtons>
                </sc.DownloadAppBlock>
              </sc.DownloadAppCard>
              <sc.AboutBlock>
                <sc.AboutTitle data-cy="NewVisitorOverlay-Guide-title">
                  <DangerouslyHTMLRenderer>
                    {t("qr-code-overlay-primary.title")}
                  </DangerouslyHTMLRenderer>
                </sc.AboutTitle>
                <sc.AboutDesBlock>
                  <sc.AboutDes data-cy="NewVisitorOverlay-Guide-step1">
                    {t("qr-code-overlay-primary.description")}
                  </sc.AboutDes>
                  <sc.HaveAccount>
                    {t("qr-code-overlay-primary.have-account")}
                  </sc.HaveAccount>
                </sc.AboutDesBlock>
                <sc.ButtonsContainer>
                  <sc.WatchVideo
                    data-cy="NewVisitorOverlay-WatchVideo-btn"
                    onClick={onWatchVideoButtonClicked}
                  >
                    {t("qr-code-overlay-primary.buttons.watch-video")}
                  </sc.WatchVideo>
                  <sc.VisitEarth onClick={() => onLoginForPartnerClicked()}>
                    {t("qr-code-overlay-primary.buttons.login")}
                  </sc.VisitEarth>
                  <Link
                    href="/[vanityName]/[deckName]"
                    as="/earthtoday/partnerships"
                    passHref={true}
                    legacyBehavior={true}
                  >
                    <sc.BecomePartner>
                      {t("qr-code-overlay-primary.buttons.become-partner")}
                    </sc.BecomePartner>
                  </Link>
                </sc.ButtonsContainer>
              </sc.AboutBlock>
            </>
          ) : (
            <>
              <sc.QrCodeCard>
                {isQrCodeExpired && (
                  <sc.QrCodeExpiredOverlay>
                    <sc.ExpiredDes>
                      {t("qr-code-overlay-secondary.expired-des")}
                    </sc.ExpiredDes>
                    <sc.ReloadButon onClick={onReloadBtnClicked}>
                      {t("qr-code-overlay-secondary.reload")}
                    </sc.ReloadButon>
                  </sc.QrCodeExpiredOverlay>
                )}
                {isLoading ? (
                  <sc.LoaderContainer>
                    <CustomLoaderV2
                      driver={{
                        text: "Just a moment",
                        subText: "We're generating QR-code...",
                        bgColor: etGreen,
                        spinnerColor: etBlack,
                        isDisableAnimation: disableLoaderAnimation,
                      }}
                    />
                  </sc.LoaderContainer>
                ) : (
                  <Image
                    data-cy="QrCodeOverlay-QR-code"
                    src={qrCodeUrl}
                    alt="qr-code"
                    width={304}
                    height={304}
                  />
                )}
                <sc.DownloadAppContainer>
                  <sc.DownloadDes data-cy="QrCodeOverlay-Download-des">
                    {t("qr-code-overlay-secondary.download-des1")}
                  </sc.DownloadDes>
                  <sc.DownloadDes2 data-cy="QrCodeOverlay-Download-des2">
                    {t("qr-code-overlay-secondary.download-des2")}
                  </sc.DownloadDes2>
                  <sc.DownloadAppButtonsWrapper>
                    <DownloadAppButton
                      dataCy="QrCodeOverlay-Download-btn-appstore"
                      store="app-store"
                      width={134}
                      height={40}
                      storeUrl="https://apps.apple.com/app/uon-earth/id6470965614"
                      buttonStyle="white"
                    />

                    <DownloadAppButton
                      dataCy="QrCodeOverlay-Download-btn-googleplay"
                      store="google-play"
                      width={134}
                      height={40}
                      storeUrl="https://play.google.com/store/apps/details?id=uon.earth"
                      buttonStyle="white"
                    />
                  </sc.DownloadAppButtonsWrapper>
                </sc.DownloadAppContainer>
              </sc.QrCodeCard>
              <sc.GuideBlock>
                <sc.GuideTitle data-cy="QrCodeOverlay-Guide-title">
                  {t("qr-code-overlay-secondary.guide.title")}
                </sc.GuideTitle>
                <sc.GuildStepsBlock>
                  <sc.GuideStep data-cy="QrCodeOverlay-Guide-step1">
                    {t("qr-code-overlay-secondary.guide.step-1")}{" "}
                    <sc.StepImage src={appLogo} />
                  </sc.GuideStep>
                  <sc.GuideStep data-cy="QrCodeOverlay-Guide-step2">
                    {t("qr-code-overlay-secondary.guide.step-2")}{" "}
                    <IconSvg
                      icon="scan-qr"
                      height="30px"
                      width="30px"
                      pointer={false}
                      padding="0"
                      fillColor={etWhite}
                    />
                  </sc.GuideStep>
                  <sc.GuideStep data-cy="QrCodeOverlay-Guide-step3">
                    {t("qr-code-overlay-secondary.guide.step-3")}{" "}
                    <sc.StepImage src={qrCodeSample} />
                  </sc.GuideStep>
                </sc.GuildStepsBlock>
                <sc.HaveAccount>
                  {t("qr-code-overlay-secondary.have-account")}
                </sc.HaveAccount>
                <sc.ButtonsWrapper>
                  <sc.BackBtn
                    data-cy="QrCodeOverlay-Back-btn"
                    onClick={onBackBtnClicked}
                  >
                    {t("qr-code-overlay-secondary.back-btn")}
                  </sc.BackBtn>
                  <sc.LearnMore
                    data-cy="QrCodeOverlay-LearnMore-btn"
                    onClick={onLearnMoreBtnClicked}
                  >
                    {t("qr-code-overlay-secondary.learn-more")}
                  </sc.LearnMore>
                </sc.ButtonsWrapper>
              </sc.GuideBlock>
            </>
          )}
        </sc.Content>
      </sc.Container>
      <sc.CloseBtnContainer>
        <sc.CloseBtnWrapper
          data-cy="QrCodeOverlay-Close-btn"
          onClick={onCloseButtonClicked}
        >
          <IconSvg
            icon="close-v2"
            height="20px"
            width="20px"
            pointer
            padding="0"
            fillColor={etWhite}
          />
        </sc.CloseBtnWrapper>
      </sc.CloseBtnContainer>

      {shouldRenderVideoPlayer && (
        <sc.MoviePlayer onClick={onVideoOverlayClicked}>
          <sc.MovieWrapper>
            <VimeoPlayer
              video="436156405"
              url="https://vimeo.com/436156405"
              height="100%"
              width="100%"
              controls={true}
              config={{
                playerOptions: {
                  autoplay: true,
                  loop: false,
                  quality: "auto",
                  responsive: true,
                },
              }}
            />
          </sc.MovieWrapper>
        </sc.MoviePlayer>
      )}
    </sc.FixedContainer>
  ) : null;
});
