import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mobileMaxHeight,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import { etBlack, etGray, etWhite } from "../../shared/colors";

namespace sc {
  export const NotificationOverLay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
  `;
  export const Notification = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40px;
    right: 0;
    padding: 22px 12px 12px 12px;
    overflow: hidden auto;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 18px 0px rgba(42, 54, 66, 0.2);
    height: 326px;
    width: 351px;
    background-color: ${etWhite};
    @media (max-width: ${smallScreenUpperLimit}) {
      position: fixed;
      top: 56px;
      left: 100%;
      transform: translateX(-103%);
      width: calc(100vw - 16px);
      max-width: 351px;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) => props.theme.themeStyle.defaultBackground};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
    }
    &.mLandscape {
      background-color: ${etBlack};
    }
  `;
  export const NotificationTitle = styled.h3`
    font-size: 20px;
    font-weight: bold;
  `;
  export const NotificationEmpty = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${etGray};
    font-size: 14px;
  `;
  export const NotificationBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;
}

export default sc;
