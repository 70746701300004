import styled from "styled-components";

import { etBrightLightBlue, etDark, etWhite } from "../../shared/colors";

namespace sc {
  export const Header = styled.div`
    position: relative;
    padding: 0;
  `;
  export const HeaderTitle = styled.h2`
    font-family: Roboto;
    font-weight: 500;
    font-stretch: normal;
    font-size: 20px;
    height: 24px;
    letter-spacing: 0px;
    color: ${etDark};
    margin-bottom: 43px;
  `;
  export const Body = styled.div`
    font-family: Roboto;
    font-weight: 400;
    font-stretch: normal;
    font-size: 14px;
    letter-spacing: 0px;
    height: 102px;
    line-height: 20px;
    color: ${etDark};
  `;
  export const MailtoLink = styled.a`
    display: block;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    margin-top: 8px;
    border-radius: 4px;
    background-color: ${etBrightLightBlue};
    color: ${etWhite};

    &:hover {
      color: ${etWhite};
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;
  export const Contact = styled.div`
    padding-top: 20px;
  `;
}

export default sc;
