import styled from "styled-components";

import { etGray, etHeatherBlue, etWhite } from "../../shared/colors";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const Container = styled.div`
    position: absolute;
    transition: 0.5s;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translate(0, 100%);
    padding: 16px;
    background-color: ${etWhite};
    box-shadow: 0 16px 24px 0 rgba(42, 54, 66, 0.2);
    border-radius: 0 0 0 8px;
    z-index: ${zIndex.CONTEXT};
    visibility: hidden;
    &.active {
      transform: translate(0, 0);
      visibility: visible;
    }
    @media (max-width: 767.98px) {
      border-radius: 0 0 8px 8px;
    }
  `;

  export const CardSharingTitle = styled.h2`
    font-weight: 900;
    font-size: 24px;
    line-height: 1.3;
    margin: 0 0 8px;
    color: ${etHeatherBlue};
  `;

  export const Close = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px 8px 0 0;
    padding: 0;
  `;

  export const LogoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `;

  export const CardLinkContainer = styled.div`
    width: 100%;
    text-align: center;
  `;

  export const CardLink = styled.span`
    font-size: 12px;
    line-height: 1.3;
    color: ${etGray};
    word-break: break-all;
  `;
}

export default sc;
