import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import { etWhite } from "../../shared/colors";
import { DECK_NAME_MAX_LENGTH_SINGLE_SIZE } from "../../shared/constants";
import { getLogoColor } from "../../shared/helpers/getLogoColor";
import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";
import { MasterLinkType } from "../../shared/models/Card";
import { ICardItemContent } from "../CardItem/CardItem";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardItemDraftMusic.styled";

interface DriverCardDraftMusic {
  readonly cardType: MasterLinkType;
  readonly isCardValidToPublish: boolean;
  readonly id: string;
  readonly cardContent: ICardItemContent | null;
  readonly vanityName: string;
  readonly curatorName: string;
  readonly comment: string;
  readonly deckPathString: string;
  readonly categoryPrinciple: string;
  readonly deckName: string;
  readonly previewImage: string;
  readonly cardDraftMusicPreviewImageBackground: string;
  readonly contextMenuActive: boolean;

  onOpenModalCardEdit?(): void;
  onEditCard?(): void;
}

interface IProps {
  driver: DriverCardDraftMusic;
  renderMenuContext(): ReactNode;
  renderDraftFooter(): ReactNode;
}

export const CardItemDraftMusic = observer(function CardItemDraftMusic(
  props: IProps,
) {
  const { driver, renderMenuContext, renderDraftFooter } = props;
  const { cardContent } = driver;

  const color = getLogoColor(driver.categoryPrinciple);

  return (
    <sc.Container bg={driver.cardDraftMusicPreviewImageBackground}>
      <sc.ContainerContent>
        {driver.contextMenuActive && <sc.BlackOverlay />}
        <sc.Header
          iconColor={color}
          data-icon={`cat-${driver.categoryPrinciple}`}
        >
          {driver.categoryPrinciple ? (
            <IconSvg
              data-cy="CardItemMusic-Icon"
              icon={`cat-${driver.categoryPrinciple}`}
              width="19px"
              height="18px"
              padding="0"
              color={color}
            />
          ) : undefined}
          {driver.deckPathString ? (
            <Link
              href="/[vanityName]/[deckName]"
              as={`/${driver.deckPathString}`}
              passHref
              legacyBehavior
            >
              <sc.HeaderTitle
                data-cy="CardItemMusicDraft-HeaderTitle"
                title={driver.deckName}
                isEmptyCategories={driver.categoryPrinciple === ""}
              >
                #
                {truncateWithEllipsis(
                  driver.deckName,
                  DECK_NAME_MAX_LENGTH_SINGLE_SIZE,
                )}
              </sc.HeaderTitle>
            </Link>
          ) : (
            <sc.NoDeckSelected
              isEmptyCategories={driver.categoryPrinciple === ""}
            >
              #NoDeckselected
            </sc.NoDeckSelected>
          )}
          <sc.ContextWrapper>{renderMenuContext()}</sc.ContextWrapper>
        </sc.Header>
        <sc.Wrap>
          {cardContent && cardContent.content?.source?.url ? (
            <sc.CoverContainer>
              <sc.PreviewImage
                data-cy="CardItemMusic-PreviewImage"
                src={driver.previewImage}
              >
                <sc.OverlaySource />
                <sc.CardSource href={cardContent.content.url} target="_blank">
                  {cardContent.content?.source.name}
                  <sc.ArrowLink>
                    <IconSvg
                      icon="carditem-link-v2"
                      width="11px"
                      height="11px"
                      padding="0"
                      margin="auto 0 auto 6px"
                      color={etWhite}
                      pointer
                    />
                  </sc.ArrowLink>
                </sc.CardSource>
              </sc.PreviewImage>
              <sc.Body
                data-cy="CardItemMusic-Body"
                href={cardContent.content?.source.url}
                target="_blank"
              >
                <sc.BodyTitle data-cy="CardItemMusic-BodyTitle">
                  {cardContent.content.title}
                </sc.BodyTitle>
                <sc.BodyAuthor data-cy="CardItemMusic-BodyAuthor">
                  {cardContent.content.authors[0] &&
                    cardContent.content.authors[0].name}
                </sc.BodyAuthor>
              </sc.Body>
            </sc.CoverContainer>
          ) : (
            <sc.RemovedCardBody data-cy="RemovedCardBody">
              <sc.RemovedCardBodyTitle>
                NO LONGER AVAILABLE
              </sc.RemovedCardBodyTitle>
              <sc.RemovedCardBodyText>
                This card was deleted by its original curator
              </sc.RemovedCardBodyText>
            </sc.RemovedCardBody>
          )}
        </sc.Wrap>
        {renderDraftFooter()}
      </sc.ContainerContent>
    </sc.Container>
  );
});
