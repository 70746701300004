/* eslint-disable unicorn/consistent-function-scoping */
import {
  ImageResponseDto,
  PhotoState,
  PromotionImageShape,
} from "@earthtoday/contract";
import { action, computed, flow, makeObservable, observable } from "mobx";

import { CardItemLocation } from "../components/CardItem/CardItem";
import { UserProfileCard } from "../components/CardProfile/CardProfile";
import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { TokenSwitchPayload, TokenType } from "../shared/apis/UserSessionApi";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import { CardRegular } from "../shared/models/Card";
import {
  CookieConsent,
  GroupInfo,
  GroupMemberRole,
  GroupProfileType,
  IUserCounts,
  Presentation,
  User,
  UserDetails,
  UserType,
} from "../shared/models/User";
import { IOption, LazyModal } from "./ModalStore";
import { ITokenStore } from "./TokenStore";

export const CARD_PROFILE_IMAGE_WIDTH: number = 120;
export const CARD_PROFILE_IMAGE_HEIGHT: number = 120;
export interface ProfileSession {
  readonly allowSwitchToken: boolean;
}
export type UserModelConnectorDependencies = Pick<
  UserModel,
  | "userCounts"
  | "userType"
  | "image"
  | "fullName"
  | "vanityName"
  | "enableGiftLastUon"
> & {
  groupProfileType?: GroupProfileType;
};

export class UserModel implements UserProfileCard {
  @observable user: User;

  constructor(
    user: User,
    private userSessionStore: IUserSessionStore,
    private tokenStore: ITokenStore,
    private profileSession?: ProfileSession,
    private openLazyModal?: (
      modal?: LazyModal | undefined,
      options?: IOption | undefined,
    ) => void,
    private featureFlaggingData?: {
      flags: {
        enableCollectibleUon: boolean;
      };
    },
  ) {
    makeObservable(this);
    this.user = user;
  }

  toJSON(): User {
    return this.user;
  }

  @computed get allowSwitchToken(): boolean {
    return !!this.profileSession?.allowSwitchToken;
  }

  @computed get id(): string {
    return this.user.id;
  }

  @computed get vanityName(): string {
    return this.user.vanityName;
  }

  @computed get firstName(): string {
    return this.user.firstName;
  }

  @computed get lastName(): string {
    return this.user.lastName;
  }

  @computed get fullName(): string {
    return this.user.fullName;
  }

  @computed get darkMode(): boolean {
    return this.user.darkMode;
  }

  @computed get bio(): string {
    return this.user.bio || "";
  }

  @computed get provider(): string {
    return this.user.provider;
  }

  @computed get providerId(): string {
    return this.user.providerId;
  }

  @computed get emailAddress(): string {
    return this.user.emailAddress;
  }

  @computed get emailAddressVerified(): boolean {
    return this.user.emailAddressVerified;
  }

  @computed get administrator(): boolean {
    return this.user.administrator;
  }
  @computed get isEmailPublic(): boolean {
    return this.user.isEmailPublic || false;
  }
  @computed get joined(): Date {
    return this.user.joined;
  }
  @computed get imageData(): ImageResponseDto {
    return this.user.image;
  }

  @computed get cover(): ImageResponseDto | undefined {
    return this.user.cover;
  }
  @computed get details(): UserDetails | undefined {
    return this.user.details;
  }
  @computed get quote(): string {
    return this.user.details?.quote || "";
  }
  @computed get termsAccepted(): boolean {
    return this.user.termsAccepted;
  }
  @computed get termsExpired(): boolean {
    return this.user.termsExpired;
  }
  @computed get cookieConsent(): CookieConsent {
    return this.user.cookieConsent;
  }
  @computed get userCounts(): IUserCounts {
    return this.user.userCounts;
  }
  @computed get followerCounts(): number {
    return this.user.userCounts.followerCounts || 0;
  }
  @computed get website(): string | undefined {
    return this.user.website;
  }
  @computed get twitterUsername(): string | undefined {
    return this.user.twitterUsername;
  }
  @computed get facebookUsername(): string | undefined {
    return this.user.facebookUsername;
  }
  @computed get instagramUsername(): string | undefined {
    return this.user.instagramUsername;
  }
  @computed get linkedInUsername(): string | undefined {
    return this.user.linkedInUsername;
  }
  @computed get presentation(): Presentation | undefined {
    return this.user.presentation;
  }
  @computed get curator(): boolean {
    return this.user.curator;
  }
  @computed get featuredCard(): CardRegular | undefined {
    return this.user.featuredCard;
  }
  @computed get isPublished(): boolean | undefined {
    if (this.userSessionStore.isGroupPublished) {
      return true;
    }
    return this.user.isPublished;
  }
  @computed get metaTags(): { [tagName: string]: string } | undefined {
    return this.user.metaTags;
  }

  @computed get image(): string {
    return getResizeImageUrl(this.user.image, {
      width: CARD_PROFILE_IMAGE_WIDTH,
      height: CARD_PROFILE_IMAGE_HEIGHT,
    });
  }

  @computed get imageCover(): string {
    return this.cover?.url || "";
  }

  @computed get userType(): UserType {
    return this.user.details?.type || UserType.CONSUMER;
  }

  @computed get uonCount(): number {
    const { uonCount } = this.userCounts;
    return uonCount && uonCount >= 0 ? uonCount : 0;
  }

  @computed get receivedUonCount() {
    return this.userCounts.receivedUonCount;
  }

  @computed get tagline() {
    if (this.profileTagOverride) {
      return this.profileTagOverride;
    }
    if (this.forceUseTagLine) {
      return this.user.tagline;
    }

    if (this.userSubTitle) {
      return "";
    }

    return this.user.tagline;
  }

  @computed get profileTagOverride() {
    return this.user.profileTagOverride || "";
  }

  @computed get forceUseTagLine(): boolean {
    return this.user.forceUseTagLine || false;
  }

  @computed get userSubTitle(): string {
    if (["earthtoday", "old_brand_earthtoday"].includes(this.user.vanityName)) {
      return "profilecard.role.community";
    }
    if (this.user.details?.type === UserType.UNCLASSIFIED_ORG) {
      return "profilecard.role.org";
    }

    if (this.userType !== UserType.GROUP_ADAPTER) {
      return "";
    }

    if (this.groupProfileType === GroupProfileType.COMMUNITY) {
      return "profilecard.role.community";
    }

    if (this.groupProfileType === GroupProfileType.PARTNER) {
      return "profilecard.role.partner";
    }
    if (this.groupProfileType?.toLowerCase() === GroupProfileType.NPO) {
      return "profilecard.role.npo";
    }
    if (this.groupProfileType === GroupProfileType.BRAND) {
      return "profilecard.role.brand";
    }

    if (this.groupProfileType === GroupProfileType.CHARITY) {
      return "profilecard.role.charity";
    }
    return "";
  }

  @computed get group(): GroupInfo | undefined {
    return this.user.group;
  }

  @computed get groupProfileType(): GroupProfileType | undefined {
    if (!this.group) return undefined;
    return this.user.group?.profileType || undefined;
  }

  @computed get groupMemberRole(): GroupMemberRole | undefined {
    return this.user.groupMemberRole;
  }

  @computed get userImage(): {
    id: string;
    url: string;
  } {
    return {
      id: this.user?.image?.id || "",
      url: this.user?.image?.url || "",
    };
  }

  @computed get isDisabledProtect(): boolean {
    return this.user.isDisabledProtect || false;
  }

  @action.bound setImage(image: ImageResponseDto): void {
    this.user.image = image;
    this.user.photoState = PhotoState.CUSTOM;
  }
  @action.bound switchToken = flow(function* (
    this: UserModel,
    redirect?: () => void,
  ) {
    const payload: TokenSwitchPayload = {
      targetType: this.userSessionStore?.isGroupToken
        ? TokenType.USER
        : TokenType.GROUP,
      refreshToken: this.tokenStore?.getRefreshToken() || "",
      groupId: this.user.group?.id,
    };

    this.userSessionStore?.switchToken(payload, redirect);
  });

  @action.bound updateUonCount(uonCount: number): void {
    this.user.userCounts.uonCount = uonCount;
  }

  @action.bound updateUserCounts(userCounts: IUserCounts): void {
    this.user.userCounts = userCounts;
  }

  @action.bound setCookieConsent = (consent: CookieConsent): void => {
    this.user.cookieConsent = consent;
  };

  @action.bound public setUser = (user: User): void => {
    this.user = user;
  };

  @action.bound public setCover = (cover: ImageResponseDto): void => {
    this.user.cover = cover;
  };

  @action.bound setFirstName = (firstName: string): void => {
    this.user.firstName = firstName;
  };
  @action.bound setLastName = (lastName: string): void => {
    this.user.lastName = lastName;
  };
  @action.bound setFullName = (): void => {
    this.user.fullName = `${this.firstName} ${this.lastName}`;
  };
  @action.bound setBio = (bio: string): void => {
    this.user.bio = bio;
  };
  @action.bound setVanityName = (vanity: string): void => {
    this.user.vanityName = vanity;
  };
  @action.bound setDetails = (details: UserDetails): void => {
    this.user.details = details;
  };

  @action.bound setSocialDetails = (
    website: string,
    facebook: string,
    twitter: string,
    instagram: string,
    linkedin: string,
  ): void => {
    this.user.website = website;
    this.user.facebookUsername = facebook;
    this.user.twitterUsername = twitter;
    this.user.instagramUsername = instagram;
    this.user.linkedInUsername = linkedin;
  };
  @action.bound setPublishStatus(isPublished: boolean): void {
    this.user.isPublished = isPublished;
  }

  @action.bound setUserInformation = (
    firstName: string,
    lastName: string,
    bio: string,
  ): void => {
    this.setFirstName(firstName);
    this.setLastName(lastName);
    this.setFullName();
    this.setBio(bio);
  };

  @action.bound updateDraftingCardCounts(number: 1 | -1): void {
    this.userCounts.draftingCards += number;
  }
  @action.bound updateFollowerCounts(
    isFollowing: boolean,
    isInitialFollowing: boolean,
    initialFollowerCounts: number,
  ): void {
    if (isInitialFollowing) {
      this.userCounts.followerCounts = isFollowing
        ? initialFollowerCounts
        : initialFollowerCounts - 1;
    } else {
      this.userCounts.followerCounts = isFollowing
        ? initialFollowerCounts + 1
        : initialFollowerCounts;
    }
  }
  @action.bound updateCuratedDecks(number: 1 | -1): void {
    this.userCounts.curatedDecks += number;
  }

  @action.bound publishGroupProfile(): void {
    this.user.isPublished = true;
  }
  @action.bound updateGroupProfileType(
    groupProfileType: GroupProfileType,
  ): void {
    if (!this.user.group) {
      return;
    }
    this.user.group = { ...this.user.group, profileType: groupProfileType };
  }

  @computed get isOwnerView() {
    return (
      !!this.userSessionStore.user && this.userSessionStore.user.id === this.id
    );
  }

  @computed get photoState(): PhotoState {
    return this.user.photoState;
  }
  @action.bound onProfileImageZoneClicked() {
    if (!this.openLazyModal || !this.shouldEnableImageUploadZone) return;

    this.openLazyModal({
      name: "ModalCreateCardCollectable",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../components/ModalCreateCardCollectable/ModalCreateCardCollectableSmart"
            ).then((component) => component.ModalCreateCardCollectableSmart)
          }
          imageShape={PromotionImageShape.ROUND}
        />
      ),
    });
  }

  @action.bound onCardProfileMount(location: CardItemLocation) {
    this.location = location;
  }

  @observable location: CardItemLocation | null = null;

  @computed get shouldEnableImageUploadZone(): boolean {
    return (
      !!this.featureFlaggingData?.flags.enableCollectibleUon &&
      this.isOwnerView &&
      this.location === "profile"
    );
  }

  @computed get shouldRenderNewImageButton(): boolean {
    return (
      this.shouldEnableImageUploadZone &&
      this.photoState === PhotoState.INITIALS
    );
  }

  @action.bound setPhoto(photo: ImageResponseDto) {
    this.user.image = photo;
  }

  @computed get enableGiftLastUon(): boolean {
    return this.user.enableGiftLastUon;
  }

  @computed get isXIconVisable(): boolean {
    return !!this.user.twitterUsername;
  }
}
