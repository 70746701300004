import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  smallScreenLowerLimit,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
  xmediumScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etGreen,
  etLicoriceBlue,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const Layout = styled.div`
    position: relative;
    min-width: 100vw;
    max-width: 100vw;
    z-index: ${zIndex.OVERLAY_HOME_PAGE};
    background: ${etBlack}20;
    font-family: Roboto, sans-serif;
  `;

  export const CardWrap = styled.div`
    margin-left: calc(50% - 105px - 328px - 50px);
    @media (max-width: 1024px) {
      margin-left: calc(50% - 70px - 328px - 25px);
    }
    @media (max-width: 1023px) {
      margin-left: unset;
    }
  `;

  export const M2Text = styled.div`
    font-size: 52px;
    font-weight: bold;
    line-height: 2.3;

    > sup {
      top: -16px;
      font-size: 65%;
    }
  `;

  export const M2 = styled.div`
    color: ${etWhite};
    width: 99px;
    height: 99px;
    border: 5px solid ${etWhite};
    display: flex;
    justify-content: center;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 63px;
      height: 63px;

      ${M2Text} {
        font-size: 32px;
        > sup {
          top: -10px;
        }
      }
    }
  `;

  export const WrapSectionImage = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  `;

  export const EarthLines = styled.div`
    position: absolute;
    top: -35px;
    left: 0;
    height: calc(100% + 34px);
    width: 100%;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 1227px 100%;
    ${(props) =>
      props.theme.isWebpSupported
        ? `background-image: url(${require("../../assets/img/topomap.webp")});`
        : `background-image: url(${require("../../assets/img/topomap.png")});`}
    z-index: 4;

    --farthest-side: 50% 100%;
    --circle-radius: 68px;
    --mask: radial-gradient(
        circle farthest-side at var(--farthest-side),
        transparent var(--circle-radius),
        #000 calc(var(--circle-radius) + 2px) 100%
      )
      50% 50%/100% 100% no-repeat;
    -webkit-mask: var(--mask);
    -webkit-mask: var(--mask);
    mask: var(--mask);
  `;

  export const Background = styled.div`
    --farthest-side: 50% 103%;
    --circle-radius: 67px;
    --mask: radial-gradient(
        circle farthest-side at var(--farthest-side),
        transparent var(--circle-radius),
        #000 calc(var(--circle-radius) + 2px) 100%
      )
      50% 50%/100% 100% no-repeat;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  `;

  export const CloseOverLay = styled.div`
    position: absolute;
    z-index: 5;
    right: 8px;
    top: 8px;
  `;

  export const CloseOverLayButton = styled.span`
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      g {
        stroke: ${etWhite};
      }
    }
    &:hover svg {
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
    }
  `;

  export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  `;

  export const FlexWrap = styled.div`
    z-index: 4;

    max-width: 1044px;

    display: block;
    width: 100%;
    position: relative;

    @media (min-width: ${xLargeScreenLowerLimit}) {
      width: 1044px;
    }

    @media (max-width: 1023px) {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  `;

  export const FlexWrapCol = styled.div`
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    max-width: 1044px;

    @media (min-width: ${xLargeScreenLowerLimit}) {
      width: 1044px;
    }
  `;

  export const SectionContent = styled.div`
    position: absolute;
    left: calc(50% - 105px);
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1024px) {
      left: calc(50% - 70px);
    }

    @media (max-width: 1023px) {
      position: static;
      transform: unset;
    }
  `;

  export const ContentHeader = styled.div<{ color?: string }>`
    color: ${(props) => props.color || etLicoriceBlue};
    font-weight: bold;
    font-size: 60px;
    line-height: 45px;
    letter-spacing: -0.5px;
    margin-bottom: 16px;

    @media (max-width: 1023px) {
      font-size: 56px;
    }
  `;

  export const ContentTitle = styled.div`
    font-family: Roboto Condensed;
    font-weight: 400;
    font-size: 24px;
    font-style: normal;
    font-stretch: condensed;
    letter-spacing: 0px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 32px;

    white-space: nowrap;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      white-space: normal;
      font-size: 20px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 18px;
    }
  `;

  export const ContentDescription = styled.span`
    font-size: 24px;
    font-family: Roboto-Regular, sans-serif;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 34px;
    margin-bottom: 32px;
    display: block;
    max-width: 680px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      font-size: 21px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 18px;
    }
  `;

  export const ContentDescriptionAlignCenter = styled(ContentDescription)`
    text-align: center;
  `;

  export const Button = styled.button`
    background-color: ${etWhite};
    border-radius: 20px;
    width: 211px;
    display: flex;
    flex: 1;
    height: 40px;
    max-height: 40px;
    align-items: center;
    min-width: 200px;
    box-sizing: border-box;
    padding: 10px 40px;
    border: solid 1px transparent;
    cursor: pointer;
    justify-content: center;
    outline: none;
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const ButtonText = styled.span<{ color?: string }>`
    color: ${(props) => props.color || etLicoriceBlue};
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-stretch: condensed;
    font-size: 16px;
    text-transform: uppercase;
  `;

  export const SectionImage = styled.div`
    width: fit-content;
    display: flex;
    flex-flow: column;
    align-items: center;
  `;

  export const ImageBackGround = styled.div<{ src?: string }>`
    ${(props) =>
      props.src
        ? `background: url(${props.src});`
        : props.theme.isWebpSupported
        ? `background: url(${require("../../assets/img/MobilePhoneImages.webp")});`
        : `background: url(${require("../../assets/img/MobilePhoneImages.png")});`}

    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  `;

  export const MeterCounts = styled.span`
    font-family: Roboto-Regular, sans-serif;
    font-size: 50px;
    font-weight: normal;
    height: 56px;
    letter-spacing: 0px;
    line-height: 45.68px;
    text-align: center;
    color: ${etWhite};
    margin-top: 22px;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 40px;
    }
  `;

  export const PartnerLogo = styled.div<{ src: string }>`
    background: url(${(props) => props.src}) center center no-repeat;
    background-size: cover;
    margin: 12px;
  `;

  export const PartnersWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    ${PartnerLogo}:nth-child(1) {
      width: 152px;
      height: 82px;
    }
    ${PartnerLogo}:nth-child(2) {
      height: 62px;
      width: 151px;
    }
    ${PartnerLogo}:nth-child(3) {
      height: 59px;
      width: 143px;
    }
    ${PartnerLogo}:nth-child(4) {
      height: 77px;
      width: 60px;
    }

    padding-bottom: 66px;

    @media (max-width: ${smallScreenUpperLimit}) {
      ${PartnerLogo}:nth-child(1) {
        width: 109px;
        height: 59px;
      }
      ${PartnerLogo}:nth-child(2) {
        height: 43px;
        width: 109px;
      }
      ${PartnerLogo}:nth-child(3) {
        height: 42px;
        width: 103px;
      }
      ${PartnerLogo}:nth-child(4) {
        height: 56px;
        width: 43px;
      }
    }
  `;

  export const ButtonCloseWrap = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  `;

  export const ButtonInner = styled.div`
    width: 40px;
    height: 40px;
    color: ${etBrightLightBlue};
    background: ${etWhite};
    border-radius: 50%;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: ${etWhite};
      background: ${etBrightLightBlue};
    }
  `;

  export const ButtonClose = styled.span`
    border-radius: 50%;
    display: inline-block;
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 3px;
  `;

  export const ActualProtected = styled.div`
    color: ${etWhite};
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-stretch: condensed;
    font-size: 14px;
    letter-spacing: 0px;
    text-align: center;
  `;

  const Section = styled.section`
    padding: 117px 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    max-height: fit-content;
    min-height: 676px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      padding-left: 84px;
      padding-right: 84px;
    }

    @media (max-width: 1024px) {
      min-height: 640px;
    }
  `;

  export const FirstSection = styled(Section)`
    position: relative;
    ${(props) =>
      props.theme.isWebpSupported
        ? `background: url(${require("../../assets/img/NATURE-IMAGE-x3.webp")});`
        : `background: url(${require("../../assets/img/NATURE-IMAGE-x3.jpg")});`}
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: ${etBlack};

    ${SectionContent} {
      color: ${etWhite};
      max-width: 668px;
      width: 668px;
    }
    ${Button} {
      background-color: ${etBrightLightBlue};
    }

    @media (max-width: ${xLargeScreenLowerLimit}) {
      ${SectionContent} {
        width: 584px;
      }
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      ${(props) =>
        props.theme.isWebpSupported
          ? `background-image: url(${require("../../assets/img/NATURE-IMAGE-x3.webp")});`
          : `background-image: url(${require("../../assets/img/NATURE-IMAGE-x3.jpg")});`}
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      ${(props) =>
        props.theme.isWebpSupported
          ? `background-image: url(${require("../../assets/img/NATURE-IMAGE-x2.webp")});`
          : `background-image: url(${require("../../assets/img/NATURE-IMAGE-x2.jpg")});`}
      background-size: cover;
    }

    @media (max-width: ${smallScreenLowerLimit}) {
      ${(props) =>
        props.theme.isWebpSupported
          ? `background-image: url(${require("../../assets/img/NATURE-IMAGE-x1.webp")});`
          : `background-image: url(${require("../../assets/img/NATURE-IMAGE-x1.jpg")});`}
      background-size: cover;
    }
  `;

  export const SecondSection = styled(Section)`
    background-color: ${etGreen};

    ${SectionContent} {
      color: ${etWhite};
      text-align: right;
      width: 610px;

      right: 0;
      transform: translate(calc(-50% - 94px), -50%);
      @media (max-width: ${xLargeScreenLowerLimit}) {
        transform: translate(calc(-50% - 94px), -50%);
      }
      @media (max-width: 1023px) {
        transform: unset;
      }
    }

    ${Button} {
      margin-left: auto;
    }

    ${ContentDescription} {
      margin-left: auto;
    }
  `;

  export const ThirdSection = styled(Section)`
    background-color: #81e05e;
    color: ${etLicoriceBlue};
    ${ImageBackGround} {
      max-height: 411px;
      height: 411px;
      width: 364px;
      @media (max-width: ${xLargeScreenLowerLimit}) {
        margin-right: 0;
        width: 320px;
        height: 300px;
      }
    }
    @media (min-width: ${xLargeScreenLowerLimit}) {
      ${ContentDescription} {
        max-width: 756px;
        width: 756px;
      }
    }
  `;

  export const FourthSection = styled(Section)`
    background-color: #a0e886;

    ${SectionContent} {
      position: static;
      transform: unset;
    }

    ${ContentDescription} {
      max-width: 630px;
    }

    @media (min-width: ${xLargeScreenLowerLimit}) {
      ${ContentDescription} {
        max-width: 622px;
        width: 622px;
      }
    }

    @media (max-width: 1024px) and (min-width: 1024px) {
      ${SectionContent} {
        margin-left: 72px;
      }
    }
  `;

  export const MaskSection = styled.div`
    background-color: #c0efaf;
    height: 55px;
    width: 100%;
    --farthest-side: 50% 128%;
    --circle-radius: 67px;
    --mask: radial-gradient(
        circle farthest-side at var(--farthest-side),
        transparent var(--circle-radius),
        #000 calc(var(--circle-radius) + 2px) 100%
      )
      50% 50%/100% 100% no-repeat;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  `;

  export const FifthSection = styled(Section)`
    flex-flow: column;
    background-color: #c0efaf;
    position: relative;
    overflow: hidden;
    max-width: unset;

    ${ImageBackGround} {
      width: 219px;
      height: 428px;
      background-position-y: top;
      @media (min-width: 1090px) {
        height: 395px;
      }
      @media (max-width: 1023px) {
        margin-right: 0;
        height: 190px;
        margin-left: unset;
      }
    }

    ${SectionImage} {
      margin-left: calc(50% - 105px - 219px - 50px);
      @media (max-width: 1024px) {
        margin-left: calc(50% - 70px - 219px - 50px);
      }
      @media (max-width: 1023px) {
        margin-left: unset;
      }
    }

    @media (min-width: ${xLargeScreenLowerLimit}) {
      ${ContentDescription} {
        max-width: 748px;
        width: 748px;
      }

      ${FlexWrap} {
        align-items: flex-start;
      }
    }
  `;

  export const SixSection = styled.div`
    position: relative;
    width: 100vw;
    padding: 0;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${largeScreenLowerLimit}) {
      height: 296px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      height: 498px;
    }
  `;

  export const Container = styled.div`
    max-width: 100vw;
    min-width: 100vw;
    padding: 0;

    position: relative;

    @media (max-width: 1023px) {
      ${Section} {
        padding: 117px 32px;
      }

      ${FlexWrap} {
        flex-flow: column;
      }

      ${FlexWrapCol} {
        display: block;
      }

      ${SecondSection} {
        ${FlexWrap} {
          flex-flow: column-reverse;
        }
      }

      ${SectionContent} {
        margin-left: 0;
        margin-top: 32px;
        text-align: center;
        max-width: unset;
        width: auto;
      }
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      ${ContentHeader} {
        font-size: 43px;
      }

      ${ImageBackGround} {
        max-width: 300px;
        background-position: center;
      }
    }

    @media (max-width: 1023px) {
      ${FirstSection} {
        ${CardWrap} {
          display: none;
        }
      }

      ${SectionContent} {
        text-align: center;
      }

      ${Button} {
        margin-left: auto;
        margin-right: auto;
      }
    }
  `;

  export const LoaderWrapper = styled.div`
    position: relative;
    min-width: 100vw;
    max-width: 100vw;
    background-color: ${etWhite};
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.isMobileDevice && props.theme.themeStyle.defaultBackground};
    }
  `;
}

export default sc;
