import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { useEffect } from "react";

import ET from "../../assets/img/et-com-logo.png";
import Group from "../../components/MobileMenu/assets/group.png";
import Logout from "../../components/MobileMenu/assets/logout.png";
import Messages from "../../components/MobileMenu/assets/messages.png";
import Search from "../../components/MobileMenu/assets/search.png";
import Settings from "../../components/MobileMenu/assets/settings.png";
import {
  etBlack,
  etBlue,
  etForestGreen,
  etGrayBold,
  etGreen,
  etRed,
  etWhite,
} from "../../shared/colors";
import { MobileMenuItemId } from "../../stores/MobileMenuStore/MobileMenuItemId";
import {
  StaticMenuItem,
  StaticMenuItemType,
} from "../../stores/MobileMenuStore/MobileMenuStore";
import { sc } from "./MobileMenu.styled";

export interface MobileMenuDriverV2 {
  userCounts: {
    curatedDecks: number;
    followerCounts?: number;
    uonCount: number;
    groupCount?: number;
    publishedCards: number;
    draftingCards?: number;
  };
  messagesCount: {
    total: number;
    unread: number;
  };
  avatarImageUrl?: string;
  isMenuAppOpen: boolean;
  fullName?: string;
  vanityName?: string;
  onMount: () => void;
  onSearchMenuItemClick: () => void;
  onCollectMenuItemClick: () => void;
  onMessageMenuItemClick: () => void;
  onLogoutMenuItemClick: () => void;
  toggleMenuApp: () => void;
}
export interface MobileMenuProps {
  driver: MobileMenuDriverV2;
}

export const MobileMenu = observer((props: MobileMenuProps) => {
  const { driver } = props;

  const menuItem: Array<StaticMenuItem> = [
    {
      type: StaticMenuItemType.ACTION,
      formatedName: "Search",
      id: MobileMenuItemId.SEARCH,
      borderColor: etWhite,
      backgroundColor: etBlack,
      color: etWhite,
      icon: Search,
      onClick: driver.onSearchMenuItemClick,
    },
    {
      type: StaticMenuItemType.LINK,
      formatedName: "About",
      id: MobileMenuItemId.ABOUT,
      formatedSubtitle: "EarthToday Purpose portal",
      borderColor: etWhite,
      backgroundColor: etBlack,
      color: etWhite,
      icon: ET,
      as: "/earthtoday/aboutearthtoday",
      href: "/[vanityName]/[deckName]",
    },
    {
      type: StaticMenuItemType.ACTION,
      formatedName: "#Collect",
      formatedSubtitle: "Uon collectibles",
      id: MobileMenuItemId.COLLECT,
      borderColor: etWhite,
      backgroundColor: etBlack,
      color: etWhite,
      icon: require("../../assets/img/m2-white.png"),
      onClick: driver.onCollectMenuItemClick,
    },
    {
      type: StaticMenuItemType.LINK,
      formatedName: "#Protect",
      formatedSubtitle: "Uon Nature Reserves",
      id: MobileMenuItemId.PROTECT,
      borderColor: etGreen,
      backgroundColor: etBlack,
      color: etGreen,
      icon: require("../../components/MobileMenu/assets/m2-white.png"),
      href: "/naturereserves",
      as: "/naturereserves",
    },
    {
      type: StaticMenuItemType.LINK,
      formatedName: driver.fullName || "Profile",
      formatedSubtitle: `${driver.userCounts.curatedDecks} Decks - ${driver.userCounts.followerCounts} Followers`,
      id: MobileMenuItemId.PROFILE,
      borderColor: etBlack,
      backgroundColor: etWhite,
      color: etBlack,
      icon: driver.avatarImageUrl || "",
      href: "/[vanityName]",
      as: `${driver.vanityName}`,
    },
    {
      type: StaticMenuItemType.LINK,
      formatedName: "My Uon",
      formatedSubtitle: `${driver.userCounts.uonCount} uon`,
      id: MobileMenuItemId.MY_UON,
      borderColor: etGreen,
      backgroundColor: etForestGreen,
      color: etGreen,
      icon: require("../../components/MobileMenu/assets/m2-green.png"),
      href: "/[vanityName]",
      as: `/${driver.vanityName}?tab=your-square-meters`,
    },
    {
      type: StaticMenuItemType.ACTION,
      formatedName: "Messages",
      formatedSubtitle: `${driver.messagesCount.total} messsages - ${driver.messagesCount.unread} unread`,
      id: MobileMenuItemId.MESSAGE,
      borderColor: etBlue,
      backgroundColor: "#002134",
      color: etBlue,
      icon: Messages,
      onClick: driver.onMessageMenuItemClick,
    },
    {
      type: StaticMenuItemType.LINK,
      formatedName: "Settings",
      formatedSubtitle: `${driver.userCounts.publishedCards} cards`,
      id: MobileMenuItemId.SETTING,
      borderColor: etGrayBold,
      backgroundColor: "#343434",
      color: etGrayBold,
      icon: Settings,
      href: "/settings",
      as: "/settings",
    },
    {
      type: StaticMenuItemType.LINK,
      formatedName: "Circles",
      formatedSubtitle: `${driver.userCounts.groupCount} groups - ${driver.userCounts.draftingCards} unpublished`,
      id: MobileMenuItemId.GROUP,
      borderColor: etGrayBold,
      backgroundColor: "#343434",
      color: etGrayBold,
      icon: Group,
      href: "/[vanityName]",
      as: `/${driver.vanityName}?tab=groups`,
    },
    {
      type: StaticMenuItemType.ACTION,
      formatedName: "Logout",
      id: MobileMenuItemId.LOGOUT,
      borderColor: etRed,
      backgroundColor: "#2F0702",
      color: etRed,
      icon: Logout,
      onClick: driver.onLogoutMenuItemClick,
    },
  ];

  useEffect(() => {
    driver.onMount();
  }, []);

  if (!driver.isMenuAppOpen) {
    return null;
  }

  return (
    <sc.Container className="visual-reg" data-cy="MobileMenu-Container">
      {menuItem.map((staticShorcut) => {
        return (
          <sc.ItemStaticContainer
            data-cy="MobileMenu-ItemStaticContainer"
            key={staticShorcut.id}
            textColor={staticShorcut.color}
            borderColor={staticShorcut.borderColor}
            backgroundColor={staticShorcut.backgroundColor}
          >
            {staticShorcut.type === StaticMenuItemType.LINK ? (
              <Link
                key={staticShorcut.id}
                href={`${staticShorcut.href}`}
                as={`${staticShorcut.as}`}
                passHref
              >
                <sc.ItemContent
                  data-cy="MobileMenu-ItemContent"
                  onClick={driver.toggleMenuApp}
                >
                  <sc.ItemImage
                    bg={staticShorcut.icon}
                    circle={staticShorcut.id === MobileMenuItemId.PROFILE}
                    data-cy="MobileMenu-ItemImage"
                  />
                  <sc.ItemTextContainer color={staticShorcut.color}>
                    <sc.ItemTitle data-cy="MobileMenu-ItemTitle">
                      {staticShorcut.formatedName}
                    </sc.ItemTitle>
                    {staticShorcut.formatedSubtitle && (
                      <sc.ItemSubtile data-cy="MobileMenu-ItemSubtile">
                        {staticShorcut.formatedSubtitle}
                      </sc.ItemSubtile>
                    )}
                  </sc.ItemTextContainer>
                </sc.ItemContent>
                <sc.ContextContainer>
                  <sc.Context />
                  <sc.Context />
                  <sc.Context />
                </sc.ContextContainer>
              </Link>
            ) : (
              <>
                <sc.ItemContent
                  data-cy="MobileMenu-ItemContent"
                  role="button"
                  onClick={staticShorcut.onClick}
                >
                  <sc.ItemImage
                    bg={staticShorcut.icon}
                    circle={staticShorcut.id === MobileMenuItemId.PROFILE}
                    data-cy="MobileMenu-ItemImage"
                  />
                  <sc.ItemTextContainer color={staticShorcut.color}>
                    <sc.ItemTitle data-cy="MobileMenu-ItemTitle">
                      {staticShorcut.formatedName}
                    </sc.ItemTitle>
                    {staticShorcut.formatedSubtitle && (
                      <sc.ItemSubtile data-cy="MobileMenu-ItemSubtile">
                        {staticShorcut.formatedSubtitle}
                      </sc.ItemSubtile>
                    )}
                  </sc.ItemTextContainer>
                </sc.ItemContent>
                <sc.ContextContainer>
                  <sc.Context />
                  <sc.Context />
                  <sc.Context />
                </sc.ContextContainer>
              </>
            )}
          </sc.ItemStaticContainer>
        );
      })}
    </sc.Container>
  );
});
