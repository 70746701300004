import styled from "styled-components";

import { etBlack, etWhite } from "../../../shared/colors";
import transparentize from "../../../shared/helpers/transparentize";

namespace sc {
  export const Container = styled.div<{
    color: string;
    bgColor: string;
  }>`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    position: relative;

    min-height: 496px;
    max-height: 496px;
    min-width: 328px;
    max-width: 328px;

    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    color: ${(props) => props.color};
    background-color: ${etWhite};
    background-image: ${(props) =>
      `linear-gradient(123.3deg, ${props.bgColor} 50%, ${transparentize(
        props.bgColor,
        0.8,
      )} 50%)`};
  `;

  export const CardTitle = styled.h3`
    margin-top: 22.5px;
    font-family: Roboto;
    font-size: 45px;
    font-weight: 300;
    height: 79px;
    letter-spacing: -0.5px;
    line-height: 55px;
  `;

  export const SubTitle = styled.h4<{ color: string }>`
    position: relative;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0px;
    line-height: 24px;
    text-transform: uppercase;

    ::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -8px;
      background-color: ${(props) => props.color};
    }
  `;

  export const BodyContent = styled.div`
    height: 180px;
  `;

  export const Description = styled.p`
    padding-top: 8px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    height: 88px;
    letter-spacing: 0px;
    line-height: 20px;
    width: 280px;
  `;

  export const LearnMoreBtn = styled.a`
    background-color: ${etWhite};
    color: ${etBlack};
    border-radius: 20px;
    height: 32px;
    width: 115px;
    padding: 7px;

    position: absolute;
    bottom: 24px;
    right: 24px;

    font-family: Roboto Condensed;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      color: ${etBlack};
      text-decoration: none;
    }
  `;
}

export { sc };
