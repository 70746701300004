import styled from "styled-components";

import { mediumScreenUpperLimit } from "../../shared/breakpoints";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Container = styled.ul`
    flex-grow: 0;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }

    ${IconSvgContainer} {
      margin-left: 0;
    }
  `;
}

export default sc;
