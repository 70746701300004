import styled from "styled-components";

import {
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
  xmediumScreenLowerLimit,
} from "../../../shared/breakpoints";
import { etBrightLightBlue, etWhite } from "../../../shared/colors";

namespace sc {
  export const Section = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `;

  export const Background = styled.div`
    @media (max-width: ${mediumScreenLowerLimit}) {
      img {
        object-position: -950px;
      }
    }
  `;
  export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 50px;

    width: 100%;
    height: 700px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    );
    ${(props) =>
      props.theme.isWebpSupported
        ? `background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${require("../../../assets/img/protect-section-eight.webp")});`
        : `background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${require("../../../assets/img/protect-section-eight.png")});`}

    @media (max-width: ${mediumScreenUpperLimit}) {
      justify-content: center;
      align-items: center;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      background-position: 67%;
      padding: 100px 0 103px 0;
    }
  `;

  export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 1244px;
    z-index: 1;

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  `;

  const Col = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
  `;

  export const ColLeft = styled(Col)`
    flex: 0 0 65%;
    text-align: center;
    label {
      color: ${etWhite};
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
  `;

  export const ColRight = styled(Col)`
    flex: 0 0 30%;
    margin-left: 30px;
    @media (max-width: ${xmediumScreenLowerLimit}) {
      display: none;
    }
  `;

  export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 44px;
  `;

  export const ButtonLinkPrimary = styled.a`
    border-radius: 23px;

    min-width: 180px;
    height: 40px;
    text-transform: uppercase;
    font-family: "Roboto Condensed";
    font-style: normal;
    font-size: 16px;

    line-height: normal;

    cursor: pointer;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${etBrightLightBlue};

    color: ${etWhite};

    &:hover {
      text-decoration: none;
      color: ${etWhite};
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-width: 211px;
      height: 40px;
    }
  `;
  export const Title = styled.label`
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-size: 60px;
    line-height: 65px;
    letter-spacing: -0.5px;
    text-align: left;
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 45px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      line-height: 50px;
      letter-spacing: -1px;
      width: 462px;
      height: 150px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      font-size: 40px;
      padding: 0 15px;
      width: 100%;
      height: fit-content;
    }
  `;
  export const SectionTitle = styled(Title)`
    text-align: center;
    @media (max-width: ${xmediumScreenLowerLimit}) {
      font-size: 45px;
      width: 100%;
      height: fit-content;
    }
  `;

  export const SubTitle = styled.label`
    font-family: "Roboto";
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    font-stretch: condensed;
    font-weight: normal;
    margin-bottom: 0;
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 20px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      padding: 0 10px;
    }
  `;
  export const SectionSubTitle = styled(SubTitle)`
    text-align: center;
    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 350px;
    }
  `;
}

export { sc };
