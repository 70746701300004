import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etGray,
  etGrayLight,
  etGreen,
  etRegentGray,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const SquareBox = styled.div`
    position: absolute;
    top: 15px;
    width: 50px;
    height: 50px;
    border: 3px solid ${etGreen};
    color: ${etGreen};
    //to detect mobile
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}),
      (orientation: landscape) and (hover: none) and (pointer: coarse) {
      top: 50px;
      width: 75px;
      height: 75px;
      border-width: 4.5px;
    }
  `;

  export const OfflineMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    padding: 16px;

    font-size: 21px;
    font-family: Roboto;
    color: ${etGray};
    background-color: ${etWhite};
    text-align: center;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 16px;
    }
  `;

  export const SquareMeter = styled.div`
    position: absolute;
    bottom: 0;
    left: 8px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    height: 34px;
    @media screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      height: 50px;
      font-size: 36px;
      font-stretch: normal;
    }
  `;

  export const SupMeter = styled.sup`
    font-size: 12px;
    @media screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      position: relative;
      top: -13px;
      left: 0;
      font-size: 18px;
    }
  `;

  export const Title = styled.div`
    position: absolute;
    top: 80px;
    color: ${(props) => props.theme.themeStyle.cardJoiningTextColor};
    font-family: Roboto;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;

    // to detect tablet and mobile landscape
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}),
      (orientation: landscape) and (hover: none) and (pointer: coarse) {
      position: absolute;
      top: 138px;
      font-size: 35px;
      height: 35px;
      line-height: 35px;
    }
    // mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
  `;

  export const Sup = styled.sup`
    position: relative;
    top: -4px;
    left: 0;
    font-size: 9px;
  `;

  export const Description = styled.div`
    position: absolute;
    top: 121px;
    height: 18px;
    padding: 0 4px;
    color: ${(props) => props.theme.themeStyle.cardJoiningTextColor};
    font-family: Roboto;
    font-weight: normal;
    font-stretch: condensed;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;

    // to detect tablet and mobile landscape
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}),
      (orientation: landscape) and (hover: none) and (pointer: coarse) {
      position: absolute;
      top: 176px;
      width: 496px;
      padding: 0;
      font-size: 15px;
      line-height: 18px;
    }
    // mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
  `;

  export const Button = styled.button`
    position: absolute;
    top: 162px;
    width: 180px;
    height: 40px;
    background-color: ${etGreen};
    border-radius: 23px;
    border: none;

    // to detect tablet and mobile landscape
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}),
      (orientation: landscape) and (hover: none) and (pointer: coarse) {
      top: 209px;
    }
  `;

  export const ButtonText = styled.div`
    height: 20px;
    color: ${etWhite};
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;

    :hover {
      text-decoration: none;
      color: ${etWhite};
    }
  `;

  export const Circle = styled.div`
    display: flex;
    width: 60px;
    height: 60px;
    background-color: ${(props) =>
      props.theme.themeStyle.cardJoiningOptionBackgroundColor};
    border-radius: 50%;
    > div > svg path {
      fill: ${(props) => props.theme.themeStyle.menuTextColor};
    }
    &.intro {
      > div > svg > g > g {
        stroke: ${(props) => props.theme.themeStyle.menuTextColor};
      }
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      > div > svg path {
        fill: ${(props) => props.theme.themeStyle.menuTextColor};
      }
      &.intro {
        > div > svg > g > g {
          stroke: ${(props) => props.theme.themeStyle.menuTextColor};
        }
      }

      background-color: ${(props) =>
        transparentize(
          props.theme.themeStyle.cardJoiningOptionBackgroundColor,
          0.25,
        )};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      > div > svg path {
        fill: ${etSolitudeLight};
      }
      &.intro {
        > div > svg > g > g {
          stroke: ${etSolitudeLight};
        }
      }
      background-color: ${transparentize(etRegentGray, 0.25)};
    }
    &.mLandscape {
      > div > svg path {
        fill: ${etSolitudeLight};
      }
      &.intro {
        > div > svg > g > g {
          stroke: ${etSolitudeLight};
        }
      }
      background-color: ${transparentize(etRegentGray, 0.25)};
    }
  `;

  export const Text = styled.div<{ isCurator?: boolean }>`
    display: flex;
    justify-content: center;
    margin-top: 3px;
    color: ${(props) => props.theme.themeStyle.cardJoiningTextColor};
    font-family: "Roboto Condensed";
    font-size: 10px;
    font-weight: normal;
    height: 20px;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    width: 60px;
    text-decoration: none;
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.cardJoiningTextColor};
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
  `;

  export const Option = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    height: 83px;
    text-decoration: none;
    :hover {
      text-decoration: none;
      cursor: pointer;
    }
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.defaultLayoutBackground};
    }
  `;

  export const Row = styled.div`
    position: absolute;
    top: 252px;
    display: flex;
    width: 250px;
    flex-flow: row nowrap;
    justify-content: space-between;

    // to detect tablet and mobile landscape
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}),
      (orientation: landscape) and (hover: none) and (pointer: coarse) {
      top: 288px;
    }
  `;

  export const RowSingle = styled(Row)`
    position: absolute;
    top: 353px;
    justify-content: center;

    // to detect tablet and mobile landscape
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}),
      (orientation: landscape) and (hover: none) and (pointer: coarse) {
      top: 384px;
    }
  `;

  export const CirclePlay = styled.div`
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: unset;
  `;

  export const IntroVideoOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: ${etGrayLight};
    opacity: 0.25;
  `;

  export const IntroVideoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    z-index: ${zIndex.INTRO_VIDEO};

    position: fixed;
    top: 0;
    left: 0;
  `;

  export const IntroVideoWrapper = styled.div<{
    shouldShowCookiePolicy: boolean;
  }>`
    background-color: ${etBlack};

    width: 90vw;
    height: calc(90vw * 759 / 1350);

    z-index: 5;
  `;

  export const Container = styled.div`
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
    width: 328px;
    min-height: 496px;
    margin-top: 24px;
    border-radius: 8px;
    background-color: ${etWhite};
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    @media (min-width: ${largeScreenLowerLimit}) {
      display: none;
    }
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      height: 496px;
      width: 680px;
      background-color: ${etWhite};
    }
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.cardMenuBackgroundColor};
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
    }
    &.mLandscape {
      background-color: ${etBlack};
    }
  `;
}

export default sc;
