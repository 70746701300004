/* eslint-disable unicorn/prefer-spread */
export const getCounterEffect = (counter: number): number => {
  const numberToArrayString = `${counter}`.split("");
  const numberFake = numberToArrayString
    .map((number, i) => {
      if (+number === 5 && i === 0) return number;
      return +number - 5 >= 0 ? +number - 5 : +number + 5;
    })
    .join("");
  return Number.parseInt(numberFake);
};
