import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import { etBlack, etRegentGray, etWhite } from "../../shared/colors";
import {
  CARD_SOURCE_MAX_LENGTH,
  DECK_NAME_MAX_LENGTH_SINGLE_SIZE,
} from "../../shared/constants";
import { getValidHttpUrl } from "../../shared/helpers/getValidHttpUrl";
import { getVideoId } from "../../shared/helpers/getVideoId";
import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";
import {
  CardSize,
  CardType,
  CardTypeRegular,
  MasterLinkType,
} from "../../shared/models/Card";
import { CardBaseContentType } from "../../stores/CardBaseModel";
import { Button } from "../Button/Button";
import { ButtonCircle } from "../ButtonCircle/ButtonCircle";
import { CardItemMetaData, ICardItemContent } from "../CardItem/CardItem";
import { CardItemFooter } from "../CardItem/CardItemFooter";
import { CardItemFooterAction } from "../CardItem/CardItemFooterAction";
import { TruncateWithEllipsis } from "../CardItem/TruncateWithEllipsis";
import CardItemLayout from "../CardItemLayout/CardItemLayout";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardItemVideo.styled";

export interface CardItemVideoDriver {
  readonly isCardDrafting: false;
  readonly cardType: MasterLinkType.VIDEO;
  readonly contentType: CardBaseContentType;
  readonly id: string;
  readonly metadata: CardItemMetaData | null;
  readonly cardContent: ICardItemContent | null;
  readonly vanityName: string;
  readonly curatorName: string;
  readonly comment: string;
  readonly deckName: string;
  readonly deckPathString: string;
  readonly category: string;
  readonly categoryPrinciple: string;
  readonly updatedDate: string;
  readonly previewImage: string;
  readonly pathSharing: string;
  readonly shareActive: boolean;
  readonly starActive: boolean;
  readonly contextMenuActive: boolean;
  readonly categoryColor: string;
  readonly isCardOwner: boolean;
  readonly isCardInFirstPosition?: boolean; // only card on homepage
  readonly isAutoplayedCard?: boolean;
  cardRepostIdUpdate(): void;
  cardRepostIdUpdate(): void;
  toggleShareActive(): void;
  toggleStarActive(): void;
  openContextMenu(): void;
  closeContextMenu(): void;

  onStarCard(): void;
}

export interface LayoutDriver {
  readonly cardSize: CardSize;
}

interface IProps {
  fullDetail?: boolean;
  driver: CardItemVideoDriver;
  layoutDriver: LayoutDriver;
  renderMenuContext(): ReactNode;
  renderSharing(): ReactNode;
  isCurrentUser?: boolean;
}
export const CardItemVideo = observer(function CardItemVideo(props: IProps) {
  const {
    driver,
    layoutDriver,
    fullDetail,
    renderMenuContext,
    renderSharing,
    isCurrentUser,
  } = props;
  const { cardContent } = driver;

  const videoId = getVideoId(cardContent?.content?.url || "");

  return (
    <CardItemLayout layoutDriver={layoutDriver}>
      <sc.Container className="visual-reg" data-cy="CardItemVideo-Container">
        {driver.contextMenuActive && <sc.BlackOverlay />}
        <sc.Header>
          {driver.deckPathString ? (
            <Link
              href="/[vanityName]/[deckName]"
              as={driver.deckPathString}
              passHref
              legacyBehavior
            >
              <sc.HeaderTitle
                data-cy="CardItemVideo-HeaderTitle"
                title={driver.deckName}
                isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
                iconColor={props.driver.categoryColor}
                data-icon={`cat-${driver.categoryPrinciple || driver.category}`}
              >
                <IconSvg
                  icon={`cat-${driver.categoryPrinciple || driver.category}`}
                  width="19px"
                  height="18px"
                  padding="0"
                  color={props.driver.categoryColor}
                />
                #
                <TruncateWithEllipsis
                  characterLimit={DECK_NAME_MAX_LENGTH_SINGLE_SIZE}
                  text={driver.deckName}
                />
              </sc.HeaderTitle>
            </Link>
          ) : (
            <sc.HeaderTitle
              data-cy="CardItemVideo-HeaderTitle"
              title={driver.deckName}
              isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
              iconColor={props.driver.categoryColor}
              data-icon={`cat-${driver.categoryPrinciple || driver.category}`}
            >
              <IconSvg
                icon={`cat-${driver.categoryPrinciple || driver.category}`}
                width="19px"
                height="18px"
                padding="0"
                color={props.driver.categoryColor}
              />
              #
              <TruncateWithEllipsis
                characterLimit={DECK_NAME_MAX_LENGTH_SINGLE_SIZE}
                text={driver.deckName}
              />
            </sc.HeaderTitle>
          )}

          <sc.ContextMenuContainer>
            {renderMenuContext()}
          </sc.ContextMenuContainer>
        </sc.Header>
        <sc.ContentContainer>
          {cardContent && cardContent.content?.source?.url ? (
            <sc.ContainerBody contextMenuActive={driver.contextMenuActive}>
              {fullDetail &&
              cardContent.content?.source.name.toLowerCase() === "youtube" ? (
                <sc.YoutubeIFrame
                  id={videoId}
                  title="YoutubeIFrame"
                  allowFullScreen
                  src={cardContent.content.url.replace("watch?v=", "embed/")}
                />
              ) : (
                <sc.PreviewImageContainer>
                  <sc.PreviewImage
                    data-cy="CardItemVideo-PreviewImage"
                    src={driver.previewImage}
                    target="_blank"
                    href={getValidHttpUrl(cardContent.content.url)}
                    isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
                    hasTitle={!!cardContent.content.title}
                  >
                    <sc.IconPlayContainer>
                      <IconSvg
                        opacity={0.7}
                        icon="play"
                        width="74px"
                        height="51px"
                        padding="0"
                        pointer
                        color={etBlack}
                        position="absolute"
                      />
                    </sc.IconPlayContainer>
                  </sc.PreviewImage>
                  <sc.ContainerButtonAction>
                    <sc.SaveButtonWrapper
                      isButtonHiden={driver.isCardOwner || !!isCurrentUser}
                    >
                      <Button
                        driver={{
                          dataCy: "CardItemVideo-SaveButton",
                          type: "primary",
                          fontSize: "14px",
                          fontStretch: "condensed",
                          height: "32px",
                          width: "88px",
                          round: true,
                          onClick: driver.cardRepostIdUpdate,
                        }}
                      >
                        SAVE
                        <IconSvg
                          data-cy="CardVideo-SaveIcon"
                          icon="save"
                          pointer
                          padding="0"
                          width="12px"
                          height="14px"
                          color={etWhite}
                        />
                      </Button>
                    </sc.SaveButtonWrapper>
                    <ButtonCircle
                      driver={{
                        margin: "0 8px",
                        type: "white",
                        dataCy: "CardItemVideo-ShareButton",
                        onClick: () => {
                          driver.toggleShareActive();
                          driver.closeContextMenu();
                        },
                      }}
                    >
                      <IconSvg
                        data-cy="CardVideo-ShareIcon"
                        icon="share"
                        pointer
                        padding="0"
                        width="12px"
                        height="14px"
                        color={etRegentGray}
                      />
                    </ButtonCircle>
                  </sc.ContainerButtonAction>

                  <sc.CardSource data-cy="CardItemVideo-CardSource">
                    <sc.ButtonLink
                      data-cy="CardItemVideo-VisitVideoBtn"
                      href={getValidHttpUrl(cardContent.content.url)}
                      target="_blank"
                    >
                      {truncateWithEllipsis(
                        cardContent.content?.source.name.toUpperCase(),
                        CARD_SOURCE_MAX_LENGTH,
                        "...",
                      )}
                      <sc.ContainerButtonIconLink>
                        <IconSvg
                          data-cy="CardAction-ShareIcon"
                          icon="carditem-link-v2"
                          pointer
                          padding="0"
                          width="11px"
                          height="11px"
                          color={etWhite}
                        />
                      </sc.ContainerButtonIconLink>
                    </sc.ButtonLink>
                  </sc.CardSource>
                  <sc.Gradient data-cy="CardItemVideo-OverlaySource" />
                </sc.PreviewImageContainer>
              )}
              <sc.Body
                data-cy="CardItemVideo-Body"
                href={getValidHttpUrl(cardContent.content.url)}
                target="_blank"
                isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
                hasTitle={!!cardContent.content.title}
              >
                <>
                  <sc.BodyText
                    data-cy="CardItemVideo-BodyText"
                    title={cardContent.content.title}
                    isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
                  >
                    {cardContent.content.title}
                  </sc.BodyText>
                </>
              </sc.Body>
              <sc.Overlay data-cy="CardItemVideo-Overlay" />
            </sc.ContainerBody>
          ) : (
            <sc.RemovedCardBody data-cy="RemovedCardBody">
              <sc.RemovedCardBodyTitle>
                NO LONGER AVAILABLE
              </sc.RemovedCardBodyTitle>
              <span>This card was deleted by its original curator</span>
            </sc.RemovedCardBody>
          )}

          <CardItemFooter
            driver={driver}
            layoutDriver={layoutDriver}
            renderCardSimpleAction={() => (
              <CardItemFooterAction driver={driver} />
            )}
          />
          {renderSharing()}
          <sc.OverlayLinkContainer data-cy="CardItem-OverlayLinkContainer">
            {driver.isCardInFirstPosition && cardContent ? (
              <a
                data-cy="CardItemVideo-CardInFirstPosition"
                target="_blank"
                href={getValidHttpUrl(cardContent.content.url)}
              />
            ) : (
              <Link
                href="/[vanityName]/[deckName]/[cardId]"
                as={`/${driver.deckPathString}/${driver.id}`}
                passHref
              ></Link>
            )}
          </sc.OverlayLinkContainer>
        </sc.ContentContainer>
      </sc.Container>
    </CardItemLayout>
  );
});
