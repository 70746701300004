import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { etWhite } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./LayoutModal.styled";

interface Props {
  renderModalWrapper(children: ReactNode): ReactNode;
  renderModalContent(): ReactNode;
  onClose(): void;
  useNewCloseBtn?: boolean;
  closeButtonTrackId?: string;
  isHideIconClose?: boolean;
}

export const LayoutModal = observer(function LayoutModal(props: Props) {
  return (
    <>
      {props.renderModalWrapper(
        <sc.Container data-cy="LayoutModal-Container" className="visual-reg">
          {props.isHideIconClose ? (
            <></>
          ) : props.useNewCloseBtn ? (
            <sc.NewCloseButton
              data-cy="LayoutModal-closeBtn-OneFlow"
              data-track-id={props.closeButtonTrackId}
              onClick={props.onClose}
            >
              <IconSvg
                icon="close"
                height="13px"
                width="13px"
                pointer
                color={etWhite}
                padding="0"
                margin="0px 0px -1px 1px"
              />
            </sc.NewCloseButton>
          ) : (
            <sc.Close
              data-cy="LayoutModal-closeBtn"
              data-track-id={props.closeButtonTrackId}
              type="button"
              onClick={props.onClose}
            >
              <IconSvg
                icon="close"
                height="16px"
                width="16px"
                pointer
                padding="0"
              />
            </sc.Close>
          )}

          <sc.Content>
            <>{props.renderModalContent()}</>
          </sc.Content>
        </sc.Container>,
      )}
    </>
  );
});
