import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import {
  largeScreenUpperLimitValue,
  mediumScreenLowerLimitValue,
  mediumScreenUpperLimitValue,
  smallScreenUpperLimitValue,
} from "../../shared/breakpoints";
import useWindowSize from "../../shared/helpers/useWindowSize";
import { CardArticleLayoutType, CardSize } from "../../shared/models/Card";
import sc from "./Grid.styled";

export interface RendererArgs {
  cardSize: CardSize;
  cardLayout?: CardArticleLayoutType;
}

export interface Renderer {
  size: CardSize;
  cardLayout?: CardArticleLayoutType;
  component: (arg: RendererArgs) => ReactNode;
}

interface IProps {
  renderer: Renderer[];
  padding?: string;
  minHeight?: string;
}

export const getGridColsNum = (width: number): number => {
  if (width <= mediumScreenLowerLimitValue) {
    return 1;
  }
  if (width <= mediumScreenUpperLimitValue) {
    return 2;
  }
  if (width <= largeScreenUpperLimitValue) {
    return 3;
  }
  return 4;
};

export const getCardColSize = (size: CardSize, width: number): number => {
  if (size === CardSize.SINGLE) {
    return 1;
  }

  if (size === CardSize.DOUBLE) {
    return 2;
  }

  if (size === CardSize.TRIPLE) {
    return 3;
  }

  if (size === CardSize.HEADER) {
    if (width <= mediumScreenUpperLimitValue) {
      return 1;
    } else if (width <= largeScreenUpperLimitValue) {
      return 2;
    }
  }

  if (width <= smallScreenUpperLimitValue) {
    return 1;
  }
  if (width <= largeScreenUpperLimitValue) {
    return 2;
  }
  return 3;
};

export const Grid = observer((props: IProps) => {
  const windowSize = useWindowSize();
  const gridColsNum = getGridColsNum(windowSize.width);

  let colIndex = 0;

  return (
    <sc.Grid
      className="visual-reg"
      padding={props.padding}
      minHeight={props.minHeight}
    >
      {props.renderer.map((item) => {
        const cardColsNum = getCardColSize(item.size, windowSize.width);
        const availableColsNum = gridColsNum - colIndex;
        colIndex += cardColsNum;

        if (colIndex >= gridColsNum) {
          colIndex = 0;
        }
        const cardSizeNum =
          cardColsNum > availableColsNum ? availableColsNum : cardColsNum;

        const { cardLayout } = item;
        const cardSize =
          cardSizeNum === 3
            ? CardSize.TRIPLE
            : cardSizeNum === 2
            ? CardSize.DOUBLE
            : CardSize.SINGLE;

        return item.component({
          cardLayout,
          cardSize,
        });
      })}
    </sc.Grid>
  );
});
