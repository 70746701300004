import { observer } from "mobx-react-lite";

import { etBlack, etGray, etWhite } from "../../shared/colors";
import { ACTION_CARD_BUTTON_TEXT_MAX_LENGTH } from "../../shared/constants";
import {
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ContextMenuCardDelete } from "../CardSimpleContext/ContextMenuCardDelete";
import ContextMenuCardEdit from "../CardSimpleContext/ContextMenuCardEdit";
import { ContextMenuCardOrder } from "../CardSimpleContext/ContextMenuCardOrder";
import { ContextMenuCardPublish } from "../CardSimpleContext/ContextMenuCardPublish";
import { ContextMenuCardReport } from "../CardSimpleContext/ContextMenuCardReport";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./CardItemActionContextMenu.styled";

export interface CardActionContextMenuDriver {
  contentType: CardType.ACTION;

  cardSize: CardSize;
  onCardSizeChanged(size: CardSize): void;

  textAlign: CardTextAlignment;
  onTextAlignmentChanged(textAlign: CardTextAlignment): void;

  textColor: CardTextColor;
  onTextColorChanged(textColor: CardTextColor): void;

  buttonText: string;
  onButtonTextInputChanged(value: string): void;

  onCardEditButtonClicked(): void;
  shouldRenderCardOrder: boolean;
  cardOrderInput: string | null;
  sortNumberInputError: string | null;
  onCardOrderChanged(order: string): void;
  onCardOrderBlured(): void;
  onCardDeleteButtonClicked(): void;
  onCardReportButtonClicked(): void;
  onCardPublishButtonClicked(): void;
  isGuestView: boolean;
  isPublishable: boolean;
  isPublishBtnVisible: boolean;

  // This prop is using for storybook only
  hoveringItem?:
    | "CARD_SIZE"
    | "TEXT_ALIGN"
    | "TEXT_COLOR"
    | "MENU_ITEM"
    | "DELETE";
}
interface IProps {
  driver: CardActionContextMenuDriver;
}

export const CardItemActionContextMenu = observer(
  function CardActionContextMenu(props: IProps) {
    const {
      cardSize,
      onCardSizeChanged,
      textAlign,
      onTextAlignmentChanged,
      textColor,
      onTextColorChanged,
      buttonText,
      onButtonTextInputChanged,
      onCardEditButtonClicked,
      shouldRenderCardOrder,
      cardOrderInput,
      sortNumberInputError,
      onCardOrderChanged,
      onCardOrderBlured,
      onCardDeleteButtonClicked,
      onCardReportButtonClicked,
      hoveringItem: hoverItem,
      isGuestView,
      isPublishBtnVisible,
      isPublishable,
      onCardPublishButtonClicked,
    } = props.driver;
    const { t } = useTranslation("CardItemAction");

    const renderContent = () => {
      if (isGuestView) {
        return (
          <sc.MenuItem data-cy="CardItemActionContextMenu-MenuItemCardReport">
            <ContextMenuCardReport
              onReport={() => onCardReportButtonClicked()}
            />
          </sc.MenuItem>
        );
      }

      return (
        <>
          <sc.CardSizeContainer>
            <sc.BlockTitle data-cy="CardItemActionContextMenu-CardSize">
              {t("context-menu.card-size.title")}
            </sc.BlockTitle>
            <sc.CardSizeItems>
              <sc.CardSizeItem
                data-cy="CardItemActionContextMenu-CardSizeSingle"
                onClick={() => onCardSizeChanged(CardSize.SINGLE)}
              >
                <sc.IconBorder
                  shouldChangeIconColorWhenHover={true}
                  className={cardSize === CardSize.SINGLE ? `active` : ""}
                >
                  <IconSvg
                    icon="single-card"
                    width="32px"
                    height="32px"
                    padding="0"
                    color={etGray}
                    pointer
                  />
                </sc.IconBorder>
                <sc.CardSizeItemText>
                  {t("context-menu.card-size.single")}
                </sc.CardSizeItemText>
              </sc.CardSizeItem>
              <sc.CardSizeItem
                data-cy="CardItemActionContextMenu-CardSizeDouble"
                onClick={() => onCardSizeChanged(CardSize.DOUBLE)}
              >
                <sc.IconBorder
                  shouldChangeIconColorWhenHover={true}
                  className={cardSize === CardSize.DOUBLE ? "active" : ""}
                >
                  <IconSvg
                    icon="double-card"
                    width="32px"
                    height="32px"
                    padding="0"
                    color={etGray}
                    pointer
                  />
                </sc.IconBorder>
                <sc.CardSizeItemText>
                  {t("context-menu.card-size.double")}
                </sc.CardSizeItemText>
              </sc.CardSizeItem>
              <sc.CardSizeItem
                data-cy="CardItemActionContextMenu-CardSizeTriple"
                onClick={() => onCardSizeChanged(CardSize.TRIPLE)}
              >
                <sc.IconBorder
                  shouldChangeIconColorWhenHover={true}
                  className={
                    cardSize === CardSize.TRIPLE || hoverItem === "CARD_SIZE"
                      ? `active`
                      : ""
                  }
                >
                  <IconSvg
                    icon="triple-card"
                    width="32px"
                    height="32px"
                    padding="0"
                    color={etGray}
                    fillColor={etGray}
                    pointer
                  />
                </sc.IconBorder>
                <sc.CardSizeItemText>
                  {t("context-menu.card-size.triple")}
                </sc.CardSizeItemText>
              </sc.CardSizeItem>
            </sc.CardSizeItems>
          </sc.CardSizeContainer>
          <sc.EditTextContainer>
            <sc.TextAlignment>
              <sc.BlockTitle data-cy="CardItemActionContextMenu-TextAlign">
                {t("context-menu.text-alignment.title")}
              </sc.BlockTitle>
              <sc.TextAlignmentItems>
                <sc.TextAlignmentItem
                  data-cy="CardItemActionContextMenu-TextAlignLeft"
                  onClick={() => onTextAlignmentChanged(CardTextAlignment.LEFT)}
                >
                  <sc.IconBorder
                    shouldChangeIconColorWhenHover={true}
                    className={
                      textAlign === CardTextAlignment.LEFT ? `active` : ""
                    }
                  >
                    <IconSvg
                      icon="text-align-left"
                      width="16px"
                      height="16px"
                      padding="0"
                      color={etGray}
                      pointer
                    />
                  </sc.IconBorder>
                  <sc.TextAlignmentItemText>
                    {t("context-menu.text-alignment.left")}
                  </sc.TextAlignmentItemText>
                </sc.TextAlignmentItem>
                <sc.TextAlignmentItem
                  data-cy="CardItemActionContextMenu-TextAlignCenter"
                  onClick={() =>
                    onTextAlignmentChanged(CardTextAlignment.CENTER)
                  }
                >
                  <sc.IconBorder
                    shouldChangeIconColorWhenHover={true}
                    className={
                      textAlign === CardTextAlignment.CENTER ||
                      hoverItem === "TEXT_ALIGN"
                        ? `active`
                        : ""
                    }
                  >
                    <IconSvg
                      icon="text-align-center"
                      width="16px"
                      height="16px"
                      padding="0"
                      color={etGray}
                      pointer
                    />
                  </sc.IconBorder>
                  <sc.TextAlignmentItemText>
                    {t("context-menu.text-alignment.center")}
                  </sc.TextAlignmentItemText>
                </sc.TextAlignmentItem>
              </sc.TextAlignmentItems>
            </sc.TextAlignment>
            <sc.TextColor>
              <sc.BlockTitle data-cy="CardItemActionContextMenu-TextColor">
                {t("context-menu.text-color.title")}
              </sc.BlockTitle>
              <sc.TextColorItems>
                <sc.TextColorItem
                  data-cy="CardItemActionContextMenu-TextColorWhite"
                  onClick={() => onTextColorChanged(CardTextColor.WHITE)}
                >
                  <sc.IconBorder
                    className={
                      textColor === CardTextColor.WHITE ? `active` : ""
                    }
                  >
                    <sc.IconWrapper>
                      <IconSvg
                        icon="a-black"
                        width="16px"
                        height="16px"
                        padding="0"
                        fillColor={etWhite}
                        pointer
                      />
                    </sc.IconWrapper>
                  </sc.IconBorder>
                  <sc.TextColorItemText>
                    {t("context-menu.text-color.white")}
                  </sc.TextColorItemText>
                </sc.TextColorItem>
                <sc.TextColorItem
                  data-cy="CardItemActionContextMenu-TextColorBlack"
                  onClick={() => onTextColorChanged(CardTextColor.BLACK)}
                >
                  <sc.IconBorder
                    className={
                      textColor === CardTextColor.BLACK ||
                      hoverItem === "TEXT_COLOR"
                        ? `active`
                        : ""
                    }
                  >
                    <IconSvg
                      icon="a-black"
                      width="16px"
                      height="16px"
                      padding="0"
                      fillColor={etBlack}
                      pointer
                    />
                  </sc.IconBorder>
                  <sc.TextColorItemText>
                    {t("context-menu.text-color.black")}
                  </sc.TextColorItemText>
                </sc.TextColorItem>
              </sc.TextColorItems>
            </sc.TextColor>
          </sc.EditTextContainer>
          <sc.ButtonTextContainer>
            <input
              data-cy="CardItemActionContextMenu-ButtonTextInput"
              placeholder={t("context-menu.button-text.placeholder")}
              value={buttonText}
              onChange={(e) => onButtonTextInputChanged(e.target.value)}
            />
            <sc.ButtonTextCounter>
              {buttonText.length +
                "/" +
                `${ACTION_CARD_BUTTON_TEXT_MAX_LENGTH}` +
                " " +
                t("context-menu.button-text.characters")}
            </sc.ButtonTextCounter>
          </sc.ButtonTextContainer>
          {shouldRenderCardOrder && (
            <sc.MenuItem
              data-cy="CardItemInfoContextMenu-MenuItemCardEdit"
              useDefaultCursor={true}
            >
              <ContextMenuCardOrder
                sortNumber={cardOrderInput || ""}
                onChanged={onCardOrderChanged}
                onBlured={onCardOrderBlured}
                sortNumberInputError={sortNumberInputError}
              />
            </sc.MenuItem>
          )}
          <sc.MenuItem data-cy="CardItemActionContextMenu-MenuItemCardEdit">
            <ContextMenuCardEdit
              onEdit={onCardEditButtonClicked}
              isHovering={hoverItem === "MENU_ITEM"}
            />
          </sc.MenuItem>

          {isPublishBtnVisible && (
            <sc.MenuItem data-cy="CardItemActionContextMenu-MenuItemCardPublish">
              <ContextMenuCardPublish
                onPublish={onCardPublishButtonClicked}
                isValid={isPublishable}
              />
            </sc.MenuItem>
          )}

          <sc.MenuItem data-cy="CardItemActionContextMenu-MenuItemCardDelete">
            <ContextMenuCardDelete
              onDelete={onCardDeleteButtonClicked}
              isHovering={hoverItem === "DELETE"}
            />
          </sc.MenuItem>
        </>
      );
    };

    return (
      <sc.Container
        data-cy="CardItemActionContextMenu-Container"
        className="visual-reg"
      >
        {renderContent()}
      </sc.Container>
    );
  },
);
