/* eslint-disable react/jsx-no-target-blank */
import { observer } from "mobx-react-lite";
import React from "react";

import { etGray, etHeatherBlue } from "../../shared/colors";
import { getValidHttpUrl } from "../../shared/helpers/getValidHttpUrl";
import { IconSvg } from "../IconSvg/IconSvg";
import { UserProfileCard } from "./CardProfile";
import sc from "./CardProfile.styled";

interface IProps {
  driver: UserProfileCard;
}

export const CardProfileFooter = observer((props: IProps) => {
  const { driver } = props;

  return (
    <sc.Footer>
      {driver.twitterUsername && (
        <a
          data-cy="CardProfile-X"
          title="X"
          href={`https://www.x.com/${driver.twitterUsername}`}
          target="_blank"
        >
          <IconSvg
            data-cy="CardProfile-XIcon"
            icon="x-twitter"
            pointer
            width="14px"
            height="14px"
            color={etHeatherBlue}
            padding="0"
            hoverColor={etGray}
          />
        </a>
      )}
      {driver.facebookUsername && (
        <a
          data-cy="CardProfile-Facebook"
          title="Facebook"
          href={`https://www.facebook.com/${driver.facebookUsername}`}
          target="_blank"
        >
          <IconSvg
            data-cy="CardProfile-FacebookIcon"
            icon="facebook2"
            pointer
            width="14px"
            height="14px"
            color={etHeatherBlue}
            padding="0"
            hoverColor={etGray}
          />
        </a>
      )}
      {driver.instagramUsername && (
        <a
          data-cy="CardProfile-Instagram"
          title="Instagram"
          href={`https://www.instagram.com/${driver.instagramUsername}`}
          target="_blank"
        >
          <IconSvg
            data-cy="CardProfile-InstagramIcon"
            icon="instagram"
            pointer
            width="14px"
            height="14px"
            color={etHeatherBlue}
            padding="0"
            hoverColor={etGray}
          />
        </a>
      )}
      {driver.linkedInUsername && (
        <a
          data-cy="CardProfile-LinkedIn"
          title="LinkedIn"
          href={`https://www.linkedin.com/in/${driver.linkedInUsername}`}
          target="_blank"
        >
          <IconSvg
            data-cy="CardProfile-LinkedInIcon"
            icon="linkedin"
            pointer
            width="14px"
            height="14px"
            color={etHeatherBlue}
            padding="0"
            hoverColor={etGray}
          />
        </a>
      )}
      {driver.emailAddress && driver.isEmailPublic && (
        <a
          data-cy="CardProfile-Email"
          title="Email"
          href={`mailto:${driver.emailAddress}`}
          target="_blank"
        >
          <IconSvg
            data-cy="CardProfile-EmailIcon"
            icon="email"
            pointer
            width="14px"
            height="14px"
            color={etHeatherBlue}
            padding="0"
            hoverColor={etGray}
          />
        </a>
      )}
      {driver.website && (
        <a
          data-cy="CardProfile-Website"
          title="Website"
          href={getValidHttpUrl(driver.website)}
          target="_blank"
        >
          <IconSvg
            data-cy="CardProfile-WebsiteIcon"
            icon="website"
            pointer
            width="16px"
            height="16px"
            color={etHeatherBlue}
            padding="0"
            hoverColor={etGray}
          />
        </a>
      )}
    </sc.Footer>
  );
});
