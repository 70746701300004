import { observer } from "mobx-react-lite";
import React from "react";

import sc from "./CardSharingOverlay.styled";

interface IProps {
  onClick(): void;
}

export const CardSharingOverlay = observer((props: IProps) => {
  return <sc.Container onClick={props.onClick} />;
});
