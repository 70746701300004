import styled from "styled-components";

import {
  etBrightLightBlue,
  etHeatherBlue,
  etShadowDarkBlueFaded,
  etWhite,
} from "../../shared/colors";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    background: ${etWhite};

    max-width: 328px;
    min-height: 496px;

    border-radius: 8px;
    border: none;

    z-index: 1;
    padding: 24px 24px 12px 24px;

    position: relative;
    box-shadow: 0px 16px 18px 0px ${etShadowDarkBlueFaded};
  `;

  export const Title = styled.h2`
    font-size: 20px;
    font-weight: bold;

    margin-bottom: 40px;
  `;

  export const Content = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  `;

  export const Row = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const Close = styled.button`
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 2;
    background: none;
    border: none;
    padding: 0;
    :hover {
      ${IconSvgContainer} {
        g {
          stroke: ${etBrightLightBlue};
        }
      }
    }
  `;

  export const NewCloseButton = styled.span`
    position: absolute;
    cursor: pointer;
    right: -12px;
    top: -12px;
    z-index: 20;
    line-height: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: ${etHeatherBlue};
    border-radius: 50%;

    div > svg > g > g {
      stroke: ${etWhite};
    }
  `;
}
export default sc;
