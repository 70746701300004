import { observer } from "mobx-react-lite";

import { useTranslation } from "../../shared/translate/NextI18next";
import { sc } from "./UserProfileSwitcherBanner.styled";

export interface IUserProfileSwitcherBannerProps {
  groupName: string;
  styled?: {
    marginTop?: string;
    marginBottom?: string;
  };
  onClick(): void;
}

export const UserProfileSwitcherBanner = observer(
  (props: IUserProfileSwitcherBannerProps) => {
    const { t } = useTranslation("Settings");
    const { styled } = props;
    return (
      <sc.Container className="container" data-cy="switcher-banner">
        <sc.GridItem>
          <sc.Overlay
            top={styled?.marginTop || "0"}
            bottom={styled?.marginBottom || "0"}
          />
          <sc.Spacing
            className="visual-reg"
            marginTop={styled?.marginTop || "0"}
            marginBottom={styled?.marginBottom || "0"}
          >
            <sc.Label data-cy="UserProfileSwitcherBanner-Text">
              {t("settings.switcher-banner.label")} {props.groupName}.
            </sc.Label>
            <sc.Button
              data-cy="UserProfileSwitcherBanner-SwitchButton"
              onClick={props.onClick}
            >
              {t("settings.switcher-banner.switch-to-personal")}
            </sc.Button>
          </sc.Spacing>
        </sc.GridItem>
      </sc.Container>
    );
  },
);
