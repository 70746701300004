export const createSpinner = (thickness: number, duration: number): string => {
  let css = ``;
  for (let i = 0; i < thickness; i++) {
    const child = `
      &:nth-child(${i + 1}) {
        transform: rotate(${(360 / thickness) * i}deg);
        animation-delay: ${(duration / thickness) * (thickness - i - 1) * -1}s;
      }

    `;
    css = css + child;
  }
  return css;
};
