import { observer } from "mobx-react-lite";

import { IconSvg } from "../../IconSvg/IconSvg";
import { sc } from "./WhereYourMoneyGoes.styled";

export interface Driver {
  isSectionTwoSubSectionActivated: boolean;
  toggleSectionTwoSubSection(): void;
}

interface IProps {
  driver: Driver;
}

export const WhereYourMoneyGoes = observer((props: IProps) => {
  return (
    <sc.Container>
      <sc.XButtonWrapper>
        <IconSvg
          dataCy="WhereYourMoneyGoes-Xbutton"
          icon="close"
          width="24px"
          height="24px"
          pointer
          onClick={props.driver.toggleSectionTwoSubSection}
        />
      </sc.XButtonWrapper>
      <sc.Img
        data-cy="WhereYourMoneyGoes-Img"
        src={require("../../../assets/img/where-your-money-goes.png")}
        alt="Where Your Money Goes"
      />

      <sc.BodyContent>
        <sc.Title data-cy="WhereYourMoneyGoes-Title">
          Where your money goes
        </sc.Title>
        <sc.Description data-cy="WhereYourMoneyGoes-Description">
          Of your contribution, 83% goes directly to nature protection and 3% is
          VAT. The 14% that is left is used to develop, roll-out, and operate
          EarthToday. And all future profit will be reinvested in the purpose.
        </sc.Description>
        <sc.CuratorInfo data-cy="WhereYourMoneyGoes-CuratorInfo">
          By <b>EarthToday</b> - 22 april 2022
        </sc.CuratorInfo>
      </sc.BodyContent>
    </sc.Container>
  );
});
