import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import { ILeaderboardModel } from "../../stores/LeaderBoardModel";
import { TheLoader } from "../TheLoader/TheLoader";
import { sc } from "./Leaderboard.styled";

export interface StateDriverLeaderboardLayout {
  readonly loading: boolean;
  readonly hiddenShowMore?: boolean;
  readonly hiddenShare?: boolean;
  readonly isLeaderboardCampaignDefault?: boolean;

  readonly isLeaderboardProtectPage?: boolean;

  readonly isNPOHeaderCardEmptyLeaderboard?: boolean; //only for leaderboard on npo header card

  openModalShareLeaderboard?(): void;
}

export interface IProps {
  stateDriver: StateDriverLeaderboardLayout;
  driver: ILeaderboardModel;
  renderContent(): ReactNode;
}

export const LeaderboardLayout = observer((props: IProps) => {
  const { driver, stateDriver, renderContent } = props;

  const { t } = useTranslation("Protect");

  const renderButton = (): ReactNode => {
    if (
      (stateDriver.isLeaderboardCampaignDefault ||
        stateDriver.isNPOHeaderCardEmptyLeaderboard) &&
      !stateDriver.hiddenShare
    ) {
      return (
        <sc.ShareLeaderboardBtn
          data-cy="Leaderboard-Btn"
          isLeaderboardCampaignDefault={
            stateDriver.isLeaderboardCampaignDefault || false
          }
          onClick={() => {
            if (stateDriver.openModalShareLeaderboard) {
              stateDriver.openModalShareLeaderboard();
            }
          }}
        >
          {t("protect.button.share")}
        </sc.ShareLeaderboardBtn>
      );
    }

    if (!stateDriver.hiddenShowMore) {
      return (
        <sc.LeaderboardBtn
          data-cy="Leaderboard-Btn"
          onClick={() => {
            driver.openLazyModal("leaderBoard");
          }}
        >
          {t("protect.button.showmore")}
        </sc.LeaderboardBtn>
      );
    }

    return <></>;
  };

  return (
    <sc.Container
      className="visual-reg"
      padding={
        stateDriver.isLeaderboardCampaignDefault ||
        stateDriver.isNPOHeaderCardEmptyLeaderboard ||
        stateDriver.isLeaderboardProtectPage
          ? "20px"
          : "24px"
      }
    >
      {renderContent()}
      {stateDriver.loading ? <TheLoader /> : renderButton()}
    </sc.Container>
  );
});
