/* eslint-disable class-methods-use-this */
import {
  CardPromotionUpdateStatePayloadDto,
  CardResponseStandard,
  CardStatus,
  PromotionSeriesType,
} from "@earthtoday/contract";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { CardItemPromotionContextMenuDriver } from "../../components/CardItemPromotion/CardItemPromotionContextMenu";
import { DynamicLazyModalLoader } from "../../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import {
  ITheMessageStore,
  ToastMessageStatus,
} from "../../components/TheMessage/TheMessageStore";
import { ICreateCardApi } from "../../shared/apis/CreateCardApi";
import { IProfileApi } from "../../shared/apis/ProfileApi";
import { MAX_CARDS_IN_DECK } from "../../shared/constants";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import { CardSize, CardType } from "../../shared/models/Card";
import { GroupProfileType } from "../../shared/models/User";
import { IModalStore } from "../ModalStore";
import { ProfilePagePresenter } from "../ProfilePagePresenter";
import { UserModel } from "../UserModel";
import {
  CardItemPromotionLocation,
  CardPromotionBaseModelDependencies,
} from "./CardPromotionBaseModel";
import { CardPromotionModel } from "./CardPromotionModel";
import { CardPromotionRepostModel } from "./CardPromotionRepostModel";
import {
  CardPromotionRepostSaveInitialState,
  CardPromotionSaveInitialState,
} from "./CardPromotionSaveStore";

export class CardPromotionContextMenuStore
  implements CardItemPromotionContextMenuDriver
{
  constructor(
    public parentStore: CardPromotionModel | CardPromotionRepostModel,
    private userSessionStore: IUserSessionStore,
    private userProfile: UserModel | null,
    private modalStore: IModalStore,
    public createCardAPI: ICreateCardApi,
    public dependencies: CardPromotionBaseModelDependencies,
    public theMessageStore: ITheMessageStore,
    private location: CardItemPromotionLocation,
    private profileApi: IProfileApi,
    private profilePagePresenter: ProfilePagePresenter | null,
    private featureFlagging?: {
      flags: {
        enableCollectibleUon: boolean;
        enableSortCardInDeck: boolean;
        enableReserveDefaultUonCollectible: boolean;
      };
    },
  ) {
    makeObservable(this);
  }

  contentType: CardType.PROMOTION | CardType.PROMOTION_REPOST =
    this.parentStore.contentType;

  @computed get shouldRenderResizeMenu() {
    return (
      (this.featureFlagging?.flags.enableCollectibleUon &&
        !this.parentStore.isCardDrafting) ||
      false
    );
  }
  @computed get cardSize(): CardSize {
    return this.parentStore.cardSize;
  }
  @computed get isOwnerView(): boolean {
    return (
      !!this.userProfile &&
      !!this.userSessionStore.user &&
      this.userProfile.id === this.userSessionStore.user.id
    );
  }
  @action.bound onCardSizeChanged = flow(function* (
    this: CardPromotionContextMenuStore,
    size: CardSize,
  ) {
    this.parentStore.onToggleContextMenu();
    yield* toFlowGeneratorFunction(this.parentStore.onCardSizeChanged)(size);
  });
  @computed get isEditCardButtonDisabled(): boolean {
    if (this.parentStore.contentType === CardType.PROMOTION_REPOST) {
      return true;
    }
    if (this.parentStore.isCardDrafting) {
      return false;
    }
    return this.parentStore.counter > 0;
  }
  @computed get shouldRenderPublishCardButton(): boolean {
    return this.parentStore.isCardDrafting;
  }
  @computed get isPublishBtnEnable(): boolean {
    return (
      this.parentStore.isCardDrafting &&
      !!(this.parentStore as CardPromotionRepostModel).deckId
    );
  }
  @computed get isSaveCardButtonDisabled(): boolean {
    return !this.userSessionStore.isCurator;
  }

  @computed get shouldRenderCardOrder(): boolean {
    if (
      !this.isOwnerView ||
      this.parentStore.location !== CardItemPromotionLocation.DECK
    ) {
      return false;
    }
    return !!this.featureFlagging?.flags.enableSortCardInDeck;
  }
  @observable cardOrder: number | null = null;
  @computed get cardOrderInput(): string | null {
    return this.cardOrder?.toString() || null;
  }
  @computed get sortNumberInputError(): string | null {
    if (this.cardOrder === null) return null;

    return this.cardOrder > MAX_CARDS_IN_DECK || this.cardOrder < 1
      ? `Card's order should be in range from 1 to ${MAX_CARDS_IN_DECK}`
      : null;
  }
  onCardOrderChanged = (order: string): void => {
    this.updateCardOrder(order);
  };
  @action.bound updateCardOrder(order: string) {
    const orderNumber = Number(order);

    if (orderNumber > MAX_CARDS_IN_DECK) {
      this.cardOrder = MAX_CARDS_IN_DECK;
      return;
    }

    this.cardOrder = orderNumber;
  }

  @action.bound onCardOrderBlured = flow(function* onCardOrderBlured(
    this: CardPromotionContextMenuStore,
  ) {
    if (
      this.sortNumberInputError ||
      this.parentStore.contentType !== CardType.PROMOTION_REPOST ||
      !this.userSessionStore.user ||
      !this.isOwnerView
    ) {
      return;
    }

    try {
      this.parentStore.dependencies.onCardOrderInputChanged(
        this.parentStore.card.id.toString(),
        Number(this.cardOrderInput),
      );
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onEditCardButtonClicked(): void {
    this.parentStore.onToggleContextMenu();
    if (
      this.parentStore.isCardDrafting &&
      this.parentStore.contentType === CardType.PROMOTION_REPOST
    ) {
      const driver: CardPromotionRepostSaveInitialState = {
        cardType: CardType.PROMOTION_REPOST,
        promotionId: this.parentStore.promotionId,
        deckId: this.parentStore.deckId,
        cardStatus: this.parentStore.card.state.toUpperCase() as
          | typeof CardStatus.DRAFTING
          | typeof CardStatus.PUBLISHED,
        cardId: this.parentStore.card.id.toString(),
      };

      this.modalStore.openLazyModal({
        name: "collectibleCardSave",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              // eslint-disable-next-line more/no-then
              import(
                "../../components/ModalSaveCollectibleCard/ModalSaveCollectibleCard"
              ).then((component) => component.ModalSaveCollectibleCard)
            }
            driver={driver}
          />
        ),
      });
      return;
    }
    this.modalStore.openLazyModal({
      name: "ModalCreateCardCollectable",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../components/ModalCreateCardCollectable/ModalCreateCardCollectableSmart"
            ).then((component) => component.ModalCreateCardCollectableSmart)
          }
          imageShape={this.parentStore.imageShape}
          promotion={this.parentStore.promotion}
        />
      ),
    });
  }
  @action.bound onShareCardButtonClicked(): void {
    // TODO: handle open card share panel
  }
  @action.bound onSaveCardButtonClicked(): void {
    this.parentStore.onToggleContextMenu();

    const driver: CardPromotionSaveInitialState =
      this.parentStore.contentType === CardType.PROMOTION
        ? {
            cardType: CardType.PROMOTION,
            promotionId: this.parentStore.promotionId,
            cardStatus: CardStatus.PUBLISHED,
          }
        : {
            cardType: CardType.PROMOTION_REPOST,
            promotionId: this.parentStore.promotionId,
            deckId: this.parentStore.deckId,
            cardStatus: this.parentStore.card.state.toUpperCase() as
              | typeof CardStatus.DRAFTING
              | typeof CardStatus.PUBLISHED,
            cardId: this.parentStore.card.id.toString(),
          };

    this.modalStore.openLazyModal({
      name: "collectibleCardSave",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../components/ModalSaveCollectibleCard/ModalSaveCollectibleCard"
            ).then((component) => component.ModalSaveCollectibleCard)
          }
          driver={driver}
        />
      ),
    });
  }

  @computed get isDeleteCardButtonDisabled(): boolean {
    if (this.parentStore.isCardDrafting) {
      return !this.isOwnerView;
    }
    if (this.parentStore.contentType === CardType.PROMOTION_REPOST) {
      return false;
    }
    return this.parentStore.counter > 0;
  }
  @computed get shouldRenderSaveCardButton(): boolean {
    if (this.parentStore.isCardDrafting) {
      return false;
    }
    return true;
  }

  @computed get reserveId(): string | null {
    if (!this.profilePagePresenter) return null;

    const reserveDeck = this.profilePagePresenter.decks.find(
      (deck) => !!deck.reserve,
    );

    return reserveDeck && reserveDeck.reserve ? reserveDeck.reserve.id : null;
  }

  @computed get shouldRenderSetDefaultCheckbox(): boolean {
    if (!this.featureFlagging?.flags.enableReserveDefaultUonCollectible) {
      return false;
    }

    if (
      this.location !== CardItemPromotionLocation.PROFILE ||
      !this.reserveId ||
      !this.userProfile ||
      !this.userProfile.group ||
      !this.userProfile.group.profileType
    ) {
      return false;
    }
    return this.userProfile.group.profileType === GroupProfileType.NPO;
  }

  @computed get isSetDefaultCheckboxChecked(): boolean {
    return !!this.parentStore.collectibleForReserve;
  }

  @observable isSettingDefaultCollectible: boolean = false;
  @action.bound onSetDefaultCheckboxChanged = flow(function* (
    this: CardPromotionContextMenuStore,
  ) {
    if (!this.reserveId || this.isSetDefaultCheckboxDisabled) {
      return;
    }
    try {
      this.isSettingDefaultCollectible = true;
      yield* toFlowGeneratorFunction(
        this.profileApi.updateReserveDefaultCollectible,
      )(this.reserveId, this.parentStore.promotionId);

      if (this.profilePagePresenter) {
        this.profilePagePresenter.updateReserveDefaultCollectible(
          this.parentStore.promotionId,
        );
      }
    } catch (error) {
      console.info(error);
    } finally {
      this.isSettingDefaultCollectible = false;
    }
  });

  @computed get isSetDefaultCheckboxDisabled(): boolean {
    if (this.parentStore.contentType === CardType.PROMOTION_REPOST) {
      return true;
    }

    return (
      this.isSettingDefaultCollectible ||
      this.isSetDefaultCheckboxChecked ||
      this.parentStore.promotionSerie.type === PromotionSeriesType.LIMITED
    );
  }
  @action.bound onPublishCardButtonClicked = flow(
    function* onPublishCardButtonClicked(this: CardPromotionContextMenuStore) {
      try {
        this.parentStore.isContextMenuActivated = false;
        if (this.parentStore.contentType === CardType.PROMOTION) return;

        const payload: CardPromotionUpdateStatePayloadDto = {
          data: {
            deckId: this.parentStore.deckId,
            state: CardStatus.PUBLISHED,
            content: {
              type: CardType.PROMOTION_REPOST,
            },
          },
        };

        const response = yield* toFlowGeneratorFunction(
          this.createCardAPI.updateCard,
        )(this.parentStore.id, payload);
        this.dependencies.onDraftingCardPromotionPublished(this.parentStore.id);

        this.theMessageStore.showMessage({
          typeMessage: "Action",
          title: "toast-message.create-card-success.title",
          status: ToastMessageStatus.SUCCESS,
          content: `Your Card has been added to ${
            (response as CardResponseStandard).deck?.name
          }`,
          actions: [
            {
              key: "cards",
              name: "View Card in Deck",
              href: "/[vanityName]/[deckName]",
              as: `/${(response as CardResponseStandard).deck?.path.join("/")}`,
            },
          ],
        });
      } catch (error) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      } finally {
        this.modalStore.closeLazyModal();
      }
    },
  );
  @action.bound onDeleteCardButtonClicked(): void {
    this.parentStore.isContextMenuActivated = false;
    this.modalStore.openLazyModal({
      name: "cardDelete",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import("../../components/ModalDeleteCard/ModalDeleteCardV2").then(
              (component) => component.ModalDeleteCardV2,
            )
          }
          driver={{
            onClose: () => this.modalStore.openModal(""),
            onDelete: () => this.onDelete(),
          }}
        />
      ),
    });
  }
  @action.bound onDelete = flow(function* (
    this: CardPromotionContextMenuStore,
  ) {
    yield* toFlowGeneratorFunction(this.parentStore.onDelete)();
  });

  @computed get isPinHeaderButtonDisabled(): boolean {
    return (
      this.location !== CardItemPromotionLocation.PROFILE &&
      this.location !== CardItemPromotionLocation.DECK
    );
  }
}
