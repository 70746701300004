import styled from "styled-components";

import {
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
} from "../../../shared/breakpoints";
import {
  etBlack,
  etGray,
  etNevadaGrey,
  etSolitudeLight,
} from "../../../shared/colors";

namespace sc {
  export const Wrapper = styled.div`
    background-color: ${etSolitudeLight};
    position: relative;
    width: 100%;
    height: 100%;
  `;

  export const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-bottom: 100px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      max-width: 680px;
      margin: 0 auto;
    }
  `;

  export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 700px;
    width: 100%;

    padding: 98px 50px 0px 50px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      padding-top: 44px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      padding-left: 30px;
      align-items: center;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      padding-left: 24px;
      padding-right: 24px;
    }
  `;

  export const Block = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column;

    position: relative;

    max-width: 1044px;
    width: 100%;
  `;

  export const Title = styled.h2`
    font-family: Roboto;
    font-size: 60px;
    font-weight: 900;
    letter-spacing: -0.5px;

    line-height: 60px;
    color: ${etBlack};

    margin: 0;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 40px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 25px;
      line-height: 30px;
    }
  `;

  export const SubTitle = styled.h3`
    font-family: Roboto Condensed;
    font-size: 24px;
    font-weight: normal;

    letter-spacing: 0px;
    line-height: 26px;
    text-transform: uppercase;
    color: ${etBlack};

    margin: 0;
    padding-top: 11px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 18px;
      padding-top: 0;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 16px;
      padding-top: 6px;
    }
  `;

  export const Description = styled.span`
    color: ${etNevadaGrey};
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;

    letter-spacing: 0px;
    line-height: 30px;
    display: inline-block;
    max-width: 688px;

    margin-top: 28px;
    margin-bottom: 32px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 16px;
      margin-top: 22px;
      line-height: 24px;
      margin-bottom: 29px;

      max-width: 90%;
    }
  `;

  export const Button = styled.a`
    font-family: Roboto;
    font-stretch: condensed;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    letter-spacing: 0px;
    text-align: center;
    color: ${etGray};

    border-radius: 20px;
    border: 1px solid ${etGray};
    min-width: 189px;
    max-width: 189px;
    height: 40px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      text-decoration: none;
      color: ${etGray};
    }
  `;

  export const SliderWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100vw;
    min-height: 500px;
    max-height: 565px;
    overflow: hidden;

    margin-top: 48px;
    padding-bottom: 66px;
  `;

  export const Item = styled.div`
    margin-right: 24px;
  `;

  export const Slider = styled.div`
    width: 101%;
    max-height: 500px;
  `;

  export const SliderPartnerContainer = styled.div`
    width: 101%;
    padding-left: 100px;

    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      padding-left: 30px;
    }
  `;

  export const PartnersWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100vw;
    overflow: hidden;

    margin-top: 54px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-top: 46px;
    }
  `;

  export const PartnerLogo = styled.div<{
    src: string;
    size: { w: string; h: string };
  }>`
    width: ${(props) => props.size.w};
    height: ${(props) => props.size.h};
    background: url(${(props) => props.src}) center center no-repeat;
    background-size: contain;

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-left: 0;
    }
  `;

  export const SliderPartner = styled.div`
    width: 100vw;
    height: 100%;
  `;
  export const ItemLogo = styled.div`
    margin-right: 210px;
  `;

  export const Line = styled.div`
    position: absolute;
    bottom: -34px;
    right: 180px;
    z-index: 100;
    width: 103px;
    height: 12px;

    @media (min-width: ${xLargeScreenLowerLimit}) {
      margin-right: 330px;
      z-index: 100;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-right: 230px;
      margin-top: 24px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100vw;
      display: flex;
      justify-content: flex-end;
      margin: 0;
      margin-top: 24px;
      margin-right: -130px;
    }
  `;

  export const TheLoaderContainer = styled.div`
    width: 100%;
    position: absolute;
  `;
}

export default sc;
