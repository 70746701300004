import { MobileSystemDeckTextAlignment } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import Slider, { Settings } from "react-slick";

import { DeckEditData } from "../../../shared/apis/DeckDetailApi";
import { utmSourceValue } from "../../../shared/env";
import { useTranslation } from "../../../shared/translate/NextI18next";
import { AutoplayStatus } from "../../../stores/AutoplayDeckStore";
import { DeckSize } from "../../../stores/CardDeckOverviewModel";
import { ModalType } from "../../../stores/ModalStore";
import { ProtectStore } from "../../../stores/ProtectStore";
import { CardDeckOverview } from "../../CardDeckOverview/CardDeckOverview";
import { CardDeckOverviewContent } from "../../CardDeckOverviewContent/CardDeckOverviewContent";
import { CardDeckOverviewFooter } from "../../CardDeckOverviewFooter/CardDeckOverviewFooter";
import { CardDeckOverviewOverLay } from "../../CardDeckOverviewOverLay/CardDeckOverviewOverLay";
import { CardDeckOverviewSharing } from "../../CardDeckOverviewSharing/CardDeckOverviewSharing";
import { DeckSimpleContext } from "../../DeckSimpleContext/DeckSimpleContext";
import { IconSvg } from "../../IconSvg/IconSvg";
import { TheLoader } from "../../TheLoader/TheLoader";
import sc from "./SectionFour.styled";

export interface SectionFourDeckDriver {
  readonly contextMenuActive: boolean;
  readonly shareActive: boolean;
  readonly currentUser: boolean;
  readonly isShareAndFollowBtnVisiable: boolean;
  readonly following: boolean;
  readonly deckUrl: string;
  readonly id: string;
  readonly number: number;
  readonly deckCardPreviewImages: Array<{
    url: string;
    isProfileImage: boolean;
  }>;
  readonly vanityName: string;
  readonly deckAuthor: string;
  readonly logo: string;
  readonly description: string;
  readonly path: string[];
  readonly isDeleteOptionVisible: boolean; // here
  readonly deckStatistic: {
    cardsCount: number;
    followersCount?: number;
  };
  readonly isDeckSystem: boolean;
  readonly textColor: string;
  readonly textAlignment: MobileSystemDeckTextAlignment;
  readonly formatedDeckName: string;
  readonly renderSize: DeckSize;
  readonly isLoggedInUser: boolean;
  readonly autoplayStatus: AutoplayStatus;
  readonly isDeckShortcut: boolean;
  readonly enableUpdateDeckCardsDesign: boolean;
  copyCardLinkToClipboard(urlToCopy: string): void;
  toggleShareActive(): void;
  onToggleFollowing(following: boolean): void;
  updateSourceDeck(): void;
  openModal(modalType: ModalType): void;
  onEditDeck(deckData: DeckEditData): void;
  openEditDeck(): void;
  openContextMenu(): void;
  closeContextMenu(): void;
  onSaveDeck(): void;
  autoplayAction(): void;
  toggleDeckShortcut(): void;
  readonly shouldRenderSystemDeckButton: boolean;
  onSystemDeckButtonClicked(): void;
}

export type Driver = Pick<
  ProtectStore,
  "visibleNpoProtectV202208" | "isLoadingReserve"
>;

interface IProps {
  driver: Driver;
  decks: SectionFourDeckDriver[];
  autoplay?: boolean;
  autoplaySpeed?: number;
}
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <sc.Line onClick={onClick} data-cy="SectionFourV202208-SampleNextArrow">
      <IconSvg
        icon="line"
        pointer={true}
        padding="0"
        width="103px"
        height="12px"
      />
    </sc.Line>
  );
}
export const SectionFour = observer((props: IProps) => {
  const { t } = useTranslation("Protect");
  const { t: tGeneral } = useTranslation("General");

  const isAutoplay = props.autoplay === undefined ? true : props.autoplay;

  const settings: Settings = {
    swipeToSlide: true,
    variableWidth: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    rows: 1,
    draggable: true,
    autoplay: isAutoplay,
    nextArrow: <SampleNextArrow />,
    useTransform: false,
  };

  const settings2: Settings = {
    swipeToSlide: true,
    variableWidth: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    rows: 1,
    draggable: true,
    autoplay: isAutoplay,
    autoplaySpeed: props.autoplaySpeed || 2000,
  };

  return (
    <sc.Wrapper>
      <sc.Section className="visual-reg" data-cy="SectionFourV202208">
        <sc.Container>
          <sc.Block>
            <sc.Title data-cy="SectionFourV202208-Title">
              {t("protect.section-four-v202208.title")}
            </sc.Title>
            <sc.SubTitle data-cy="SectionFourV202208-SubTitle">
              {t("protect.section-four-v202208.sub-title")}
            </sc.SubTitle>
            <sc.Description data-cy="SectionFourV202208-Description">
              {t("protect.section-four-v202208.desc")}
            </sc.Description>
            <sc.Button
              href={`https://naturereserves.earthtoday.com/?${utmSourceValue}`}
              target="_blank"
              data-cy="SectionFourV202208-LearnMoreBtn"
            >
              {tGeneral("general.learn-more")}
            </sc.Button>

            <sc.SliderWrapper>
              {props.driver.isLoadingReserve ? (
                <sc.TheLoaderContainer>
                  <TheLoader />
                </sc.TheLoaderContainer>
              ) : (
                <sc.Slider>
                  <Slider {...settings}>
                    {props.decks?.map((deck) => {
                      return (
                        <sc.Item key={deck.id}>
                          <CardDeckOverview
                            key={`${deck.id}deck`}
                            driver={deck}
                            renderOverlay={() => (
                              <CardDeckOverviewOverLay driver={deck} />
                            )}
                            renderDeckContent={() => (
                              <CardDeckOverviewContent driver={deck} />
                            )}
                            renderDeckFooter={() => (
                              <CardDeckOverviewFooter driver={deck} />
                            )}
                            renderMenuContext={() => (
                              <DeckSimpleContext
                                featureFlaggingDriver={{
                                  enableAutoplayDeck: false,
                                  enableDeckShortcuts: false,
                                }}
                                driver={deck}
                              />
                            )}
                            renderSharing={() => (
                              <CardDeckOverviewSharing driver={deck} />
                            )}
                          />
                        </sc.Item>
                      );
                    })}
                  </Slider>
                </sc.Slider>
              )}
            </sc.SliderWrapper>
          </sc.Block>
        </sc.Container>

        <sc.PartnersWrap>
          <sc.SliderPartnerContainer className="visual-reg-pass-slider">
            <Slider {...settings2}>
              {props.driver.visibleNpoProtectV202208.map((logo) => (
                <sc.ItemLogo key={logo.key}>
                  <sc.PartnerLogo
                    data-cy="SectionFive-PartnerLogo"
                    src={logo.src}
                    size={logo.size}
                    key={logo.key}
                  />
                </sc.ItemLogo>
              ))}
            </Slider>
          </sc.SliderPartnerContainer>
        </sc.PartnersWrap>
      </sc.Section>
    </sc.Wrapper>
  );
});
