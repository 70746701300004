import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
} from "../../../shared/breakpoints";
import { etSolitudeLight } from "../../../shared/colors";

export namespace sc {
  export const Dot = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      background-color: ${(props) =>
        props.theme.themeStyle.theNavbarMenuButtonColorForTablet};
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.theNavbarMenuButtonColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etSolitudeLight};
    }
    &.mLandscape {
      background-color: ${etSolitudeLight};
    }
  `;

  export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  export const BentoButton = styled.div<{
    isActive: boolean;
    isUserLoggedOut: boolean;
  }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
    width: 20px;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    cursor: pointer;
    ${(props) =>
      props.isUserLoggedOut &&
      `margin-top: auto; margin-left: 15px; margin-bottom: auto;`}
    @media (min-width: ${largeScreenLowerLimit}) {
      display: none;
    }
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      margin-left: 22px;
    }
    // apply animation for both phone and tablets
    @media (max-width: ${mediumScreenUpperLimit}) {
      &.opening {
        ${Dot} {
          background-color: ${(props) =>
            props.theme.themeStyle.theNavbarMenuButtonActiveColor};
        }
        ${Row} {
          transition: transform 500ms ease-in-out;
        }
        ${Row}#top {
          transform-origin: top left;
          transform: rotate(90deg) translate(0px, -2px);
        }
        ${Row}#middle {
          transform-origin: top left;
          transform: rotate(90deg) translate(-8px, -11px);
        }
        ${Row}#bottom {
          transform-origin: top left;
          transform: rotate(90deg) translate(-16px, -20px);
        }
      }
      &.closing {
        ${Dot} {
          @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
            background-color: ${(props) =>
              props.theme.themeStyle.theNavbarMenuButtonColorForTablet};
          }
          @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
            background-color: ${(props) =>
              props.theme.themeStyle.theNavbarMenuButtonColor};
          }
          // to detect mobile landscape
          @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
            background-color: ${etSolitudeLight};
          }
          &.mLandscape {
            background-color: ${etSolitudeLight};
          }
        }
        ${Row} {
          transition: transform 500ms ease-in-out;
        }
        ${Row}#top {
          transform-origin: top left;
          transform: rotate(180deg) translate(-20px, -4px);
        }
        ${Row}#middle {
          transform-origin: top left;
          transform: rotate(180deg) translate(-20px, -4px);
        }
        ${Row}#bottom {
          transform-origin: top left;
          transform: rotate(180deg) translate(-20px, -4px);
        }
      }
    }
  `;
}

export default sc;
