import styled from "styled-components";

import {
  etBlack,
  etGray,
  etGrayDark,
  etGrayLight,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import {
  CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT,
  CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT,
  CARD_DECK_HEIGHT_DEFAULT,
  CARD_DECK_MODAL_HEIGHT_DEFAULT,
  CARD_DECK_MODAL_WIDTH_DEFAULT,
  CARD_DECK_WIDTH_DEFAULT,
  DeckSize,
} from "../../stores/CardDeckOverviewModel";

namespace sc {
  export const DescriptionWrap = styled.div<{ deckSize: DeckSize }>`
    cursor: alias;
    display: block;
    font-size: 16px;
    color: ${etWhite};
    z-index: 1;
    margin-top: 12px;
    font-family: ROBOTO, sans-serif;
    word-break: break-word;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontSize: "12px",
        marginTop: "10px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontSize: "11px",
        marginTop: "16px",
        maxWidth: "208px",
        maxHeight: "170px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }};
  `;

  export const Container = styled.div<{ deckSize: DeckSize }>`
    position: relative;
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0.5px 1px 1px ${etGrayLight};
    border-bottom: 2px ${etGray};
    width: 328px;

    ${(props) =>
      props.deckSize === DeckSize.Modal &&
      `
      box-shadow: 0px 1px 1px 0px ${transparentize(etBlack, 0.05)},
        0px 2px 3px 0px ${transparentize(etBlack, 0.1)};
      border-bottom: solid 1px ${etGrayLight};
      border-left: solid 1px ${etGrayLight};
      width: 275px;
      height: 417px;
      `};
    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck &&
      `
      background: rgb(255, 255, 255);
      border-radius: 6.24px;
      box-shadow: 0px 16px 18px 0px ${transparentize(etGrayDark, 0.2)};
      height: 387px;
      width: 256px;
      `};
  `;

  export const HeaderNumber = styled.div`
    font-family: Roboto Condensed;
    font-weight: 900;
    font-size: 64px;
    line-height: 48px;
    padding-bottom: 25px;
    color: ${etWhite};
    z-index: 1;
  `;

  export const ContainerBody = styled.div<{ deckSize: DeckSize }>`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px;
    height: 403px;
    background-size: cover;
    border-radius: 8px 8px 0 0;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        padding: "17px 24px 24px 24px",
        height: "339px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        padding: "17px 24px 24px 24px",
        height: "315px",
      }};

    > .slick-slider {
      position: absolute;
      top: 0;
      left: 0;
      width: ${(props) =>
        props.deckSize === DeckSize.Modal
          ? "275px"
          : props.deckSize === DeckSize.DisplayInDeck
          ? "256px"
          : "328px"};
      height: ${(props) =>
        props.deckSize === DeckSize.Modal
          ? "339px"
          : props.deckSize === DeckSize.DisplayInDeck
          ? "315px"
          : "403px"};
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      z-index: 0;
    }
  `;

  export const PreviewImageContainer = styled.div<{ deckSize: DeckSize }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 328px;
    height: 403px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    z-index: 0;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        width: `${CARD_DECK_MODAL_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_MODAL_HEIGHT_DEFAULT}px`,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        width: `${CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT}px`,
      }};
  `;
  export const PreviewImage = styled.div<{
    previewImage: string;
    deckSize: DeckSize;
  }>`
    width: ${CARD_DECK_WIDTH_DEFAULT}px;
    height: ${CARD_DECK_HEIGHT_DEFAULT}px;
    border-radius: 8px 8px 0 0;
    background: url(${(props) => props.previewImage}) center center no-repeat;
    background-size: cover;

    z-index: -1;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        width: `${CARD_DECK_MODAL_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_MODAL_HEIGHT_DEFAULT}px`,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        width: `${CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT}px`,
      }};
  `;

  export const PreviewImageProfileRepost = styled.div<{
    previewImage: string;
    renderSize?: DeckSize;
  }>`
    background: ${etGray};
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    height: 406px;
    width: ${(props) =>
      props.renderSize === DeckSize.Modal ? "275px" : "328px"};
    position: relative;

    ::after {
      content: "";
      height: 120px;
      width: 120px;

      position: absolute;
      bottom: ${(props) =>
        props.renderSize === DeckSize.Modal ? "100px" : "40px"};
      left: 50%;

      transform: translateX(-50%);
      background-color: ${etWhite};
      background-image: url(${(props) => props.previewImage});
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
    }
  `;

  export const SubCardContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
  `;

  export const SubCard1 = styled.div`
    position: absolute;
    bottom: 4px;
    width: 100%;
    height: 16px;
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid ${etGrayLight};
  `;

  export const SubCard2 = styled.div`
    position: absolute;
    bottom: 8.8px;
    width: 100%;
    height: 16px;
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid ${etGrayLight};
  `;

  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #000000;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 12;
  `;

  export const SharingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 12;
  `;
}

export default sc;
