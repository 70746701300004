import styled from "styled-components";

import {
  etBlack,
  etDark,
  etGray,
  etGrayLight,
  etShadow,
  etShadowLight,
  etWhite,
} from "../../shared/colors";
import {
  CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT,
  CARD_DECK_DISPLAY_IN_DECK_LOGO_HEIGHT,
  CARD_DECK_DISPLAY_IN_DECK_LOGO_WIDTH,
  CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT,
  CARD_DECK_MODAL_HEIGHT_DEFAULT,
  CARD_DECK_MODAL_LOGO_HEIGHT,
  CARD_DECK_MODAL_LOGO_WIDTH,
  CARD_DECK_MODAL_WIDTH_DEFAULT,
  DeckSize,
} from "../../stores/CardDeckOverviewModel";

export namespace sc {
  export const Description = styled.p<{
    color: string;
    textAlign: string;
  }>`
    cursor: alias;
    display: -webkit-box;
    color: ${({ color }) => color};
    z-index: 1;
    margin-top: 26px;
    margin-bottom: 0;

    font-family: Roboto, sans-serif;
    word-break: break-word;
    font-size: 16px;
    font-weight: normal;

    letter-spacing: 0px;
    line-height: 20px;

    white-space: break-spaces;

    text-align: ${({ textAlign }) => textAlign};

    line-clamp: 6;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  export const Container = styled.div<{
    deckSize: DeckSize;
  }>`
    overflow: hidden;
    position: relative;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0.5px 1px 1px ${etGrayLight};
    border-bottom: 2px ${etGray};
    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        width: `${CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT}px`,
      }};

    max-height: 496px;
    max-width: 328px;
  `;

  export const Body = styled.div<{
    deckSize: DeckSize;
  }>`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
    width: 328px;
    height: 403px;
    background-size: cover;
    border-radius: 8px 8px 0 0;

    > .slick-slider {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      z-index: 0;
      width: 100%;
      background-color: ${etBlack};
    }

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        width: `${CARD_DECK_MODAL_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_MODAL_HEIGHT_DEFAULT}px`,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        width: `${CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT}px`,
      }};
  `;

  export const PreviewImageContainer = styled.div<{
    deckSize: DeckSize;
  }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 328px;
    height: 403px;
    overflow: hidden;
    z-index: 0;
    background-color: ${etBlack};
    border-radius: 8px 8px 0 0;
    border-style: solid;
    border-color: ${etWhite};
    border-width: 1px 1px 0 1px;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        width: `${CARD_DECK_MODAL_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_MODAL_HEIGHT_DEFAULT}px`,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        width: `${CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT}px`,
      }};
  `;
  export const PreviewImage = styled.div<{
    previewImage: string;
    deckSize: DeckSize;
  }>`
    position: relative;
    width: 328px;
    height: 403px;
    border-radius: 8px 8px 0 0;
    background-image: url(${(props) => props.previewImage});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    z-index: -1;
    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        width: `${CARD_DECK_MODAL_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_MODAL_HEIGHT_DEFAULT}px`,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        width: `${CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT}px`,
        height: `${CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT}px`,
      }};
  `;

  export const SubCardContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
  `;

  export const SubCard1 = styled.div`
    position: absolute;
    bottom: 4px;
    width: 100%;
    height: 16px;
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid ${etGrayLight};
  `;

  export const SubCard2 = styled.div`
    position: absolute;
    bottom: 9px;
    width: 100%;
    height: 16px;
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid ${etGrayLight};
  `;

  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 12;
  `;

  export const Overlay = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      ${etShadow},
      ${etShadowLight},
      transparent
    );
  `;

  export const OverlayLink = styled.a`
    z-index: 10;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
  `;
  export const FooterContainer = styled.div<{
    deckSize: DeckSize;
  }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 93px;
    background-color: ${etWhite};
    border-radius: 0 0 8px 8px;
    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        height: "77px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        height: "72px",
        width: `${CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT}px`,
      }};
  `;

  export const CuratorImageContainer = styled.div<{
    deckSize: DeckSize;
  }>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -20px;
    height: 80px;
    width: 80px;
    background-color: ${etWhite};
    border-radius: 50%;
    cursor: pointer;
    z-index: 11;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        height: "67px",
        width: "67px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        height: "62px",
        width: "62px",
      }};
  `;

  export const CuratorImage = styled.a<{ logo: string; deckSize: DeckSize }>`
    height: 40px;
    width: 40px;
    background-image: url(${(props) => props.logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        height: `${CARD_DECK_MODAL_LOGO_HEIGHT}px`,
        width: `${CARD_DECK_MODAL_LOGO_WIDTH}px`,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        height: `${CARD_DECK_DISPLAY_IN_DECK_LOGO_HEIGHT}px`,
        width: `${CARD_DECK_DISPLAY_IN_DECK_LOGO_WIDTH}px`,
      }};
  `;

  export const FooterText = styled.span<{
    deckSize: DeckSize;
  }>`
    padding: 0 10px 0 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: ${etDark};
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    z-index: 1;
    margin-bottom: 20px;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontSize: "10px",
        lineHeight: "11px",
        marginBottom: "19px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontSize: "9px",
        lineHeight: "11px",
        marginBottom: "19px",
      }};
  `;

  export const CuratorName = styled.a`
    font-family: "Roboto Condensed";
    font-size: 12px;
    font-weight: 700;
    position: relative;
    z-index: 10;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    color: ${etBlack};
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: ${etBlack};
    }
  `;

  export const DeckBy = styled.span`
    font-family: "Roboto Condensed";
    font-size: 12px;
    font-weight: 400;
  `;

  export const Name = styled.span<{
    color: string;
    textAlign: string;
    deckSize: DeckSize;
  }>`
    font-family: "Roboto Condensed";
    cursor: alias;
    z-index: 1;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-top: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: ${({ textAlign }) => textAlign};
    color: ${({ color }) => color};

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontSize: "21px",
        marginTop: "100px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontSize: "21px",
        lineHeight: "24px",
        marginTop: "90px",
      }};
  `;

  export const Statistic = styled.span<{
    color: string;
    textAlign: string;
  }>`
    cursor: alias;
    z-index: 1;
    font-weight: 400;
    line-height: 16px;
    margin-top: 12px;
    min-height: 16px;
    color: ${({ color }) => color};
    text-align: ${({ textAlign }) => textAlign};
  `;
}
