import { action, makeObservable, observable } from "mobx";
import { RefObject } from "react";

import { SectionSevenDriver } from "../components/ProtectV202208/SectionSevenV202208/SectionSevenV202208";

export class SectionSevenStore implements SectionSevenDriver {
  @observable expended: boolean = false;
  @observable logoRotateDeg: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setLogoRotateDeg(n: number) {
    this.logoRotateDeg = n;
  }

  @action.bound
  setExpendState(state: boolean) {
    this.expended = state;
  }

  @action
  expandContract(contractRef: RefObject<HTMLDivElement>) {
    if (!contractRef.current) return;

    contractRef.current.classList.toggle("expanded");
    contractRef.current.classList.toggle("collapsed");
    this.setExpendState(!this.expended);
  }

  @action
  handleScroll(sectionRef: RefObject<HTMLDivElement>) {
    if (!sectionRef.current) return;

    const bounding = sectionRef.current.getBoundingClientRect();
    const amplitude = window.innerHeight - sectionRef.current.offsetHeight;
    const navBarHeight = 64;
    const deg =
      ((window.innerHeight - bounding.y - amplitude + navBarHeight) /
        sectionRef.current.offsetHeight) *
      180; // a half circle

    /* stop when filled the half circle */
    if (deg > 180) {
      this.setLogoRotateDeg(180);
      return;
    }

    if (deg < 0) {
      this.setLogoRotateDeg(0);
      return;
    }

    this.setLogoRotateDeg(deg);
  }
}
