import { DeckDetailResponseDto } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { ReactNode } from "react";

import { pluralize } from "../../shared/helpers/pluralize";
import { useTranslation } from "../../shared/translate/NextI18next";
import { AutoSlide } from "../AutoSlide/AutoSlide";
import sc from "./CardDeckHeader.styled";

export type CardDeckHeaderDriver = {
  readonly shareActive: boolean;
  readonly deck: DeckDetailResponseDto;
  readonly number: number;
  readonly cardDeckPreviewImages: Array<{
    url: string;
    isProfileImage: boolean;
  }>;
  readonly contextMenuActive: boolean;
  readonly formatedDeckName: string;
  readonly deckStatistic: {
    cardsCount: number;
    followersCount?: number;
  };
  toggleShareActive(): void;
};

interface IProps {
  driver: CardDeckHeaderDriver;
  renderContext(): ReactNode;
  renderActions(): ReactNode;
  renderSharing(): ReactNode;
}

export const CardDeckHeader = observer(function CardDeckHeader(props: IProps) {
  const { driver, renderContext, renderActions, renderSharing } = props;

  const { t } = useTranslation("DeckGeneral");

  return (
    <sc.Container className="visual-reg" data-cy="CardDeckHeader-Container">
      {driver.shareActive && (
        <>
          <sc.SharingOverlay onClick={() => driver.toggleShareActive()} />
        </>
      )}

      {driver.contextMenuActive && <sc.BlackOverlay />}
      {renderContext()}
      {renderSharing()}
      <sc.LIP />
      <sc.Content>
        <AutoSlide images={driver.cardDeckPreviewImages} />
        <sc.Body>
          {driver.number === 0 ? undefined : (
            <sc.GoalNumber>{driver.number}</sc.GoalNumber>
          )}
          <sc.Title
            data-cy="CardDeckHeader-Title"
            title={driver.formatedDeckName}
          >
            {driver.formatedDeckName}
          </sc.Title>
          <sc.Detail data-cy="CardDeckHeader-Detail">
            <>
              {t("deckgeneral.author")} &nbsp;
              <Link
                href="/[vanityName]"
                as={`/${driver.deck.data.curator.vanityName}`}
                passHref
                legacyBehavior
              >
                <sc.Curator data-cy="CardDeckHeader-Curator">
                  {driver.deck.data.curator.name}
                </sc.Curator>
              </Link>
              &nbsp;
            </>
            <sc.HeaderCounts>
              <sc.Dash>-&nbsp;</sc.Dash>
              {pluralize("Card", driver.deckStatistic.cardsCount, t)}
              {driver.deckStatistic.followersCount !== undefined &&
                " - " +
                  pluralize("Follower", driver.deckStatistic.followersCount, t)}
            </sc.HeaderCounts>
          </sc.Detail>

          <sc.Description data-cy="CardDeckHeader-Description">
            <sc.DescriptionText title={driver.deck.data.description}>
              {driver.deck.data.description}
            </sc.DescriptionText>
          </sc.Description>
        </sc.Body>
        <sc.ActionsContent data-cy="CardDeckHeader-ActionsContent">
          {renderActions()}
        </sc.ActionsContent>
        <sc.OverLay />
      </sc.Content>
    </sc.Container>
  );
});
