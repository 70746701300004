import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import {
  CARD_MUCSIC_WHY_COMMENT_LIMIT,
  DATE_FORMAT_MMMM_DD_YYYY,
} from "../../shared/constants";
import { TruncateWithEllipsis } from "../CardItem/TruncateWithEllipsis";
import { HydratedDateWrapper } from "../HydratedDateWrapper/HydratedDateWrapper";
import sc from "./CardItemMusicFooter.styled";

interface IProps {
  id: string;
  isDoubleSize: boolean;
  comment: string;
  vanityName: string;
  curatorName: string;
  updatedDate: string;
  deckPathString: string;
  renderCardSimpleAction(): ReactNode;
}
export const CardItemMusicFooter = observer(function CardItemMusicFooter(
  props: IProps,
) {
  const {
    comment,
    vanityName,
    curatorName,
    updatedDate,
    isDoubleSize,
    renderCardSimpleAction,
  } = props;

  const renderContainer = (children: ReactNode) => {
    if (isDoubleSize) {
      return <sc.ContainerDouble>{children}</sc.ContainerDouble>;
    }
    return <sc.ContainerSingle>{children}</sc.ContainerSingle>;
  };

  return renderContainer(
    <>
      <sc.Curator>
        By&nbsp;
        <Link href="/[vanityName]" as={`/${vanityName}`} passHref>
          {curatorName}
        </Link>
        &nbsp;-&nbsp;
        <HydratedDateWrapper>
          {format(new Date(updatedDate), DATE_FORMAT_MMMM_DD_YYYY)}
        </HydratedDateWrapper>
      </sc.Curator>

      {!isDoubleSize && (
        <sc.CardFooterTextContainer>
          <sc.CardFooterText>
            <TruncateWithEllipsis
              characterLimit={CARD_MUCSIC_WHY_COMMENT_LIMIT}
              text={comment}
            />
          </sc.CardFooterText>
        </sc.CardFooterTextContainer>
      )}
      {renderCardSimpleAction()}
    </>,
  );
});
