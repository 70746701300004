import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { CirclePicker } from "react-color";

import { INFO_CARD_ALLOWED_COLORS } from "../ModalCreateCardInfo/ModalCreateCardInfoBackgroundColorButton";
import { sc } from "./CardItemInfoContextMenu.styled";

interface IProps {
  hoveredColorIndex?: number; // for visual test only
  renderLabel?(): ReactNode;
  color: string;
  onChange(hexColor: string): void;
}

export const EtCircleColorPicker = observer(function EtCircleColorPicker(
  props: IProps,
) {
  const activeColorIndex = INFO_CARD_ALLOWED_COLORS.indexOf(props.color);

  return (
    <sc.EtCircleColorPickerWrapper
      data-cy="EtCircleColorPicker-Wrapper"
      activeColorIndex={activeColorIndex}
      hoveredColorIndex={props.hoveredColorIndex || 0}
    >
      {props.renderLabel && props.renderLabel()}
      <CirclePicker
        data-cy="EtCircleColorPicker-CirclePicker"
        colors={INFO_CARD_ALLOWED_COLORS}
        circleSize={20}
        width="180px"
        onChange={(event) => props.onChange(event.hex)}
      />
    </sc.EtCircleColorPickerWrapper>
  );
});
