import { observer } from "mobx-react-lite";
import getConfig from "next/config";
import { FacebookShareButton, TwitterShareButton } from "next-share";

import {
  etBlack,
  etFbPurple,
  etGray,
  etHeatherBlue,
  etLiBlueWaterDark,
  etPiRed,
  etWaGreen,
  etWhite,
} from "../../shared/colors";
import { getSocialNetworkShareLink } from "../../shared/helpers/getSocialNetworkShareLink";
import { openLinkInNewWindow } from "../../shared/helpers/openLinkInNewWindow";
import { useTranslation } from "../../shared/translate/NextI18next";
import AlertMessage from "../AlertMessage/AlertMessage";
import { ButtonCircleSocial } from "../ButtonCircleSocial/ButtonCircleSocial";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardSharing.styled";

export interface CardSharingDriver {
  readonly pathSharing: string;
  readonly shareActive: boolean;
  readonly previewImage?: string;
  readonly pinterestTitle?: string;
  readonly shareTitle: string;
  readonly cardSharingError: string;
  toggleShareActive(): void;
  updateCardSharingError(err: string): void;
  copyPreviewTextAction?(): void;
  copyCardLinkToClipboard?(cardUrl: string): void;
}
interface IProps {
  driver: CardSharingDriver;
}

const url = getConfig().publicRuntimeConfig.REACT_APP_HOST;

export const CardSharing = observer((props: IProps) => {
  const { t } = useTranslation("CardGeneral");

  const { driver } = props;
  const cardUrl = `${url}/${driver.pathSharing}`;

  const openCardLinkInNewWindow = (cardLink: string) => {
    openLinkInNewWindow(cardLink);
    driver.toggleShareActive();
  };
  return (
    <>
      {driver.shareActive ? (
        <sc.Overlay onClick={driver.toggleShareActive} />
      ) : undefined}
      <sc.Container
        className={driver.shareActive ? "active" : ""}
        data-cy="cardSharing-Container"
      >
        <sc.CardSharingTitle data-cy="cardSharing-title">
          <span>{t(driver.shareTitle)}</span>
        </sc.CardSharingTitle>
        {driver.cardSharingError !== "" && (
          <AlertMessage>{driver.cardSharingError}</AlertMessage>
        )}
        <sc.Close data-cy="cardSharing-closeBtn">
          <IconSvg
            pointer
            icon="close"
            color={etGray}
            hoverColor={etGray}
            padding="0"
            width="16px"
            height="16px"
            onClick={() => {
              driver.toggleShareActive();
              driver.updateCardSharingError("");
            }}
          />
        </sc.Close>
        <sc.LogoRow>
          <div>
            <FacebookShareButton url={cardUrl}>
              <ButtonCircleSocial
                driver={{
                  dataCy: "cardSharing-facebook",
                  icon: "facebook",
                  iconColor: etWhite,
                  backgroundColor: etFbPurple,
                  width: "32px",
                  height: "32px",
                  iconHeight: "20px",
                  iconWidth: "20px",
                }}
              />
            </FacebookShareButton>
          </div>
          <div>
            <TwitterShareButton url={`${cardUrl}`}>
              <ButtonCircleSocial
                driver={{
                  dataCy: "cardSharing-x",
                  icon: "x-twitter",
                  iconColor: etWhite,
                  backgroundColor: etBlack,
                  width: "32px",
                  height: "32px",
                  iconHeight: "20px",
                  iconWidth: "20px",
                }}
              />
            </TwitterShareButton>
          </div>
          <ButtonCircleSocial
            driver={{
              dataCy: "cardSharing-whatsapp",
              icon: "whatsapp",
              iconColor: etWhite,
              backgroundColor: etWaGreen,
              width: "32px",
              height: "32px",
              iconHeight: "20px",
              iconWidth: "20px",

              onClick: () => {
                openCardLinkInNewWindow(
                  getSocialNetworkShareLink("whatsapp", cardUrl),
                );
              },
            }}
          />
          <ButtonCircleSocial
            driver={{
              dataCy: "cardSharing-pinterest",
              icon: "pinterest",
              iconColor: etWhite,
              backgroundColor: etPiRed,
              width: "32px",
              height: "32px",
              iconHeight: "20px",
              iconWidth: "20px",

              onClick: () =>
                openCardLinkInNewWindow(
                  getSocialNetworkShareLink(
                    "pinterest",
                    cardUrl,
                    driver.pinterestTitle,
                    driver.previewImage,
                  ),
                ),
            }}
          />
          <ButtonCircleSocial
            driver={{
              dataCy: "cardSharing-linkedin",
              icon: "linkedin",
              iconColor: etWhite,
              backgroundColor: etLiBlueWaterDark,
              width: "32px",
              height: "32px",
              iconHeight: "20px",
              iconWidth: "20px",

              onClick: () => {
                openCardLinkInNewWindow(
                  getSocialNetworkShareLink("linkedin", cardUrl),
                );
              },
            }}
          />
          <ButtonCircleSocial
            driver={{
              dataCy: "cardSharing-link",
              title: `Copy link: ${cardUrl}`,
              icon: "link",
              iconColor: etWhite,
              backgroundColor: etHeatherBlue,
              width: "32px",
              height: "32px",
              iconHeight: "16px",
              iconWidth: "16px",

              onClick: () => {
                if (driver.copyPreviewTextAction) {
                  driver.copyPreviewTextAction();
                } else if (driver.copyCardLinkToClipboard) {
                  driver.copyCardLinkToClipboard(cardUrl);
                } else {
                  driver.updateCardSharingError("Error: Can not copy.");
                }
              },
            }}
          />
        </sc.LogoRow>
      </sc.Container>
    </>
  );
});
