import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { ETReactProps } from "../../../typings/types";
import sc from "./ButtonCircle.styled";

export interface ButtonCircleDriver {
  readonly type: "gray" | "white" | "blue";
  readonly dataCy: string;
  readonly disabled?: boolean;
  readonly margin?: string;
  readonly padding?: string;

  onClick?(): void;
}

interface IProps extends ETReactProps {
  driver: ButtonCircleDriver;
}

export const ButtonCircle = observer((props: IProps) => {
  const { driver } = props;

  const { disabled = false, margin = "0", padding = "0" } = driver;

  const renderIconWrapper = (children: ReactNode): ReactNode => {
    switch (driver.type) {
      case "gray": {
        return <sc.GrayButton disabled={disabled}>{children}</sc.GrayButton>;
      }
      case "white": {
        return <sc.WhiteButton disabled={disabled}>{children}</sc.WhiteButton>;
      }
      case "blue": {
        return <sc.BlueButton disabled={disabled}>{children}</sc.BlueButton>;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <sc.Container
      className="visual-reg"
      margin={margin}
      padding={padding}
      data-cy={driver.dataCy}
      onClick={driver.onClick}
    >
      {renderIconWrapper(props.children)}
    </sc.Container>
  );
});
