import styled from "styled-components";

import {
  etBlack,
  etBlue,
  etBrightLightBlue,
  etGray,
  etGrayLight,
  etRed,
  etRegentGray,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import zIndex from "../../shared/zIndex";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Container = styled.div<{ contextMenuActive: boolean }>`
    position: relative;
    margin-left: auto;
    user-select: none;
    z-index: ${(props) =>
      props.contextMenuActive ? zIndex.CONTEXT_MENU_OPEN_CARD_ACTIVE : 1};
    width: 100%;
    height: 100%;
  `;

  export const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: default;
  `;

  export const ContextMenuContainer = styled.div<{ iconColor: string }>`
    position: relative;
    line-height: 1.25rem;
    font-size: 1.25rem;
    padding: 0 0 0 0.3rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  `;
  export const ContextMenuContent = styled.div`
    position: relative;
    height: 20px;
  `;
  export const ContextMenu = styled.div<{
    display: string;
  }>`
    display: ${(props) => props.display};
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 24px;
    right: 0;
    padding: 12px;
    z-index: ${zIndex.CONTEXT};
    background-color: ${etWhite};
    border-radius: 0.5rem;
    box-shadow: 0.25rem 1.5rem 0.75rem rgba(0, 0, 0, 0.175);
  `;

  export const ContextMenuItemBlue = styled.span`
    margin-left: 10px;
  `;

  export const ContextMenuTextResize = styled.span`
    font-size: 10px;
    text-transform: uppercase;
    font-family: Roboto Condensed;
    color: ${etGray};
  `;

  export const ContextMenuItemRed = styled.span`
    color: ${etRed};
  `;
  export const ContextMenuItemGrayLight = styled.span`
    color: ${etGrayLight};
    display: flex;
    align-items: center;
  `;
  export const IconContainer = styled.div`
    position: absolute;
    z-index: -1;
    opacity: 0.2;
    transform: scale(1.8);
    top: 5rem;
    right: -4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ::before {
      content: "";
      position: absolute;
      height: 92%;
      width: 92%;
      background-color: ${etWhite};
      border-radius: 1rem;
    }
  `;
  export const ContextMenuRow = styled.div<{ hoverColor?: string }>`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 190px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${etSolitudeLight};

    color: ${etRegentGray};
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;

    > a {
      color: ${etBlack};
      text-decoration: none;
    }
    :hover > ${ContextMenuItemBlue}, :hover > a {
      color: ${etBrightLightBlue};
    }
    :hover > ${ContextMenuItemRed} {
      color: ${etRed};
    }
    :hover > ${IconSvgContainer} {
      fill: ${(props) => props.hoverColor || etBrightLightBlue};
    }
    :hover {
      background-color: ${transparentize(etBrightLightBlue, 0.063)};
      border-color: ${transparentize(etBrightLightBlue, 0.063)};
    }
  `;

  export const ContextMenuRowPublish = styled(ContextMenuRow)<{
    isValid: boolean;
  }>`
    :hover > ${IconSvgContainer} {
      fill: ${(props) => (props.isValid ? etBlue : etGrayLight)};
    }
  `;
  export const ContextMenuRowResize = styled(ContextMenuRow)`
    padding: 0;
  `;

  export const ResizeLayout = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 8px 4px;
    min-width: 160px;
  `;

  export const ResizeText = styled.div`
    color: ${etGray};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    padding-left: 0.25rem;
    margin-bottom: 0.25rem;
  `;

  export const ResizeBorder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    border: 1px solid ${etGrayLight};
    border-radius: 0.25rem;
    box-shadow: none;
    cursor: pointer;
    &.active {
      background-color: #edf8ff;
      border-color: #00a2ff;
      box-shadow: 0 0.25rem 1rem 0 rgba(42, 54, 66, 0.2);
      ${IconSvgContainer} {
        fill: ${etBlue};
      }
      ${ContextMenuItemBlue} {
        color: ${etBlue};
      }
    }
  `;

  export const ResizeWrapper = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `;
}

export default sc;
