import { observer } from "mobx-react-lite";
import React from "react";

import { ModalType } from "../../stores/ModalStore";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";
import { ModalInfoLayout } from "./ModalInfoLayout";

type IProps = {
  openModal(modalTypes: ModalType): void;
};

export const ModalInfo = observer((props: IProps) => {
  const { openModal } = props;

  return (
    <ModalInfoLayout
      driver={{ openModal }}
      renderModalWrapper={(children) => (
        <ModalWrapper modalTypes="info">{children}</ModalWrapper>
      )}
    />
  );
});
