import { SearchResponseDto } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import React from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import { TheLoader } from "../TheLoader/TheLoader";
import { TheNavbarSearchResultItem } from "../TheNavbarSearchResultItem/TheNavbarSearchResultItem";
import { SearchResultTab } from "../TheNavbarTop/TheNavbarStore/TheNavbarStore";
import sc from "./TheNavbarSearchResult.styled";

export interface TheNavbarSearchResultDriver {
  readonly currentResultTabs: SearchResultTab;
  readonly searchResultFocus: boolean;
  readonly searching: boolean;
  readonly searchInputFocus: boolean;
  readonly searchInputValue: string;
  readonly searchResultsForCurrentTab: SearchResponseDto[];
  readonly userLoggedIn: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test

  searchResultFocusUpdate(b: boolean): void;
  searchInputFocusUpdate(b: boolean): void;
  currentResultTabsUpdate(t: SearchResultTab): void;
  turnMenuOff(): void;
  searchInputValueUpdate(s: string): void;
}
interface IProps {
  driver: TheNavbarSearchResultDriver;
}

export const TheNavbarSearchResult = observer(function TheNavbarSearchResult(
  props: IProps,
) {
  const { t } = useTranslation("NavSearch");

  return (
    <sc.SearchResultContainer
      data-cy="SearchResult-Container"
      className={props.driver.mLandscape ? "mLandscape" : ""}
      display={
        (props.driver.searchInputFocus || props.driver.searchResultFocus) &&
        props.driver.searchInputValue
          ? "flex"
          : "none"
      }
      userLoggedIn={props.driver.userLoggedIn}
      onMouseOver={() => props.driver.searchResultFocusUpdate(true)}
      onMouseLeave={() => props.driver.searchResultFocusUpdate(false)}
    >
      <sc.TabContainer>
        <sc.TabSmall
          data-cy="SearchResult-AllTab"
          className={[
            props.driver.currentResultTabs === "all" ? "active" : "",
            props.driver.mLandscape ? "mLandscape" : "",
          ].join(" ")}
          onClick={() => props.driver.currentResultTabsUpdate("all")}
        >
          {t("nav-search.tabs.all")}
        </sc.TabSmall>
        <sc.TabMedium
          data-cy="SearchResult-UserTab"
          className={[
            props.driver.currentResultTabs === "user" ? "active" : "",
            props.driver.mLandscape ? "mLandscape" : "",
          ].join(" ")}
          onClick={() => props.driver.currentResultTabsUpdate("user")}
        >
          {t("nav-search.tabs.users")}
        </sc.TabMedium>
        <sc.TabMedium
          data-cy="SearchResult-DeckTab"
          className={[
            props.driver.currentResultTabs === "deck" ? "active" : "",
            props.driver.mLandscape ? "mLandscape" : "",
          ].join(" ")}
          onClick={() => props.driver.currentResultTabsUpdate("deck")}
        >
          {t("nav-search.tabs.decks")}
        </sc.TabMedium>
      </sc.TabContainer>
      {props.driver.searching ? (
        <sc.SearchNoResult>
          <TheLoader />
        </sc.SearchNoResult>
      ) : props.driver.searchResultsForCurrentTab.length === 0 ? (
        <sc.SearchNoResult data-cy="SearchResult-NoResult">
          <b>{t("nav-search.no-results")}</b>
          <sc.SearchNoResultText
            data-cy="SearchResult-NoResultText"
            className="text-muted"
          >
            {t("nav-search.could-not-find")}{" "}
            <b>{props.driver.searchInputValue}</b>
          </sc.SearchNoResultText>
        </sc.SearchNoResult>
      ) : (
        <sc.SearchResults
          data-cy="SearchResult-Item"
          userLoggedIn={props.driver.userLoggedIn}
        >
          {props.driver.searchResultsForCurrentTab.map(
            (item: SearchResponseDto) => (
              <TheNavbarSearchResultItem
                key={item.id}
                item={item}
                searchInputValue={props.driver.searchInputValue}
                searchResultFocusUpdate={props.driver.searchResultFocusUpdate}
                searchInputFocusUpdate={props.driver.searchInputFocusUpdate}
                turnMenuOff={props.driver.turnMenuOff}
                mLandscape={props.driver.mLandscape}
                searchInputValueUpdate={props.driver.searchInputValueUpdate}
              />
            ),
          )}
        </sc.SearchResults>
      )}
    </sc.SearchResultContainer>
  );
});
