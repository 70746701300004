/* eslint-disable import/no-default-export */
import styled, { css, keyframes } from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  smallScreenUpperLimit,
  xmediumScreenLowerLimit,
} from "../../../shared/breakpoints";
import { etBlack, etBrightLightBlue, etWhite } from "../../../shared/colors";
import { sc as scLeaderboard } from "../../Leaderboard/Leaderboard.styled";

export namespace sc {
  const wordChange = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    2% {
      opacity: 1;
      transform: translateY(0px);
    }
    18% {
      opacity: 1;
      transform: translateY(0px);
    }
    30% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  `;
  export const Container = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 857px;
    min-height: 857px;

    @media (max-width: ${largeScreenUpperLimit}) {
      height: 700px;
      min-height: 700px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      height: 1209px;
      min-height: 1209px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      height: 1209px;
      min-height: 1209px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex-direction: column;
    }
  `;

  export const SpaceBackground = styled.div<{ enableOneFlow: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${require("../../../assets/img/space-background-protect.png")});
    z-index: 1;
    background-repeat: ${(props) =>
      props.enableOneFlow ? "auto" : "no-repeat"};

    @media (max-width: ${xmediumScreenLowerLimit}) {
      background-size: cover;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      background-size: 100%;
      background-image: url(${require("../../../assets/img/space-1-column.png")});
    }
  `;

  export const GlobeBackground = styled.div`
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position-x: 5%;
    background-position-y: -23%;
    background-size: 70%;
    background-image: url(${require("../../../assets/img/earth.png")});
    z-index: 2;

    @media (max-width: ${largeScreenUpperLimit}) {
      background-position-x: 5%;
      background-position-y: -70%;
      background-size: 70%;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      background-position-y: -40%;
      background-position-x: -140%;
      background-size: 90%;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      background-position-x: 60%;
      background-position-y: 31%;
      background-size: 108%;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      background-position-x: 50%;
      background-position-y: 48%;
      background-size: 230%;
    }
  `;

  export const GradientOverlay = styled.div`
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 100%
    );
    z-index: 3;
  `;

  const Col = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 4;
  `;
  export const ColLeft = styled(Col)`
    position: relative;
    flex: 0 0 65%;
    align-items: center;
    justify-content: center;
    margin-top: 6.5%;
    margin-left: 5%;
    z-index: 10;

    @media (max-width: ${mediumScreenUpperLimit}) {
      flex: 1;
      margin-left: 48px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex: 0;
      margin-top: 141px;
      margin-left: 0;
      width: 100%;

      position: absolute;
      top: -18px;
    }
  `;
  export const ColRight = styled(Col)`
    flex: 0 0 33%;
    justify-content: center;
    @media (max-width: ${mediumScreenUpperLimit}) {
      flex: 0 0 40%;
      margin-left: 24px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex: unset;
      justify-content: center;
      align-items: center;
      padding: 100px 0;
      margin-left: 0;
      margin-top: 543px;
    }
    @media (max-width: ${mobileDeviceWidth}) {
      padding: 128px 0;
      margin-top: 517px;
    }
  `;
  export const Leaderboard = styled.div`
    background: ${etWhite};
    border-radius: 8px;
    box-shadow: 0px 16px 24px 0px rgba(42, 54, 66, 0.2);
    height: 496px;
    width: 328px;
    z-index: 3;
    ${scLeaderboard.ItemContent} {
      grid-gap: 12px;
      margin: 24px 0 10px 0;
    }
  `;
  export const CountsWrapper = styled.div`
    display: flex;
    overflow: hidden;
    padding-left: 8px;
    margin-bottom: 21px;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
      height: 52px;
      justify-content: center;
      padding-left: 4px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-bottom: 20px;
      height: 51px;
      padding-left: 2px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      height: 28px;
      margin-top: -30px;
      margin-bottom: 0px;
    }
  `;
  export const WrapperM2 = styled.div<{ isLast?: boolean }>`
    border: 4.5px solid ${etWhite};
    height: 75px;
    width: 75px;

    margin-left: -4.5px;
    padding-top: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
    }

    ${(props) =>
      props.isLast &&
      css`
        span {
          animation: move linear 1.5s 1;
        }
      `}

    @keyframes move {
      from {
        transform: translateY(50%);
      }
      to {
        transform: translateY(0%);
      }
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      width: 51px;
      height: 51px;
    }
  `;
  export const Buttons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    justify-content: center;
    @media (max-width: ${mobileDeviceWidth}) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      rows-gap: 12px;
      columns-gap: 0px;
    }
  `;
  export const LabelProtect = styled.label`
    height: 29px;
    color: ${etWhite};
    font-size: 24px;
    font-weight: normal;
    font-stretch: condensed;
    letter-spacing: 0px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    margin: 14px 0 34px 0;

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 20px;
      margin-top: 5px;
      margin-bottom: 18px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      margin-top: 4px;
      margin-bottom: -4px;
      font-size: 20px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      font-size: 16px;
      height: 29px;
      line-height: 29px;
      margin-top: 16px;
    }
  `;
  export const LastNameProtector = styled(LabelProtect)`
    line-height: 45px;
    margin: 0;
    transform: translateY(-30px);

    @media (max-width: ${xmediumScreenLowerLimit}) {
      transform: translateY(0px);
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      transform: translateY(-60px);
    }
  `;
  export const EarthFlag = styled.a`
    position: absolute;
    top: -3%;
    left: 50%;
    width: 117px;
    max-width: 117px;
    height: 75px;
    max-height: 75px;
    background-color: transparent;
    opacity: 0.5;
    z-index: 5;

    @media (max-width: ${largeScreenUpperLimit}) {
      top: 4%;
      left: 50%;
      width: 90px;
      height: 50px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      top: 6%;
      left: 50%;
      width: 75px;
      height: 50px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      top: -4%;
      left: 50%;
      width: 75px;
      max-width: 75px;
      height: 50px;
      max-height: 50px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      top: 6%;
      left: 47%;
      width: 67px;
      max-width: 67px;
      height: 45px;
      max-height: 45px;
    }
  `;
  export const ButtonSectionOne = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: none;
    height: 40px;
    max-height: 40px;
    width: 195px;
    max-width: 195px;

    color: ${etWhite};
    font-family: Roboto;
    font-stretch: condensed;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;

    letter-spacing: 0px;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
      color: ${etWhite};
    }
  `;
  export const FinancialStatementButton = styled(ButtonSectionOne)`
    background-color: transparent;
    border: 1px solid ${etWhite};
  `;
  export const StartProtectButton = styled(ButtonSectionOne)`
    cursor: pointer;
    background-color: ${etBrightLightBlue};
  `;
  export const TabContainer = styled.div`
    position: absolute;
    top: 26px;
    left: 120px;
    display: grid;
    grid-template-columns: repeat(6, auto);
    column-gap: 12px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      left: 40px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      grid-template-columns: repeat(6, auto);
      column-gap: 6px;
      left: 12px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(2, auto);
      gap: 6px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);
    }
  `;

  export const Tab = styled.a<{
    width: string;
    height: string;
  }>`
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${etWhite};
    min-height: ${(props) => props.height};
    max-height: ${(props) => props.height};
    min-width: ${(props) => props.width};
    max-width: ${(props) => props.width};

    color: ${etWhite};
    font-family: Roboto;
    font-stretch: condensed;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    text-align: center;
    :hover {
      text-decoration: none;
      color: ${etWhite};
      background-color: ${etBlack};
      border: 1px solid ${etBlack};
    }

    @media (max-width: ${mobileDeviceWidth}) {
      :nth-child(2) {
        margin-left: -55px;
      }
      :nth-child(6) {
        margin-left: -6px;
      }
    }
  `;
  export const TopIcon = styled.div`
    margin-bottom: 9px;
  `;

  export const FlagPoleIcon = styled.div`
    height: 150px;
    width: 125px;

    position: absolute;
    left: calc(50% + 22.5px);
    transform: translateX(-50%);
    z-index: 3;

    @media (max-width: ${largeScreenUpperLimit}) {
      top: 23.5%;
      left: calc(50% + 16.5px);
      height: 115px;
      width: 90px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      top: 23.5%;
      left: 53%;
      height: 100px;
      width: 80px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      top: 17.3%;
      left: 53.5%;
      height: 100px;
      width: 80px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      top: 23%;
      height: 80px;
      width: 60px;
    }
  `;

  export const SquareMeterIcon = styled.div`
    background: url(${require("../../../assets/img/m2-white.png")}) center
      center no-repeat;
    background-size: contain;

    width: 106px;
    height: 106px;

    @media (max-width: ${largeScreenUpperLimit}) {
      width: 80px;
      height: 80px;
      margin-top: 78px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 80px;
      height: 80px;

      margin-top: 78px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      width: 60px;
      height: 60px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      width: 45px;
      height: 45px;
      margin-top: 109px;
    }
  `;

  export const Text = styled.p`
    color: ${etWhite};
    font-family: Roboto;
    font-size: 60px;
    font-weight: 300;
    height: 83px;
    letter-spacing: -0.5px;
    line-height: 65px;
    text-align: center;

    margin: 20px 0 9px 0;

    span > span {
      font-weight: 500;
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 40px;
      margin-top: 14px;
      margin-bottom: -5px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 30px;
      line-height: 24px;
      margin-top: 25px;
      margin-bottom: -13px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      font-size: 40px;
      font-weight: 300;
      line-height: 45px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      font-size: 25px;
      height: 83px;
      line-height: 30px;
      letter-spacing: -0.5px;
      margin-top: 22px;
      margin-bottom: -6px;
    }
  `;

  export const TextAnimate = styled(Text)`
    position: absolute;
    top: 0;
    left: 8px;
    overflow: hidden;
    animation: ${wordChange} 6s linear infinite;
    opacity: 0;

    :nth-child(1) {
      animation-delay: 0;
    }
    :nth-child(2) {
      animation-delay: 1.5s;
    }
    :nth-child(3) {
      animation-delay: 3s;
    }
    :nth-child(4) {
      animation-delay: 4.5s;
    }
  `;

  export const AnimationTextWrapper = styled.div<{
    noAnimationCounter: boolean;
  }>`
    width: 312px;
    position: relative;

    ${TextAnimate} {
      :nth-child(1) {
        ${(props) =>
          props.noAnimationCounter &&
          `
          opacity: 1;
          transform: translateY(0px);
        `}
      }

      ${(props) =>
        props.noAnimationCounter &&
        `
        animation: none;
        
    `}
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      width: 210px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 160px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      width: 210px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      width: 135px;
    }
  `;

  export const Title = styled.div`
    display: flex;
    justify-content: center;
  `;

  export const SubDescription = styled.span`
    font-size: 16px;
    font-family: Roboto;
    font-stretch: normal;
    font-weight: 500;
    height: 38px;
    line-height: 37px;
    text-align: center;
    color: ${etWhite};
    margin-bottom: 11px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-top: 22px;
      margin-bottom: 9px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      margin-top: 29px;
      margin-bottom: 3px;
    }
  `;
}
