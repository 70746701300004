import styled from "styled-components";

import { etBlack, etDark, etWhite } from "../../shared/colors";
import {
  CARD_DECK_DISPLAY_IN_DECK_LOGO_HEIGHT,
  CARD_DECK_DISPLAY_IN_DECK_LOGO_WIDTH,
  CARD_DECK_MODAL_LOGO_HEIGHT,
  CARD_DECK_MODAL_LOGO_WIDTH,
  DeckSize,
} from "../../stores/CardDeckOverviewModel";
import {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "../TheNavbarControllers/TheNavbarControllers.styled";

namespace sc {
  export const FooterContainer = styled.div<{ deckSize: DeckSize }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 93px;
    background-color: ${etWhite};
    border-radius: 0 0 8px 8px;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        height: "77px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        height: "72px",
      }};
  `;

  export const LogoContainer = styled.div<{ deckSize: DeckSize }>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -20px;
    height: 80px;
    width: 80px;
    background-color: ${etWhite};
    border-radius: 50%;
    cursor: pointer;
    z-index: 11;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        height: "67px",
        width: "67px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        height: "62px",
        width: "62px",
      }};
  `;

  export const Logo = styled.a<{ logo: string; deckSize: DeckSize }>`
    height: ${PROFILE_AVATAR_HEIGHT_DEFAULT}px;
    width: ${PROFILE_AVATAR_WIDTH_DEFAULT}px;
    background-image: url(${(props) => props.logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        height: `${CARD_DECK_MODAL_LOGO_HEIGHT}px`,
        width: `${CARD_DECK_MODAL_LOGO_WIDTH}px`,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        height: `${CARD_DECK_DISPLAY_IN_DECK_LOGO_HEIGHT}px`,
        width: `${CARD_DECK_DISPLAY_IN_DECK_LOGO_WIDTH}px`,
      }};
  `;

  export const FooterText = styled.span<{ deckSize: DeckSize }>`
    padding: 0 10px 0 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: ${etDark};
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    z-index: 1;
    margin-bottom: 20px;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontSize: "10px",
        lineHeight: "11px",
        marginBottom: "19px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontSize: "9px",
        lineHeight: "11px",
        marginBottom: "19px",
      }};
  `;

  export const AuthorName = styled.a`
    position: relative;
    z-index: 10;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    color: ${etBlack};
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: ${etBlack};
    }
  `;

  export const OverlayLink = styled.a`
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  `;

  export const DeckBy = styled.span``;
}

export default sc;
