import styled from "styled-components";

import { etRegentGray, etSolitudeLight, etWhite } from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { DeckSize } from "../../stores/CardDeckOverviewModel";

namespace sc {
  export const DeckName = styled.span<{ deckSize: DeckSize }>`
    cursor: alias;
    z-index: 1;
    font-weight: 900;
    font-size: 19px;
    line-height: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontize: "21px",
        fontFamily: "Roboto Condensed",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontSize: "21px",
        lineHeight: "24px",
        fontFamily: "Roboto Condensed",
      }};
  `;

  export const DeckNameNewDesign = styled.span<{ deckSize: DeckSize }>`
    cursor: alias;
    z-index: 1;
    font-family: "Roboto Condensed";
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-top: 126px;
    text-align: center;

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        marginTop: "101px",
        fontize: "23px",
        fontFamily: "Roboto Condensed",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        marginTop: "101px",
        fontSize: "21px",
        lineHeight: "24px",
        fontFamily: "Roboto Condensed",
      }};
  `;

  export const DeckInfo = styled.span<{ deckSize: DeckSize }>`
    cursor: alias;
    z-index: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin: 12px 0;
    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontFamily: "Roboto Condensed",
      }};
    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontFamily: "Roboto Condensed",
        fontSize: "16px",
      }};
  `;

  export const DeckInfoNewDesign = styled.span<{ deckSize: DeckSize }>`
    cursor: alias;
    z-index: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin: 12px 0;
    width: 100%;
    text-align: center;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontFamily: "Roboto Condensed",
        fontSize: "12px",
        marginTop: "4px",
      }};
    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontFamily: "Roboto Condensed",
        fontSize: "12px",
      }};
  `;

  export const DescriptionWrap = styled.div<{
    deckSize: DeckSize;
    centered: boolean;
  }>`
    cursor: alias;
    display: block;
    font-size: 16px;
    color: ${etWhite};
    z-index: 1;
    margin-top: 12px;
    font-family: Roboto;
    word-break: break-word;
    text-align: ${(props) => (props.centered ? "center" : "left")};

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontSize: "12px",
        marginTop: "10px",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontSize: "12px",
        marginTop: "16px",
        maxWidth: "208px",
        maxHeight: "170px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }};
  `;

  export const DescriptionNewDesign = styled.p<{
    deckSize: DeckSize;
    centered: boolean;
  }>`
    cursor: alias;
    display: -webkit-box;
    font-size: 16px;
    color: ${etWhite};
    z-index: 1;
    font-family: Roboto;
    word-break: break-word;
    text-align: ${(props) => (props.centered ? "center" : "left")};
    margin: 0;
    width: 100%;
    height: 96px;
    margin: 12px 0;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        fontSize: "12px",
        margin: "0 0",
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        fontSize: "12px",
        marginTop: 0,
        height: "74px",
      }};
  `;

  export const ButtonsWrapper = styled.div`
    position: relative;
    z-index: 11;
    width: 100%;
    height: 32px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
  `;
  export const FollowButton = styled.div<{ deckSize: DeckSize }>`
    text-transform: uppercase;
    z-index: 11;
    margin-top: 12px;
    width: min-content;
    padding: 6px 18px;
    border-radius: 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    border: none;
    color: ${etWhite};
    background-color: ${transparentize(etSolitudeLight, 0.5)};
    cursor: pointer;
    :hover {
      font-stretch: condensed;
      color: ${etRegentGray};
      background-color: ${etWhite};
    }

    ${(props) =>
      props.deckSize === DeckSize.Modal && {
        marginTop: "unset",
        width: "77.93px",
        padding: "6px 8px",
        zIndex: 4,
      }};

    ${(props) =>
      props.deckSize === DeckSize.DisplayInDeck && {
        marginTop: "unset",
        width: "77.93px",
        padding: "6px 8px",
      }};
  `;
  export const BtnNewDesign = styled(FollowButton)`
    margin: 0;
  `;
}

export default sc;
