import { observer } from "mobx-react-lite";

import sc from "./CardSimpleContext_Update.styled";

interface BlackOverlayDriver {
  readonly contextMenuActive: boolean;
}

interface IProps {
  driver: BlackOverlayDriver;
}

export const BlackOverlay = observer(function CardDeckOverview(props: IProps) {
  const { driver } = props;

  return <>{driver.contextMenuActive && <sc.BlackOverlay />}</>;
});
