/* eslint-disable unicorn/prefer-spread */
import { observer } from "mobx-react-lite";
import React from "react";

import star from "../../assets/svgs/star.svg";
import UonM2Logo from "../../assets/svgs/uon-m2-logo.svg";
import {
  Direction,
  transformDigitToDegrees,
} from "../../shared/helpers/coordinates";
import { getNewImageUon } from "../../shared/helpers/getImageUon";
import { LatLng } from "../../shared/models/Uon";
import { protectflowStarted } from "../../shared/snowplow";
import { formatUonNumber } from "../CardItemTransactionStandard/helper/formatUonNumber";
import { LazyImage } from "../LazyImage/LazyImage";
import sc from "./CardSquareTransactionV202208.styled";

interface Driver {
  uonReserveSize: number;
  point: LatLng | null;
  isEndWithOne: boolean;
}
interface IProps {
  driver: Driver;

  updateUonSize(dataCy: string): void;
}

export const CardSquareTransactionV202208 = observer((props: IProps) => {
  return (
    <sc.Wrapper data-cy="CardSquareTransactionV202208-Wrapper">
      <sc.OverlayContainer
        data-cy={`LayoutProtect-UonCardSquare-${props.driver.uonReserveSize}`}
        data-track-id={`protectPage_fold-section2_btn-${props.driver.uonReserveSize}uon`}
        onClick={(event) => {
          event.preventDefault();
          props.updateUonSize(
            `LayoutProtect-UonCardSquare-${props.driver.uonReserveSize}`,
          );
          protectflowStarted(event);
        }}
      />
      <sc.Container
        data-cy={`LayoutProtect-BuyBtn${props.driver.uonReserveSize}`}
      >
        <sc.EarthLines uonCount={props.driver.uonReserveSize} />
        <sc.CardContentContainer>
          <sc.Overlay
            uonReserveSize={props.driver.uonReserveSize}
            data-cy="CardSquareTransactionV202208-Overlay"
          />
          <sc.UonReserveBackground data-cy="CardSquareTransactionV202208-Background">
            <LazyImage
              src={getNewImageUon(props.driver.uonReserveSize)}
              width="100%"
              maxWidth="100%"
              height="304px"
              maxHeight="304px"
              alt="uon reserve image"
              position="absolute"
              top="0px"
              left="0px"
            />
            <sc.SquareM2>
              <LazyImage
                src={UonM2Logo}
                width="280px"
                maxWidth="280px"
                height="280px"
                maxHeight="280px"
                alt="uon m2 logo"
              />
              <sc.UonReserveTop>
                <sc.UonReserveCount
                  data-cy="CardSquareTransactionV202208-UonCount"
                  isEndWithOne={props.driver.isEndWithOne}
                  uonCount={props.driver.uonReserveSize}
                >
                  {formatUonNumber(props.driver.uonReserveSize)}
                </sc.UonReserveCount>
                <sc.UonReserveTitle data-cy="CardSquareTransactionV202208-Title">
                  <sc.UonReserveTitle1>uon</sc.UonReserveTitle1>
                  unit of nature
                </sc.UonReserveTitle>
              </sc.UonReserveTop>
              <sc.TopRightStar src={star} />
              <sc.TopRightText>
                {props.driver.point && (
                  <>
                    <sc.Coordinate data-cy="CardSquareTransactionV202208-Coordinate">
                      {`${transformDigitToDegrees(
                        props.driver.point.lat,
                        Direction.Latitude,
                      )} 
                       -
                        ${transformDigitToDegrees(
                          props.driver.point.lng,
                          Direction.Longitude,
                        )}`}
                    </sc.Coordinate>
                  </>
                )}
              </sc.TopRightText>
            </sc.SquareM2>
          </sc.UonReserveBackground>
        </sc.CardContentContainer>
      </sc.Container>
    </sc.Wrapper>
  );
});
