import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { etWhite } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import { OverlayDriver } from "./LayoutError";
import sc from "./LayoutErrorCustom.styled";

interface IProps {
  overlayDriver: OverlayDriver;
}

export const LayoutErrorInternalServer = observer((props: IProps) => {
  return (
    <sc.Wrapper
      className="visual-reg"
      data-cy="LayoutErrorInternalServer"
      position={props.overlayDriver.isOverlayActive ? "fixed" : "relative"}
    >
      <sc.BackgroundBlur data-cy="LayoutError-Background" />
      <sc.Overlay data-cy="LayoutError-Overlay" />
      <sc.Container>
        <sc.ColLeft>
          <sc.ErrorImage data-cy="LayoutError-ErrorImage">
            <IconSvg
              width="281px"
              height="250px"
              padding="0"
              icon="page-not-found-error"
              pointer
              color={etWhite}
              margin="-35px 0 0 0"
            />
          </sc.ErrorImage>
        </sc.ColLeft>

        <sc.ColRight>
          <sc.StatusCode data-cy="LayoutError-StatusCode">
            Error 500: The server returned an error and the support has been
            notified
          </sc.StatusCode>
          <sc.ErrorMessage data-cy="LayoutError-ErrorMessage">
            Sorry, the server returned an error.
          </sc.ErrorMessage>
          <sc.ErrorTitle>
            The server returned an error and the support has been notified
          </sc.ErrorTitle>
          <sc.ErrorDescription data-cy="LayoutError-ErrorDescription">
            Double check the URL for spelling and CAPS, otherwise, try using our
            search box.
          </sc.ErrorDescription>

          <sc.LinkNavContainer data-cy="LayoutError-Nav">
            <Link href="/" as="/" passHref legacyBehavior>
              <sc.LinkNavItem>Home »</sc.LinkNavItem>
            </Link>

            <Link
              href="/naturereserves"
              as="/naturereserves"
              passHref
              legacyBehavior
            >
              <sc.LinkNavItem>Protect m² »</sc.LinkNavItem>
            </Link>

            <sc.BreakLine />
            <Link
              href="https://support.earthtoday.com"
              as="https://support.earthtoday.com/"
              passHref
              legacyBehavior
            >
              <sc.LinkNavItem target="_blank">Support »</sc.LinkNavItem>
            </Link>

            <Link
              href="https://support.earthtoday.com/en/collections/3529132-introduction"
              as="https://support.earthtoday.com/en/collections/3529132-introduction"
              passHref
              legacyBehavior
            >
              <sc.LinkNavItem target="_blank">FAQ »</sc.LinkNavItem>
            </Link>
          </sc.LinkNavContainer>
        </sc.ColRight>
      </sc.Container>
    </sc.Wrapper>
  );
});
