import { observer } from "mobx-react-lite";
import React from "react";

import { useTranslation } from "../../../shared/translate/NextI18next";
import { sc } from "./SectionFiveV202208.styled";

export const SectionFiveV202208 = observer(() => {
  const { t } = useTranslation("Protect");
  const { t: tGeneral } = useTranslation("General");

  return (
    <sc.Section data-cy="SectionFiveV202208" className="visual-reg">
      <sc.Background />

      <sc.Wrapper>
        <sc.Container>
          <sc.ColLeft>
            <sc.SectionTitle data-cy="SectionFiveV202208-Title">
              {t("protect.section-five-v202208.title")}
            </sc.SectionTitle>
            <sc.SectionSubTitle data-cy="SectionFiveV202208-SubTitle">
              {t("protect.section-five-v202208.sub-title")}
            </sc.SectionSubTitle>
            <sc.Buttons>
              <sc.ButtonLinkPrimary
                href="#sectionTwo"
                data-cy="SectionFiveV202208-StartProtecting"
              >
                {tGeneral("general.start-protecting")}
              </sc.ButtonLinkPrimary>
            </sc.Buttons>
          </sc.ColLeft>
        </sc.Container>
      </sc.Wrapper>
    </sc.Section>
  );
});
