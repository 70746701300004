/* eslint-disable @typescript-eslint/ban-ts-comment */
import { observer } from "mobx-react-lite";
import { LoaderComponent } from "next/dynamic";

import { ModalType } from "../../stores/ModalStore";
import { useRootStore } from "../../stores/rootStore";
import { DynamicModalLoader } from "../DynamicModalLoader/DynamicModalLoader";
import ModalCardReport from "../ModalCardReport/ModalCardReport";
import ModalDeckReport from "../ModalDeckReport/ModalDeckReport";
import { ModalDeleteCard } from "../ModalDeleteCard/ModalDeleteCard";
import { ModalDeleteDeck } from "../ModalDeleteDeck/ModalDeleteDeck";
import { ModalInfo } from "../ModalInfo/ModalInfo";
import ModalProfileReport from "../ModalProfileReport/ModalProfileReport";
import ModalSendEmail from "../ModalSendEmail/ModalSendEmail";

export const StatefulModals = observer(() => {
  const rootStore = useRootStore();
  const { openModal } = rootStore.modalStore;
  const { goBack } = rootStore.theNavbarStore;

  return (
    <>
      <ModalDeckReport
        openModal={(modalTypes: ModalType) => openModal(modalTypes)}
      />
      <ModalCardReport
        openModal={(modalTypes: ModalType) => openModal(modalTypes)}
      />
      <ModalProfileReport
        openModal={(modalTypes: ModalType) => openModal(modalTypes)}
      />
      <ModalInfo openModal={(modalTypes: ModalType) => openModal(modalTypes)} />
      <ModalDeleteDeck />
      <ModalDeleteCard />
      <ModalSendEmail
        openModal={(modalTypes: ModalType) => openModal(modalTypes)}
      />
      <DynamicModalLoader
        modalType="donate"
        loadComponent={() =>
          import("../ModalDonation/ModalDonation") as LoaderComponent<unknown>
        }
      />
      <DynamicModalLoader
        modalType="loginForm"
        // @ts-ignore
        loadComponent={() => import("../ModalLogin/ModalLogin")}
      />
      <DynamicModalLoader
        modalType="signup"
        // @ts-ignore
        loadComponent={() => import("../ModalSignUp/ModalSignUp")}
      />
      <DynamicModalLoader
        modalType="forgotPassWord"
        // @ts-ignore
        loadComponent={() =>
          import("../ModalForgotPassword/ModalForgotPassword")
        }
      />
      <DynamicModalLoader
        modalType="restorePassWord"
        // @ts-ignore
        loadComponent={() =>
          import("../ModalRestorePassword/ModalRestorePassword")
        }
      />
      <DynamicModalLoader
        modalType="passRestored"
        // @ts-ignore
        loadComponent={() => import("../ModalPassRestored/ModalPassRestored")}
      />
      <DynamicModalLoader
        modalType="signupForm"
        // @ts-ignore
        loadComponent={() => import("../ModalSignUpForm/ModalSignUpForm")}
      />
      <DynamicModalLoader
        modalType="loginSocial"
        // @ts-ignore
        loadComponent={() => import("../ModalLoginSocial/ModalLoginSocial")}
        goBack={() => goBack()}
        openModal={(modalTypes: ModalType) => openModal(modalTypes)}
      />
      <DynamicModalLoader
        modalType="acceptTermAndPolicy"
        // @ts-ignore
        loadComponent={() => import("../ModalAcceptTerms/ModalAcceptTerms")}
      />
    </>
  );
});
