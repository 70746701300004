import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etDark,
  etHeatherBlue,
  etIceBlue,
  etLicoriceBlue,
  etNevadaGrey,
  etSolitude,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";
import {
  CARD_HEIGHT_IS_NOT_CARD_LAYOUT_EDITORIAL,
  CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE,
  CARD_HEIGHT_WITH_TITLE,
  CARD_HEIGHT_WITHOUT_TITLE,
} from "../../stores/CardBaseModel";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Header = styled.div`
    display: flex;
    padding: 0 12px;
    align-items: center;
    height: 44px;
    border-radius: 8px 8px 0 0;
    width: 100%;
  `;
  export const HeaderTitle = styled.span<{ iconColor: string }>`
    margin-bottom: 0;
    flex: 1;
    font-family: "Roboto Condensed";
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    a {
      color: ${etNevadaGrey};
    }

    a:hover {
      color: ${etBlack};
    }

    > ${IconSvgContainer} {
      margin-right: 6px;
    }

    svg {
      height: 18px;
      width: 19px;
      > g > g {
        stroke: ${(props) => props.iconColor};
        fill: ${(props) => props.iconColor};
      }
    }
  `;
  export const ContextMenuContainer = styled.div`
    width: 32px;
    height: 44px;
  `;
  export const ContentContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `;
  export const PreviewImageContainer = styled.div`
    position: relative;
  `;
  export const PreviewImage = styled.div<{
    isCardSizeDouble: boolean;
    url: string;
    hasTitle: boolean;
    isLayoutEditorialActivated: boolean;
  }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    height: ${(props) =>
      props.isLayoutEditorialActivated
        ? props.isCardSizeDouble &&
          props.hasTitle &&
          props.isLayoutEditorialActivated
          ? `${CARD_HEIGHT_WITH_TITLE}px`
          : props.isCardSizeDouble &&
            !props.hasTitle &&
            props.isLayoutEditorialActivated
          ? `${CARD_HEIGHT_WITHOUT_TITLE}px`
          : `${CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE}px`
        : `${CARD_HEIGHT_IS_NOT_CARD_LAYOUT_EDITORIAL}px`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props) => props.url});
    @media (max-width: ${smallScreenUpperLimit}) {
      height: ${(props) =>
        props.isLayoutEditorialActivated
          ? `${CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE}px`
          : `${CARD_HEIGHT_IS_NOT_CARD_LAYOUT_EDITORIAL}px`};
    }
  `;

  export const TextLayoutContainer = styled.div<{
    backgrondColor: string;
  }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    height: 408px;
    max-height: 408px;
    background-color: ${(props) => props.backgrondColor};
  `;

  export const TextLayoutTittle = styled.div<{
    isCardSizeDouble: boolean;
    shouldReduceLetterSpacing: boolean;
    shouldBreakWord: boolean;
    titleColor: string;
  }>`
    font-family: "Roboto Slab";
    color: ${(props) => props.titleColor};
    font-size: ${(props) => (props.isCardSizeDouble ? "45px" : "30px")};
    line-height: ${(props) => (props.isCardSizeDouble ? "55px" : "40px")};
    font-weight: normal;
    letter-spacing: ${(props) =>
      props.shouldReduceLetterSpacing && !props.shouldBreakWord
        ? "-1.5px"
        : "-0.5px"};
    word-break: ${(props) => (props.shouldBreakWord ? "break-word" : "normal")};
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 58px 17px 38px 12px;
    display: flex;
    align-items: ${(props) => (props.isCardSizeDouble ? "center" : "flex-end")};
    justify-content: ${(props) =>
      props.isCardSizeDouble ? "center" : "flex-start"};
    text-align: ${(props) => (props.isCardSizeDouble ? "center" : "left")};
  `;

  export const CardSourceContainer = styled.div`
    position: absolute;
    bottom: 9px;
    left: 12px;
    display: flex;
    flex-direction: row;
    height: 14px;
    z-index: ${zIndex.CARDITEM_LINK};
  `;

  export const CardSource = styled.div<{
    isCardTextLayout: boolean;
    color: string;
  }>`
    color: ${(props) => (props.isCardTextLayout ? props.color : etWhite)};
    height: 14px;
    line-height: 14px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: condensed;
    letter-spacing: 0;
  `;

  export const CardLink = styled.a`
    display: flex;
    svg {
      display: none;
    }
    :hover {
      text-decoration: none;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      display: flex;
    }
  `;

  export const Body = styled.a<{
    isCardSizeDouble: boolean;
    hasTitle: boolean;
  }>`
    display: ${(props) =>
      props.isCardSizeDouble && !props.hasTitle ? "none" : "flex"};
    flex-direction: column;
    height: ${(props) =>
      props.isCardSizeDouble && props.hasTitle ? "94px" : "100px"};
    min-height: 94px;
    padding: ${(props) =>
      props.isCardSizeDouble ? "17px 0 21px" : "9px 0 12px"};
    line-height: 1.33;
    font-size: 18px;
    color: ${etDark};
    background-color: ${etWhite};
    border-bottom: 1px solid ${etHeatherBlue};
    @media (max-width: ${smallScreenUpperLimit}) {
      height: 100px;
    }
    :hover {
      text-decoration: none;
      color: ${etBlack};
    }
  `;

  export const BodyTextSingle = styled.div`
    flex: 1;
    height: 78px;
    padding: 0 12px;
    font-family: "Roboto Slab";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.5px;
    color: ${etLicoriceBlue};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  `;

  export const BodyTextDouble = styled.div`
    flex: 1;
    max-height: 56px;
    max-width: 483px;
    padding: 0 12px;
    font-family: "Roboto Slab";
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: ${etLicoriceBlue};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  `;

  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: ${zIndex.CARDITEM_BLACK_OVERLAY};
  `;

  export const RemovedCardBody = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    flex: 3;
    color: ${etBlack};
    background-color: ${etSolitude};
    padding: 1rem 1rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    min-height: 304px;
    height: 304px;
    font-weight: 600;
  `;
  export const RemovedCardBodyTitle = styled.div`
    font-weight: bold;
    font-size: 0.625rem;
    padding: 1rem 0;
  `;

  export const Overlay = styled.div`
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 15%;

    background-color: ${etBlack};

    z-index: ${zIndex.CARDITEM_OVERLAY};
  `;
  export const OverlayLinkContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.OVERLAY_LINK};
    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    }
  `;
  export const ContainerButtonAction = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: ${zIndex.ACTION_CONTAINER};
    top: 12px;
    right: 12px;
    visibility: hidden;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;

  export const ButtonLink = styled.a`
    width: 134px;
    height: 32px;
    display: block;
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    border: solid 1px ${etIceBlue};
    font-size: 14px;
    background-color: transparent;
    color: ${etWhite};
    &:hover {
      color: ${etLicoriceBlue};
      text-decoration: none;
      background-color: ${etWhite};
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      display: none;
    }
  `;
  export const ContainerBody = styled.div<{
    isCardSizeDouble: boolean;
    isLayoutEditorialActivated: boolean;
  }>`
    position: relative;
    height: ${(props) =>
      props.isLayoutEditorialActivated
        ? props.isCardSizeDouble && props.isLayoutEditorialActivated
          ? "398px"
          : "304px"
        : "408px"};
    margin: 0 12px 0;
  `;

  export const Gradient = styled.div`
    content: "";
    width: 100%;
    height: 75px;
    opacity: 0.35;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    position: absolute;
    bottom: 0;
    z-index: 0;
  `;

  export const Container = styled.div<{ contextMenuActive: boolean }>`
    position: relative;
    height: 496px;
    padding-bottom: 0;
    background-color: ${etWhite};
    color: ${etBlack};
    :hover {
      ${CardLink} svg {
        @media (max-width: ${mediumScreenUpperLimit}) {
          display: none;
        }
        display: flex;
      }
      ${ContainerButtonAction} {
        visibility: visible;
      }
      ${Overlay} {
        display: ${(props) => (props.contextMenuActive ? "none" : "flex")};
        @media (max-width: ${mediumScreenUpperLimit}) {
          display: none;
        }
      }

      ${Body} {
        border-bottom-color: ${(props) =>
          props.contextMenuActive ? "etHeatherBlue" : "transparent"};
        @media (max-width: ${mediumScreenUpperLimit}) {
          border-bottom-color: ${etHeatherBlue};
        }
      }
    }
  `;

  export const SaveButtonWrapper = styled.div<{ isButtonHiden: boolean }>`
    display: ${(props) => (props.isButtonHiden ? "none" : "block")};
  `;
}
export default sc;
