import { observer } from "mobx-react-lite";
import React from "react";

import { ILeaderboardModel } from "../../stores/LeaderBoardModel";
import {
  DriverLeaderboardContent,
  LeaderboardContent,
  LeaderboardVisibleTab,
} from "./LeaderboardContent";
import { LeaderboardLayout } from "./LeaderboardLayout";

export interface StateDriver {
  readonly loading: boolean;
  readonly hiddenShowMore?: boolean;
  readonly hiddenShare?: boolean;
  readonly scroll?: boolean;
  readonly isLeaderboardCampaignDefault?: boolean;

  readonly isLeaderboardProtectPage?: boolean;

  readonly isNPOHeaderCardEmptyLeaderboard?: boolean; //only for leaderboard on npo header card
}

export interface IProps {
  stateDriver: StateDriver;
  driver: ILeaderboardModel;

  visibleTabs?: LeaderboardVisibleTab[];
}

export const Leaderboard = observer((props: IProps) => {
  const { driver, stateDriver } = props;

  const contentDriver: DriverLeaderboardContent = {
    scroll: stateDriver.scroll || false,
    leaderboard: driver,
    visibleTabs: props.visibleTabs,
    dataCy: "LeaderboardDefault",
    isLeaderboardCampaignDefault:
      stateDriver?.isLeaderboardCampaignDefault || false,
    isNPOHeaderCardEmptyLeaderboard:
      stateDriver.isNPOHeaderCardEmptyLeaderboard,
    isLeaderboardProtectPage: stateDriver.isLeaderboardProtectPage || false,
  };

  return (
    <LeaderboardLayout
      driver={driver}
      stateDriver={stateDriver}
      renderContent={() => <LeaderboardContent driver={contentDriver} />}
    />
  );
});
