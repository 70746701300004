import { observer } from "mobx-react-lite";
import React from "react";

import sc from "./LayoutAbout.styled";

interface Driver {
  closeOverlay(): void;
}
interface IProps {
  isOverlay?: boolean;
  driver: Driver;
}

export const SixthSection = observer(function SixthSection(props: IProps) {
  return (
    <sc.SixSection>
      {props.isOverlay ? null : (
        <sc.ButtonCloseWrap>
          <sc.ButtonInner>
            <sc.ButtonClose
              data-cy="LayoutAbout-CloseOverLayBtn"
              onClick={() => {
                props.driver.closeOverlay();
                window.scrollTo(0, 0);
              }}
            >
              &times;
            </sc.ButtonClose>
          </sc.ButtonInner>
        </sc.ButtonCloseWrap>
      )}
    </sc.SixSection>
  );
});
