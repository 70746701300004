import { observer } from "mobx-react-lite";
import React from "react";

import sc from "./CardBlank.styled";

export interface CardBlankDriver {
  readonly shouldCardBlankClose: boolean;
  readonly bgColor?: string;
  mLandscape?: boolean; // to customize snapshot in visual test
  handleClick(): void;
  handleTouchStart(clientY: number): void;
  handleOnTouchMove(clientY: number): void;
  handleTouchEnd(clientY: number): void;
}

interface IProps {
  driver: CardBlankDriver;
}

export const CardBlank = observer(function CardBlank(props: IProps) {
  const {
    shouldCardBlankClose,
    bgColor,
    mLandscape,
    handleClick,
    handleTouchStart,
    handleOnTouchMove,
    handleTouchEnd,
  } = props.driver;

  return shouldCardBlankClose ? (
    <></>
  ) : (
    <sc.Container
      data-cy="CardBlank"
      className={["visual-reg", mLandscape ? "mLandscape" : ""].join(" ")}
      bgColor={bgColor}
      // handle click only on tablet
      onClick={() => handleClick()}
      // handle touch events
      onTouchStart={(event) =>
        handleTouchStart(event.changedTouches[0].clientY)
      }
      onTouchMove={(event) =>
        handleOnTouchMove(
          event.changedTouches[event.changedTouches.length - 1].clientY,
        )
      }
      onTouchEnd={(event) =>
        handleTouchEnd(
          event.changedTouches[event.changedTouches.length - 1].clientY,
        )
      }
      // handle mouse events
      onMouseDown={(event) => handleTouchStart(event.clientY)}
      onMouseMove={(event) => handleOnTouchMove(event.clientY)}
      onMouseUp={(event) => handleTouchEnd(event.clientY)}
    >
      <sc.Blank />
    </sc.Container>
  );
});
