import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { CardDeckOverview } from "../CardDeckOverview/CardDeckOverview";
import { DeckSystem } from "../DeckSystem/DeckSystem";
import { DeckOverviewModelDriver } from "../ModalSaveDeck/ModalSaveDeck";
interface IProps {
  driver: DeckOverviewModelDriver;
  renderDeckContent(): ReactNode;
  renderDeckFooter(): ReactNode;
  renderMenuContext(): ReactNode;
  renderOverlay(): ReactNode;
  renderSharing(): ReactNode;
}
export const CardDeckSystemOverview = observer(function CardDeckSystemOverview(
  props: IProps,
) {
  const {
    driver,
    renderOverlay,
    renderDeckContent,
    renderDeckFooter,
    renderMenuContext,
    renderSharing,
  } = props;

  if (driver.isDeckSystem) {
    return (
      <DeckSystem
        driver={driver}
        renderOverlay={renderOverlay}
        renderMenuContext={renderMenuContext}
      />
    );
  }

  return (
    <CardDeckOverview
      driver={driver}
      renderOverlay={renderOverlay}
      renderDeckContent={renderDeckContent}
      renderDeckFooter={renderDeckFooter}
      renderMenuContext={renderMenuContext}
      renderSharing={renderSharing}
    />
  );
});
