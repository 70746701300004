import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { etGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { NamePage } from "../../stores/OverlaySystemPagePresenter";
import { SystemDeckParam } from "../../stores/SystemDeckStore";
import { CardIntroDriver } from "../CardIntro/CardIntro";
import { IconSvg } from "../IconSvg/IconSvg";
import { TheLoader } from "../TheLoader/TheLoader";
import { sc } from "./CardIntroSystemDeck.styled";

export type CardIntroSystemDeckDriver = Pick<
  CardIntroDriver,
  | "systemPage"
  | "whiteBannerInfo"
  | "isHomePage"
  | "flagCharitiesTab"
  | "openModalDonate"
  | "openModalSignUp"
  | "closeCardIntro"
>;
interface IPropsCardIntroSystemDeck {
  driver: CardIntroSystemDeckDriver;
}

export const CardIntroSystemDeck = observer(function AboutPageBanner(
  props: IPropsCardIntroSystemDeck,
) {
  const { t } = useTranslation("SystemPageTabs");
  const { driver } = props;

  return (
    <sc.Container
      data-cy="CardIntroSystemDeck-Container"
      className="visual-reg"
      isAboutOverlay={driver.systemPage === NamePage.AboutEarthToday}
    >
      <sc.CloseButton
        display={
          driver.systemPage === SystemDeckParam.NPO ||
          driver.systemPage === SystemDeckParam.BRAND ||
          driver.systemPage === SystemDeckParam.PROTECTOR
            ? "none"
            : "block"
        }
        data-cy="CardIntroSystemDeck-closeBtn"
        onClick={() => driver.closeCardIntro(`${driver.systemPage}`)}
      >
        <IconSvg
          icon="close"
          pointer
          color={etGray}
          height="16px"
          width="16px"
          padding="0"
        />
      </sc.CloseButton>
      {driver.whiteBannerInfo.logo ? (
        <>
          <sc.ContentWrapper data-cy="CardIntroSystemDeck-ContentWrapper">
            <sc.BannerLogo
              logo={driver.whiteBannerInfo.logo}
              data-cy="CardIntroSystemDeck-BannerLogo"
            />
            <sc.Content data-cy="CardIntroSystemDeck-Content">
              <sc.Title
                data-cy="CardIntroSystemDeck-Title"
                isNPOPage={driver.systemPage === SystemDeckParam.NPO}
              >
                {t(driver.whiteBannerInfo.title)}
              </sc.Title>
              {driver.systemPage === SystemDeckParam.NPO && (
                <sc.Subtitle data-cy="CardIntroSystemDeck-SubTitle">
                  {t(driver.whiteBannerInfo.subTitle)}
                </sc.Subtitle>
              )}
              <sc.ButtonsWrapper
                aboutPage={driver.systemPage === NamePage.AboutEarthToday}
                data-cy="CardIntroSystemDeck-ButtonWrapper"
              >
                {driver.systemPage === SystemDeckParam.NPO ? (
                  <Link
                    passHref
                    href="/naturereserves"
                    as="/naturereserves"
                    scroll
                    legacyBehavior
                  >
                    <sc.ProtectLink data-cy="NatureReserves-ProtectLink">
                      {t("systempagetabs.start-protecting")}
                    </sc.ProtectLink>
                  </Link>
                ) : driver.systemPage === SystemDeckParam.PROTECTOR ? (
                  <sc.ProtectLink
                    data-cy="NatureReserves-ProtectLink"
                    onClick={() => props.driver.openModalDonate()}
                  >
                    {t("systempagetabs.start-protecting")}
                  </sc.ProtectLink>
                ) : (
                  <Link
                    href={driver.whiteBannerInfo.blueBtnHref}
                    passHref={true}
                    legacyBehavior
                  >
                    <sc.BlueBtn
                      target={
                        ["/", "#"].includes(
                          driver.whiteBannerInfo.blueBtnHref[0],
                        )
                          ? "_self"
                          : "_blank"
                      }
                      onClick={driver.openModalSignUp}
                      data-cy="CardIntroSystemDeck-BlueBtn"
                      aboutPage={driver.systemPage === NamePage.AboutEarthToday}
                    >
                      {t(driver.whiteBannerInfo.blueBtnTitle)}
                    </sc.BlueBtn>
                  </Link>
                )}
              </sc.ButtonsWrapper>
            </sc.Content>
          </sc.ContentWrapper>
        </>
      ) : (
        <TheLoader />
      )}
    </sc.Container>
  );
});
