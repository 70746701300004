import { observer } from "mobx-react-lite";
import React from "react";
import Slider from "react-slick";

import sc from "./AutoSlide.styled";

interface IProps {
  images: Array<{ url: string; isProfileImage: boolean }>;
}

export const AutoSlide = observer(function AutoSlide(props: IProps) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    fade: true,
    arrows: false,
  };

  return (
    <sc.Container>
      {props.images.length > 1 ? (
        <Slider {...settings}>
          {props.images.map((previewImage) => {
            return previewImage.isProfileImage ? (
              <sc.PreviewImageProfileRepost url={previewImage.url} />
            ) : (
              <sc.Background
                data-cy="AutoSlide-Background"
                key={previewImage.url}
                url={previewImage.url}
              />
            );
          })}
        </Slider>
      ) : props.images[0]?.isProfileImage ? (
        <sc.PreviewImageProfileRepost url={props.images[0]?.url} />
      ) : (
        <sc.Background url={props.images[0]?.url} />
      )}
    </sc.Container>
  );
});
