import styled from "styled-components";

import {
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
  xmediumScreenLowerLimit,
} from "../../shared/breakpoints";
import { etBrightLightBlue, etGrayBold, etWhite } from "../../shared/colors";

namespace sc {
  export const Container = styled.div<{
    isOverlayActive: boolean;
    isCardJoiningOpen: boolean;
    isMenuAppOpen: boolean;
  }>`
    position: ${(props) =>
      props.isCardJoiningOpen || props.isMenuAppOpen
        ? "relative"
        : props.isOverlayActive
        ? "fixed"
        : "relative"};
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    max-width: 100vw;
    min-width: 100vw;
    overflow: hidden;
    scroll-behavior: smooth;
  `;

  export const Section = styled.div`
    display: flex;
    flex-direction: column;

    > div {
      align-items: center;
      justify-content: center;
      min-height: 700px;
      @media (max-width: ${mediumScreenUpperLimit}) {
        justify-content: start;
      }
    }
  `;

  export const Title = styled.label`
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-size: 60px;
    line-height: 65px;
    letter-spacing: -0.5px;
    text-align: left;
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 45px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      line-height: 50px;
      letter-spacing: -1px;
      width: 462px;
      height: 150px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      font-size: 40px;
      padding: 0 15px;
      width: 100%;
      height: fit-content;
    }
  `;

  export const TitleSectionFourAndEight = styled(Title)`
    text-align: center;
    @media (max-width: ${xmediumScreenLowerLimit}) {
      font-size: 45px;
      width: 100%;
      height: fit-content;
    }
  `;
  export const TitleSectionThree = styled(Title)`
    height: fit-content;
    margin-bottom: 10px;
    @media (max-width: ${xmediumScreenLowerLimit}) {
      margin-top: 48px;
      width: 100%;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      text-align: center;
      padding: 0;
      font-size: 45px;
    }
  `;

  export const TitleSectionSix = styled(Title)`
    text-align: center;
    @media (max-width: ${mediumScreenUpperLimit}) {
      padding: 0 20px;
      width: 484px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      margin-top: 91px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 350px;
      padding: 0 15px;
      font-size: 45px;
    }
  `;

  export const SubTitle = styled.label`
    font-family: "Roboto";
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    font-stretch: condensed;
    font-weight: normal;
    margin-bottom: 0;
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 20px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      padding: 0 10px;
    }
  `;

  export const SubTitleFive = styled(SubTitle)`
    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 100%;
      padding: 0 60px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      padding: 0 15px;
    }
  `;

  export const SubTitleSectionSixAndEight = styled(SubTitle)`
    text-align: center;
    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 350px;
    }
  `;

  export const Text = styled.label`
    font-family: "Roboto";
    font-style: normal;
    font-size: 20px;
  `;

  const ButtonLink = styled.a`
    border-radius: 23px;

    min-width: 180px;
    height: 40px;
    text-transform: uppercase;
    font-family: "Roboto Condensed";
    font-style: normal;
    font-size: 16px;

    line-height: normal;

    cursor: pointer;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      text-decoration: none;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      min-width: 156px;
      height: 40px;
    }
  `;

  export const ButtonLinkPrimary = styled(ButtonLink)`
    background: ${etBrightLightBlue};

    color: ${etWhite};

    &:hover {
      color: ${etWhite};
    }
  `;

  export const ButtonLinkPrimaryBuy = styled(ButtonLink)`
    background: ${etBrightLightBlue};

    color: ${etWhite};

    &:hover {
      color: ${etWhite};
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100px;
      height: 40px;
    }
  `;

  export const ButtonLinkSecond = styled(ButtonLink)`
    background: transparent;
    border: 1px solid ${etGrayBold};
    color: ${etGrayBold};

    &:hover {
      color: ${etGrayBold};
    }
  `;

  export const ButtonFinancialStatement = styled(ButtonLinkSecond)`
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 180px;
      height: 40px;
    }
  `;
  export const BackgroundLinear = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
  `;
}

export default sc;
