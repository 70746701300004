import styled from "styled-components";

import { etShadow, etShadowLight } from "../../shared/colors";

namespace sc {
  export const Overlay = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      ${etShadow},
      ${etShadowLight},
      transparent
    );
  `;

  export const OverlayLink = styled.a`
    z-index: 10;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  `;
}

export default sc;
