import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mobileMaxHeight,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etGray,
  etGrayLight,
  etSolitudeLight,
} from "../../shared/colors";

namespace sc {
  export const Container = styled.a`
    flex: 1;
    border: 1px solid ${etGrayLight};
    border-radius: 4px;
    cursor: pointer;
    max-height: 64px;
    display: flex;
    align-items: center;
    padding: 12px;
    color: ${etBlack};
    height: 100%;
    :hover {
      text-decoration: none;
      color: ${etBlack};
      border-color: ${etBrightLightBlue};
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      border-color: ${(props) =>
        props.theme.themeStyle.theNavbarSearchResultItemBorderColor}20;
      min-width: 327px;
      max-width: 327px;
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      border-color: ${etSolitudeLight}20;
    }
    &.mLandscape {
      border-color: ${etSolitudeLight}20;
    }
  `;
  export const PreviewDeckImg = styled.div<{ src: string }>`
    width: 2.5rem;
    height: 2.5rem;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.src});
    border-radius: 0.25rem;
  `;
  export const PreviewUserImg = styled(PreviewDeckImg)`
    border-radius: 50%;
  `;
  export const PreviewBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 0.75rem;
    flex: 1;
    max-width: 198px;
  `;
  export const Title = styled.div`
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    mark {
      color: ${etBrightLightBlue};
      padding: 0;
      background-color: transparent;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultLargeTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const Description = styled.div`
    color: ${etGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    min-height: 16px;
    font-family: "Roboto Condensed";
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultLargeTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const CardCount = styled.div`
    font-size: 0.75rem;
    color: ${etGray};
    line-height: 1rem;
  `;
  export const DeckContent = styled.div`
    display: flex;
    align-items: center;
  `;
}

export default sc;
