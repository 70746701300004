/* eslint-disable import/no-default-export */
import { observer } from "mobx-react-lite";
import React from "react";

import { ModalType } from "../../stores/ModalStore";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";
import { ModalProfileReportLayout } from "./ModalProfileReportLayout";

interface IProps {
  openModal(modalTypes: ModalType): void;
}

export default observer(function (props: IProps) {
  const { openModal } = props;
  return (
    <ModalProfileReportLayout
      driver={{ openModal }}
      renderModalWrapper={(children) => (
        <ModalWrapper modalTypes="profileReport" top="0">
          {children}
        </ModalWrapper>
      )}
    />
  );
});
