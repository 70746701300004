import styled from "styled-components";

import {
  etBrightLightBlue,
  etGrayBold,
  etRegentGray,
  etWhite,
} from "../../shared/colors";

export namespace sc {
  export const Container = styled.div`
    display: grid;
    grid-gap: 16px;
    width: 224px;
    max-width: 224px;
    padding: 12px;
    border-radius: 6px;
    background-color: ${etWhite};
  `;

  export const MenuItem = styled.div<{ useDefaultCursor?: boolean }>`
    cursor: ${(props) => (props.useDefaultCursor ? "default" : "pointer")};
    & > button {
      width: 200px;
    }
  `;

  export const CheckBoxContainer = styled.div<{ disabled: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 12px;
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  `;
  export const CheckBox = styled.input`
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${etGrayBold};
    height: 15px;
    max-height: 15px;
    width: 15px;
    max-width: 15px;
    appearance: none;
    background-color: ${etWhite};
    margin: 0 13px 0 3px;

    &:checked {
      background-color: ${etBrightLightBlue};
      border: none;
    }
    &:disabled {
      cursor: default;
    }
  `;
  export const CheckboxDes = styled.span`
    align-items: center;
    color: ${etRegentGray};
    font-family: Roboto;
    font-stretch: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0;
    margin: 0;
  `;
}
