import { observer } from "mobx-react-lite";
import React from "react";

import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";

interface IProps {
  characterLimit: number;
  text: string;
}

export const TruncateWithEllipsis = observer(function (props: IProps) {
  if (
    props.characterLimit &&
    props.text &&
    props.text?.length > props.characterLimit
  ) {
    const truncatedText =
      props.text && truncateWithEllipsis(props.text, props.characterLimit);

    return <>{truncatedText}</>;
  }

  return <>{props.text}</>;
});
