import { observer } from "mobx-react-lite";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";

import { isBrowser } from "../../shared/helpers/isBrowser";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardJoining.styled";

const VimeoPlayer = dynamic(() => import("react-player/vimeo"), { ssr: false });

export interface CardJoiningDriver {
  readonly isIntroVideoOpen: boolean;
  readonly isCardJoiningOpen: boolean;
  readonly shouldShowCookiePolicy: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  toggleMenuApp(): void;
  setIntroVideoOpen(isOpen: boolean): void;
  onStartProtectingClicked(): void;

  isOffline?: boolean;
}

interface IProps {
  driver: CardJoiningDriver;
}

export const CardJoining = observer(function CardJoining(props: IProps) {
  const router = useRouter();
  const {
    isIntroVideoOpen,
    isCardJoiningOpen,
    shouldShowCookiePolicy,
    mLandscape,
    toggleMenuApp,
    setIntroVideoOpen,
  } = props.driver;

  const isOffline =
    props.driver.isOffline || !isBrowser()
      ? props.driver.isOffline
      : !window.navigator.onLine;

  if (!isCardJoiningOpen) {
    return <></>;
  }

  return (
    <>
      <sc.Container
        data-cy="CardJoining-Container"
        className={["visual-reg", mLandscape ? "mLandscape" : ""].join(" ")}
      >
        <sc.SquareBox>
          <sc.SquareMeter>
            m<sc.SupMeter>2</sc.SupMeter>
          </sc.SquareMeter>
        </sc.SquareBox>
        <sc.Title>Join Uon.Earth</sc.Title>
        <sc.Description>
          Protect 1m<sc.Sup>2</sc.Sup> for only &#8364;1.20
        </sc.Description>
        <sc.Button
          data-cy="CardJoining-StartProtecting"
          onClick={() => {
            toggleMenuApp();
            router.push("/uonearth?oneFlowType=protect&protectSize=1");
          }}
        >
          <sc.ButtonText>START PROTECTING</sc.ButtonText>
        </sc.Button>

        <sc.Row>
          <sc.Option
            data-cy="CardJoining-Intro"
            onClick={() => {
              setIntroVideoOpen(true);
            }}
          >
            <sc.Circle
              className={["intro", mLandscape ? "mLandscape" : ""].join(" ")}
            >
              <IconSvg
                icon="menu-intro"
                width="17px"
                height="20px"
                margin="auto auto"
                padding="0"
                pointer
              />
            </sc.Circle>
            <sc.Text>INTRO</sc.Text>
          </sc.Option>

          <Link href="/uonearth/about" passHref legacyBehavior>
            <sc.Option
              data-cy="CardJoining-About"
              onClick={() => {
                toggleMenuApp();
              }}
            >
              <sc.Circle className={mLandscape ? "mLandscape" : ""}>
                <IconSvg
                  icon="menu-about"
                  width="28px"
                  height="28px"
                  margin="auto auto"
                  padding="0"
                  pointer
                />
              </sc.Circle>
              <sc.Text>ABOUT</sc.Text>
            </sc.Option>
          </Link>

          <sc.Option
            onClick={() => {
              toggleMenuApp();
              router.push(`/naturereserves`);
            }}
            data-cy="CardJoining-Protect"
          >
            <sc.Circle className={mLandscape ? "mLandscape" : ""}>
              <IconSvg
                icon="menu-protected"
                width="24px"
                height="24px"
                margin="auto auto"
                padding="0"
                pointer
              />
            </sc.Circle>
            <sc.Text>PROTECT</sc.Text>
          </sc.Option>
        </sc.Row>
        <sc.RowSingle>
          <Link href="/uonearth/q-a_uon.earth" passHref legacyBehavior>
            <sc.Option
              data-cy="CardJoining-Play"
              onClick={() => {
                toggleMenuApp();
              }}
            >
              <sc.CirclePlay>
                <IconSvg
                  icon="et-com-logo"
                  width="60px"
                  height="60px"
                  margin="auto auto"
                  padding="0"
                  pointer
                />
              </sc.CirclePlay>
              <sc.Text>Q&A</sc.Text>
            </sc.Option>
          </Link>
        </sc.RowSingle>
      </sc.Container>
      {isIntroVideoOpen ? (
        <sc.IntroVideoContainer>
          <sc.IntroVideoOverlay
            data-cy="CardJoining-IntroOverlay"
            className={mLandscape ? "mLandscape" : ""}
            onClick={() => setIntroVideoOpen(false)}
          />
          <sc.IntroVideoWrapper
            data-cy="CardJoining-IntroWrapper"
            shouldShowCookiePolicy={shouldShowCookiePolicy}
          >
            {isOffline ? (
              <sc.OfflineMessage>
                You appear to be offline, access to some media might be
                limited&nbsp;
                <div>until you reconnect to the internet</div>
              </sc.OfflineMessage>
            ) : (
              <VimeoPlayer
                video="dd703106ee"
                url="https://vimeo.com/892384175/53ce14c80f?share=copy"
                height="100%"
                width="100%"
                controls={true}
                config={{
                  playerOptions: {
                    autoplay: true,
                    loop: false,
                    quality: "1080p",
                  },
                }}
              />
            )}
          </sc.IntroVideoWrapper>
        </sc.IntroVideoContainer>
      ) : null}
    </>
  );
});
