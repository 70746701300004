import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import { DeckSize } from "../../stores/CardDeckOverviewModel";
import sc from "./CardDeckOverviewFooter.styled";

export type CardDeckOverviewFooterDriver = {
  readonly vanityName: string;
  readonly deckAuthor: string;
  readonly logo: string;
  readonly path: string[];
  readonly renderSize: DeckSize;
};
interface IProps {
  driver: CardDeckOverviewFooterDriver;
}

export const CardDeckOverviewFooter = observer(function CardDeckOverviewFooter(
  props: IProps,
) {
  const { driver } = props;

  const { t } = useTranslation("DeckGeneral");

  return (
    <sc.FooterContainer deckSize={props.driver.renderSize}>
      <Link
        href="/[vanityName]"
        as={`/${driver.vanityName}`}
        passHref
        legacyBehavior
      >
        <sc.LogoContainer deckSize={props.driver.renderSize}>
          <sc.Logo
            deckSize={props.driver.renderSize}
            data-cy="CardDeckOverviewFooter-Logo"
            logo={driver.logo}
          />
        </sc.LogoContainer>
      </Link>
      <sc.FooterText
        deckSize={props.driver.renderSize}
        data-cy="CardDeckOverviewFooter-Text"
      >
        <Link
          href="/[vanityName]/[deckName]"
          as={`/${props.driver.path.join("/")}`}
          passHref
          legacyBehavior
        >
          <sc.OverlayLink data-cy="CardDeckOverviewOverLayFooter-OverlayLink" />
        </Link>
        <sc.DeckBy>{t("deckgeneral.author-lower")} </sc.DeckBy>
        <Link
          href="/[vanityName]"
          as={`/${driver.vanityName}`}
          passHref
          legacyBehavior
        >
          <sc.AuthorName
            data-cy="CardDeckOverviewFooter-AuthorName"
            title={driver.deckAuthor}
          >
            {driver.deckAuthor}
          </sc.AuthorName>
        </Link>
      </sc.FooterText>
    </sc.FooterContainer>
  );
});
