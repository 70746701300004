/* eslint-disable more/no-then */
import {
  DeckBackgroundImageType,
  DeckCuratingResponseDto,
  DeckDetailResponseDto,
  ImageResponseDto,
  MobileSystemDeckResponseDto,
  MobileSystemDeckStatus,
  MobileSystemDeckTextAlignment,
  ReserveDeckResponseDto,
} from "@earthtoday/contract";
import { action, computed, makeObservable, observable } from "mobx";
import getConfig from "next/config";

import { CardDeckOverviewDriver } from "../components/CardDeckOverview/CardDeckOverview";
import { CardDeckOverviewContentDriver } from "../components/CardDeckOverviewContent/CardDeckOverviewContent";
import { CardDeckOverviewFooterDriver } from "../components/CardDeckOverviewFooter/CardDeckOverviewFooter";
import { CardDraftDeckDriver } from "../components/CardItemDraft/CardItemDraftDeck";
import { CardDraftDeckContentDriver } from "../components/CardItemDraft/CardItemDraftDeckContent";
import { DeckSystemDriver } from "../components/DeckSystem/DeckSystem";
import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { SectionTenDeckDriver } from "../components/LayoutProtect/SectionTen";
import { IModalDeleteDeckStore } from "../components/ModalDeleteDeck/ModalDeleteDeckStore";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import {
  ITheMessageStore,
  ToastMessageStatus,
} from "../components/TheMessage/TheMessageStore";
import {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "../components/TheNavbarControllers/TheNavbarControllers.styled";
import { DeckEditData, IDeckDetailApi } from "../shared/apis/DeckDetailApi";
import { etWhite } from "../shared/colors";
import { host } from "../shared/env";
import { buildResizedAwsImageRequest } from "../shared/helpers/buildAwsImageRelated";
import { copyLinkToClipboard } from "../shared/helpers/copyLinkToClipboard";
import { getLogoColor } from "../shared/helpers/getLogoColor";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import trimAndUppercase from "../shared/helpers/trimAndUppercase";
import { wait } from "../shared/helpers/wait";
import { IPreviewImagesMetaData } from "../shared/models/DeckDetail";
import { AutoPlayDeckStore, AutoplayStatus } from "./AutoplayDeckStore";
import {
  BACKGROUND_IMAGE_PREVIEW_HEIGHT,
  BACKGROUND_IMAGE_PREVIEW_WIDTH,
} from "./CardDeckHeaderModel";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IFollowingRequest } from "./FollowingStore";
import { ModalEditDeckPresenterDependencies } from "./ModalEditDeckPresenter/ModalEditDeckPresenter";
import { IModalStore, ModalType } from "./ModalStore";
import { ModalSystemDeckPresenter } from "./ModalSystemDeckPresenter/ModalSystemDeckPresenter";
import {
  CARD_PROFILE_IMAGE_HEIGHT,
  CARD_PROFILE_IMAGE_WIDTH,
  UserModel,
} from "./UserModel";

export const CARD_DECK_MODAL_LOGO_WIDTH: number = 33;
export const CARD_DECK_MODAL_LOGO_HEIGHT: number = 33;
export const CARD_DECK_DISPLAY_IN_DECK_LOGO_WIDTH: number = 32;
export const CARD_DECK_DISPLAY_IN_DECK_LOGO_HEIGHT: number = 32;
export const CARD_DECK_WIDTH_DEFAULT: number = 328;
export const CARD_DECK_HEIGHT_DEFAULT: number = 402;
export const CARD_DECK_MODAL_WIDTH_DEFAULT: number = 275;
export const CARD_DECK_MODAL_HEIGHT_DEFAULT: number = 339;
export const CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT: number = 256;
export const CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT: number = 315;
export const CARD_DRAFT_IMAGE_WIDTH: number = 328;
export const CARD_DRAFT_IMAGE_HEIGHT: number = 330;

export interface DeckCurating {
  cardsCount: number;
  cards: Array<any>;
  curator: {
    id: string | undefined;
    image: ImageResponseDto;
    name: string;
    vanityName: string;
    isPublished?: boolean;
    currentUser?: boolean;
  };
  description: string;
  followersCount: number;
  following: {
    following: boolean;
  };
  id: string;
  name: string;
  path: Array<string>;
  presentation?: {
    labelColor: string;
    labelIcon: string;
    labelTitle: string;
  };
  categoryPrinciples?: string[];
  previewImages: {
    url?: string;
    awsKey: string;
    awsBucket: string;
    isTransparent: boolean | null;
    isProfileImage: boolean;
  }[];
  metaTags?: { [tagName: string]: string };
  isShortcut?: boolean;
}

export type ProfileRepost = Pick<
  UserModel,
  | "id"
  | "administrator"
  | "bio"
  | "cookieConsent"
  | "curator"
  | "darkMode"
  | "details"
  | "emailAddress"
  | "emailAddressVerified"
  | "facebookUsername"
  | "firstName"
  | "fullName"
  | "instagramUsername"
  | "isPublished"
  | "joined"
  | "lastName"
  | "linkedInUsername"
  | "photoState"
  | "provider"
  | "providerId"
  | "termsAccepted"
  | "termsExpired"
  | "twitterUsername"
  | "userCounts"
  | "vanityName"
  | "website"
  | "imageData"
  | "userSubTitle"
  | "tagline"
  | "profileTagOverride"
>;
export interface PageStoreDriver {
  toggleFollowing(data: IFollowingRequest): void;
  updateDeckDeleteId?(id: string): void;
  onDeleteDeck?(id: string): void;
  submitAutoplay?(
    sourceDeckId: string,
    targetDeckId: string,
    numberOfCards: number,
    status: AutoplayStatus,
  ): void;
  getAutoplayItem?(sourceDeckId: string): void;
  cancelAutoplay?(sourceDeckId: string, targetDeckId: string): void;
  saveDeckShortcut?(deckId: string, isShortcut: boolean): void;
  updateShortcutName?(deckId: string, deckName: string): void;
  onOpenLoginModal?: () => void;
}
export enum DeckSize {
  Modal = "modal",
  Default = "default",
  DisplayInDeck = "display_in_deck",
}
// eslint-disable-next-line import/no-default-export
export default class CardDeckOverviewModel
  implements
    CardDeckOverviewContentDriver,
    CardDeckOverviewDriver,
    CardDeckOverviewFooterDriver,
    CardDraftDeckDriver,
    CardDraftDeckContentDriver,
    SectionTenDeckDriver,
    DeckSystemDriver
{
  @observable contextMenuActive: boolean = false;
  @observable shareActive: boolean = false;
  @observable deck: DeckCuratingResponseDto & { isShortcut?: boolean };
  @observable autoplayStatus: AutoplayStatus = AutoplayStatus.NONE;
  @observable autoplayTargetDeckId: string = "";
  @observable profileRepost: ProfileRepost | undefined;

  constructor(
    public modalStore: IModalStore,
    private modalDeleteDeckStore: IModalDeleteDeckStore,
    private autoplayDeckStore: AutoPlayDeckStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private pageStore: PageStoreDriver | null,
    deck: DeckCuratingResponseDto & { isShortcut?: boolean },
    private featureFlaggingData: FeatureFlaggingData,
    private deckDetailApi: IDeckDetailApi,
    public renderSize: DeckSize = DeckSize.Default,
    private goalNumber?: number,
    profileRepost?: ProfileRepost,
  ) {
    makeObservable(this);
    this.deck = deck;
    this.profileRepost = profileRepost;
    this.modalSystemDeckDriver = new ModalSystemDeckPresenter(
      this,
      this.deckDetailApi,
      this.theMessageStore,
      this.modalStore,
    );
  }

  toJSON(): DeckCuratingResponseDto {
    return this.deck;
  }
  @computed get enableUpdateDeckCardsDesign(): boolean {
    return this.featureFlaggingData.flags.enableUpdateDeckCardsDesign;
  }
  @computed get isDeckSystem() {
    return (
      !!this.deck.mobileSystemOverride &&
      this.deck.mobileSystemOverride.status === MobileSystemDeckStatus.ACTIVE
    );
  }
  @computed get textColor() {
    return this.deck.mobileSystemOverride?.textColor || etWhite;
  }
  @computed get textAlignment() {
    return (
      this.deck.mobileSystemOverride?.textAlignment ||
      MobileSystemDeckTextAlignment.LEFT
    );
  }

  @computed get deckId(): string {
    return this.deck.id;
  }

  @action openEditDeck = (): void => {
    this.modalStore.openLazyModal({
      name: "editDeck",
      component: (
        // eslint-disable-next-line react/react-in-jsx-scope
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalEditDeck/ModalEditDeck").then(
              (component) => component.ModalEditDeck,
            )
          }
          driver={this}
        />
      ),
    });
  };

  @action openContextMenu = (): void => {
    this.pageStore?.getAutoplayItem &&
      this.pageStore.getAutoplayItem(this.deck.id);
    this.contextMenuActive = true;
  };

  @action closeContextMenu = (): void => {
    this.contextMenuActive = false;
  };

  @action.bound onIconMoreClicked(): void {
    if (this.contextMenuActive) {
      this.closeContextMenu();
      return;
    }
    this.openContextMenu();
  }

  @computed get currentUser(): boolean {
    return (
      this.userSessionStore.user?.id === this.deck.curator.id ||
      this.userSessionStore.user?.vanityName === this.deck.curator.vanityName
    );
  }

  @computed get deckUrl(): string {
    const url = getConfig().publicRuntimeConfig.REACT_APP_HOST;

    return `${url}/${this.path.join("/")}`;
  }

  @computed get deckMobileUrl(): string {
    const url = getConfig().publicRuntimeConfig.REACT_APP_HOST;

    return `${url}/mobile/${this.path.join("/")}`;
  }

  get menuColor(): string {
    return etWhite;
  }

  @computed get name(): string {
    return this.deck.name;
  }

  @computed get cardsCount(): number {
    return this.deck.cardsCount || 0;
  }

  @computed get followersCount(): number {
    return this.deck.followersCount || 0;
  }

  @computed get following(): boolean {
    return this.deck.following?.following || false;
  }

  @computed get id(): string {
    return this.deck.id;
  }
  @computed get categoryPrinciple(): string {
    if (!this.deck.categoryPrinciples) {
      return "";
    }
    if (this.deck.categoryPrinciples.length === 0) {
      return "";
    }

    return this.deck.categoryPrinciples[0];
  }

  @computed get categoryColor(): string {
    return getLogoColor(this.categoryPrinciple);
  }

  @computed get presentation(): {
    labelTitle: string;
    labelColor: string;
    labelIcon: string;
  } {
    return {
      labelIcon: this.deck.presentation.labelIcon || "",
      labelTitle: this.deck.presentation.labelTitle || "",
      labelColor: this.deck.presentation.labelColor || "",
    };
  }

  @computed get number(): number {
    return this.goalNumber || 0;
  }

  @computed get deckCardPreviewImages(): Array<{
    url: string;
    isProfileImage: boolean;
  }> {
    if (
      this.deck.previewImages === null ||
      this.deck.previewImages === undefined
    )
      return [];

    const previewImages: Array<{ url: string; isProfileImage: boolean }> = [];
    const limit = 6;

    if (
      this.deck.backgroundImage.type ===
      DeckBackgroundImageType.BACKGROUND_IMAGE
    ) {
      const url = buildResizedAwsImageRequest(
        this.deck.backgroundImage.bgImage,
        {
          width:
            this.renderSize === DeckSize.DisplayInDeck
              ? CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT
              : CARD_DECK_WIDTH_DEFAULT,
          height:
            this.renderSize === DeckSize.DisplayInDeck
              ? CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT
              : CARD_DECK_HEIGHT_DEFAULT,
        },
      );

      previewImages.push({
        url,
        isProfileImage: false,
      });

      return previewImages;
    }

    if (this.deck.previewImages.length <= limit) {
      for (const previewImage of this.deck.previewImages) {
        if (!previewImage.awsBucket || !previewImage.awsKey) {
          previewImages.push({
            url: previewImage.url || "",
            isProfileImage: previewImage.isProfileImage,
          });
          continue;
        }
        const url = buildResizedAwsImageRequest(previewImage, {
          width: previewImage.isProfileImage
            ? CARD_PROFILE_IMAGE_WIDTH
            : this.renderSize === DeckSize.Modal
            ? CARD_DECK_MODAL_WIDTH_DEFAULT
            : this.renderSize === DeckSize.DisplayInDeck
            ? CARD_DECK_DISPLAY_IN_DECK_WIDTH_DEFAULT
            : CARD_DECK_WIDTH_DEFAULT,
          height: previewImage.isProfileImage
            ? CARD_PROFILE_IMAGE_HEIGHT
            : this.renderSize === DeckSize.Modal
            ? CARD_DECK_MODAL_HEIGHT_DEFAULT
            : this.renderSize === DeckSize.DisplayInDeck
            ? CARD_DECK_DISPLAY_IN_DECK_HEIGHT_DEFAULT
            : CARD_DECK_HEIGHT_DEFAULT,
        });
        previewImages.push({
          url,
          isProfileImage: previewImage.isProfileImage,
        });
      }
    }
    if (this.deck.previewImages.length > limit) {
      const copyDeckPreviewImages: Array<IPreviewImagesMetaData> = JSON.parse(
        JSON.stringify(this.deck.previewImages),
      );
      while (previewImages.length < 6) {
        const randomIndex = Math.floor(
          Math.random() * copyDeckPreviewImages.length,
        );
        if (
          !this.deck.previewImages[randomIndex].awsBucket ||
          !this.deck.previewImages[randomIndex].awsKey
        ) {
          previewImages.push({
            url: this.deck.previewImages[randomIndex].url || "",
            isProfileImage: this.deck.previewImages[randomIndex].isProfileImage,
          });
          copyDeckPreviewImages.splice(randomIndex, 1);
          continue;
        }

        const url = buildResizedAwsImageRequest(
          this.deck.previewImages[randomIndex],
          {
            width: this.deck.previewImages[randomIndex].isProfileImage
              ? CARD_PROFILE_IMAGE_WIDTH
              : CARD_DECK_WIDTH_DEFAULT,
            height: this.deck.previewImages[randomIndex].isProfileImage
              ? CARD_PROFILE_IMAGE_HEIGHT
              : CARD_DECK_HEIGHT_DEFAULT,
          },
        );
        previewImages.push({
          url,
          isProfileImage: this.deck.previewImages[randomIndex].isProfileImage,
        });
        copyDeckPreviewImages.splice(randomIndex, 1);
      }
    }

    return previewImages;
  }
  @computed get isBackgroundSlider(): boolean {
    return (
      this.deck.backgroundImage.type === DeckBackgroundImageType.PREVIEW_IMAGES
    );
  }

  @computed get deckCardDraftPreviewImages(): Array<{
    url: string;
  }> {
    if (
      this.deck.previewImages === null ||
      this.deck.previewImages === undefined
    )
      return [];

    const previewImages: Array<{ url: string }> = [];
    const limit = 6;

    if (
      this.deck.backgroundImage.type ===
      DeckBackgroundImageType.BACKGROUND_IMAGE
    ) {
      const url = buildResizedAwsImageRequest(
        this.deck.backgroundImage.bgImage,
        {
          width: CARD_DRAFT_IMAGE_WIDTH,
          height: CARD_DRAFT_IMAGE_HEIGHT,
        },
      );

      previewImages.push({
        url,
      });

      return previewImages;
    }

    if (this.deck.previewImages.length <= limit) {
      for (const previewImage of this.deck.previewImages) {
        if (!previewImage.awsBucket || !previewImage.awsKey) {
          previewImages.push({
            url: previewImage.url || "",
          });
          continue;
        }
        const url = buildResizedAwsImageRequest(previewImage, {
          width: CARD_DRAFT_IMAGE_WIDTH,
          height: CARD_DRAFT_IMAGE_HEIGHT,
        });
        previewImages.push({
          url,
        });
      }
    }
    if (this.deck.previewImages.length > limit) {
      const copyDeckPreviewImages: Array<IPreviewImagesMetaData> = JSON.parse(
        JSON.stringify(this.deck.previewImages),
      );
      while (previewImages.length < 6) {
        const randomIndex = Math.floor(
          Math.random() * copyDeckPreviewImages.length,
        );
        if (
          !this.deck.previewImages[randomIndex].awsBucket ||
          !this.deck.previewImages[randomIndex].awsKey
        ) {
          previewImages.push({
            url: this.deck.previewImages[randomIndex].url || "",
          });
          copyDeckPreviewImages.splice(randomIndex, 1);
          continue;
        }

        const url = buildResizedAwsImageRequest(
          this.deck.previewImages[randomIndex],
          {
            width: CARD_DRAFT_IMAGE_WIDTH,
            height: CARD_DRAFT_IMAGE_HEIGHT,
          },
        );
        previewImages.push({
          url,
        });
        copyDeckPreviewImages.splice(randomIndex, 1);
      }
    }

    return previewImages;
  }

  @computed get imageSharing(): string {
    return this.deckCardPreviewImages[0].url;
  }

  @computed get vanityName(): string {
    return this.deck.curator.vanityName || "";
  }

  @computed get deckAuthor(): string {
    return this.deck.curator.name || "";
  }

  @computed get logo(): string {
    const width =
      this.renderSize === DeckSize.Modal
        ? CARD_DECK_MODAL_LOGO_WIDTH
        : this.renderSize === DeckSize.DisplayInDeck
        ? CARD_DECK_DISPLAY_IN_DECK_LOGO_WIDTH
        : PROFILE_AVATAR_WIDTH_DEFAULT;
    const height =
      this.renderSize === DeckSize.Modal
        ? CARD_DECK_MODAL_LOGO_HEIGHT
        : this.renderSize === DeckSize.DisplayInDeck
        ? CARD_DECK_DISPLAY_IN_DECK_LOGO_HEIGHT
        : PROFILE_AVATAR_HEIGHT_DEFAULT;

    return getResizeImageUrl(this.deck.curator.image as ImageResponseDto, {
      width,
      height,
    });
  }

  @computed get description(): string {
    return this.deck.description || "";
  }

  @computed get path(): string[] {
    return this.deck.path || [];
  }

  @computed get followingCounts(): number {
    return this.deck.followersCount || 0;
  }

  @computed get isDeleteOptionVisible(): boolean {
    return (
      this.currentUser ||
      this.userSessionStore.user?.administrator ||
      this.userSessionStore.user?.vanityName === this.deck.curator.vanityName
    );
  }

  @computed get isLoggedInUser(): boolean {
    return !!this.userSessionStore.user;
  }

  @computed get deckStatistic(): {
    cardsCount: number;
    followersCount: number | undefined;
  } {
    return {
      cardsCount: this.deck.cardsCount,
      followersCount:
        this.deck.name.toLowerCase() === "today"
          ? undefined
          : this.deck.followersCount,
    };
  }

  @computed get formatedDeckName(): string {
    return trimAndUppercase(this.deck.name);
  }

  @action copyCardLinkToClipboard = async (
    urlToCopy: string,
  ): Promise<void> => {
    copyLinkToClipboard(urlToCopy);
    await wait(500);
    this.toggleShareActive();
  };

  @action toggleShareActive = (): void => {
    this.shareActive = !this.shareActive;
  };

  @computed get isShareAndFollowBtnVisiable(): boolean {
    if (
      this.renderSize === DeckSize.DisplayInDeck ||
      this.renderSize === DeckSize.Modal
    ) {
      return false;
    }
    return !this.currentUser;
  }

  @action.bound updateDeckDeleteId(s: string): void {
    this.pageStore?.updateDeckDeleteId && this.pageStore.updateDeckDeleteId(s);
  }

  @action.bound updateDeckDelete(): void {
    this.modalDeleteDeckStore.updateDeckDelete(this);
  }

  @action.bound onDeleteDeck(): void {
    this.pageStore?.onDeleteDeck &&
      this.pageStore.onDeleteDeck(this.deck.id || "");
  }

  @action.bound updateSourceDeck(): void {
    this.autoplayDeckStore.updateSourceDeck(this);
  }

  @action.bound submitAutoplay(
    targetDeckId: string,
    numberOfCards: number,
    status: AutoplayStatus,
  ): void {
    this.pageStore?.submitAutoplay &&
      this.pageStore.submitAutoplay(
        this.deck.id,
        targetDeckId,
        numberOfCards,
        status,
      );
  }

  @action.bound cancelAutoplay(targetDeckId: string): void {
    this.pageStore?.cancelAutoplay &&
      this.pageStore.cancelAutoplay(this.deck.id, targetDeckId);
  }

  @action.bound onToggleFollowing(following: boolean): void {
    if (!this.userSessionStore.user) {
      if (
        this.featureFlaggingData.flags.enableLoginWithQRCode &&
        this.pageStore?.onOpenLoginModal
      ) {
        this.pageStore.onOpenLoginModal();
      } else {
        this.openModal("loginForm");
      }
      return;
    }

    if (!this.userSessionStore.user?.emailAddressVerified) {
      this.theMessageStore.showMessage(
        {
          typeMessage: "Action",
          status: ToastMessageStatus.WARNING,
          title: "toast-message.email-not-verified.title",
          content: "toast-message.email-not-verified.content",
          actions: [
            {
              key: "close",
              name: "toast-message.general.action-close",
              action: () => this.theMessageStore.close(),
            },
            {
              key: "resend",
              name: "toast-message.general.action-resend",
              action: () => this.userSessionStore.resendVerificationEmail(),
            },
          ],
        },
        { closeDuration: "never" },
      );
      return;
    }

    this.pageStore?.toggleFollowing({
      following,
      deckId: this.deck.id,
    });
  }

  @action.bound
  openModal(modalType: ModalType): void {
    this.modalStore.openModal(modalType);
  }

  @action.bound
  updateFollowCounts(counts: number, following: boolean): void {
    Object.assign(this.deck, {
      followersCount: counts,
      following: {
        following,
        count: counts,
      },
    });
  }

  @action.bound
  updateCardCounts(): void {
    this.deck.cardsCount += 1;
  }

  @action.bound
  updateDeck(deck: DeckCuratingResponseDto): void {
    Object.assign(this.deck, { ...deck });

    if (this.pageStore?.updateShortcutName) {
      this.pageStore.updateShortcutName(deck.id, deck.name);
    }
  }

  @observable isEdittingDeck: boolean = false;
  @action.bound updateIsEdittingDeck(b: boolean): void {
    this.isEdittingDeck = b;
  }

  @action.bound onEditDeck(deckData: DeckEditData): void {}

  @action.bound onSaveDeck(): void {
    if (!this.isLoggedInUser) {
      if (
        this.featureFlaggingData.flags.enableLoginWithQRCode &&
        this.pageStore &&
        this.pageStore.onOpenLoginModal
      ) {
        this.pageStore.onOpenLoginModal();
      } else {
        this.modalStore.openModal("loginForm");
      }
      return;
    }

    this.modalStore.openLazyModal({
      name: "cardCreate",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalCreateCard/ModalCreateCard")
          }
          driver={{
            deckUrl: `${host}/${this.deck.path.join("/")}`,
          }}
        />
      ),
    });
  }

  @action.bound updateAutoplayStatus(s: AutoplayStatus): void {
    this.autoplayStatus = s;
  }

  @action.bound autoplayAction(): void {
    if (
      [AutoplayStatus.STARTED, AutoplayStatus.STOPPED].includes(
        this.autoplayStatus,
      )
    ) {
      this.pageStore?.cancelAutoplay &&
        this.pageStore.cancelAutoplay(this.deck.id, this.autoplayTargetDeckId);
    } else {
      this.modalStore.openModal("autoPlayDeck");
    }
  }

  @action.bound updateAutoplayTargetDeckId(s: string): void {
    this.autoplayTargetDeckId = s;
  }

  @computed get isDeckShortcut(): boolean {
    return !!this.deck.isShortcut;
  }

  @action.bound toggleDeckShortcut(): void {
    this.pageStore?.saveDeckShortcut &&
      this.pageStore.saveDeckShortcut(this.deck.id, !this.isDeckShortcut);
  }

  @action.bound updateShortcut(b: boolean): void {
    this.deck.isShortcut = b;
  }

  @computed get backgroundImageUrl(): string | null {
    if (
      this.deck.backgroundImage.type === DeckBackgroundImageType.PREVIEW_IMAGES
    ) {
      return null;
    }

    const bgImageData = this.deck.backgroundImage.bgImage;
    return getResizeImageUrl(
      { ...bgImageData, url: bgImageData.url || "" },
      {
        width: BACKGROUND_IMAGE_PREVIEW_WIDTH,
        height: BACKGROUND_IMAGE_PREVIEW_HEIGHT,
      },
    );
  }

  @computed.struct
  private get localModalEditDeckDependencies(): ModalEditDeckPresenterDependencies {
    return {
      onDeckUpdated: this.updateDeck,
    };
  }

  @computed
  get modalEditDeckDependencies(): ModalEditDeckPresenterDependencies {
    return this.localModalEditDeckDependencies;
  }

  @computed get shouldRenderSystemDeckButton(): boolean {
    if (!this.featureFlaggingData.flags.enableSetSystemDeck) {
      return false;
    }

    return !!this.userSessionStore.user?.administrator;
  }

  modalSystemDeckDriver: ModalSystemDeckPresenter;
  @action.bound onSystemDeckButtonClicked(): void {
    this.modalStore.openLazyModal({
      name: "systemDeck",
      component: (
        // eslint-disable-next-line react/react-in-jsx-scope
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalSystemDeck/ModalSystemDeck").then(
              (component) => component.ModalSystemDeck,
            )
          }
          driver={this.modalSystemDeckDriver}
        />
      ),
    });
  }

  @observable systemDeck: MobileSystemDeckResponseDto | null = null;
  @action.bound updateSystemDeck(
    systemDeck: MobileSystemDeckResponseDto,
  ): void {
    this.systemDeck = systemDeck;
    this.deck.mobileSystemOverride = systemDeck.data;
  }

  @computed get reserve(): ReserveDeckResponseDto | null {
    return this.deck.reserves && this.deck.reserves.length > 0
      ? this.deck.reserves[0]
      : null;
  }
}
