import { observer } from "mobx-react-lite";
import Link from "next/link";
import { RefObject, useEffect, useRef } from "react";

import StaticCard01 from "../../../assets/img/protect/card01.png";
import StaticCard02 from "../../../assets/img/protect/card02.png";
import StaticCard03 from "../../../assets/img/protect/card03.png";
import StaticCard04 from "../../../assets/img/protect/card04.png";
import NatureNeedsHalf from "../../../assets/img/protect/nature-needs-half.png";
import NatureNeedsHalfLine from "../../../assets/img/protect/nature-needs-half-line.png";
import { useTranslation } from "../../../shared/translate/NextI18next";
import { useIsInViewport } from "../../../shared/useIsInViewport";
import scLayoutProtect from "../../LayoutProtect/LayoutProtect.styled";
import { LazyImage } from "../../LazyImage/LazyImage";
import sc from "./SectionSevenV202208.styled";
export interface SectionSevenDriver {
  readonly expended: boolean;
  readonly logoRotateDeg: number;
  setLogoRotateDeg(n: number): void;
  setExpendState(state: boolean): void;
  expandContract(contractRef: RefObject<HTMLDivElement>): void;
  handleScroll(sectionRef: RefObject<HTMLDivElement>): void;
}
export interface ISectionSevenV2002208Props {
  driver: SectionSevenDriver & {
    expendLearnMore?: boolean; // use by storybook
  };
  disableAnimation?: boolean;
}

export const SectionSevenV202208 = observer(
  (props: ISectionSevenV2002208Props) => {
    const { t } = useTranslation("Protect");
    const { t: tGeneral } = useTranslation("General");

    const sectionRef = useRef<HTMLDivElement>(null);
    const contractRef = useRef<HTMLDivElement>(null);

    const { driver } = props;

    const isInViewport = useIsInViewport(sectionRef);

    function handleScroll() {
      driver.handleScroll(sectionRef);
    }

    useEffect(() => {
      if (props.disableAnimation) return;

      if (!isInViewport) {
        window.removeEventListener("scroll", handleScroll);
        return;
      }
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [isInViewport]);

    return (
      <scLayoutProtect.Section
        id="financial-statement"
        data-cy="SectionSevenV202208"
        ref={sectionRef}
      >
        <sc.Wrapper expendLearnMore={driver.expendLearnMore}>
          <sc.Container className="visual-reg">
            <sc.NatureNeedsHalf>
              <sc.Row>
                <sc.Left>
                  <sc.Title data-cy="SectionSevenV202208-Title">
                    {t("protect.section-seven-v202208.title")}
                  </sc.Title>
                  <sc.SubTitle data-cy="SectionSevenV202208-SubTitle">
                    {t("protect.section-seven-v202208.sub-title")}
                  </sc.SubTitle>
                  <sc.Description data-cy="SectionSevenV202208-Description">
                    {t("protect.section-seven-v202208.desc")}
                  </sc.Description>
                  <sc.ButtonWrapper>
                    {driver.expended || driver.expendLearnMore ? (
                      <sc.CloseBtn
                        data-cy="SectionSevenV202208-CloseBtn"
                        onClick={() => driver.expandContract(contractRef)}
                      >
                        {tGeneral("general.close")}
                      </sc.CloseBtn>
                    ) : (
                      <sc.LearnMoreBtn
                        data-cy="SectionSevenV202208-LearnMoreBtn"
                        onClick={() => driver.expandContract(contractRef)}
                      >
                        {tGeneral("general.learn-more")}
                      </sc.LearnMoreBtn>
                    )}
                  </sc.ButtonWrapper>
                </sc.Left>
                <sc.Right>
                  <sc.ImageWrapper>
                    <sc.Image
                      data-cy="SectionSevenV202208-NatureNeedsHalf"
                      bg={NatureNeedsHalf}
                    >
                      <LazyImage
                        src={NatureNeedsHalf}
                        width="100%"
                        maxWidth="100%"
                        height="100%"
                        maxHeight="100%"
                        alt="nature needs half"
                      />
                    </sc.Image>
                    <sc.LineWrapper>
                      {driver.logoRotateDeg > 0 && (
                        <sc.Line
                          data-cy="SectionSevenV202208-Line"
                          bg={NatureNeedsHalfLine}
                          rotateDeg={driver.logoRotateDeg}
                        />
                      )}
                    </sc.LineWrapper>
                  </sc.ImageWrapper>
                </sc.Right>
              </sc.Row>
            </sc.NatureNeedsHalf>

            <sc.ExpandContainer>
              <sc.ExpandContract
                ref={contractRef}
                expand={driver.expendLearnMore}
                disableAnimation={props.disableAnimation || false}
              >
                <sc.CardRow>
                  <Link
                    href="https://earthtoday.com/natureneedshalf/how-much-does-nature-need/594594526216157185"
                    passHref
                    legacyBehavior
                  >
                    <sc.StaticCardImg
                      bg={StaticCard01}
                      data-cy="SectionSevenV202208-Card01"
                    />
                  </Link>
                  <Link
                    href="https://earthtoday.com/michielcurfs/europeanunion/5098875857771593728"
                    passHref
                    legacyBehavior
                  >
                    <sc.StaticCardImg
                      bg={StaticCard02}
                      data-cy="SectionSevenV202208-Card02"
                    />
                  </Link>
                  <Link
                    href="https://earthtoday.com/lenameisinger/natureineverything/5098530763772166144"
                    passHref
                    legacyBehavior
                  >
                    <sc.StaticCardImg
                      bg={StaticCard03}
                      data-cy="SectionSevenV202208-Card03"
                    />
                  </Link>
                  <Link
                    href="https://earthtoday.com/jessicaouter/biodiversitycrisis/5100118487249145856"
                    passHref
                    legacyBehavior
                  >
                    <sc.StaticCardImg
                      bg={StaticCard04}
                      data-cy="SectionSevenV202208-Card04"
                    />
                  </Link>
                </sc.CardRow>
              </sc.ExpandContract>
            </sc.ExpandContainer>
          </sc.Container>
        </sc.Wrapper>
      </scLayoutProtect.Section>
    );
  },
);
