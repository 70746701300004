import { TFunction } from "next-i18next";

import { utmSourceValue } from "../../shared/env";

export enum NavSecondaryTabItem {
  TODAY = "Today",
  ABOUT_EARTH_TODAY = "About EarthToday",
  FINANCIAL_STATEMENT = "Financial statement",
  NATURE_RESERVES = "Nature reserves",
  BRANDS = "Brands",
}

interface NavSecondaryTabInfo {
  width: string;
  height: string;
  label: string;
  href: string;
}
export const getNavSecondaryTab = (
  tab: NavSecondaryTabItem,
  t: TFunction,
): NavSecondaryTabInfo => {
  switch (tab) {
    case NavSecondaryTabItem.TODAY: {
      return {
        width: "58px",
        height: "32px",
        label: t("systempagetabs.today"),
        href: "/",
      };
    }
    case NavSecondaryTabItem.ABOUT_EARTH_TODAY: {
      return {
        width: "58px",
        height: "32px",
        label: t("systempagetabs.about"),
        href: `https://about.earthtoday.com/?${utmSourceValue}`,
      };
    }
    case NavSecondaryTabItem.FINANCIAL_STATEMENT: {
      return {
        width: "128px",
        height: "32px",
        label: t("systempagetabs.financial-statement"),
        href: `https://financials.earthtoday.com/?${utmSourceValue}`,
      };
    }
    case NavSecondaryTabItem.NATURE_RESERVES: {
      return {
        width: "70px",
        height: "32px",
        label: t("systempagetabs.protect"),
        href: "/naturereserves",
      };
    }
    case NavSecondaryTabItem.BRANDS: {
      return {
        width: "63px",
        height: "32px",
        label: t("systempagetabs.brands"),
        href: "https://brands.earthtoday.com",
      };
    }
    default: {
      return {
        width: "106px",
        height: "32px",
        label: t("systempagetabs.top-protectors"),
        href: "/topprotectors",
      };
    }
  }
};
