import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { SUPPORTED_LOCALES_MAP } from "../../../server/LocaleDetector";
import { LocaleStore } from "../../stores/LocaleStore";
import { sc } from "./LocaleSwitcher.styled";

export interface ILocaleSwitcher {
  forceShowList?: boolean;
  driver: Pick<LocaleStore, "currentLocale" | "updateLocale">;
  supportedLocales: string[];
}

export const LocaleSwitcher = observer((props: ILocaleSwitcher) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <sc.Wrapper data-cy="LocaleSwitcher-Wrapper" className="visual-reg">
      <sc.Container>
        <sc.Item
          data-cy="LocaleSwitcher-CurrentLocale"
          className="flex-center"
          htmlFor="toggleLocaleSwitcher"
        >
          {props.driver.currentLocale.toUpperCase()}
        </sc.Item>
        <input
          ref={ref}
          type="checkbox"
          id="toggleLocaleSwitcher"
          hidden
          defaultChecked={!!props.forceShowList}
        />
        <sc.Overlay
          onClick={(e) => {
            e.stopPropagation();

            if (ref.current) {
              ref.current.checked = false;
            }
          }}
        />
        <sc.Options display={props.forceShowList ? "flex" : "none"}>
          <sc.SwitcherOverlay />
          {props.supportedLocales.map((locale) => (
            <sc.Item
              data-cy="LocaleSwitcher-Item"
              key={locale}
              className="flex-center"
              htmlFor="toggleLocaleSwitcher"
              onClick={() =>
                props.driver.updateLocale(SUPPORTED_LOCALES_MAP[locale])
              }
              order={locale === props.driver.currentLocale ? 0 : 1}
            >
              {locale.toUpperCase()}
            </sc.Item>
          ))}
        </sc.Options>
      </sc.Container>
    </sc.Wrapper>
  );
});
