export function getImageUon(uonCount: number, isWebpSupported: boolean) {
  if (uonCount <= 4) {
    return isWebpSupported
      ? require("../../assets/img/imgUon/1uon.webp")
      : require("../../assets/img/imgUon/1uon.png");
  }
  if (uonCount > 4 && uonCount <= 9) {
    return isWebpSupported
      ? require("../../assets/img/imgUon/5uon.webp")
      : require("../../assets/img/imgUon/5uon.png");
  }
  if (uonCount > 9 && uonCount <= 49) {
    return isWebpSupported
      ? require("../../assets/img/imgUon/10uon.webp")
      : require("../../assets/img/imgUon/10uon.png");
  }
  if (uonCount > 49 && uonCount <= 99) {
    return isWebpSupported
      ? require("../../assets/img/imgUon/50uon.webp")
      : require("../../assets/img/imgUon/50uon.png");
  }
  if (uonCount > 99 && uonCount <= 499) {
    return isWebpSupported
      ? require("../../assets/img/imgUon/100uon.webp")
      : require("../../assets/img/imgUon/100uon.png");
  }
  if (uonCount > 499 && uonCount <= 999) {
    return isWebpSupported
      ? require("../../assets/img/imgUon/500uon.webp")
      : require("../../assets/img/imgUon/500uon.png");
  }

  return isWebpSupported
    ? require("../../assets/img/imgUon/1000uon.webp")
    : require("../../assets/img/imgUon/1000uon.png");
}

export function getNewImageUon(uonCount: number) {
  if (uonCount <= 4) {
    return require("../../assets/img/imgUon/new1uon.png");
  }
  if (uonCount <= 9) {
    return require("../../assets/img/imgUon/new5uons.png");
  }
  if (uonCount <= 19) {
    return require("../../assets/img/imgUon/new10uons.png");
  }
  if (uonCount <= 49) {
    return require("../../assets/img/imgUon/new20uons.png");
  }
  if (uonCount <= 99) {
    return require("../../assets/img/imgUon/new50uons.jpg");
  }
  if (uonCount <= 499) {
    return require("../../assets/img/imgUon/new100uons.png");
  }
  if (uonCount <= 999) {
    return require("../../assets/img/imgUon/new500uons.png");
  }
  return require("../../assets/img/imgUon/new1000uons.png");
}
