import { observer } from "mobx-react-lite";

import { useTranslation } from "../../../shared/translate/NextI18next";
import { sc } from "./MoreAboutCard.styled";

export enum MoreAboutCardTitle {
  ABOUT = "About",
  NATURE_RESERVES = "Nature Reserves",
  BRANDS = "Brands",
  CURATION = "Curation",
}

export interface MoreAboutCardDriver {
  title: MoreAboutCardTitle;
  subTitle: string;
  description: string;
  learnMoreUrl: string;
  color: string;
  logo: string;
  bgColor: string;
}

interface IProps {
  driver: MoreAboutCardDriver;
}

export const MoreAboutCard = observer((props: IProps) => {
  const { t: tGeneral } = useTranslation("General");
  const { driver } = props;

  return (
    <sc.Container
      data-cy="MoreAboutCard"
      className="visual-reg"
      bgColor={driver.bgColor}
      color={driver.color}
    >
      <div>
        <img
          data-cy="MoreAboutCard-Logo"
          src={driver.logo}
          alt="Square Meter"
          width={54}
        />
        <sc.CardTitle data-cy="MoreAboutCard-CardTitle">
          {driver.title}
        </sc.CardTitle>
      </div>
      <sc.BodyContent>
        <sc.SubTitle data-cy="MoreAboutCard-SubTitle" color={driver.color}>
          {driver.subTitle}
        </sc.SubTitle>

        <sc.Description data-cy="MoreAboutCard-Description">
          {driver.description}
        </sc.Description>
        <sc.LearnMoreBtn
          data-cy="MoreAboutCard-LearnMoreBtn"
          href={driver.learnMoreUrl}
          target="_blank"
        >
          {tGeneral("general.learn-more")}
        </sc.LearnMoreBtn>
      </sc.BodyContent>
    </sc.Container>
  );
});
