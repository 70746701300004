import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { etBrightLightBlue, etGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardSimpleContext.styled";

const ProfileMenuContext = observer(() => {
  const { t } = useTranslation("ProfileCard");

  return (
    <Link href="/settings" as="/settings" passHref legacyBehavior>
      <sc.ContextMenuRow
        data-cy="CardProfile-SettingMenu"
        hoverColor={etBrightLightBlue}
      >
        <IconSvg
          width="18px"
          height="18px"
          padding="0"
          icon="edit-card"
          color={etGray}
          pointer
        />
        <sc.ContextMenuItemBlue>
          {t("profilecard.button.edit")}
        </sc.ContextMenuItemBlue>
      </sc.ContextMenuRow>
    </Link>
  );
});
export default ProfileMenuContext;
