/* eslint-disable unicorn/filename-case */
/* eslint-disable unicorn/prefer-logical-operator-over-ternary */
import styled from "styled-components";

import {
  etBlack,
  etBlue,
  etBlueFaded,
  etBrightLightBlue,
  etGray,
  etGrayBold,
  etGrayLight,
  etIceBlue,
  etRed,
  etRegentGray,
  etShadowDarkBlueFaded,
  etSolitudeLight,
  etVoteRed,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { CardType } from "../../shared/models/Card";
import zIndex from "../../shared/zIndex";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Container = styled.div<{
    contextMenuActive: boolean;
    contentType: CardType;
  }>`
    position: relative;
    height: 20px;
    z-index: ${(props) =>
      props.contextMenuActive ? zIndex.CARDSIMPLE_CONTEXT_CONTAINER : 11};
    margin-left: auto;
    width: 100%;
    height: 100%;
    user-select: none;

    ${(props) =>
      props.contentType === CardType.DECK_REPOST &&
      `
      position: absolute;
      right: 12px;
      width: 32px;
      height: 44px;
    `}
  `;

  export const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndex.CARDSIMPLE_CONTEXT_OVERLAY};
    cursor: default;
  `;

  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: ${zIndex.CARDSIMPLE_CONTEXT_BLACKOVERLAY};
  `;

  export const ContextMenuContainer = styled.div<{ iconColor: string }>`
    position: relative;
    line-height: 1rem;
    font-size: 1.25rem;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `;
  export const ContextMenuContent = styled.div`
    position: relative;
  `;
  export const ContextMenuContainerDouble = styled.div<{ iconColor: string }>`
    position: relative;
    line-height: 1.25rem;
    font-size: 1.25rem;
    padding: 0 0 0 0.3rem;
    cursor: pointer;
  `;
  export const ContextMenu = styled.div<{ display: string }>`
    display: ${(props) => props.display};
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 24px;
    right: 0;
    width: 214px;
    margin-top: -6px;
    padding: 12px;
    z-index: ${zIndex.CONTEXT};
    background-color: ${etWhite};
    border-radius: 6px;
    box-shadow: 0 16px 24px 0 ${etShadowDarkBlueFaded};
  `;

  export const ContextMenuText = styled.span`
    margin-top: 1px;
    color: ${etGray};
    font-family: Roboto;
    font-stretch: condensed;
    font-size: 10px;
    font-weight: normal;
    height: 11px;
    letter-spacing: 0;
    text-transform: uppercase;
    text-align: center;
    width: 40px;
    cursor: pointer;
  `;

  export const ContextMenuItemBlue = styled.span`
    color: ${etRegentGray};
    font-family: Roboto;
    font-stretch: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0;
    margin-left: 12px;
  `;
  export const ContextMenuItemBluePublish = styled(ContextMenuItemBlue)`
    margin-left: 15px;
  `;
  export const ContextMenuItemRed = styled.span<{ location?: string }>`
    color: ${etVoteRed};
    font-family: Roboto;
    font-stretch: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0;
    margin-left: 12px;
  `;

  export const IconWrapper = styled.div`
    width: 15px;
    max-width: 15px;
    display: flex;
    justify-content: center;
  `;

  export const ContextMenuRow = styled.button<{
    hoverColor?: string;
  }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 190px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid ${etSolitudeLight};
    white-space: nowrap;
    color: ${etBlack};
    background-color: ${etWhite};
    cursor: pointer;
    > a {
      color: ${etBlack};
      text-decoration: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;

      &:hover,
      &.hover {
        background-color: ${etWhite};
        border-color: ${etSolitudeLight};
        ${IconSvgContainer} {
          fill: ${etRegentGray};
        }
      }

      &:hover
        > ${ContextMenuItemBlue},
        &.hover
        > ${ContextMenuItemBlue},
        :hover
        > a {
        color: ${etRegentGray};
      }

      &:hover > svg,
      &.hover > svg {
        fill: ${etRegentGray};
      }
    }

    :hover,
    &.hover {
      background-color: ${transparentize(etBrightLightBlue, 0.1)};
      border-color: ${transparentize(etBrightLightBlue, 0.1)};
    }

    :hover
      > ${ContextMenuItemBlue},
      &.hover
      > ${ContextMenuItemBlue},
      :hover
      > a {
      color: ${etBrightLightBlue};
    }

    :hover > ${ContextMenuItemRed}, &.hover > ${ContextMenuItemRed} {
      color: ${etRed};
    }

    :hover > ${IconSvgContainer}, &.hover > ${IconSvgContainer} {
      fill: ${(props) =>
        props.hoverColor ? props.hoverColor : etBrightLightBlue};
    }

    :hover > svg,
    &.hover > svg {
      fill: ${(props) =>
        props.hoverColor ? props.hoverColor : etBrightLightBlue};
    }

    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
  `;

  export const ContextMenuRowEmpty = styled.div`
    height: 0;
    width: 190px;
  `;

  export const ContextMenuRowDelete = styled(ContextMenuRow)`
    border-color: ${etRed};

    &:hover,
    &.hover {
      background-color: ${etVoteRed};
      ${ContextMenuItemRed} {
        color: ${etWhite};
      }
      ${IconSvgContainer} {
        fill: ${etWhite};
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
      border: 1px solid ${etRed};
      background-color: ${etWhite};
      ${ContextMenuItemRed} {
        color: ${etRed};
      }
      ${IconSvgContainer} {
        fill: ${etRed};
      }

      &:hover {
        border: 1px solid ${etRed};
      }
    }
  `;

  export const ResizeLayout = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 8px;
    margin-bottom: 12px;
  `;

  export const ResizeText = styled.div`
    color: ${etGray};
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding-left: 0;
    margin-bottom: 6px;
  `;

  export const ResizeIcon = styled.div<{ hoverColor?: string }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    :hover > ${ContextMenuItemBlue}, :hover > a {
      color: ${etBrightLightBlue};
    }

    :hover > ${ContextMenuItemRed} {
      color: ${etRed};
    }

    :hover > svg {
      fill: ${(props) =>
        props.hoverColor ? props.hoverColor : etBrightLightBlue};
    }
  `;

  export const ResizeBorder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    padding: 0;
    border: 1px solid ${etIceBlue};
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;

    &.active {
      background-color: ${etBlueFaded};
      border-color: ${etBrightLightBlue};
      box-shadow: 0 0.25rem 1rem 0 ${etShadowDarkBlueFaded};

      svg {
        fill: ${etBrightLightBlue};
      }
    }
  `;

  export const ResizeOption = styled.button`
    display: flex;
    flex-direction: column;
    border: none;
    background: transparent;
    padding: 0;

    :disabled {
      box-shadow: none;
      opacity: 0.5;
      cursor: default;
      :hover {
        ${ResizeBorder} {
          border-color: ${etIceBlue};
          cursor: default;
        }
        ${ContextMenuText} {
          cursor: default;
        }
        svg {
          fill: ${etRegentGray};
        }
      }
    }

    :hover {
      ${ResizeBorder} {
        border-color: ${etBrightLightBlue};
      }
      svg {
        fill: ${etBrightLightBlue};
      }
    }
  `;

  export const ResizeWrapper = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `;

  export const ContextMenuRowResize = styled(ContextMenuRow)`
    padding: 0;
  `;

  export const ContextMenuTextResize = styled.span`
    font-size: 10px;
    text-transform: uppercase;
    font-family: Roboto Condensed;
    color: ${etGray};
  `;
  export const ContextMenuItemGrayLight = styled(ContextMenuItemBlue)`
    margin-left: 15px;
    color: ${etGrayLight};
    display: flex;
    align-items: center;
  `;
  export const ContextMenuRowPublish = styled(ContextMenuRow)<{
    isValid: boolean;
  }>`
    margin-top: 12px;
    border: 1px solid ${etSolitudeLight};

    :hover {
      background-color: ${(props) =>
        props.isValid ? transparentize(etBrightLightBlue, 0.1) : etWhite};
      border-color: ${(props) =>
        props.isValid
          ? transparentize(etBrightLightBlue, 0.1)
          : etSolitudeLight};
    }
    :hover > ${ContextMenuItemGrayLight} {
      color: ${etGrayLight};
    }
    :hover > ${IconSvgContainer} {
      fill: ${(props) => (props.isValid ? etBlue : etGrayLight)};
    }
  `;
  export const Spacing = styled.div`
    height: 9px;
    max-height: 9px;
  `;

  export const ContextMenuSort = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-family: Roboto;
  `;
  export const ContextMenuSortLabel = styled.div<{ isInvalid: boolean }>`
    width: 100%;
    height: 100%;
    border: 1px solid ${(props) => (props.isInvalid ? etRed : etSolitudeLight)};
    border-radius: 4px;
    color: ${etGrayBold};
    padding-left: 10px;
    display: flex;
    align-items: center;
  `;
  export const ContextMenuSortInput = styled.input`
    position: absolute;
    width: 50px;
    height: 40px;
    top: 0;
    right: 0;
    background-color: ${transparentize(etBlack, 0.1)};
    border-radius: 0 4px 4px 0;
    color: ${etGray};
    border: none;
    text-align: center;
  `;
}

export default sc;
