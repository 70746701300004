import { TFunction } from "next-i18next";

import { abbreviateNumber } from "./abbreviateNumber";

export const pluralize = (
  noun: "Card" | "Following" | "Follower" | "Deck" | "Follower",
  counts: number,
  t: TFunction | ((input: string) => string),
  options?: { isAbbreviated?: boolean },
): string => {
  if (counts < 0) {
    throw new Error("Expect counts greater or equal 0");
  }

  if (counts === 1) {
    return `${options?.isAbbreviated ? abbreviateNumber(counts) : counts} ${t(
      "deckgeneral.statistic." + noun.toLocaleLowerCase() + ".one",
    )}`;
  }

  return `${options?.isAbbreviated ? abbreviateNumber(counts) : counts} ${t(
    "deckgeneral.statistic." + noun.toLocaleLowerCase() + ".many",
  )}`;
};
