import { observer } from "mobx-react-lite";

import {
  etBrightLightBlue,
  etGray,
  etGrayDark,
  etVoteRed,
  etWhite,
} from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { AutoplayStatus } from "../../stores/AutoplayDeckStore";
import { LazyModalType, ModalType } from "../../stores/ModalStore";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./DeckSimpleContext.styled";

export interface FeatureFlaggingDriver {
  enableAutoplayDeck: boolean;
  enableDeckShortcuts: boolean;
}

export interface DeckSimpleContextDriver {
  readonly currentUser: boolean;
  readonly menuColor?: string;
  readonly contextMenuActive: boolean;
  readonly isDeleteOptionVisible: boolean;
  readonly isLoggedInUser: boolean;
  readonly autoplayStatus: AutoplayStatus;
  readonly isDeckShortcut: boolean;

  updateDeckDelete?(): void;
  openModal(modalTypes: ModalType | LazyModalType): void;
  openContextMenu(): void;
  closeContextMenu(): void;
  openEditDeck(): void;
  onSaveDeck(): void;
  toggleShareActive(): void;
  updateSourceDeck(): void;
  autoplayAction(): void;
  toggleDeckShortcut(): void;
  readonly shouldRenderSystemDeckButton: boolean;
  onSystemDeckButtonClicked(): void;
}

interface IProps {
  driver: DeckSimpleContextDriver;
  featureFlaggingDriver: FeatureFlaggingDriver;
}
export const DeckSimpleContext = observer(function DeckSimpleContext(
  props: IProps,
) {
  const { t } = useTranslation("DeckGeneral");

  const { driver, featureFlaggingDriver } = props;

  const renderContextMenuItem = (currentUser: boolean) => {
    if (currentUser) {
      return (
        driver.contextMenuActive && (
          <sc.ContextMenu data-cy="DeckSimpleContext-ContextMenu">
            <sc.ContextMenuRowEdit
              hoverColor={etBrightLightBlue}
              onClick={() => {
                driver.closeContextMenu();
                driver.updateDeckDelete && driver.updateDeckDelete();
                driver.openEditDeck();
              }}
            >
              <sc.IconWrapper>
                <IconSvg
                  width="18px"
                  height="18px"
                  padding="0"
                  icon="edit-card"
                  color={etGray}
                  pointer
                />
              </sc.IconWrapper>
              <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-EditDeck">
                {t("deckgeneral.button.edit")}
              </sc.ContextMenuItemBlue>
            </sc.ContextMenuRowEdit>
            {featureFlaggingDriver.enableAutoplayDeck ? (
              <sc.ContextMenuRow
                hoverColor={etBrightLightBlue}
                onClick={() => {
                  driver.closeContextMenu();
                  driver.updateSourceDeck();
                  driver.autoplayAction();
                }}
              >
                <sc.IconWrapper>
                  <IconSvg
                    icon="auto-follow"
                    color={etGray}
                    hoverColor={etBrightLightBlue}
                    pointer
                    width="18px"
                    height="18px"
                    padding="0"
                  />
                </sc.IconWrapper>
                <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-AutoFollow">
                  {[AutoplayStatus.STARTED, AutoplayStatus.STOPPED].includes(
                    driver.autoplayStatus,
                  )
                    ? t("deckgeneral.button.cancel-autoplay")
                    : t("deckgeneral.button.autoplay")}
                </sc.ContextMenuItemBlue>
              </sc.ContextMenuRow>
            ) : null}
            {featureFlaggingDriver.enableDeckShortcuts && (
              <sc.ContextMenuRow
                hoverColor={etBrightLightBlue}
                onClick={driver.toggleDeckShortcut}
              >
                <sc.IconWrapper>
                  <IconSvg
                    icon="shortcut"
                    color={etGray}
                    hoverColor={etBrightLightBlue}
                    pointer
                    width="18px"
                    height="18px"
                    padding="0"
                  />
                </sc.IconWrapper>
                <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-DeckShortcut">
                  {driver.isDeckShortcut ? "Unpin Deck" : "Pin Deck"}
                </sc.ContextMenuItemBlue>
              </sc.ContextMenuRow>
            )}

            <sc.ContextMenuRow
              hoverColor={etBrightLightBlue}
              onClick={() => {
                driver.closeContextMenu();
                driver.onSaveDeck();
              }}
            >
              <sc.IconWrapper>
                <IconSvg
                  icon="save"
                  color={etGray}
                  pointer
                  width="13px"
                  height="15px"
                  padding="0"
                />
              </sc.IconWrapper>
              <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-SaveDeck">
                {t("deckgeneral.button.save")}
              </sc.ContextMenuItemBlue>
            </sc.ContextMenuRow>

            {driver.shouldRenderSystemDeckButton && (
              <sc.ContextMenuRow
                hoverColor={etBrightLightBlue}
                onClick={() => {
                  driver.closeContextMenu();
                  driver.onSystemDeckButtonClicked();
                }}
              >
                <sc.IconWrapper>
                  <IconSvg
                    icon="forward"
                    color={etGray}
                    pointer
                    width="13px"
                    height="15px"
                    padding="0"
                  />
                </sc.IconWrapper>
                <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-SystemDeck">
                  {t("deckgeneral.button.system-deck")}
                </sc.ContextMenuItemBlue>
              </sc.ContextMenuRow>
            )}

            <sc.ContextMenuRowDelete
              hoverColor={etVoteRed}
              onClick={() => {
                driver.closeContextMenu();
                driver.updateDeckDelete && driver.updateDeckDelete();
                driver.openModal("deckDelete");
              }}
            >
              <sc.IconWrapper>
                <IconSvg
                  icon="delete-card"
                  color={etVoteRed}
                  pointer
                  hoverColor={etVoteRed}
                  width="16px"
                  height="18px"
                  padding="0"
                />
              </sc.IconWrapper>
              <sc.ContextMenuItemRedInInit data-cy="DeckSimpleContext-DeleteDeck">
                {t("deckgeneral.button.delete")}
              </sc.ContextMenuItemRedInInit>
            </sc.ContextMenuRowDelete>
          </sc.ContextMenu>
        )
      );
    }
    return (
      driver.contextMenuActive && (
        <sc.ContextMenu>
          <sc.ContextMenuRow
            hoverColor={etBrightLightBlue}
            onClick={() => {
              driver.closeContextMenu();
              driver.onSaveDeck();
            }}
          >
            <sc.IconWrapper>
              <IconSvg
                icon="save"
                color={etGray}
                pointer
                width="13px"
                height="15px"
                padding="0"
              />
            </sc.IconWrapper>
            <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-SaveDeck">
              {t("deckgeneral.button.save")}
            </sc.ContextMenuItemBlue>
          </sc.ContextMenuRow>

          <sc.ContextMenuRow
            hoverColor={etBrightLightBlue}
            onClick={() => {
              driver.closeContextMenu();
              driver.toggleShareActive();
            }}
          >
            <sc.IconWrapper>
              <IconSvg
                icon="share"
                color={etGray}
                pointer
                width="13px"
                height="15px"
                padding="0"
              />
            </sc.IconWrapper>
            <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-ShareDeck">
              {t("deckgeneral.button.share")}
            </sc.ContextMenuItemBlue>
          </sc.ContextMenuRow>

          {featureFlaggingDriver.enableAutoplayDeck && driver.isLoggedInUser ? (
            <sc.ContextMenuRow
              hoverColor={etBrightLightBlue}
              onClick={() => {
                driver.closeContextMenu();
                driver.updateSourceDeck();
                driver.autoplayAction();
              }}
            >
              <sc.IconWrapper>
                <IconSvg
                  icon="auto-follow"
                  color={etGray}
                  hoverColor={etBrightLightBlue}
                  pointer
                  width="18px"
                  height="18px"
                  padding="0"
                />
              </sc.IconWrapper>
              <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-AutoFollow">
                {[AutoplayStatus.STARTED, AutoplayStatus.STOPPED].includes(
                  driver.autoplayStatus,
                )
                  ? t("deckgeneral.button.cancel-autoplay")
                  : t("deckgeneral.button.autoplay")}
              </sc.ContextMenuItemBlue>
            </sc.ContextMenuRow>
          ) : null}

          {driver.shouldRenderSystemDeckButton && (
            <sc.ContextMenuRow
              hoverColor={etBrightLightBlue}
              onClick={() => {
                driver.closeContextMenu();
                driver.onSystemDeckButtonClicked();
              }}
            >
              <sc.IconWrapper>
                <IconSvg
                  icon="forward"
                  color={etGray}
                  pointer
                  width="13px"
                  height="15px"
                  padding="0"
                />
              </sc.IconWrapper>
              <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-SystemDeck">
                {t("deckgeneral.button.system-deck")}
              </sc.ContextMenuItemBlue>
            </sc.ContextMenuRow>
          )}

          {driver.isDeleteOptionVisible ? (
            <sc.ContextMenuRowDelete
              hoverColor={etVoteRed}
              onClick={() => {
                driver.closeContextMenu();
                driver.updateDeckDelete && driver.updateDeckDelete();
                driver.openModal("deckDelete");
              }}
            >
              <sc.IconWrapper data-cy="Icon-Wrapper">
                <IconSvg
                  icon="delete-card"
                  color={etVoteRed}
                  pointer
                  hoverColor={etVoteRed}
                  width="16px"
                  height="18px"
                  padding="0"
                />
              </sc.IconWrapper>
              <sc.ContextMenuItemRedInInit data-cy="DeckSimpleContext-DeleteDeck">
                {t("deckgeneral.button.delete")}
              </sc.ContextMenuItemRedInInit>
            </sc.ContextMenuRowDelete>
          ) : (
            <sc.ContextMenuRowReport
              hoverColor={etBrightLightBlue}
              onClick={() => {
                driver.closeContextMenu();
                driver.openModal("deckReport");
              }}
            >
              <sc.IconWrapper data-cy="Icon-Wrapper">
                <IconSvg
                  icon="report"
                  color={etGray}
                  pointer
                  width="18px"
                  height="18px"
                  padding="0"
                />
              </sc.IconWrapper>
              <sc.ContextMenuItemBlue data-cy="DeckSimpleContext-ReportDeck">
                {t("deckgeneral.button.report")}
              </sc.ContextMenuItemBlue>
            </sc.ContextMenuRowReport>
          )}
        </sc.ContextMenu>
      )
    );
  };

  return (
    <sc.Container>
      {driver.contextMenuActive && (
        <sc.Overlay onClick={() => driver.closeContextMenu()} />
      )}
      <sc.ContextMenuContainer
        data-cy="DeckSimpleContext-ContextMenuContainer"
        menuColor={driver.menuColor || ""}
        onClick={
          driver.contextMenuActive
            ? driver.closeContextMenu
            : driver.openContextMenu
        }
        isContextMenuActive={driver.contextMenuActive}
      >
        <IconSvg
          icon="more"
          pointer
          width="18px"
          height="18px"
          padding="0"
          color={driver.contextMenuActive ? etGrayDark : etWhite}
          hoverColor={etGrayDark}
        />
        {renderContextMenuItem(driver.currentUser || false)}
      </sc.ContextMenuContainer>
    </sc.Container>
  );
});
