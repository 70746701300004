import { observer } from "mobx-react-lite";
import React from "react";

import sc from "./BentoButton.styled";

export interface IBentoButtonDriver {
  readonly isUserLoggedOut: boolean;
  readonly isNavbarMenuAppActive: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  toggleMenuApp(): void;
}

interface IProps {
  driver: IBentoButtonDriver;
  closeOverlay?(): void; //! must have in home page
}

export const BentoButton = observer(function BentoButton(props: IProps) {
  const { isUserLoggedOut, isNavbarMenuAppActive, mLandscape, toggleMenuApp } =
    props.driver;
  const mobileLandscape = mLandscape ? "mLandscape" : "";
  return (
    <sc.BentoButton
      data-cy="BentoButton"
      isActive={isNavbarMenuAppActive}
      isUserLoggedOut={isUserLoggedOut}
      onClick={() => {
        toggleMenuApp();
        props.closeOverlay && props.closeOverlay();
      }}
      className={[
        isNavbarMenuAppActive ? "opening" : "closing",
        mLandscape ? "mLandscape" : "",
      ].join(" ")}
    >
      <sc.Row id="top">
        <sc.Dot className={mobileLandscape} />
        <sc.Dot className={mobileLandscape} />
        <sc.Dot className={mobileLandscape} />
      </sc.Row>
      <sc.Row id="middle">
        <sc.Dot className={mobileLandscape} />
        <sc.Dot className={mobileLandscape} />
        <sc.Dot className={mobileLandscape} />
      </sc.Row>
      <sc.Row id="bottom">
        <sc.Dot className={mobileLandscape} />
        <sc.Dot className={mobileLandscape} />
        <sc.Dot className={mobileLandscape} />
      </sc.Row>
    </sc.BentoButton>
  );
});
