/* eslint-disable no-unused-expressions */

import { observer } from "mobx-react-lite";
import React from "react";

import EditCard from "-!svg-react-loader?name=EditCard!./assets/edit-card.svg";
import Photo from "-!svg-react-loader?name=Photo!./assets/photo.svg";
import Text from "-!svg-react-loader?name=Text!./assets/text.svg";

import { etRegentGray } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import sc from "./CardSimpleContext_Update.styled";

interface Driver {
  readonly isCardLayoutEditorial: boolean;
  readonly isCardLayoutPhoto: boolean;
  readonly isCardLayoutText: boolean;
  onChangeCardLayoutEditorial(): void;
  onChangeCardLayoutPhoto(): void;
  onChangeCardLayoutText(): void;
}
interface IProps {
  driver: Driver;
}

export const ContextMenuCardLayout = observer(function (props: IProps) {
  const { driver } = props;
  const { t } = useTranslation("ContextMenu");

  return (
    <>
      <sc.ResizeText data-cy="CardMenuItem-CardLayout-Title">
        {t("context-menu.card-menu.card-layout")}
      </sc.ResizeText>
      <sc.ResizeLayout data-cy="CardMenuItem-CardLayout">
        <sc.ResizeOption
          onClick={(e) => {
            e.stopPropagation();
            !driver.isCardLayoutEditorial &&
              driver.onChangeCardLayoutEditorial();
          }}
        >
          <sc.ResizeBorder
            data-cy="CardMenuItem-CardLayout-Editorial"
            className={driver.isCardLayoutEditorial ? "active" : ""}
          >
            <sc.ResizeIcon>
              <EditCard fill={etRegentGray} />
            </sc.ResizeIcon>
          </sc.ResizeBorder>
          <sc.ContextMenuText data-cy="CardMenuItem-Editorial-Text">
            {t("context-menu.card-menu.editorial")}
          </sc.ContextMenuText>
        </sc.ResizeOption>

        <sc.ResizeOption
          onClick={(e) => {
            e.stopPropagation();
            !driver.isCardLayoutPhoto && driver.onChangeCardLayoutPhoto();
          }}
        >
          <sc.ResizeBorder
            data-cy="CardMenuItem-CardLayout-Photo"
            className={driver.isCardLayoutPhoto ? "active" : ""}
          >
            <sc.ResizeIcon>
              <Photo fill={etRegentGray} width={16} height={16} />
            </sc.ResizeIcon>
          </sc.ResizeBorder>
          <sc.ContextMenuText data-cy="CardMenuItem-Photo-Text">
            {t("context-menu.card-menu.photo")}
          </sc.ContextMenuText>
        </sc.ResizeOption>

        <sc.ResizeOption
          onClick={(e) => {
            e.stopPropagation();
            !driver.isCardLayoutText && driver.onChangeCardLayoutText();
          }}
        >
          <sc.ResizeBorder
            data-cy="CardMenuItem-CardLayout-Text"
            className={driver.isCardLayoutText ? "active" : ""}
          >
            <sc.ResizeIcon>
              <Text fill={etRegentGray} />
            </sc.ResizeIcon>
          </sc.ResizeBorder>
          <sc.ContextMenuText data-cy="CardMenuItem-Text-Text">
            {t("context-menu.card-menu.text")}
          </sc.ContextMenuText>
        </sc.ResizeOption>
      </sc.ResizeLayout>
      <sc.Spacing></sc.Spacing>
    </>
  );
});
