import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";

import { ModalType } from "../../stores/ModalStore";
import { LayoutModal } from "../LayoutModal/LayoutModal";
import sc from "./ModalInfo.styled";

interface Driver {
  openModal(modalTypes: ModalType): void;
}

interface IProps {
  driver: Driver;
  renderModalWrapper(children: ReactNode): ReactNode;
}

export const ModalInfoLayout = observer((props: IProps) => {
  const { driver } = props;
  const router = useRouter();

  const onClose = () => {
    driver.openModal("");

    const [asPath, search] = router.asPath.split("?");
    const params = new URLSearchParams(search);
    params.delete("codepooldry");
    params.delete("codepoolcollected");
    const queryParams = params.toString() ? `?${params.toString()}` : "";
    router.replace(
      router.pathname,
      decodeURIComponent(`${asPath}${queryParams}`),
      {
        shallow: true,
      },
    );
  };

  return (
    <LayoutModal
      onClose={onClose}
      renderModalWrapper={props.renderModalWrapper}
      renderModalContent={() => (
        <>
          <sc.Header>
            <sc.HeaderTitle data-cy="ModalInfo-HeaderTitle">
              <sc.Logo />
            </sc.HeaderTitle>
          </sc.Header>
          <sc.Body data-cy="ModalInfo-Body">
            You just missed it! The QR code is no longer valid.
          </sc.Body>
        </>
      )}
    />
  );
});
