import styled from "styled-components";

import { etDark } from "../../shared/colors";

namespace sc {
  export const Title = styled.h2`
    font-size: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    color: ${etDark};

    margin-bottom: 39px;
  `;

  export const Row = styled.div`
    display: flex;
    color: ${etDark};
  `;

  export const Paragraph = styled.p`
    font-size: 14px;
    height: 77px;
    margin: 0;
  `;
}

export default sc;
