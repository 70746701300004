import { NotificationResponseDto } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { SUPPORTED_LOCALES } from "../../../server/LocaleDetector";
import {
  etBrightLightBlue,
  etGray,
  etGrayDark,
  etRegentGray,
  etWhite,
} from "../../shared/colors";
import { getResizeImageUrl } from "../../shared/helpers/getResizeImageUrl";
import { useTranslation } from "../../shared/translate/NextI18next";
import { AuthUserModel } from "../../stores/AuthUserModel";
import { LocaleStore } from "../../stores/LocaleStore";
import { Button } from "../Button/Button";
import { IconSvg } from "../IconSvg/IconSvg";
import { LocaleSwitcher } from "../LocaleSwitcher/LocaleSwitcher";
import { TheNavbarCreateCollectibleButton } from "../TheNavBarCreateCollectibleButton/TheNavBarCreateCollectibleButton";
import { TheNavbarNotification } from "../TheNavbarNotification/TheNavbarNotification";
import { TheNavbarUserContextMenu } from "../TheNavbarUserContextMenu/TheNavbarUserContextMenu";
import sc, {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "./TheNavbarControllers.styled";

export interface TheNavbarControllerDriver {
  readonly user: AuthUserModel | null;
  readonly userLoggedIn: boolean;
  readonly notifications: NotificationResponseDto[];
  readonly showMenu;
  readonly showNotifications;
  readonly isLoadingNotification: boolean;
  readonly isLoadingReply: boolean;
  readonly isInvitationAccepted: boolean | null;
  readonly isInvitationReplied: boolean;
  readonly shouldRenderCreateCard: boolean;
  readonly shouldRenderCreateActionCard: boolean;
  readonly shouldRenderCreateInfoCard: boolean;
  readonly shouldRenderCreateCollectableCard: boolean;
  readonly shouldRenderSignupButton: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  showNotificationsToggle(): void;
  showMenuToggle(): void;
  onLogout(redirect: () => void): void;
  onMount(): void;
  onMarkNotificationRead(notificationItem: NotificationResponseDto): void;
  onSigninButtonClicked(): void;
  onLoginButtonClicked(): void;
  formatLatestTime(
    fromDate: Date,
    notification: NotificationResponseDto,
  ): string;
  turnMenuOff(): void;
  handleReplyGroupInvitation(
    b: boolean,
    notification: NotificationResponseDto,
  ): void;
  getGiftCollectedNotificationTitle(notification: NotificationResponseDto): {
    key: string;
    vars: {
      uonCount: number;
    };
  };
  getDeckAutoplayedNotificationTitle(
    notification: NotificationResponseDto,
  ): ReactNode;
  openModalCreateCard(): void;
  onCreateActionCardMenuItemClicked(): void;
  onCreateInfoCardMenuItemClicked(): void;
  onCreateCollectableCardMenuItemClicked(): void;
}

interface IProps {
  driver: TheNavbarControllerDriver;
  localeDriver: Pick<LocaleStore, "currentLocale" | "updateLocale">;
  routerDriver: {
    push(url: string, undefined, options?: { shallow: boolean }): void;
  };
  didInitLoginStatus: boolean;
  featureFlaggingDriver: {
    enableNewProfilePage: boolean;
    enableLanguageSwitcher: boolean;
    enableGroup: boolean;
  };
}

export const TheNavbarControllers = observer(function TheNavbarControllers(
  props: IProps,
) {
  const { t } = useTranslation("ModalJoin");

  const { driver, featureFlaggingDriver, localeDriver } = props;

  const unreadNotifications = driver.notifications.filter(
    (notification) => notification.visited === false,
  );

  return driver.user ? (
    <sc.UserControllers>
      {featureFlaggingDriver.enableLanguageSwitcher ? (
        <LocaleSwitcher
          supportedLocales={SUPPORTED_LOCALES}
          driver={localeDriver}
        />
      ) : null}

      <sc.BellContainer
        showNotifications={driver.showNotifications}
        data-cy="Navbar-notification"
        mLandscape={driver.mLandscape}
      >
        <IconSvg
          data-cy="Navbar-notificationIcon"
          onClick={() => {
            driver.onMount();
            driver.showNotificationsToggle();
          }}
          icon="notification"
          width="20px"
          height="20px"
          color={driver.showNotifications ? etBrightLightBlue : etGray}
          hoverColor={etGrayDark}
          padding="0"
          pointer
        />
        {unreadNotifications.length > 0 && <sc.BlueDot />}
        {driver.showNotifications && <TheNavbarNotification driver={driver} />}
      </sc.BellContainer>
      {driver.shouldRenderCreateCollectableCard && (
        <TheNavbarCreateCollectibleButton driver={driver} />
      )}
      <sc.UserProfile position={driver.showMenu ? "relative" : "unset"}>
        <sc.UserImg
          data-cy="Navbar-userAvatar"
          src={getResizeImageUrl(driver.user.imageData, {
            width: PROFILE_AVATAR_WIDTH_DEFAULT,
            height: PROFILE_AVATAR_HEIGHT_DEFAULT,
          })}
          onClick={() => driver.showMenuToggle()}
        />
        {driver.showMenu && (
          <TheNavbarUserContextMenu
            driver={driver}
            routerDriver={props.routerDriver}
            enableNewProfilePage={featureFlaggingDriver.enableNewProfilePage}
            enableGroup={featureFlaggingDriver.enableGroup}
          />
        )}
      </sc.UserProfile>
    </sc.UserControllers>
  ) : (
    <sc.NavbarRightButtons didInitLoginStatus={props.didInitLoginStatus}>
      <sc.SignInContainer>
        {driver.shouldRenderSignupButton && (
          <Button
            driver={{
              type: "primary",
              dataCy: "TheNavbar-signUpBtn",
              width: "70px",
              height: "36px",
              fontStretch: "condensed",
              margin: "0 12px 0 0",
              round: true,
              mobileWidth: "70px",
              mobileHeight: "32px",
              mobileFontsize: "14px",
              color: etWhite,
              fontWeight: "normal",
              fontFamily: "Roboto Condensed",
              onClick: () => {
                driver.onSigninButtonClicked();
              },
            }}
          >
            {t("modaljoin.nav-buttons.join")}
          </Button>
        )}
        <sc.LoginButtonContainer>
          <Button
            driver={{
              type: "outline-light",
              dataCy: "TheNavbar-loginBtn",
              width: "70px",
              height: "36px",
              color: etRegentGray,
              round: true,
              fontStretch: "condensed",
              fontFamily: "Roboto Condensed",
              mobileWidth: "70px",
              mobileHeight: "32px",
              mobileFontsize: "14px",
              mobileVisible: true,
              onClick: () => {
                driver.onLoginButtonClicked();
              },
            }}
          >
            {t("modaljoin.nav-buttons.login")}
          </Button>
        </sc.LoginButtonContainer>
      </sc.SignInContainer>
    </sc.NavbarRightButtons>
  );
});
