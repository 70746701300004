import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import { etHeatherBlue, etRegentGray, etWhite } from "../../shared/colors";
import {
  CARD_SOURCE_MAX_LENGTH,
  DECK_NAME_MAX_LENGTH_SINGLE_SIZE,
} from "../../shared/constants";
import { getValidHttpUrl } from "../../shared/helpers/getValidHttpUrl";
import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";
import {
  CardArticleLayoutType,
  CardSize,
  CardType,
  CardTypeRegular,
  MasterLinkType,
} from "../../shared/models/Card";
import { useTranslation } from "../../shared/translate/NextI18next";
import { CardBaseContentType } from "../../stores/CardBaseModel";
import { Button } from "../Button/Button";
import { ButtonCircle } from "../ButtonCircle/ButtonCircle";
import { CardItemMetaData, ICardItemContent } from "../CardItem/CardItem";
import { CardItemFooter } from "../CardItem/CardItemFooter";
import { CardItemFooterAction } from "../CardItem/CardItemFooterAction";
import CardItemLayout from "../CardItemLayout/CardItemLayout";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardItemArticle.styled";

const LAYOUT_TEXT_WORD_BREAK_TITLE_LIMIT = 23;
const LAYOUT_TEXT_REDUCE_SPACING_TITLE_LIMIT = 20;

export interface CardItemArticleDriver {
  readonly isCardDrafting: false;
  readonly cardType:
    | MasterLinkType.ARTICLE
    | MasterLinkType.WIKI
    | MasterLinkType.REMOVED;
  readonly contentType: CardBaseContentType;

  readonly id: string;

  readonly metadata: CardItemMetaData | null;
  readonly cardContent: ICardItemContent;
  readonly vanityName: string;
  readonly curatorName: string;
  readonly comment: string;
  readonly deckPathString: string;
  readonly category: string;
  readonly categoryPrinciple: string;
  readonly updatedDate: string;
  readonly previewImage: string;
  readonly cardItemPreviewImage: string;
  readonly pathSharing: string;
  readonly shareActive: boolean;
  readonly starActive: boolean;
  readonly deckName: string;
  readonly contextMenuActive: boolean;
  readonly categoryColor: string;
  readonly adminOrOwner: boolean;
  readonly isCardOwner: boolean;
  readonly isCardInFirstPosition?: boolean; // only card on homepage
  readonly cardLayoutTextColor: string;
  readonly isCardLayoutEditorial: boolean;
  readonly isCardLayoutText: boolean;
  readonly isAutoplayedCard?: boolean;
  readonly isSaveButtonEnabled: boolean;
  readonly isCurrentUser?: boolean;

  cardRepostIdUpdate(): void;
  toggleShareActive(): void;
  toggleStarActive(): void;
  openContextMenu(): void;
  closeContextMenu(): void;
  onStarCard(): void;
}

export interface LayoutDriver {
  readonly cardSize: CardSize;
  readonly cardLayout?: CardArticleLayoutType;
}

const shouldReduceLetterSpacing = (text: string): boolean => {
  const words: string[] = text.split(" ");
  const index = words.findIndex(
    (word) => word.length > LAYOUT_TEXT_REDUCE_SPACING_TITLE_LIMIT,
  );
  return index !== -1;
};

const shouldBreakWord = (text: string): boolean => {
  const words: string[] = text.split(" ");
  const index = words.findIndex(
    (word) => word.length > LAYOUT_TEXT_WORD_BREAK_TITLE_LIMIT,
  );
  return index !== -1;
};

interface IProps {
  driver: CardItemArticleDriver;
  layoutDriver: LayoutDriver;
  renderMenuContext(): ReactNode;
  renderSharing(): ReactNode;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardItemArticle = observer(function CardItemArticle(
  props: IProps,
) {
  const { driver, layoutDriver, renderMenuContext, renderSharing } = props;

  const { cardContent } = driver;

  const { t } = useTranslation("CardGeneral");

  return (
    <CardItemLayout layoutDriver={layoutDriver}>
      <sc.Container
        data-cy="CardItemArticle-Container"
        className="card shadow"
        contextMenuActive={driver.contextMenuActive}
      >
        {driver.contextMenuActive && <sc.BlackOverlay />}

        <sc.Header data-cy="CardItemArticle-Header">
          <sc.HeaderTitle
            data-cy="CardItemArticle-Title"
            title={driver.deckName}
            iconColor={props.driver.categoryColor}
            data-icon={`cat-${driver.categoryPrinciple || driver.category}`}
          >
            <IconSvg
              data-cy="CardItemArticle-Icon"
              icon={`cat-${driver.categoryPrinciple || driver.category}`}
              width="19px"
              height="18px"
              padding="0"
              color={props.driver.categoryColor}
            />
            <Link href={"/" + driver.deckPathString}>
              {`#${truncateWithEllipsis(
                driver.deckName,
                DECK_NAME_MAX_LENGTH_SINGLE_SIZE,
              )}`}
            </Link>
          </sc.HeaderTitle>
          <sc.ContextMenuContainer>
            {renderMenuContext()}
          </sc.ContextMenuContainer>
        </sc.Header>
        <sc.ContentContainer data-cy="CardItemArticle-ContentContainer">
          {cardContent && cardContent.content?.source?.url ? (
            <sc.ContainerBody
              data-cy={`CardItemArticle-ContainerBody-${layoutDriver.cardLayout}`}
              isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
              isLayoutEditorialActivated={driver.isCardLayoutEditorial}
            >
              <sc.PreviewImageContainer>
                {driver.isCardLayoutText ? (
                  <sc.TextLayoutContainer
                    data-cy="CardItemArticle-TextLayoutContainer"
                    backgrondColor={driver.categoryColor || etHeatherBlue}
                  >
                    <sc.TextLayoutTittle
                      data-cy="CardItemArticle-TextLayoutTittle"
                      titleColor={driver.cardLayoutTextColor}
                      isCardSizeDouble={
                        layoutDriver.cardSize === CardSize.DOUBLE
                      }
                      shouldBreakWord={shouldBreakWord(
                        cardContent.content.title,
                      )}
                      shouldReduceLetterSpacing={shouldReduceLetterSpacing(
                        cardContent.content.title,
                      )}
                    >
                      {truncateWithEllipsis(cardContent.content.title, 125)}
                    </sc.TextLayoutTittle>
                  </sc.TextLayoutContainer>
                ) : (
                  <sc.PreviewImage
                    data-cy="CardItemArticle-PreviewImage"
                    isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
                    isLayoutEditorialActivated={driver.isCardLayoutEditorial}
                    url={driver.cardItemPreviewImage}
                    hasTitle={!!cardContent.content.title}
                  />
                )}
                <sc.CardSourceContainer>
                  <sc.CardLink
                    data-cy="CardItemArticle-VisitArticleBtn"
                    target="_blank"
                    href={getValidHttpUrl(cardContent.content.url)}
                  >
                    <sc.CardSource
                      data-cy="CardItemArticle-CardSource"
                      isCardTextLayout={driver.isCardLayoutText}
                      color={driver.cardLayoutTextColor}
                    >
                      {truncateWithEllipsis(
                        cardContent.content?.source.name.toUpperCase(),
                        CARD_SOURCE_MAX_LENGTH,
                        "...",
                      )}
                    </sc.CardSource>
                    <IconSvg
                      icon="carditem-link-v2"
                      width="11px"
                      height="11px"
                      padding="0"
                      margin="auto 0 auto 6px"
                      color={etWhite}
                      pointer
                    />
                  </sc.CardLink>
                </sc.CardSourceContainer>
                {!driver.isCardLayoutText && (
                  <sc.Gradient data-cy="CardItemArticle-OverlaySource" />
                )}
              </sc.PreviewImageContainer>

              {driver.isCardLayoutEditorial && (
                <sc.Body
                  data-cy="CardItemArticle-Body"
                  href={cardContent.content?.source.url}
                  target="_blank"
                  isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
                  hasTitle={!!cardContent.content.title}
                >
                  {layoutDriver.cardSize === CardSize.DOUBLE ? (
                    <sc.BodyTextDouble
                      data-cy="CardItemArticle-BodyText"
                      title={cardContent.content.title}
                    >
                      {cardContent.content.title}
                    </sc.BodyTextDouble>
                  ) : (
                    <sc.BodyTextSingle
                      data-cy="CardItemArticle-BodyText"
                      title={cardContent.content.title}
                    >
                      {cardContent.content.title}
                    </sc.BodyTextSingle>
                  )}
                </sc.Body>
              )}
              <sc.ContainerButtonAction data-cy="CardItemArticle-OverlayContent">
                <sc.SaveButtonWrapper
                  isButtonHiden={driver.isCardOwner || !!driver.isCurrentUser}
                >
                  <Button
                    driver={{
                      dataCy: "CardItemArticle-ButtonSave",
                      type: "primary",
                      fontSize: "14px",
                      fontStretch: "condensed",
                      height: "32px",
                      width: "88px",
                      round: true,
                      cursor: driver.isSaveButtonEnabled
                        ? "pointer"
                        : "default",
                      onClick: driver.cardRepostIdUpdate,
                    }}
                  >
                    SAVE
                    <IconSvg
                      data-cy="CardArticle-SaveIcon"
                      icon="save"
                      pointer
                      padding="0"
                      width="12px"
                      height="14px"
                      color={etWhite}
                    />
                  </Button>
                </sc.SaveButtonWrapper>
                <ButtonCircle
                  driver={{
                    margin: "0 8px",
                    type: "white",
                    dataCy: "CardItemArticle-ButtonShare",
                    onClick: () => {
                      driver.toggleShareActive();
                      driver.closeContextMenu();
                    },
                  }}
                >
                  <IconSvg
                    data-cy="CardArticle-ShareIcon"
                    icon="share"
                    pointer
                    padding="0"
                    width="12px"
                    height="14px"
                    color={etRegentGray}
                  />
                </ButtonCircle>
              </sc.ContainerButtonAction>
              <sc.Overlay data-cy="CardArticle-Overlay" />
            </sc.ContainerBody>
          ) : (
            <sc.RemovedCardBody data-cy="RemovedCardBody">
              <sc.RemovedCardBodyTitle>
                {t("card-general.deleted.no-available")}
              </sc.RemovedCardBodyTitle>
              <span>{t("card-general.deleted.was-deleted")}</span>
            </sc.RemovedCardBody>
          )}

          <CardItemFooter
            driver={driver}
            layoutDriver={layoutDriver}
            renderCardSimpleAction={() => (
              <CardItemFooterAction driver={driver} />
            )}
          />
          {renderSharing()}
          <sc.OverlayLinkContainer data-cy="CardItem-OverlayLinkContainer">
            {driver.isCardInFirstPosition &&
            cardContent &&
            cardContent.content?.source?.url ? (
              <a
                data-cy="CardItemArticle-CardInFirstPosition"
                target="_blank"
                href={getValidHttpUrl(cardContent.content.url)}
              />
            ) : (
              <Link
                href="/[vanityName]/[deckName]/[cardId]"
                as={`/${driver.deckPathString}/${driver.id}`}
                passHref
              ></Link>
            )}
          </sc.OverlayLinkContainer>
        </sc.ContentContainer>
      </sc.Container>
    </CardItemLayout>
  );
});
