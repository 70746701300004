import { observer } from "mobx-react-lite";
import React from "react";
import { Element } from "react-scroll";

import {
  etBlack,
  etFbPurple,
  etGray,
  etHeatherBlue,
  etLiBlueWaterDark,
  etPiRed,
  etWaGreen,
  etWhite,
} from "../../shared/colors";
import { getSocialNetworkShareLink } from "../../shared/helpers/getSocialNetworkShareLink";
import { openLinkInNewWindow } from "../../shared/helpers/openLinkInNewWindow";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ButtonCircleSocial } from "../ButtonCircleSocial/ButtonCircleSocial";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardDeckOverviewSharing.styled";

export interface CardDeckHeaderSharingDriver {
  readonly path: string[];
  readonly title?: string;
  readonly imageSharing?: string;
  readonly shareActive: boolean;
  readonly deckUrl: string;

  toggleShareActive(): void;
  copyCardLinkToClipboard(urlToCopy: string): void;
}

interface IProps {
  driver: CardDeckHeaderSharingDriver;
}

export const CardDeckOverviewSharing = observer(
  function CardDeckHeaderSharingDeckSharing(props: IProps) {
    const { driver } = props;

    const { t } = useTranslation("DeckGeneral");

    return (
      <sc.Container
        data-cy="CardDeckOverviewSharing-Container"
        className={driver.shareActive ? "active" : ""}
      >
        <Element name="cardHeaderShare">
          <sc.CardSharingTitle>
            {t("deckgeneral.button.share")}
          </sc.CardSharingTitle>
          <sc.Close>
            <IconSvg
              dataCy="CardDeckOverviewSharing-Close"
              pointer
              icon="close"
              color={etGray}
              hoverColor={etGray}
              padding="0"
              width="16px"
              height="16px"
              onClick={driver.toggleShareActive}
            />
          </sc.Close>
          <sc.LogoRow>
            <ButtonCircleSocial
              driver={{
                dataCy: "",
                icon: "facebook",
                iconColor: etWhite,
                backgroundColor: etFbPurple,
                width: "32px",
                height: "32px",
                iconHeight: "20px",
                iconWidth: "20px",
                margin: "8px",
                onClick: () =>
                  openLinkInNewWindow(
                    getSocialNetworkShareLink("facebook", driver.deckUrl),
                  ),
              }}
            />
            <ButtonCircleSocial
              driver={{
                dataCy: "",
                icon: "x-twitter",
                iconColor: etWhite,
                backgroundColor: etBlack,
                width: "32px",
                height: "32px",
                iconHeight: "20px",
                iconWidth: "20px",
                margin: "8px",
                onClick: () =>
                  openLinkInNewWindow(
                    getSocialNetworkShareLink("twitter", driver.deckUrl),
                  ),
              }}
            />
            <ButtonCircleSocial
              driver={{
                dataCy: "",
                icon: "whatsapp",
                iconColor: etWhite,
                backgroundColor: etWaGreen,
                width: "32px",
                height: "32px",
                iconHeight: "20px",
                iconWidth: "20px",
                margin: "8px",
                onClick: () =>
                  openLinkInNewWindow(
                    getSocialNetworkShareLink("whatsapp", driver.deckUrl),
                  ),
              }}
            />
            <ButtonCircleSocial
              driver={{
                dataCy: "",
                icon: "pinterest",
                iconColor: etWhite,
                backgroundColor: etPiRed,
                width: "32px",
                height: "32px",
                iconHeight: "20px",
                iconWidth: "20px",
                margin: "8px",
                onClick: () =>
                  openLinkInNewWindow(
                    getSocialNetworkShareLink(
                      "pinterest",
                      driver.deckUrl,
                      driver.title,
                      driver.imageSharing,
                    ),
                  ),
              }}
            />
            <ButtonCircleSocial
              driver={{
                dataCy: "",
                icon: "linkedin",
                iconColor: etWhite,
                backgroundColor: etLiBlueWaterDark,
                width: "32px",
                height: "32px",
                iconHeight: "20px",
                iconWidth: "20px",
                margin: "8px",
                onClick: () =>
                  openLinkInNewWindow(
                    getSocialNetworkShareLink("linkedin", driver.deckUrl),
                  ),
              }}
            />
            <ButtonCircleSocial
              driver={{
                dataCy: "",
                title: `Copy link: ${driver.deckUrl}`,
                icon: "link",
                iconColor: etWhite,
                backgroundColor: etHeatherBlue,
                width: "32px",
                height: "32px",
                iconHeight: "16px",
                iconWidth: "16px",
                margin: "8px",
                onClick: () => driver.copyCardLinkToClipboard(driver.deckUrl),
              }}
            />
          </sc.LogoRow>
        </Element>
      </sc.Container>
    );
  },
);
