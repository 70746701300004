import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { useRootStore } from "../../stores/rootStore";
import { ModalsRenderer } from "../ModalsRenderer/ModalsRenderer";
import { QrCodeOverlay } from "../QrCodeOverlay/QrCodeOverlay";
import { SearchPanel } from "../SearchPanel/SearchPanel";
import { TheNavbarControllers } from "../TheNavbarControllers/TheNavbarControllers";
import { TheNavbarLinkItems } from "../TheNavbarLinkItems/TheNavbarLinkItems";
import { TheNavbarSearch } from "../TheNavbarSearch/TheNavbarSearch";
import { PlayButtons } from "../TheNavbarTop/PlayButtons";
import { TheNavbarTop } from "../TheNavbarTop/TheNavbarTop";
import { BentoButton } from "./BentoButton/BentoButton";

export const TheNavbarTopStateful = observer(
  function TheNavbarTopStateful(props: { closeOverlay?(): void }) {
    const rootStore = useRootStore();
    const {
      theNavbarStore,
      deviceStore,
      mobileMenuStore,
      featureFlaggingStore,
    } = rootStore;
    const router = useRouter();

    useEffect(() => {
      theNavbarStore.fetchPromotionsCount();
    }, [theNavbarStore.user]);

    useEffect(() => {
      if (
        !theNavbarStore.qrCodeOverlayDriver ||
        !theNavbarStore.qrCodeOverlayDriver.expireTime
      ) {
        return;
      }
      const timer = setInterval(() => {
        theNavbarStore.qrCodeOverlayDriver?.updateNow(new Date());
      }, 1000);

      return () => clearInterval(timer);
    }, [theNavbarStore.qrCodeOverlayDriver?.expireTime]);

    return (
      <>
        <TheNavbarTop
          routerDriver={router}
          driverTheNavbarTop={theNavbarStore}
          driverTheNavbarLinkItem={theNavbarStore}
          deviceDriver={deviceStore}
          renderPlayButtons={() => (
            <PlayButtons
              driver={router}
              featureFlaggingDriver={featureFlaggingStore.flags}
            />
          )}
          renderCenteredSearch={() => <SearchPanel driver={theNavbarStore} />}
          renderSearch={() => <TheNavbarSearch driver={theNavbarStore} />}
          renderButtonCreateCard={() => (
            <TheNavbarLinkItems driver={theNavbarStore} />
          )}
          renderControllers={() => (
            <TheNavbarControllers
              didInitLoginStatus={rootStore.didInitLoginStatus}
              driver={theNavbarStore}
              routerDriver={router}
              featureFlaggingDriver={featureFlaggingStore.flags}
              localeDriver={rootStore.localeStore}
            />
          )}
          renderBentoButton={() => (
            <BentoButton
              driver={mobileMenuStore}
              closeOverlay={props.closeOverlay}
            />
          )}
        />
        <ModalsRenderer />
        <QrCodeOverlay driver={theNavbarStore.qrCodeOverlayDriver} />
      </>
    );
  },
);
