import styled from "styled-components";

import { mediumScreenUpperLimit } from "../../shared/breakpoints";
import { etBlack, etWhite } from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import zIndex from "../../shared/zIndex";
import {
  CARD_ITEM_MUSIC_HEIGHT,
  CARD_ITEM_MUSIC_WIDTH,
} from "../CardItemMusic/CardItemMusic.styled";
namespace sc {
  export const Container = styled.div<{ bg: string }>`
    position: relative;
    height: 100%;
    min-height: 496px;
    position: relative;
    background-color: ${etBlack};
    color: ${etWhite};
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      background: url(${(props) => props.bg});
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-filter: blur(10px);
      filter: blur(10px);
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.5);
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;
  export const ContainerContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 11;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
  `;
  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 15;
  `;
  export const Header = styled.div<{ iconColor: string }>`
    display: flex;
    align-items: center;
    height: 44px;
    width: 100%;

    > div > svg {
      height: 18px;
      width: 19px;
      > g > g {
        stroke: ${(props) => props.iconColor};
        fill: ${(props) => props.iconColor};
      }
    }
  `;
  export const HeaderTitle = styled.a<{ isEmptyCategories: boolean }>`
    margin-left: ${(props) => (props.isEmptyCategories ? "26px" : "7px")};
    font-family: Roboto;
    font-weight: normal;
    font-stretch: Condensed;
    font-style: normal;
    font-size: 14px;
    height: 20px;
    letter-spacing: 0px;
    line-height: 20px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${etWhite};
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: ${etWhite};
      text-decoration: none;
    }
  `;
  export const ContextWrapper = styled.div`
    width: 32px;
    height: 44px;
  `;
  export const Wrap = styled.div`
    display: flex;
    justify-content: center;
  `;

  export const CoverContainer = styled.div`
    width: 304px;
    height: 304px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid ${transparentize(etWhite, 0.25)};
  `;
  export const PreviewImage = styled.div<{ src: string }>`
    margin: 13px auto 0 auto;
    height: ${CARD_ITEM_MUSIC_HEIGHT}px;
    width: ${CARD_ITEM_MUSIC_WIDTH}px;
    border-radius: 50%;
    overflow: hidden;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  `;
  export const OverlaySource = styled.div`
    content: "";
    width: 100%;
    height: 44px;
    opacity: 0.6;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 100%
    );
    position: absolute;
    bottom: 0;
    z-index: 1;
  `;
  export const CardSource = styled.a`
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 9px;
    left: 95px;
    z-index: ${zIndex.CARDITEM_LINK};
    text-transform: uppercase;
    height: 20px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etWhite};
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: ${etWhite};
      text-decoration: none;
    }
  `;
  export const ArrowLink = styled.div`
    visibility: hidden;
    display: inline-block;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const Body = styled.a`
    font-family: "RobotoSlab Regular";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 54px;
    text-align: center;
    color: ${etWhite};
    &:hover {
      color: ${etWhite};
      text-decoration: none;
    }
    display: flex;
    flex-direction: column;
  `;
  export const BodyTitle = styled.div`
    margin-top: 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Roboto Slab";
    font-size: 18px;
  `;
  export const BodyAuthor = styled.div``;
  export const RemovedCardBody = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    flex: 3;
    color: ${etWhite};
    background-color: ${etBlack};
    padding: 16px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    height: 304px;
    font-weight: 600;
  `;
  export const RemovedCardBodyTitle = styled.div`
    font-weight: bold;
    font-size: 10px;
    padding: 16px 0;
  `;
  export const RemovedCardBodyText = styled.div`
    font-size: 14px;
    font-weight: lighter;
  `;
  export const NoDeckSelected = styled(HeaderTitle)<{
    isEmptyCategories: boolean;
  }>`
    margin-left: ${(props) => (props.isEmptyCategories ? "26px" : "7px")};
    pointer-events: none;
  `;
}
export default sc;
