import {
  etGreen,
  etLightBlue,
  etOrange,
  etPurple,
  etRed,
  etRegentGray,
  etYellow,
} from "../colors";

export const getColorProtected = (numberProtected: number): string => {
  if (numberProtected <= 0) {
    return etRegentGray;
  }
  if (numberProtected > 0 && numberProtected <= 4) {
    return etRed;
  }
  if (numberProtected > 4 && numberProtected <= 9) {
    return etOrange;
  }
  if (numberProtected > 9 && numberProtected <= 49) {
    return etYellow;
  }
  if (numberProtected > 49 && numberProtected <= 99) {
    return etGreen;
  }
  if (numberProtected > 99 && numberProtected <= 499) {
    return etLightBlue;
  }
  if (numberProtected > 499 && numberProtected <= 999) {
    return etPurple;
  }
  if (numberProtected > 999 && numberProtected <= 4999) {
    return etRed;
  }
  if (numberProtected > 4999 && numberProtected <= 9999) {
    return etOrange;
  }
  if (numberProtected > 9999 && numberProtected <= 49999) {
    return etYellow;
  }
  if (numberProtected > 49999 && numberProtected <= 99999) {
    return etGreen;
  }
  if (numberProtected > 99999 && numberProtected <= 499999) {
    return etLightBlue;
  }
  if (numberProtected > 499999 && numberProtected <= 999999) {
    return etPurple;
  }
  if (numberProtected > 999999 && numberProtected <= 4999999) {
    return etRed;
  }
  if (numberProtected > 4999999 && numberProtected <= 9999999) {
    return etOrange;
  }
  if (numberProtected > 9999999 && numberProtected <= 49999999) {
    return etYellow;
  }
  if (numberProtected > 49999999 && numberProtected <= 99999999) {
    return etGreen;
  }
  if (numberProtected > 99999999 && numberProtected <= 499999999) {
    return etLightBlue;
  }
  if (numberProtected > 499999999 && numberProtected <= 999999999) {
    return etPurple;
  }
  if (numberProtected > 999999999 && numberProtected <= 4999999999) {
    return etRed;
  }
  if (numberProtected > 4999999999 && numberProtected <= 9999999999) {
    return etOrange;
  }
  if (numberProtected > 9999999999 && numberProtected <= 49999999999) {
    return etYellow;
  }
  if (numberProtected > 49999999999 && numberProtected <= 99999999999) {
    return etGreen;
  }
  if (numberProtected > 99999999999 && numberProtected <= 499999999999) {
    return etLightBlue;
  }

  return etPurple;
};
