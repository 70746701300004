import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import badRequestErrorSvg from "../../assets/svgs/bad-request-error.svg";
import { OverlayDriver } from "./LayoutError";
import sc from "./LayoutErrorCustom.styled";

interface IProps {
  overlayDriver: OverlayDriver;
}

export const LayoutErrorBadRequest = observer((props: IProps) => {
  return (
    <sc.Wrapper
      className="visual-reg"
      data-cy="LayoutErrorBadRequest"
      position={props.overlayDriver.isOverlayActive ? "fixed" : "relative"}
    >
      <sc.BackgroundBlur data-cy="LayoutError-Background" />
      <sc.Overlay data-cy="LayoutError-Overlay" />
      <sc.Container>
        <sc.ColLeft>
          <sc.ErrorImage data-cy="LayoutError-ErrorImage">
            <sc.ErrorImageBackgroundSmall bg={badRequestErrorSvg} />
          </sc.ErrorImage>
        </sc.ColLeft>

        <sc.ColRight>
          <sc.StatusCode data-cy="LayoutError-StatusCode">
            Error 400: Bad request to the server
          </sc.StatusCode>
          <sc.ErrorMessage data-cy="LayoutError-ErrorMessage">
            Sorry. Bad Request.
          </sc.ErrorMessage>
          <sc.ErrorTitle data-cy="LayoutError-ErrorTitle">
            We can’t process this request.
          </sc.ErrorTitle>
          <sc.ErrorDescription data-cy="LayoutError-ErrorDescription">
            Let’s get you back on track. Let’s guide you back{" "}
            <Link href="/" as="/" passHref legacyBehavior>
              <sc.Link>home</sc.Link>
            </Link>
            .
          </sc.ErrorDescription>
        </sc.ColRight>
      </sc.Container>
    </sc.Wrapper>
  );
});
