import styled from "styled-components";

import {
  etBrightTurquoise,
  etGray,
  etGrayBold,
  etLicoriceBlue,
  etNevadaGrey,
  etShadowLighter,
  etVoteRed,
  etWhite,
} from "../../shared/colors";
import { UPTOBILLION } from "../../shared/constants";
import { getColorProtected } from "../../shared/helpers/getColorProtected";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

export const CARD_PROFILE_REPOST_AVATAR_WIDTH: number = 101;
export const CARD_PROFILE_REPOST_AVATAR_HEIGHT: number = 101;
namespace sc {
  export const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 275px;
    max-width: 275px;
    height: 416px;
    max-height: 416px;
    background: ${etWhite};
    box-shadow: 0px 1px 1px 0px ${etShadowLighter},
      0px 2px 3px 0px ${etShadowLighter};
    border-radius: 6.71px;
  `;
  export const Content = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  `;
  export const SquareProtected = styled.span<{ protected: number }>`
    position: absolute;
    top: 18px;
    left: 18px;
    z-index: 5;
    height: 24px;
    width: 24px;
    background-color: ${(props) =>
      props.protected > UPTOBILLION
        ? "transparent"
        : getColorProtected(props.protected)};
    transform: rotate(45deg);
    border: 0.5px solid ${(props) => getColorProtected(props.protected)};
    ::before {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      width: 36px;
      background-color: transparent;
      border-top: 0.5px solid ${(props) => getColorProtected(props.protected)};
      transform: translate(-7px, 11px);
    }
    ::after {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      height: 36px;
      background-color: transparent;
      border-left: 0.5px solid ${(props) => getColorProtected(props.protected)};
      transform: translate(11px, -8px);
    }
  `;
  export const CircleProtected = styled.span<{ protected: number }>`
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 4;
    height: 36px;
    width: 36px;
    overflow: hidden;
    background-color: ${etWhite};
    border-radius: 50%;
    border: 0.5px solid ${(props) => getColorProtected(props.protected)};
    ::after {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      width: 36px;
      background-color: transparent;
      border-bottom: 0.5px solid
        ${(props) => getColorProtected(props.protected)};
      transform: translate(-2px, 17px);
    }
  `;
  export const UonReserveSizeUnit = styled.span<{ protected: number }>`
    position: absolute;
    top: 18px;
    left: 18px;
    height: 24px;
    width: 24px;
    z-index: 6;
    background-color: ${(props) =>
      props.protected > UPTOBILLION ? "transparent" : etWhite};
    border: ${(props) => (props.protected > UPTOBILLION ? "0.5px" : "1px")}
      solid ${(props) => getColorProtected(props.protected)};
    color: ${(props) => getColorProtected(props.protected)};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    ::before {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      height: 36px;
      background-color: transparent;
      border-right: 0.5px solid ${(props) => getColorProtected(props.protected)};
      transform: translate(0px, 7px);
    }
  `;
  export const M2Logo = styled.span<{ display: "none" | "block" }>`
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: -4px;
    margin-right: -1px;
    display: ${(props) => props.display};
    text-transform: lowercase;
  `;
  export const ProtectedNumber = styled.span<{ top: "47px" | "42px" }>`
    position: absolute;
    top: ${(props) => props.top};
    left: 20px;
    height: 17px;
    width: 19px;
    font-size: 8px;
    font-family: Roboto Condensed;
    font-weight: normal;
    line-height: 16.77px;
    text-align: center;
    color: ${etNevadaGrey};
  `;
  export const Avatar = styled.span<{ src: string }>`
    height: ${CARD_PROFILE_REPOST_AVATAR_HEIGHT}px;
    width: ${CARD_PROFILE_REPOST_AVATAR_WIDTH}px;
    margin-top: 60px;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  `;
  export const Title = styled.span`
    height: 26px;
    width: 247px;
    margin-top: 18px;
    font-family: Roboto Condensed;
    font-size: 20px;
    font-weight: normal;
    line-height: 20.12px;
    text-transform: capitalize;
    text-align: center;
    color: ${etLicoriceBlue};
  `;
  export const SubTitle = styled.span`
    height: 11px;
    font-family: Roboto;
    font-size: 9px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${etGray};
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
  export const Tagline = styled.span`
    height: 11px;
    font-family: Roboto;
    font-size: 9px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${etGray};
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
  export const Bio = styled.span`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 25px;
    height: 81px;
    width: 247px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 16.77px;
    text-align: center;
    color: ${etNevadaGrey};
  `;
  export const Joined = styled.p`
    margin-top: 12px;
    margin-bottom: 0;
    width: 118px;
    height: 17px;
    font-family: Roboto Condensed;
    font-size: 8px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 16.77px;
    text-align: center;
    color: ${etNevadaGrey};
  `;
  export const UserCounts = styled.p`
    margin-top: -2px;
    margin-bottom: 0;
    height: 12px;
    font-family: Roboto Condensed;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 16.77px;
    text-align: center;
    text-transform: capitalize;
  `;
  export const DeskCounts = styled.span`
    color: ${etVoteRed};
  `;
  export const FollowerCounts = styled.span`
    color: ${etBrightTurquoise};
  `;
  export const SocialContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    > div {
      width: 27px;
      height: 27px;
      border: 1px solid ${etGrayBold};
      border-radius: 50%;
      margin-right: 6px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;
  export const ContextMenuContainer = styled.div`
    position: absolute;
    z-index: 5;
    line-height: 20px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;

    width: 40px;
    height: 30px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > ${IconSvgContainer} {
      fill: ${etGrayBold};
    }
  `;

  export const Wrapper = styled.div``;
  export const WrapperLink = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    :hover {
      text-decoration: none;
    }
  `;
}

export default sc;
