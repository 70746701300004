import styled from "styled-components";

import {
  mediumScreenLowerLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import { etNevadaGrey, etWhite } from "../../shared/colors";
import zIndex from "../../shared/zIndex";

export namespace sc {
  export const Container = styled.div<{
    isCardSizeDouble: boolean;
    isLayoutEditorialActivated: boolean;
  }>`
    position: ${(props) => (props.isCardSizeDouble ? "absolute" : "relative")};
    bottom: 0;
    left: 0;
    display: ${(props) => (props.isCardSizeDouble ? "flex" : "block")};
    align-items: center;
    justify-content: space-between;
    height: ${(props) =>
      props.isCardSizeDouble || !props.isLayoutEditorialActivated
        ? "44px"
        : "149px"};
    width: ${(props) => (props.isCardSizeDouble ? "680px" : "328px")};
    margin-top: 0;
    padding: ${(props) =>
      props.isCardSizeDouble ? "20px 12px 20px" : "16px 12px 12px"};
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: ${etWhite};
    @media (max-width: ${mediumScreenLowerLimit}) {
      display: block;
      height: ${(props) =>
        props.isLayoutEditorialActivated ? "148px" : "44px"};
      max-height: ${(props) =>
        props.isLayoutEditorialActivated ? "148px" : "44px"};
      width: 100%;
    }
  `;

  export const DeckNameContainer = styled.div<{ borderColor: string }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    margin-bottom: 15px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.borderColor};
  `;
  export const CardFooterText = styled.div`
    color: ${etNevadaGrey};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    word-break: break-word;
  `;
  export const CardFooterTextContainer = styled.div<{
    isCardSizeDouble: boolean;
    isLayoutEditorialActivated: boolean;
  }>`
    position: relative;
    display: ${(props) =>
      props.isCardSizeDouble || !props.isLayoutEditorialActivated
        ? "none"
        : "block"};
    width: 100%;
    height: 44px;
    margin: 7px 0 0;
    @media (max-width: ${smallScreenUpperLimit}) {
      display: ${(props) =>
        props.isLayoutEditorialActivated ? "block" : "none"};
    }
  `;

  export const CuratorContainer = styled.div`
    position: relative;
    width: 100%;
    height: 14px;
  `;
  export const Curator = styled.div`
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: 0;
    height: 14px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etNevadaGrey};
    top: 0;
    left: 0;
    z-index: 13;
    > a {
      color: ${etNevadaGrey};
      text-decoration: none;
    }
  `;

  export const CuratorName = styled.a`
    display: inline-block;
    text-transform: uppercase;
    color: ${etNevadaGrey};
    text-decoration: none;
    font-weight: bold;
    max-width: 182px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: -3px;
    :hover {
      text-decoration: none;
      color: ${etNevadaGrey};
    }
  `;
  export const Date = styled.span``;

  export const CardActions = styled.div`
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: ${etWhite};
    &:hover svg {
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
    }
  `;

  export const CardActionsContainer = styled.div`
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: ${zIndex.ACTION_CONTAINER};
  `;
}
