/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
import { observer } from "mobx-react-lite";
import React from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import scUpdate from "./CardSimpleContext_Update.styled";
import { ContextMenuCardOrderInput } from "./ContextMenuCardOrderInput";

interface IProps {
  sortNumberInputError: string | null;
  sortNumber: string;
  onChanged(order: string): void;
  onBlured(): void;
}

export const ContextMenuCardOrder = observer(function (props: IProps) {
  const { t } = useTranslation("ContextMenu");

  return (
    <scUpdate.ContextMenuSort data-cy="ContextMenuItem-SortNumber">
      <scUpdate.ContextMenuSortLabel
        data-cy="ContextMenuItem-SortNumberLabel"
        isInvalid={!!props.sortNumberInputError}
      >
        {t("context-menu.card-menu.sort-number")}
      </scUpdate.ContextMenuSortLabel>
      <ContextMenuCardOrderInput {...props} />
    </scUpdate.ContextMenuSort>
  );
});
