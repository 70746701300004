import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import { DATE_FORMAT_MMMM_DD_YYYY } from "../../shared/constants";
import { CardSize, MasterLinkType } from "../../shared/models/Card";
import { LayoutDriver } from "../CardItemArticle/CardItemArticle";
import { HydratedDateWrapper } from "../HydratedDateWrapper/HydratedDateWrapper";
import { sc as scUpdate } from "./CardItemFooter.styled";
import { TruncateWithEllipsis } from "./TruncateWithEllipsis";

export type CardItemFooterDriver = {
  readonly id: string;
  readonly cardType: MasterLinkType;
  readonly deckName: string;
  readonly comment: string;
  readonly vanityName: string;
  readonly curatorName: string;
  readonly updatedDate: string;
  readonly deckPathString: string;
  readonly isCardLayoutEditorial?: boolean;
};

interface IProps {
  driver: CardItemFooterDriver;
  layoutDriver: LayoutDriver;
  renderCardSimpleAction(): ReactNode;
}

export const CardItemFooter = observer(function CardItemFooter(props: IProps) {
  const { driver, layoutDriver } = props;

  return (
    <scUpdate.Container
      className="card-footer"
      isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
      isLayoutEditorialActivated={driver.isCardLayoutEditorial || false}
    >
      <scUpdate.CuratorContainer>
        <scUpdate.Curator data-cy="CardItemFooter-CuratorInfo">
          <Link
            href="/[vanityName]/[deckName]/[cardId]"
            as={`/${driver.vanityName}/${driver.deckName}/${driver.id}`}
            passHref
            legacyBehavior
          >
            <>
              By&nbsp;
              <Link
                href="/[vanityName]"
                as={`/${driver.vanityName}`}
                passHref
                legacyBehavior
              >
                <scUpdate.CuratorName data-cy="CardItemFooter-CuratorName">
                  {driver.curatorName}
                </scUpdate.CuratorName>
              </Link>
              <scUpdate.Date>
                &nbsp;-&nbsp;
                <HydratedDateWrapper>
                  {format(
                    new Date(driver.updatedDate || 0),
                    DATE_FORMAT_MMMM_DD_YYYY,
                  )}
                </HydratedDateWrapper>
              </scUpdate.Date>
            </>
          </Link>
        </scUpdate.Curator>
      </scUpdate.CuratorContainer>
      <scUpdate.CardFooterTextContainer
        data-cy="CardItemFooter-Comment"
        isCardSizeDouble={layoutDriver.cardSize === CardSize.DOUBLE}
        isLayoutEditorialActivated={driver.isCardLayoutEditorial || false}
      >
        <scUpdate.CardFooterText
          data-cy="CardItemFooter-CardFooterText"
          title={driver.comment}
        >
          <TruncateWithEllipsis characterLimit={80} text={driver.comment} />
        </scUpdate.CardFooterText>
      </scUpdate.CardFooterTextContainer>
      {props.renderCardSimpleAction()}
    </scUpdate.Container>
  );
});
