import styled from "styled-components";

import { smallScreenUpperLimit } from "../../shared/breakpoints";

namespace sc {
  export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    height: 100%;
    width: 100%;

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: calc(100vh - 64px - 458px);
    }
  `;
}

export default sc;
