import styled, { css } from "styled-components";

import promotionCardTopoLines from "../../assets/img/earthLines/promotionCardTopoLines.png";
import promotionUonCardTopoLines from "../../assets/img/earthLines/promotionUonCardTopoLines.png";
import { etBlack, etBrightLightBlue, etWhite } from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import {
  PROMOTION_IMAGE_ROUND_SHAPE_HEIGHT,
  PROMOTION_IMAGE_ROUND_SHAPE_WIDTH,
  PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT,
  PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH,
} from "../../stores/CardPromotionModel/CardPromotionBaseModel";

export namespace sc {
  const pTag = styled.div`
    padding: 0;
    margin: 0;
  `;
  export const Container = styled.div<{ zIndex: number }>`
    position: relative;
    width: 328px;
    max-width: 328px;
    height: 496px;
    max-height: 496px;
    border-radius: 8px;
    z-index: ${(props) => props.zIndex};
  `;
  export const CardContainer = styled.div`
    position: relative;
    width: 328px;
    max-width: 328px;
    height: 496px;
    max-height: 496px;
    border-radius: 8px;
    padding: 0;
    border: 12px solid ${etWhite};
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  `;
  export const ContextMenu = styled.div`
    position: absolute;
    top: -3px;
    right: 5px;
    z-index: 3;
  `;
  export const ContextMenuContainer = styled.div`
    position: absolute;
    top: 30px;
    right: 16px;
    z-index: 18;
  `;
  export const ContextMenuOverlay = styled.div<{ zIndex: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${(props) => props.zIndex};
  `;
  export const CardOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 4;
    background-color: ${transparentize(etBlack, 0.3)};
  `;
  export const BackgroundImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
  `;
  export const BackgroundImage = styled.div<{ bgImage: string }>`
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(8px);
    transform: scale(1.1); // to remove blurry white border
  `;
  export const EarthLines = styled.div<{ isUonTransactionCard: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: ${(props) =>
      props.isUonTransactionCard
        ? css`url(${promotionUonCardTopoLines})`
        : css`url(${promotionCardTopoLines})`};
    background-size: 100% 100%;
  `;

  export const TopBlock = styled.div<{ bgImage?: string }>`
    position: relative;
    padding: 12px;
    width: ${PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH}px;
    height: ${PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT}px;
    z-index: ${(props) => (props.bgImage ? 1 : 2)};

    ${(props) =>
      props.bgImage &&
      css`
        background-image: url(${props.bgImage});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `}
  `;
  export const SquareMetterContainer = styled.div`
    position: relative;
    width: 280px;
    max-width: 280px;
    height: 280px;
    max-height: 280px;
  `;
  export const UnitOfNatureBlock = styled.div`
    position: absolute;
    top: 19px;
    left: 16px;
    z-index: 4;
    display: flex;
    flex-direction: row;
  `;
  export const UonNumber = styled(pTag)`
    font-family: Roboto Condensed;
    font-size: 60px;
    color: ${etWhite};
    height: 43px;
    line-height: 43px;
    margin-top: 2px;
  `;
  export const UonPromotionImage = styled.div<{ bgImage: string }>`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: ${PROMOTION_IMAGE_ROUND_SHAPE_WIDTH}px;
    height: ${PROMOTION_IMAGE_ROUND_SHAPE_HEIGHT}px;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 3;
  `;
  export const Series = styled(pTag)`
    position: absolute;
    bottom: 22px;
    right: 42px;
    font-family: Roboto Condensed;
    font-size: 10px;
    color: ${etWhite};
    font-weight: normal;
    line-height: 10px;
    text-align: right;
    text-transform: uppercase;
    z-index: 3;
  `;
  export const IconWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    right: 16px;
    width: 18px;
    height: 18px;
  `;
  export const TopRightText = styled(pTag)`
    position: absolute;
    bottom: 46px;
    right: 19px;
    margin: 0;
    font-family: Roboto Condensed;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0;
    text-align: right;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-transform: uppercase;
    color: ${etWhite};
  `;

  export const BottomBlock = styled.div`
    position: relative;
    width: 304px;
    height: 168px;
    color: ${etWhite};
    z-index: 3;
  `;
  export const BottomContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: Roboto Condensed;
    padding: 0 12px 12px 12px;
  `;
  export const Collect = styled(pTag)`
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
    line-height: 22px;
  `;
  export const Protect = styled(pTag)`
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    margin: 2px 0;
  `;
  export const CounterWrapper = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    height: 30px;
    max-height: 30px;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
  `;
  export const ProtectedBy = styled(pTag)`
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 20px;
  `;
  export const ButtonContainer = styled.div`
    position: absolute;
    bottom: 12px;
    left: 0;
    height: 32px;
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;
  const ButtonBase = styled.button`
    height: 32px;
    width: 132px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: ${etWhite};
  `;
  export const LearnMoreBtn = styled(ButtonBase)`
    border: 1px solid ${etWhite};
    background-color: transparent;

    &:hover,
    &.hover {
      background-color: ${etWhite};
      color: ${etBlack};
    }
  `;
  export const CollectBtn = styled(ButtonBase)`
    border: none;
    background-color: ${etBrightLightBlue};

    &:hover,
    &.hover {
      background-color: ${etWhite};
      color: ${etBrightLightBlue};
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px 0px,
        rgba(0, 0, 0, 0.1) 0px 2px 3px 0px;
    }

    &:disabled {
      background-color: ${etBrightLightBlue};
      color: ${etWhite};
      opacity: 0.5;
    }
  `;
}
