import styled from "styled-components";

import {
  largeScreenLowerLimit,
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import { etGray, etGrayDark, etWhite } from "../../shared/colors";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const Container = styled.ul`
    flex-grow: 0;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }

    ${IconSvgContainer} {
      margin-left: 0;
    }
  `;

  export const ItemLogoProtect = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${etGray};
    height: 30px;
    width: 30px;
    line-height: 1.625rem;
    margin-right: 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    @media (max-width: ${smallScreenUpperLimit}) {
      margin-right: 0;
    }
  `;
  export const ItemLogoPlay = styled(ItemLogoProtect)`
    height: 32px;
    width: 32px;
    line-height: 1.85rem;
    border-radius: 50%;
    margin-right: 8px;
    padding: 3px 0 0 0;
  `;
  export const Item = styled.a`
    /* width: 126px; */
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;
    color: ${etGray};
    margin-right: 30px;
    height: 100%;
    background-color: ${etWhite};
    cursor: pointer;
    &:not([href]) {
      color: ${etGray};
    }
    @media (min-width: ${largeScreenLowerLimit}) and (max-width: ${largeScreenUpperLimit}) {
      margin-right: 22px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-right: 8px;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      flex-direction: column;
      justify-content: center;
      margin-right: 0;
    }
    :hover {
      text-decoration: none;
      color: ${etGrayDark};
      > ${ItemLogoProtect} {
        background-color: ${etGrayDark};
        border-color: ${etGrayDark};
        color: ${etWhite};
      }
      ${IconSvgContainer} {
        fill: ${etWhite};
      }
    }
  `;

  export const NavbarLinkItemContainer = styled.div`
    padding-left: 0;
    @media (min-width: ${largeScreenLowerLimit}) {
      display: flex;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc((328px - 76px) / 2);
      margin-top: 0;
      padding-left: 0;
      background-color: ${etWhite};
    }
  `;

  export const NavbarLinkItemProtectContainer = styled.div<{
    loggedIn: boolean;
  }>`
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: ${(props) => (props.loggedIn ? "none" : "flex")};
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      display: ${(props) => (props.loggedIn ? "none" : "flex")};
      justify-content: center;
      align-items: center;
      width: calc((328px - 76px) / 2);
      background-color: ${etWhite};
    }
  `;
}

export default sc;
