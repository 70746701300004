import { observer } from "mobx-react-lite";

import { StatefulModals } from "../StatefulModals/StatefulModals";
import { TheFooter, TheFooterProps } from "../TheFooter/TheFooter";
import { TheNavbarBottom } from "../TheNavbarBottom/TheNavbarBottom";

export const PageLayoutBottom = observer(function PageLayoutBottom(
  props: TheFooterProps,
) {
  const { driverMenu, driver } = props;
  return (
    <>
      <TheNavbarBottom />
      <TheFooter driverMenu={driverMenu} driver={driver} />
      <StatefulModals />
    </>
  );
});
