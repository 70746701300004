/* eslint-disable consistent-return */
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { useEffect } from "react";

import { ACCESS_TOKEN_KEY } from "../../shared/constants";
import { ETLocalStorage } from "../../shared/helpers/EtStorages";
import { isBrowser } from "../../shared/helpers/isBrowser";
import useWindowSize from "../../shared/helpers/useWindowSize";
import { AuthUserModel } from "../../stores/AuthUserModel";
import { LazyModal } from "../../stores/ModalStore";
import { TheNavbarLinkItemDriver } from "../TheNavbarLinkItems/TheNavbarLinkItems";
import sc from "./TheNavbarTop.styled";

export interface TheNavbarTopDriver {
  readonly shouldRenderCenteredSearchBar: boolean;
  readonly shouldRenderCreateCard: boolean;
  readonly user: AuthUserModel | null;
  mLandscape?: boolean; // to customize snapshot in visual test
  openLazyModal(modal?: LazyModal): void;
  goBack(): void;
  updateScrollYPosition(pos: number): void;
  turnMenuOff(): void;
  subscribe: (accessToken: string) => void;
  unsubscribe: () => void;
  updateWindowWidth: (width: number) => void;
}

export interface DeviceDriver {
  readonly isWebPSupported: boolean;
}

export interface RouterDriver {
  asPath: string;
}

interface IProps {
  readonly driverTheNavbarTop: TheNavbarTopDriver;
  readonly driverTheNavbarLinkItem: TheNavbarLinkItemDriver;
  readonly deviceDriver: DeviceDriver;
  readonly routerDriver: RouterDriver;

  renderSearch: () => React.ReactNode;
  renderCenteredSearch: () => React.ReactNode;
  renderControllers: () => React.ReactNode;
  renderPlayButtons: () => React.ReactNode;
  renderButtonCreateCard: () => React.ReactNode;
  renderBentoButton: () => React.ReactNode;
}

export const TheNavbarTop = observer(function TheNavbarTop(props: IProps) {
  const {
    shouldRenderCenteredSearchBar,
    shouldRenderCreateCard,
    user,
    mLandscape,
    updateScrollYPosition,
    turnMenuOff,
    subscribe,
    unsubscribe,
    updateWindowWidth,
  } = props.driverTheNavbarTop;
  const { openModal } = props.driverTheNavbarLinkItem;
  const { width } = useWindowSize();

  useEffect(() => {
    (async () => {
      if (isBrowser()) {
        const img = new Image();
        img.src = require("../../assets/svgs/et-com-logo.svg");
        window.preloadedPictures = [img];
      }
    })();
  }, []);

  useEffect(() => {
    updateWindowWidth(width);
  }, [width]);

  useEffect(() => {
    if (isBrowser() && props.routerDriver.asPath.includes("policies")) {
      return;
    }

    if (user && !user.termsAccepted) {
      openModal("acceptTermAndPolicy");
    }
  }, [user]);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const accessToken = ETLocalStorage.getItem(ACCESS_TOKEN_KEY) || "";

    subscribe(accessToken);

    return () => {
      unsubscribe();
    };
  }, [!!user]);

  return (
    <>
      <sc.NavbarTopContainer
        className={[
          "shadow-sm visual-reg",
          mLandscape ? "mLandscape" : "",
        ].join(" ")}
        data-cy="NavbarTop"
      >
        <sc.Navbar className="navbar flex-nowrap navbar-expand-lg navbar-light bg-transparent">
          <sc.NavbarLeft
            className="navbar-brand mr-0"
            shouldRenderCenteredSearchBar={shouldRenderCenteredSearchBar}
          >
            <Link href="/" legacyBehavior passHref>
              <sc.Logo
                data-cy="TheNavbarLogo"
                onClick={() => {
                  updateScrollYPosition(0);
                  turnMenuOff();
                }}
                className={mLandscape ? "mLandscape" : ""}
              />
            </Link>
          </sc.NavbarLeft>
          {shouldRenderCenteredSearchBar
            ? props.renderCenteredSearch()
            : props.renderPlayButtons()}
          <sc.TheNavbarRightSideContainer>
            {!shouldRenderCenteredSearchBar && props.renderSearch()}
            {shouldRenderCreateCard &&
              !shouldRenderCenteredSearchBar &&
              props.renderButtonCreateCard()}
            {props.renderControllers()}
            {props.renderBentoButton()}
          </sc.TheNavbarRightSideContainer>
        </sc.Navbar>
      </sc.NavbarTopContainer>
    </>
  );
});
