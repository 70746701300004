import styled from "styled-components";

import {
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  xLargeScreenLowerLimit,
} from "../../../shared/breakpoints";
import { etBlack, etSolitudeLight, etWhite } from "../../../shared/colors";

export namespace sc {
  export const Container = styled.div`
    position: relative;
    height: 910px;
    max-height: 910px;
    width: 100%;
    max-width: 100%;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      height: 800px;
      max-height: 800px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      height: 640px;
      max-height: 640px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      height: 720px;
      max-height: 720px;
    }
  `;

  export const WrapContent = styled.div<{ isShowAdditionalText: boolean }>`
    position: absolute;

    left: 0;
    right: 0;
    z-index: 2;

    width: 100%;
    max-width: 100%;

    max-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Roboto;
    color: ${etWhite};

    ${(props) =>
      props.isShowAdditionalText
        ? `
      height: 100%;
      bottom: 0;
      top: 0;
    `
        : `
      height: unset;
      bottom: unset;
      top: 230px;
    `}
    @media (max-width: ${largeScreenLowerLimit}) {
      ${(props) =>
        props.isShowAdditionalText
          ? `
      height: 100%;
      bottom: 0;
      top: 0;
    `
          : `
      height: unset;
      bottom: unset;
      top: 69px;
    `}
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      justify-content: flex-start;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      padding-top: 50px;

      height: 100%;
      bottom: 0;
      top: 0;
    }
  `;
  export const Title = styled.h3`
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 10px;

    > span > span {
      font-weight: bold;
    }

    @media (max-width: ${xLargeScreenLowerLimit}) {
      font-size: 40px;
      line-height: 45px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      font-size: 25px;
      line-height: 30px;
    }
  `;
  export const SubTitle = styled.p`
    font-stretch: condensed;
    font-size: 24px;
    font-weight: normal;
    height: 26px;
    letter-spacing: 0px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;

    margin-bottom: 28px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      font-size: 20px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      font-size: 18px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      font-size: 16px;
    }
  `;
  export const Content = styled.p`
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 31px;

    width: 914px;
    max-width: 914px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      width: 720px;
      max-width: 720px;
      font-size: 20px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      font-size: 18px;
      width: 518px;
      max-width: 518px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      br {
        display: none;
      }
      line-height: 28px;
      width: 328px;
      max-width: 328px;
      height: 168px;
      margin-bottom: 22px;
    }
  `;

  export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    background-color: ${etBlack};
    opacity: 0.5;
  `;

  export const HowProtect = styled.a<{ hide?: boolean }>`
    display: ${(props) => (props.hide ? "block" : "none")};
    background-color: transparent;
    border-radius: 20px;
    border: 1px solid ${etWhite};
    min-height: 40px;
    max-height: 40px;
    min-width: 189px;
    max-width: 189px;

    color: ${etWhite};
    font-stretch: condensed;
    font-size: 16px;
    font-weight: normal;
    height: 19px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    line-height: 38px;

    :hover {
      text-decoration: none;
      color: ${etWhite};
      cursor: pointer;
    }
  `;

  export const Additional = styled.p`
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 0;

    width: 914px;
    max-width: 914px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      width: 720px;
      max-width: 720px;
      font-size: 20px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      font-size: 18px;
      width: 518px;
      max-width: 518px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      br {
        display: none;
      }
      line-height: 28px;
      width: 328px;
      max-width: 328px;
      height: 168px;
    }
  `;

  export const Background = styled.div`
    background-color: ${etSolitudeLight};
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    div > div > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      overflow: hidden;
    }
    div > div > div > iframe {
      width: 100vw;
      height: 56.25vw;
      min-height: 100vh;
      min-width: 177.77vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: ${xLargeScreenLowerLimit}) {
        min-height: 125vh;
        min-width: 222.2125vh;
      }

      @media (max-width: ${largeScreenLowerLimit}) {
        min-height: unset;
      }
    }
  `;
}
