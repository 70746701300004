import { observer } from "mobx-react-lite";
import Router from "next/router";

import { etBrightLightBlue, etGray } from "../../shared/colors";
import { UserType } from "../../shared/models/User";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./GroupCardMenuContext.styled";

interface IMenuContext {
  userType: string;
  switchToken(redirect?: () => void): void;
}

interface IProps {
  driver: IMenuContext;
}

export const GroupCardMenuContext = observer((props: IProps) => {
  const { t } = useTranslation("Group");

  return (
    <sc.ContextMenuRow
      data-cy="ContextMenuRow"
      hoverColor={etBrightLightBlue}
      onClick={() =>
        props.driver.switchToken(() => {
          Router.push("/settings", undefined, {
            shallow: true,
          });
        })
      }
    >
      <IconSvg
        width="20px"
        height="20px"
        padding="0"
        icon={
          props.driver.userType === UserType.GROUP_ADAPTER
            ? "report-card"
            : "edit-card"
        }
        color={etGray}
      />
      <sc.ContextMenuItemBlue>
        <sc.MenuItemText>
          {props.driver.userType === UserType.GROUP_ADAPTER
            ? `${t("groups.context-menu.profile-setting")}`
            : `${t("groups.context-menu.edit-profile")}`}
        </sc.MenuItemText>
      </sc.ContextMenuItemBlue>
    </sc.ContextMenuRow>
  );
});
