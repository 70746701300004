import { useEffect, useMemo, useState } from "react";

import { isBrowser } from "./helpers/isBrowser";

export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      isBrowser()
        ? new IntersectionObserver(([entry]) =>
            setIsIntersecting(entry.isIntersecting),
          )
        : null,
    [],
  );

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
