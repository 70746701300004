/* eslint-disable import/no-default-export */
import styled, { css } from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
  xmediumScreenLowerLimit,
} from "../../../shared/breakpoints";
import { etBlack, etBrightLightBlue, etWhite } from "../../../shared/colors";
import { sc as scLeaderboard } from "../../Leaderboard/Leaderboard.styled";

export namespace sc {
  export const Section = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  `;
  export const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 700px;
    background-position-x: 60%;
    background-position-y: 40%;
    background-repeat: no-repeat;
    background-size: 140%;
    ${(props) =>
      props.theme.isWebpSupported
        ? `background-image: url(${require("../../../assets/img/protect-section-one.webp")});`
        : `background-image: url(${require("../../../assets/img/protect-section-one.png")});`}

    @media (max-width: ${largeScreenUpperLimit}) {
      background-position-x: 40%;
      background-position-y: 25%;
      ${(props) =>
        props.theme.isWebpSupported
          ? `background-image: url(${require("../../../assets/img/protect-section-one-tablet.webp")});`
          : `background-image: url(${require("../../../assets/img/protect-section-one-tablet.png")});`}
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      justify-content: flex-end;

      background-size: 107% 100%;
      background-position-x: 100%;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      padding: 0;
      height: 1219px;
      background-position: center 17%;
      background-size: cover;
      flex-direction: column;
      align-items: center;
      ${(props) =>
        props.theme.isWebpSupported
          ? `background-image: url(${require("../../../assets/img/protect-section-one-mobile.webp")});`
          : `background-image: url(${require("../../../assets/img/protect-section-one-mobile.png")});`}
    }
  `;

  const Col = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
  `;
  export const ColLeft = styled(Col)`
    position: relative;
    flex: 0 0 65%;
    align-items: center;
    justify-content: center;
    margin-top: 285px;

    @media (max-width: ${largeScreenUpperLimit}) {
      margin-top: 318px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      flex: 1;
      margin-left: 48px;
      margin-top: 225px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex: 0;
      margin-top: 355px;
      margin-left: 0;
      width: 100%;

      position: absolute;
      top: -18px;
    }
  `;
  export const ColRight = styled(Col)`
    flex: 0 0 33%;
    justify-content: center;
    @media (max-width: ${mediumScreenUpperLimit}) {
      flex: 0 0 40%;
      margin-left: 24px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex: unset;
      justify-content: center;
      align-items: center;
      padding: 100px 0;
      margin-left: 0;
    }
    @media (max-width: ${mobileDeviceWidth}) {
      padding: 128px 0;
    }
  `;
  export const Leaderboard = styled.div`
    background: ${etWhite};
    border-radius: 8px;
    box-shadow: 0px 16px 24px 0px rgba(42, 54, 66, 0.2);
    height: 496px;
    width: 328px;

    ${scLeaderboard.ItemContent} {
      grid-gap: 12px;
      margin: 24px 0 10px 0;
    }
  `;
  export const CountsWrapper = styled.div`
    display: flex;
    overflow: hidden;
    padding-left: 8px;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
      height: 52px;
      justify-content: center;
      padding-left: 4px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-bottom: 20px;
      height: 51px;
      padding-left: 2px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      height: 28px;
      margin-top: -30px;
      margin-bottom: 0px;
    }
  `;
  export const WrapperM2 = styled.div<{ isLast?: boolean }>`
    border: 4.5px solid ${etWhite};
    height: 75px;
    width: 75px;

    margin-left: -4.5px;
    padding-top: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
    }

    ${(props) =>
      props.isLast &&
      css`
        span {
          animation: move linear 1.5s 1;
        }
      `}

    @keyframes move {
      from {
        transform: translateY(50%);
      }
      to {
        transform: translateY(0%);
      }
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      width: 51px;
      height: 51px;
    }
  `;
  export const Buttons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    justify-content: center;
    @media (max-width: ${mobileDeviceWidth}) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      rows-gap: 12px;
      columns-gap: 0px;
    }
  `;
  export const LabelProtect = styled.label`
    height: 40px;
    color: ${etWhite};
    font-size: 20px;
    font-weight: normal;
    font-stretch: condensed;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    margin: 16px 0 19px 0;
  `;
  export const LastNameProtector = styled(LabelProtect)`
    line-height: 45px;
    margin: 0;
    transform: translateY(-30px);

    @media (max-width: ${xmediumScreenLowerLimit}) {
      transform: translateY(0px);
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      transform: translateY(-60px);
    }
  `;
  export const EarthFlag = styled.a`
    position: absolute;
    top: -50%;
    left: 49%;
    width: 142px;
    max-width: 142px;
    height: 86px;
    max-height: 86px;
    background-color: transparent;
    opacity: 0.5;
    transform: translateY(50%);

    @media (max-width: ${xLargeScreenLowerLimit}) {
      transform: translate(24px, 26px);
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      top: -19%;
      left: 44%;
      width: 86px;
      max-width: 86px;
      height: 55px;
      max-height: 55px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      top: -119%;
      left: 47%;
      width: 134px;
      max-width: 134px;
      height: 85px;
      max-height: 85px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      top: -116%;
      left: 43%;
      width: 97px;
      max-width: 97px;
      height: 60px;
      max-height: 60px;
    }
  `;
  export const ButtonSectionOne = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: none;
    height: 40px;
    max-height: 40px;
    width: 195px;
    max-width: 195px;

    color: ${etWhite};
    font-family: Roboto;
    font-stretch: condensed;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;

    letter-spacing: 0px;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
      color: ${etWhite};
    }
  `;
  export const FinancialStatementButton = styled(ButtonSectionOne)`
    background-color: transparent;
    border: 1px solid ${etWhite};
  `;
  export const StartProtectButton = styled(ButtonSectionOne)`
    cursor: pointer;
    background-color: ${etBrightLightBlue};
  `;
  export const TabContainer = styled.div`
    position: absolute;
    top: 26px;
    left: 120px;
    display: grid;
    grid-template-columns: repeat(6, auto);
    column-gap: 12px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      left: 40px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      grid-template-columns: repeat(6, auto);
      column-gap: 6px;
      left: 12px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(2, auto);
      gap: 6px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);
    }
  `;

  export const Tab = styled.a<{
    width: string;
    height: string;
  }>`
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${etWhite};
    min-height: ${(props) => props.height};
    max-height: ${(props) => props.height};
    min-width: ${(props) => props.width};
    max-width: ${(props) => props.width};

    color: ${etWhite};
    font-family: Roboto;
    font-stretch: condensed;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    text-align: center;
    :hover {
      text-decoration: none;
      color: ${etWhite};
      background-color: ${etBlack};
      border: 1px solid ${etBlack};
    }

    @media (max-width: ${mobileDeviceWidth}) {
      :nth-child(2) {
        margin-left: -55px;
      }
      :nth-child(6) {
        margin-left: -6px;
      }
    }
  `;
}
