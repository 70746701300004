import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import sc from "./CardRemovedContent.styled";

export type CardRemovedContentDriver = {
  readonly adminOrOwner: boolean;
  readonly contextMenuActive: boolean;
};
interface IProps {
  driver: CardRemovedContentDriver;
  renderMenuContext(): ReactNode;
}

export const CardRemovedContent = observer((props: IProps) => {
  return (
    <sc.Container className="visual-reg" data-cy="CardRemovedContent-Container">
      {props.driver.contextMenuActive && <sc.Overlay />}
      <sc.Body>
        {props.driver.adminOrOwner && (
          <sc.ContextWrapper>{props.renderMenuContext()}</sc.ContextWrapper>
        )}

        <sc.BackgroundFrame />
        <sc.Content>
          <sc.TextHead data-cy="CardRemovedContent-TextHead">
            NO LONGER AVAILABLE
          </sc.TextHead>
          <sc.Text ta-cy="CardRemovedContent-Text">
            This Card was closed by its curator
          </sc.Text>
        </sc.Content>
      </sc.Body>
    </sc.Container>
  );
});
