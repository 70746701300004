import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { ETReactProps } from "../../../typings/types";
import sc from "./Button.styled";
import { DangerButton } from "./DangerButton";
import { LightButton } from "./LightButton";
import { LighterButton } from "./LighterButton";
import { OutLineDangerButton } from "./OutLineDangerButton";
import { OutlineLightButton } from "./OutlineLightButton";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";
import { SuccessButton } from "./SuccessButton";
import { TransparentButton } from "./TransparentButton";

export type ButtonType =
  | "primary"
  | "secondary"
  | "success"
  | "outline-danger"
  | "danger"
  | "transparent"
  | "outline-light"
  | "light"
  | "lighter";

export type FontWeight =
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | "normal"
  | "bold"
  | "bolder"
  | "lighter";

export type FontStretch =
  | "ultra-condensed"
  | "extra-condensed"
  | "condensed"
  | "semi-condensed"
  | "normal"
  | "semi-expanded"
  | "expanded"
  | "extra-expanded"
  | "ultra-expanded";

export type TextTransform = "capitalize" | "uppercase" | "lowercase" | "none";
export type ButtonAction = "submit" | "reset" | "button";
export interface ButtonDriver {
  readonly action?: ButtonAction;
  readonly type: ButtonType;
  readonly dataCy: string;
  readonly icon?: string;
  readonly round?: boolean;
  readonly height?: string;
  readonly width?: string;
  readonly color?: string;
  readonly bgColor?: string;
  readonly mobileHeight?: string;
  readonly mobileWidth?: string;
  readonly mobileFontsize?: string;
  readonly fontSize?: string;
  readonly textTransform?: TextTransform;
  readonly disabled?: boolean;
  readonly fontWeight?: FontWeight;
  readonly fontStretch?: FontStretch;
  readonly fontFamily?: string;
  readonly margin?: string;
  readonly padding?: string;
  readonly mobileVisible?: boolean;
  readonly borderColor?: string;
  readonly dataTrackId?: string;
  readonly bgOnHovered?: string;
  readonly hover?: boolean;
  readonly cursor?: "default" | "pointer";
  onClick?(params?: any): void;
}

interface IProps extends ETReactProps {
  driver: ButtonDriver;
}

export const Button = observer((props: IProps) => {
  const {
    action = "submit",
    dataCy,
    width = "100%",
    height = "40px",
    color,
    mobileHeight = height,
    mobileWidth = width,
    round = false,
    fontSize = "16px",
    mobileFontsize = fontSize,
    textTransform = "capitalize",
    disabled = false,
    fontWeight = 500,
    fontStretch = "normal",
    fontFamily = "Roboto Condensed",
    margin = "0",
    padding = "0",
    mobileVisible = true,
    borderColor = "",
    bgColor = "",
    dataTrackId,
    bgOnHovered,
    hover,
    cursor,
    onClick = () => {},
  } = props.driver;

  const renderButton = (children: ReactNode): ReactNode => {
    const buttonDriver = {
      action,
      dataCy,
      round,
      height,
      width,
      color,
      mobileHeight,
      mobileWidth,
      mobileFontsize,
      fontSize,
      textTransform,
      disabled,
      fontWeight,
      fontStretch,
      fontFamily,
      margin,
      padding,
      mobileVisible,
      borderColor,
      bgColor,
      dataTrackId,
      bgOnHovered,
      hover,
      cursor,
      onClick,
    };

    switch (props.driver.type) {
      case "primary": {
        return <PrimaryButton driver={buttonDriver}>{children}</PrimaryButton>;
      }
      case "secondary": {
        return (
          <SecondaryButton driver={buttonDriver}>{children}</SecondaryButton>
        );
      }
      case "success": {
        return <SuccessButton driver={buttonDriver}>{children}</SuccessButton>;
      }
      case "outline-danger": {
        return (
          <OutLineDangerButton driver={buttonDriver}>
            {children}
          </OutLineDangerButton>
        );
      }
      case "danger": {
        return <DangerButton driver={buttonDriver}>{children}</DangerButton>;
      }
      case "transparent": {
        return (
          <TransparentButton driver={buttonDriver}>
            {children}
          </TransparentButton>
        );
      }
      case "outline-light": {
        return (
          <OutlineLightButton driver={buttonDriver}>
            {children}
          </OutlineLightButton>
        );
      }
      case "light": {
        return <LightButton driver={buttonDriver}>{children}</LightButton>;
      }
      case "lighter": {
        return <LighterButton driver={buttonDriver}>{children}</LighterButton>;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <sc.Container margin={margin} className="visual-reg">
      {renderButton(<>{props.children}</>)}
    </sc.Container>
  );
});
