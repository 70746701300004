import styled from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import { etShadow, etWhite } from "../../shared/colors";

namespace sc {
  export const GoalNumber = styled.div`
    font-family: Roboto Condensed;
    font-weight: 900;
    font-size: 112px;
    line-height: 1;
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 80px;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 40px;
      padding-bottom: 8px;
    }
  `;

  export const SharingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 17;
  `;

  export const Body = styled.div`
    font-family: Roboto Condensed;
    width: 100%;
    padding: 24px 50px 24px 24px;
    z-index: 2;
  `;
  export const Title = styled.h1`
    font-family: Roboto Condensed Bold;
    line-height: 69px;
    font-size: 60px;
    margin-bottom: 0;
    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 42px;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 21px;
    }
  `;
  export const Description = styled.div`
    font-family: Roboto;
    display: block;
    width: unset;
    @media (min-width: ${largeScreenUpperLimit}) {
      width: 779px;
    }
  `;
  export const DescriptionText = styled.span`
    width: 640px;
    display: inline-block;
    font-family: Roboto;
    position: relative;
    padding-top: 16px;
    font-size: 20px;
    max-height: 104px;
    line-height: 30px;
    word-break: break-word;
    @media (max-width: ${smallScreenUpperLimit}) {
      max-height: 79px;
      width: 100%;
      line-height: 21px;
      font-size: 14px;
    }
  `;

  export const Detail = styled.div`
    font-family: Roboto Condensed;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 18px;
    width: unset;
    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 16px;
      height: unset;
    }
    @media (min-width: ${largeScreenUpperLimit}) {
      width: 668px;
    }
  `;
  export const Curator = styled.a`
    font-family: Roboto Condensed Bold;
    color: inherit;
    :hover {
      color: inherit;
    }
  `;
  export const ActionsContent = styled.div`
    font-family: Roboto Condensed;
    width: 100%;
    padding: 24px;
    z-index: 2;
  `;

  export const OverLay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 1;

    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.36) 36%,
      #000000 95%
    );
    opacity: 0.4;
  `;

  export const Container = styled.div`
    font-family: Roboto Condensed;
    background-color: transparent;
    position: relative;
    grid-column: 3 span;
    border-radius: 8px;
    color: ${etWhite};
    overflow: hidden;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    height: 100%;
    width: 100%;

    @media (max-width: ${largeScreenUpperLimit}) {
      grid-column: 2 span;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      grid-column: 1 span;
    }
    &.open {
      height: 1016px;
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
      @media (max-width: ${mediumScreenUpperLimit}) {
        ${OverLay} {
          background: linear-gradient(
            to top,
            transparent,
            rgba(0, 0, 0, 0.36) 36%,
            ${etShadow}
          );
        }
        height: min-content;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      @media (max-width: ${smallScreenUpperLimit}) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  `;
  export const Content = styled.div`
    font-family: Roboto Condensed;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
  `;

  export const LIP = styled.div`
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: ${etWhite};
    position: absolute;
    top: 50%;
    right: -94px;
    transform: translateY(-50%);
    z-index: 2;
  `;

  export const HeaderCounts = styled.div`
    @media (min-width: ${mediumScreenLowerLimit}) {
      display: inline-block;
    }
  `;

  export const Dash = styled.span`
    display: none;
    @media (min-width: ${mediumScreenLowerLimit}) {
      display: inline-block;
    }
  `;

  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #000000;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 3;
  `;
}

export default sc;
