import { observer } from "mobx-react-lite";

import {
  etBlack,
  etGreen,
  etOrange,
  etScarletRed,
  etWhite,
  etYellow,
} from "../../../shared/colors";
import { utmSourceValue } from "../../../shared/env";
import { useTranslation } from "../../../shared/translate/NextI18next";
import { DangerouslyHTMLRenderer } from "../../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import {
  MoreAboutCard,
  MoreAboutCardDriver,
  MoreAboutCardTitle,
} from "./MoreAboutCard";
import { sc } from "./SectionMoreAbout.styled";

export const SectionMoreAbout = observer(() => {
  const { t } = useTranslation("Protect");
  const moreAboutItems: MoreAboutCardDriver[] = [
    {
      title:
        (t(
          "protect.section-more-about.item.about.title",
        ) as MoreAboutCardTitle) || MoreAboutCardTitle.ABOUT,
      subTitle:
        t("protect.section-more-about.item.about.sub-title") || "EARTHTODAY",
      description:
        t("protect.section-more-about.item.about.desc") ||
        "A for-purpose tech company founded to accelerate nature protection and increase awareness on the state of the planet.",
      learnMoreUrl: `https://about.earthtoday.com/?${utmSourceValue}`,
      color: etBlack,
      logo: require("../../../assets/img/m2-black.png"),
      bgColor: etYellow,
    },
    {
      title:
        (t(
          "protect.section-more-about.item.nature-reserves.title",
        ) as MoreAboutCardTitle) || MoreAboutCardTitle.NATURE_RESERVES,
      subTitle:
        t("protect.section-more-about.item.nature-reserves.sub-title") ||
        "PROTECT NATURE",
      description:
        t("protect.section-more-about.item.nature-reserves.desc") ||
        "Check out the nature reserves our community supports. They are protected with the help of local communities.",
      learnMoreUrl: `https://naturereserves.earthtoday.com/?${utmSourceValue}`,
      color: etBlack,
      logo: require("../../../assets/img/m2-black.png"),
      bgColor: etGreen,
    },
    {
      title:
        (t(
          "protect.section-more-about.item.brands.title",
        ) as MoreAboutCardTitle) || MoreAboutCardTitle.BRANDS,
      subTitle:
        t("protect.section-more-about.item.brands.sub-title") ||
        "JOIN EARTHTODAY",
      description:
        t("protect.section-more-about.item.brands.desc") ||
        "Connect protected m² of nature to your products and services as a purpose-driven reward in your marketing or loyalty programs.",
      learnMoreUrl: `https://brands.earthtoday.com/?${utmSourceValue}`,
      color: etWhite,
      logo: require("../../../assets/img/m2-white.png"),
      bgColor: etScarletRed,
    },
    {
      title:
        (t(
          "protect.section-more-about.item.curation.title",
        ) as MoreAboutCardTitle) || MoreAboutCardTitle.CURATION,
      subTitle:
        t("protect.section-more-about.item.curation.sub-title") ||
        "INCREASE AWARENESS",
      description:
        t("protect.section-more-about.item.curation.desc") ||
        "Find out more about discovering and sharing content to inform, inspire, educate, and entertain commutity members.",
      learnMoreUrl: `https://curation.earthtoday.com/?${utmSourceValue}`,
      color: etBlack,
      logo: require("../../../assets/img/m2-black.png"),
      bgColor: etOrange,
    },
  ];

  return (
    <sc.Container className="visual-reg">
      <sc.TitleWrapper>
        <sc.SectionTitle data-cy="SectionMoreAbout-SectionTitle">
          <DangerouslyHTMLRenderer>
            {t("protect.section-more-about.title")}
          </DangerouslyHTMLRenderer>
        </sc.SectionTitle>
        <sc.SectionSubTitle data-cy="SectionMoreAbout-SectionSubTitle">
          {t("protect.section-more-about.sub-title")}
        </sc.SectionSubTitle>
      </sc.TitleWrapper>
      <sc.CustomGrid>
        {moreAboutItems.map((item) => {
          return <MoreAboutCard driver={item} key={item.title} />;
        })}
      </sc.CustomGrid>
    </sc.Container>
  );
});
