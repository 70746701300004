import { observer } from "mobx-react-lite";
import Link from "next/link";

import FlagPoleIcon from "../../../assets/img/flagpole-2.png";
import { urlCardTheEarthFlag, utmSourceValue } from "../../../shared/env";
import { protectPageSectionOneNumberAnimation } from "../../../shared/helpers/protectPageSectionOneNumberAnimation";
import { useTranslation } from "../../../shared/translate/NextI18next";
import { Currency } from "../../../stores/DonateStore/DonateStore";
import { CounterFlipEffect } from "../../CounterFlipEffect/CounterFlipEffect";
import { DangerouslyHTMLRenderer } from "../../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { LazyImage } from "../../LazyImage/LazyImage";
import { Leaderboard } from "../../Leaderboard/Leaderboard";
import { TheLoader } from "../../TheLoader/TheLoader";
import { M2Icon } from "../../UonCountNumberAnimation/M2Icon";
import { UonCountNumberLocation } from "../../UonCountNumberAnimation/StylesUonCountNumberAnimation";
import { UonCountNumberAnimation } from "../../UonCountNumberAnimation/UonCountNumberAnimation";
import { IPropsSectionOneBodyV202208 } from "./SectionOneBodyV202208";
import { sc } from "./SectionOneBodyWithEnableOneFlow.styled";

export const SectionOneBodyWithEnableOneFlow = observer(
  function SectionOneBodyWithEnableOneFlow(props: IPropsSectionOneBodyV202208) {
    const { t } = useTranslation("Protect");
    const { t: tEtHeaderCard } = useTranslation("EtHeaderCard");

    return (
      <sc.Container>
        <sc.SpaceBackground enableOneFlow={true} />
        <sc.GlobeBackground />
        <sc.GradientOverlay />
        <sc.ColLeft data-cy="SectionOneBodyWithEnableOneFlow-ColLeft">
          <sc.TopIcon>
            <sc.EarthFlag
              href={urlCardTheEarthFlag}
              data-cy="SectionOneBodyWithEnableOneFlow-EarthFlag"
            />

            <sc.FlagPoleIcon data-cy="SectionOneBodyWithEnableOneFlow-Flag">
              <LazyImage
                src={FlagPoleIcon}
                width="100%"
                maxWidth="100%"
                height="100%"
                maxHeight="100%"
                alt="flag pole image"
                position="absolute"
                top="-100%"
                left="20%"
              />
            </sc.FlagPoleIcon>
            <sc.SquareMeterIcon data-cy="SectionOneBodyWithEnableOneFlow-SquareMeter" />
          </sc.TopIcon>

          <sc.Title data-cy="SectionOneBodyWithEnableOneFlow-Title">
            <sc.Text>Subscribe²</sc.Text>
            <sc.AnimationTextWrapper
              noAnimationCounter={!!props.noAnimationCounter}
            >
              <sc.TextAnimate>
                <DangerouslyHTMLRenderer>
                  {tEtHeaderCard("etheadercard.nature")}
                </DangerouslyHTMLRenderer>
              </sc.TextAnimate>
              <sc.TextAnimate>
                <DangerouslyHTMLRenderer>
                  {tEtHeaderCard("etheadercard.bio")}
                </DangerouslyHTMLRenderer>
              </sc.TextAnimate>
              <sc.TextAnimate>
                <DangerouslyHTMLRenderer>
                  {tEtHeaderCard("etheadercard.clean")}
                </DangerouslyHTMLRenderer>
              </sc.TextAnimate>
              <sc.TextAnimate>
                <DangerouslyHTMLRenderer>
                  {tEtHeaderCard("etheadercard.fresh")}
                </DangerouslyHTMLRenderer>
              </sc.TextAnimate>
            </sc.AnimationTextWrapper>
          </sc.Title>

          {!props.stateDriver.loading && (
            <CounterFlipEffect
              protectCount={props.driver.protectCount}
              renderChildren={(counter: number) => {
                return (
                  <sc.CountsWrapper data-cy="SectionOneBodyWithEnableOneFlow-CountsWrapper">
                    {props.driver.protectCount === null ? (
                      <TheLoader className="visual-reg-remove" />
                    ) : (
                      <>
                        {protectPageSectionOneNumberAnimation(counter).map(
                          (count, i) => (
                            <UonCountNumberAnimation
                              dataCy={
                                "SectionOneBodyWithEnableOneFlow-CountNumber"
                              }
                              enableOneFlow={true}
                              noAnimationCounter={props.noAnimationCounter}
                              flags={props.flags}
                              countNumbers={count.countNumbers}
                              lastNumber={count.lastNumber}
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${count.lastNumber}${i}`}
                              location={UonCountNumberLocation.NewProtectPage}
                            />
                          ),
                        )}
                      </>
                    )}
                    <M2Icon
                      location={UonCountNumberLocation.NewProtectPage}
                      enableOneFlow={true}
                    />
                  </sc.CountsWrapper>
                );
              }}
              flags={props.flags}
            />
          )}

          <sc.LabelProtect data-cy="SectionOneBodyWithEnableOneFlow-LabelProtect">
            {props.driver.currency === Currency.USD
              ? tEtHeaderCard("etheadercard.oneflow.description.usd-title")
              : tEtHeaderCard("etheadercard.oneflow.description.euro-title")}
          </sc.LabelProtect>

          <sc.SubDescription>
            {props.driver.currency === Currency.USD
              ? tEtHeaderCard("etheadercard.oneflow.sub-description.usd-title")
              : tEtHeaderCard(
                  "etheadercard.oneflow.sub-description.euro-title",
                )}
          </sc.SubDescription>

          <sc.Buttons>
            {props.flags.enableOneFlow && !props.driver.isGroupUnpublished ? (
              <sc.StartProtectButton
                data-cy="SectionOneBodyWithEnableOneFlow-StartProtecting"
                data-track-id="protectPage_fold-section1_btn-startProtect"
                onClick={() => {
                  props.driver.openSubscribeOneFlowModal(
                    "SectionOneBodyWithEnableOneFlow-StartProtecting",
                  );
                }}
              >
                {t("protect.section-one-v202208.start-protecting")}
              </sc.StartProtectButton>
            ) : (
              <Link passHref href="#sectionTwo" scroll legacyBehavior>
                <sc.StartProtectButton data-cy="SectionOneBodyWithEnableOneFlow-StartProtecting">
                  {t("protect.section-one-v202208.start-protecting")}
                </sc.StartProtectButton>
              </Link>
            )}

            <sc.FinancialStatementButton
              data-cy="SectionOneBodyWithEnableOneFlow-LearnMoreButton"
              data-track-id="protectPage_fold-section1_btn-learnMore"
              href={`https://gifting.earthtoday.com/?${utmSourceValue}`}
              target="_blank"
            >
              <>{t("protect.section-one-v202208.learn-more-btn")}</>
            </sc.FinancialStatementButton>
          </sc.Buttons>
        </sc.ColLeft>
        <sc.ColRight
          id="leaderBoard"
          data-cy="SectionOneBodyWithEnableOneFlow-ColRight"
        >
          <sc.Leaderboard>
            <Leaderboard
              driver={props.leaderboard}
              stateDriver={props.stateDriver}
            />
          </sc.Leaderboard>
        </sc.ColRight>
      </sc.Container>
    );
  },
);
