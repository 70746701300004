import styled from "styled-components";

import { createSpinner } from "./createSpinner";

export namespace sc {
  export const Container = styled.div<{
    bgColor?: string;
    borderRadius?: number;
  }>`
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.bgColor || "transparent"};
    border-radius: ${(props) => props.borderRadius + `px` || 0};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `;
  export const Spinner = styled.div<{
    isDisableAnimation: boolean;
    position: { top: string; left: string };
    spinnerColor: string;
    spinnerSize: number;
    marginBottom: number;
    stickNumber: number;
    duration: number;
  }>`
    top: ${(props) => props.position.top};
    left: ${(props) => props.position.left};
    font-family: Roboto;
    display: inline-flex;
    position: relative;
    width: ${(props) => `${props.spinnerSize}px`};
    height: ${(props) => `${props.spinnerSize}px`};
    margin-bottom: ${(props) => `${props.marginBottom}px`};

    & > div {
      transform-origin: ${(props) =>
        `${props.spinnerSize / 2 + 1}px ${props.spinnerSize / 2 + 1}px`};
      animation: ${(props) =>
        props.isDisableAnimation
          ? "none"
          : `et-spinner ${props.duration}s linear infinite`};

      ${(props) => createSpinner(props.stickNumber, props.duration)}
    }

    & > div:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: ${(props) => `${props.spinnerSize / 2}px`};
      width: 2.5px;
      height: 5px;
      border-radius: 10%;
      background: ${(props) => props.spinnerColor};
    }

    @keyframes et-spinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  `;

  export const WrapperText = styled.div``;
  export const Text = styled.p<{
    textColor: string;
    fontSize: number;
  }>`
    text-align: center;
    color: ${(props) => props.textColor};
    font-size: ${(props) => `${props.fontSize}px`};
    font-weight: bold;
    height: 1.17em;
    letter-spacing: 0px;
    margin-bottom: 6px;
  `;
  export const Subtext = styled.p<{
    textColor: string;
    fontSize: number;
  }>`
    text-align: center;
    color: ${(props) => props.textColor};
    font-stretch: condensed;
    font-size: ${(props) => `${props.fontSize}px`};
    font-weight: normal;
    height: 1.17em;
    letter-spacing: 0px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 0px;
  `;
}
