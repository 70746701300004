import styled from "styled-components";

import { etBlack, etVoteRed, etWhite } from "../../shared/colors";

export namespace sc {
  export const Container = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    gap: 8px;
    max-width: 100vh;
    padding-bottom: 60px;
  `;

  export const ItemTextContainer = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.color || etBlack};
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
  `;

  export const ItemTitle = styled.span`
    font-family: Roboto;
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
  `;

  export const ItemSubtile = styled.span`
    font-family: Roboto Condensed Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    height: 14px;
    letter-spacing: 0px;
  `;
  export const Context = styled.div`
    background: ${etBlack};
    border-radius: 20px;
    height: 4px;
    width: 4px;
  `;

  export const ContextContainer = styled.div`
    height: 15px;
    width: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 12px;
    top: 50%;
    gap: 2px;

    transform: translateY(-50%);
  `;

  export const ContextText = styled.span`
    color: ${etVoteRed};
    font-stretch: normal;

    line-height: 16px;
    letter-spacing: 0;

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
    padding-left: 12px;
  `;

  export const ItemContainer = styled.div<{
    backgroundColor: string;
    backgroundImage?: string;
  }>`
    background-color: ${(props) => props.backgroundColor};
    border-radius: 8px;
    box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.15);
    height: 64px;
    width: 328px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;

    position: relative;

    ${(props) =>
      props.backgroundImage &&
      `
      background: url(${props.backgroundImage}) center center no-repeat;
      background-size: cover;
      ${ItemTitle} {
        color: ${etWhite};
      }
      ${Context} {
        background-color: ${etWhite};
      }
    `}
  `;

  export const ItemStaticContainer = styled(ItemContainer)<{
    borderColor: string;
    textColor: string;
  }>`
    border: 1px solid ${(props) => props.borderColor};
    height: 65px;
    width: 328px;

    ${Context} {
      background-color: ${(props) => props.borderColor};
    }

    ${ItemTitle} {
      color: ${(props) => props.textColor};
    }

    ${(props) =>
      props.backgroundImage &&
      `
        background: url(${props.backgroundImage}) center center no-repeat;
        background-size: cover;
        ${ItemTitle} {
          color: ${etWhite} !important;
        }
        ${Context} {
          background-color: ${etWhite};
        }
      `}
    > a {
      width: 100%;
    }
  `;

  export const ItemContent = styled.div`
    display: flex;
    align-items: center;
    gap: 11.5px;

    width: 100%;
  `;

  export const ItemImage = styled.div<{ bg: string; circle?: boolean }>`
    background: url(${(props) => props.bg}) center center no-repeat;
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;

    border-radius: ${(props) => (props.circle ? "50%" : 0)};
    background-size: cover;
  `;
}
