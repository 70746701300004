import styled from "styled-components";

import {
  etBlueLight,
  etBrightLightBlue,
  etDark,
  etGray,
  etWhite,
} from "../../shared/colors";

namespace sc {
  export const Header = styled.div`
    position: relative;
    padding: 0;
  `;
  export const HeaderTitle = styled.h2`
    font-family: Roboto;
    font-size: 20px;
    color: ${etDark};
    font-weight: 500;
    margin-bottom: 7px;
    height: 24px;
  `;
  export const HeaderDescription = styled.div`
    font-family: Roboto Regular;

    font-size: 12px;
    color: ${etGray};
  `;
  export const ReportLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    margin: 9px 0px;
    font-weight: 500;
    color: ${etWhite};
    background-color: ${etBrightLightBlue};

    &:hover {
      text-decoration: none;
      color: ${etWhite};
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;
  export const Body = styled.div`
    padding-top: 36px;
    font-family: Roboto;
    font-size: 14px;
  `;
  export const Paragraph = styled.p`
    margin: 0;
  `;
  export const Contact = styled.div`
    margin-top: 14px;
  `;
}

export default sc;
