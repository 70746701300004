import { observer } from "mobx-react-lite";
import React from "react";

import sc from "./TheNavbarBottom.styled";

export const TheNavbarBottom = observer(function TheNavbarBottom(props: {
  mLandscape?: boolean;
}) {
  return (
    <>
      <sc.Container className="visual-reg" mLandscape={props.mLandscape}>
        <sc.NavbarTopContainer className="shadow-sm">
          <sc.Navbar className="navbar flex-nowrap navbar-light bg-transparent" />
        </sc.NavbarTopContainer>
        <sc.CircleCenter className={props.mLandscape ? "mLandscape" : ""} />
      </sc.Container>
    </>
  );
});
