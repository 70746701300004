import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
} from "../../shared/breakpoints";
import {
  etGray,
  etGrayDark,
  etRegentGray,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";

namespace sc {
  export const Profile = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  `;

  export const Text = styled.div<{ isCurator?: boolean; isVerified?: boolean }>`
    display: flex;
    justify-content: center;
    margin-top: 3px;
    color: ${etGray};
    font-family: "Roboto Condensed";
    font-size: 10px;
    font-weight: normal;
    height: 20px;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    width: 60px;
    text-decoration: none;
    ${(props) => props.isVerified === false && `opacity: 0.5; cursor: unset;`}
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.menuTextColor};
    }
    // mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;

  export const Circle = styled.div<{
    isVerified?: boolean;
    mLandscape?: boolean;
  }>`
    display: flex;
    width: 60px;
    height: 60px;
    background-color: ${etWhite};
    border-radius: 50%;
    ${(props) => props.isVerified === false && `opacity: 0.5; cursor: unset;`}
    > div > svg path {
      fill: ${etGray};
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      > div > svg path {
        fill: ${(props) => props.theme.themeStyle.menuTextColor};
      }

      background-color: ${(props) =>
        props.theme.isMobileDevice
          ? transparentize(etRegentGray, 0.25)
          : etWhite};

      &.menu-following {
        > div > svg > g > g {
          stroke: ${(props) =>
            props.theme.isMobileDevice ? etSolitudeLight : etGrayDark};
        }
      }
    }

    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      &.menu-following {
        > div > svg > g > g {
          stroke: ${etGrayDark};
        }
      }
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      > div > svg path {
        fill: ${etSolitudeLight};
      }
      &.menu-following {
        > div > svg > g > g {
          stroke: ${etSolitudeLight};
        }
      }
      background-color: ${transparentize(etRegentGray, 0.25)};
    }
    ${(props) =>
      props.mLandscape &&
      `> div > svg path {
      fill: ${etSolitudeLight};
    };
    &.menu-following {
        > div > svg > g > g {
          stroke: ${etSolitudeLight};
        }
      }`}
    background-color: ${(props) =>
      props.mLandscape ? transparentize(etRegentGray, 0.25) : etWhite};
  `;

  export const CircleProtect = styled(Circle)<{
    bgColor: string;
    iconColor: string;
  }>`
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}),
      (orientation: landscape) and (hover: none) and (pointer: coarse) {
      > div > svg path {
        fill: ${(props) => props.iconColor};
      }
      background-color: ${(props) => transparentize(props.bgColor, 0.25)};
    }
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      > div > svg path {
        fill: ${(props) => props.iconColor};
      }
      background-color: ${(props) => transparentize(props.bgColor, 0.25)};
    }
  `;

  export const CircleCreateCard = styled(Circle)<{
    isCurator: boolean;
    bgColor: string;
    iconColor: string;
  }>`
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      > div > svg path {
        fill: ${(props) =>
          props.isCurator && props.theme.isMobileDevice
            ? etWhite
            : props.iconColor};
      }
      background-color: ${(props) =>
        props.theme.isMobileDevice
          ? props.isCurator
            ? props.bgColor
            : transparentize(props.bgColor, 0.25)
          : transparentize(props.bgColor, 0.25)};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      > div > svg path {
        fill: ${(props) => (props.isCurator ? etWhite : props.iconColor)};
      }
      background-color: ${(props) =>
        props.isCurator ? props.bgColor : transparentize(props.bgColor, 0.25)};
    }
    &.mLandscape {
      > div > svg path {
        fill: ${(props) => (props.isCurator ? etWhite : props.iconColor)};
      }
      background-color: ${(props) =>
        props.isCurator ? props.bgColor : transparentize(props.bgColor, 0.25)};
    }

    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      > div > svg path {
        fill: ${(props) =>
          props.isCurator
            ? etWhite
            : props.iconColor || props.theme.themeStyle.menuTextColor};
      }
      background-color: ${(props) =>
        props.isCurator ? props.bgColor : transparentize(props.bgColor, 0.25)};
    }
  `;

  export const CirclePlay = styled.div<{
    bgColor?: string;
    iconColor: string;
    isLoggedOut?: boolean;
  }>`
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${(props) => props.bgColor || etWhite};
  `;

  export const Option = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    height: 83px;
    text-decoration: none;
    :hover {
      text-decoration: none;
      cursor: pointer;
    }
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.defaultLayoutBackground};
    }
  `;

  export const Row = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
  `;

  export const RowSingle = styled(Row)`
    justify-content: center;
  `;

  export const Container = styled.div`
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
    width: 328px;
    min-height: 496px;
    margin-top: 24px;
    padding: 56.5px 38.5px;
    border-radius: 8px;
    @media screen and (min-width: ${largeScreenLowerLimit}) {
      display: none;
    }
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.defaultLayoutBackground};
    }
  `;
}
export default sc;
