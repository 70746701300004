import { observer } from "mobx-react-lite";
import React from "react";

import { pluralize } from "../../shared/helpers/pluralize";
import { useTranslation } from "../../shared/translate/NextI18next";
import { DeckSize } from "../../stores/CardDeckOverviewModel";
import sc from "./CardDeckOverviewContent.styled";

export type CardDeckOverviewContentDriver = {
  readonly enableUpdateDeckCardsDesign: boolean;
  readonly following: boolean;
  readonly deckStatistic: {
    cardsCount: number;
    followersCount?: number;
  };
  readonly renderSize: DeckSize;
  readonly formatedDeckName: string;
  readonly description: string;

  isShareAndFollowBtnVisiable: boolean;
  onToggleFollowing(following: boolean): void;
  toggleShareActive(): void;
};

interface IProps {
  driver: CardDeckOverviewContentDriver;
}

export const CardDeckOverviewContent = observer(
  function CardDeckOverviewContent(props: IProps) {
    const { driver } = props;

    const { t: tGeneral } = useTranslation("General");
    const { t: tDeckGeneral } = useTranslation("DeckGeneral");

    return driver.enableUpdateDeckCardsDesign ? (
      <>
        <sc.DeckNameNewDesign
          data-cy="CardDeckOverviewContent-DeckNameNewDesign"
          className="text-white"
          title={driver.formatedDeckName}
          deckSize={props.driver.renderSize}
        >
          {driver.formatedDeckName}
        </sc.DeckNameNewDesign>
        <sc.DeckInfoNewDesign
          data-cy="CardDeckOverviewContent-DeckInfo"
          className="text-white"
          deckSize={props.driver.renderSize}
        >
          {pluralize("Card", driver.deckStatistic.cardsCount, tDeckGeneral)}
          {driver.deckStatistic.followersCount !== undefined &&
            " - " +
              pluralize(
                "Follower",
                driver.deckStatistic.followersCount,
                tDeckGeneral,
              )}
        </sc.DeckInfoNewDesign>
        <sc.DescriptionNewDesign
          deckSize={props.driver.renderSize}
          data-cy="CardDeckOverview-DescriptionNewDesign"
          centered={true}
        >
          {driver.description}
        </sc.DescriptionNewDesign>
        {driver.isShareAndFollowBtnVisiable && (
          <sc.ButtonsWrapper>
            <sc.BtnNewDesign
              deckSize={props.driver.renderSize}
              data-cy="CardDeckOverviewContent-FollowBtn"
              onClick={() => driver.onToggleFollowing(!driver.following)}
            >
              {driver.following
                ? tGeneral("general.unfollow")
                : tGeneral("general.follow")}
            </sc.BtnNewDesign>
            <sc.BtnNewDesign
              deckSize={props.driver.renderSize}
              data-cy="CardDeckOverviewContent-ShareBtn"
              onClick={() => driver.toggleShareActive()}
            >
              {tGeneral("general.share")}
            </sc.BtnNewDesign>
          </sc.ButtonsWrapper>
        )}
      </>
    ) : (
      <>
        <sc.DeckName
          data-cy="CardDeckOverviewContent-DeckName"
          className="text-white"
          title={driver.formatedDeckName}
          deckSize={props.driver.renderSize}
        >
          {driver.formatedDeckName}
        </sc.DeckName>
        <sc.DeckInfo
          data-cy="CardDeckOverviewContent-DeckInfo"
          className="text-white"
          deckSize={props.driver.renderSize}
        >
          {pluralize("Card", driver.deckStatistic.cardsCount, tDeckGeneral)}
          {driver.deckStatistic.followersCount !== undefined &&
            " - " +
              pluralize(
                "Follower",
                driver.deckStatistic.followersCount,
                tDeckGeneral,
              )}
        </sc.DeckInfo>
        {driver.isShareAndFollowBtnVisiable && (
          <sc.FollowButton
            deckSize={props.driver.renderSize}
            data-cy="CardDeckOverviewContent-FollowBtn"
            onClick={() => driver.onToggleFollowing(!driver.following)}
          >
            {driver.following
              ? tGeneral("general.unfollow")
              : tGeneral("general.follow")}
          </sc.FollowButton>
        )}
        <sc.DescriptionWrap
          deckSize={props.driver.renderSize}
          data-cy="CardDeckOverview-DescriptionWrap"
          centered={false}
        >
          {driver.description}
        </sc.DescriptionWrap>
      </>
    );
  },
);
