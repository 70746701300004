/* eslint-disable jsx-a11y/no-autofocus */
import { SearchResponseDto } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import React from "react";
import { DebounceInput } from "react-debounce-input";

import {
  etBlue,
  etBrightLightBlue,
  etGray,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import { isVisualSanityTestRunning } from "../../shared/env";
import { IconSvg } from "../IconSvg/IconSvg";
import { TheNavbarSearchResult } from "../TheNavbarSearchResult/TheNavbarSearchResult";
import { SearchResultTab } from "../TheNavbarTop/TheNavbarStore/TheNavbarStore";
import sc from "./TheNavbarSearch.styled";

export interface TheNavbarSearchDriver {
  readonly searchInputFocus: boolean;
  readonly searchInputValue: string;
  readonly searching: boolean;
  readonly searchResultsForCurrentTab: SearchResponseDto[];
  readonly searchResultFocus: boolean;
  readonly currentResultTabs: SearchResultTab;
  readonly userLoggedIn: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test

  searchResultFocusUpdate(b: boolean): void;
  searchInputFocusUpdate(b: boolean): void;
  onSearchInputChange(s: string): void;
  currentResultTabsUpdate(tab: SearchResultTab): void;
  turnMenuOff(): void;
  searchInputValueUpdate(s: string): void;
}

export const TheNavbarSearch = observer(function TheNavbarSearch(props: {
  driver: TheNavbarSearchDriver;
}) {
  const renderSearchReserve = () => {
    if (!props.driver.searchInputFocus) {
      return null;
    }

    return (
      <sc.SearchReserve
        userLoggedIn={props.driver.userLoggedIn}
        data-cy="TheNavbarSearch-searchReserve"
        display="flex"
        className={[
          "visual-reg",
          props.driver.mLandscape ? "mLandscape" : "",
        ].join(" ")}
      >
        <sc.FormGroup
          backgroundColor={
            props.driver.searchInputFocus ? etWhite : etSolitudeLight
          }
          borderColor={props.driver.searchInputFocus ? etBlue : "transparent"}
          loggedIn={props.driver.userLoggedIn}
          className={props.driver.mLandscape ? "mLandscape" : ""}
        >
          <IconSvg
            icon="search"
            width="18px"
            height="18px"
            padding="0"
            margin="0"
            color={etGray}
            pointer
          />
          <DebounceInput
            className="d-md-block d-lg-block"
            data-cy="TheNavbar-searchbox"
            value={props.driver.searchInputValue}
            debounceTimeout={300}
            onFocus={() => props.driver.searchInputFocusUpdate(true)}
            onChange={(e) => props.driver.onSearchInputChange(e.target.value)}
            autoFocus={!isVisualSanityTestRunning}
          />
        </sc.FormGroup>
        <TheNavbarSearchResult
          data-cy="TheNavbar-searchResult"
          driver={props.driver}
        />
      </sc.SearchReserve>
    );
  };

  return (
    <>
      {props.driver.searchInputFocus ? (
        <sc.SearchContainerOverlay
          onClick={() =>
            props.driver.searchInputFocusUpdate(!props.driver.searchInputFocus)
          }
          data-cy="TheNavbarSearch-SearchContainerOverlay"
        />
      ) : null}

      <sc.SearchContainer data-cy="TheNavbarSearch-SearchContainer">
        <sc.SearchSubmit
          searchInputFocus={props.driver.searchInputFocus}
          mLandscape={props.driver.mLandscape}
          title="Search"
          data-cy="TheNavbar-searchBtn"
        >
          <IconSvg
            icon="search"
            width="18px"
            height="18px"
            padding="0"
            color={props.driver.searchInputFocus ? etBrightLightBlue : etGray}
            pointer
            onClick={() => {
              props.driver.searchInputFocusUpdate(
                !props.driver.searchInputFocus,
              );
            }}
          />
        </sc.SearchSubmit>

        {renderSearchReserve()}
      </sc.SearchContainer>
    </>
  );
});
