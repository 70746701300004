import { observer } from "mobx-react-lite";
import React from "react";

import { ModalDeleteButtons } from "../ModalDeleteButtons/ModalDeleteButtons";
import sc from "./ModalDelete.styled";

export interface ModalDeleteCardDriver {
  onClose(): void;
  onDelete(): void;
}

interface Props {
  readonly title: string;
  readonly description: string;
  readonly deleteBtnText: string;
  driver: ModalDeleteCardDriver;
}

export const ModalDelete = observer((props: Props) => {
  return (
    <>
      <sc.Title>{props.title}</sc.Title>
      <sc.Paragraph data-cy="ModalDelete-Description">
        {props.description}
      </sc.Paragraph>
      <sc.Row>
        <ModalDeleteButtons
          deleteBtnText={props.deleteBtnText}
          onClose={props.driver.onClose}
          onDelete={props.driver.onDelete}
        />
      </sc.Row>
    </>
  );
});
