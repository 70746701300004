import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { ModalType } from "../../stores/ModalStore";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./LayoutAbout.styled";
import { SixthSection } from "./SixthSection";

export interface LayoutAboutDriver {
  isOverlayActive: boolean;
  openModal(t: ModalType): void;
  closeOverlay(): void;
}
interface IProps {
  driver: LayoutAboutDriver;
  renderContent: () => ReactNode;
}

export const LayoutAbout = observer((props: IProps) => {
  return props.driver.isOverlayActive ? (
    <sc.Layout data-cy="LayoutOverlay" className="visual-reg">
      <sc.Container data-cy="LayoutAbout-Container" className="container">
        <sc.CloseOverLay>
          <sc.CloseOverLayButton
            data-cy="LayoutAbout-CloseOverLayBtn"
            onClick={() => props.driver.closeOverlay()}
          >
            <IconSvg
              icon="close"
              height="16px"
              width="16px"
              pointer
              padding="0"
              color="red"
            />
          </sc.CloseOverLayButton>
        </sc.CloseOverLay>
        {props.renderContent()}
      </sc.Container>
      <SixthSection driver={props.driver} isOverlay />
    </sc.Layout>
  ) : null;
});
