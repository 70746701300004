/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { ReactNode } from "react";

import { etBlack, etWhite } from "../../shared/colors";
import { RequireAtLeastOne } from "../../shared/helpers/commonValidations";
import {
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import CardItemLayout, { LayoutDriver } from "../CardItemLayout/CardItemLayout";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardItemAction.styled";

export enum CardActionTypeOfAction {
  URL_INTERNAL = "URL_INTERNAL",
  URL_EXTERNAL = "URL_EXTERNAL",
  SYSTEM_COMMAND = "SYSTEM_COMMAND",
}
export enum CardActionCommandType {
  EDIT_HEADER_CARD = "EDIT_HEADER_CARD",
  OPEN_ONEFLOW_MODAL = "OPEN_ONEFLOW_MODAL",
}
export interface ActionCardUrlInternal {
  type: CardActionTypeOfAction.URL_INTERNAL;
  nextjsHrefPath: string;
  nextjsAsPath: string;
}
export interface ActionCardUrlExternal {
  type: CardActionTypeOfAction.URL_EXTERNAL;
  url: string;
}
interface ActionCardSystemCommand {
  type: CardActionTypeOfAction.SYSTEM_COMMAND;
  command: CardActionCommandType;
}
export type ActionResource =
  | ActionCardUrlInternal
  | ActionCardUrlExternal
  | ActionCardSystemCommand;

export interface CardItemActionProperties {
  contentType: CardType.ACTION;
  actionResource: ActionResource;

  curatorAvatarUrl: string;
  deckNameWithHashTag: string;
  deckPath: string;
  isContextMenuActive: boolean;
  onContextMenuEllipsisClicked(): void;
  onContextMenuFullscreenOverlayClicked(): void;

  title: string | undefined;
  subtitle: string | undefined;
  backgroundImage: string | undefined;
  textAlign: CardTextAlignment;
  textColor: CardTextColor;

  buttonText?: string;

  onCardLinkClicked(): void;
}

export type CardItemActionDriver = RequireAtLeastOne<
  CardItemActionProperties,
  "title" | "subtitle" | "backgroundImage"
>;

interface IProps {
  driver: CardItemActionDriver;
  layoutDriver: LayoutDriver;
  renderMenuContext(): ReactNode;
}

export const CardItemAction = observer(function CardItemAction(props: IProps) {
  const {
    curatorAvatarUrl,
    deckNameWithHashTag,
    deckPath,
    textAlign,
    backgroundImage,
    title,
    subtitle,
    actionResource,
    buttonText,
    textColor,
    isContextMenuActive,
    onContextMenuEllipsisClicked,
    onContextMenuFullscreenOverlayClicked,
  } = props.driver;
  const { cardSize: cardSize } = props.layoutDriver;

  const titleWidth =
    textAlign === CardTextAlignment.CENTER || cardSize === CardSize.SINGLE
      ? "100%"
      : cardSize === CardSize.DOUBLE
      ? "502px"
      : "654px";
  const titleFontSize =
    cardSize === CardSize.SINGLE
      ? "30px"
      : cardSize === CardSize.DOUBLE
      ? "40px"
      : "50px";
  const titleLineHeight =
    cardSize === CardSize.SINGLE
      ? "35px"
      : cardSize === CardSize.DOUBLE
      ? "45px"
      : "55px";
  const subtitleWidth =
    textAlign === CardTextAlignment.CENTER || cardSize === CardSize.SINGLE
      ? "100%"
      : cardSize === CardSize.DOUBLE
      ? "556px"
      : "779px";
  const subtitleFontSize =
    cardSize === CardSize.SINGLE
      ? "20px"
      : cardSize === CardSize.DOUBLE
      ? "24px"
      : "32px";
  const subtitleLineHeight =
    cardSize === CardSize.SINGLE
      ? "25px"
      : cardSize === CardSize.DOUBLE
      ? "29px"
      : "37px";

  let articlePadding;
  if (textAlign === CardTextAlignment.CENTER) {
    articlePadding =
      cardSize === CardSize.SINGLE
        ? "12px"
        : cardSize === CardSize.DOUBLE
        ? "21px"
        : "76px";
  } else if (textAlign === CardTextAlignment.LEFT) {
    articlePadding =
      cardSize === CardSize.SINGLE
        ? "12px"
        : cardSize === CardSize.DOUBLE
        ? "24px"
        : "48px";
  }

  const color = backgroundImage
    ? textColor === CardTextColor.BLACK
      ? etBlack
      : etWhite
    : etBlack;

  return (
    <CardItemLayout
      layoutDriver={props.layoutDriver}
      options={{ noBoxShadow: backgroundImage ? false : true }}
    >
      <sc.Container
        data-cy="CardItemAction-Container"
        cardSize={cardSize}
        color={color}
        bgImage={backgroundImage}
        align={textAlign}
      >
        {isContextMenuActive && (
          <>
            <sc.ContextMenuFullScreenLayer
              onClick={onContextMenuFullscreenOverlayClicked}
            />
            <sc.CardOverLay />
            <sc.ContextMenuWrapper>
              {props.renderMenuContext()}
            </sc.ContextMenuWrapper>
          </>
        )}
        {actionResource.type === CardActionTypeOfAction.SYSTEM_COMMAND ? (
          <sc.CardLink
            data-cy="CardItemAction-CardOverlay"
            onClick={() => alert("Add function later")}
          />
        ) : actionResource.type === CardActionTypeOfAction.URL_INTERNAL ? (
          <Link
            href="/[vanityName]/[deckName]/[cardId]"
            as={actionResource.nextjsAsPath}
            passHref
            legacyBehavior
          >
            <sc.CardLink data-cy="CardItemAction-CardOverlay" />
          </Link>
        ) : actionResource.type === CardActionTypeOfAction.URL_EXTERNAL ? (
          <Link
            href={actionResource.url}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              props.driver.onCardLinkClicked();
            }}
          >
            <sc.CardLink data-cy="CardItemAction-CardOverlay" />
          </Link>
        ) : (
          <></>
        )}
        <sc.CardHeader data-cy="CardItemAction-CardHeader">
          <sc.CardHeaderLeft>
            <sc.Logo src={curatorAvatarUrl} />
            <Link href={deckPath}>
              <sc.DeckName
                data-cy="CardItemAction-DeckName"
                color={color}
                cardSize={cardSize}
              >
                <span>{deckNameWithHashTag}</span>
              </sc.DeckName>
            </Link>
          </sc.CardHeaderLeft>
          <sc.CardHeaderRight
            data-cy="CardItemAction-OpenContextMenuButton"
            onClick={onContextMenuEllipsisClicked}
          >
            <IconSvg
              icon="more"
              pointer
              width="20px"
              height="20px"
              padding="0"
              color={color}
            />
          </sc.CardHeaderRight>
        </sc.CardHeader>
        <sc.Article
          textAlign={textAlign}
          padding={articlePadding}
          className="Article-Content"
        >
          <sc.TitleWrapper width={titleWidth}>
            <sc.ArticleTitle
              data-cy="CardItemAction-ArticleTitle"
              fontSize={titleFontSize}
              lineHeight={titleLineHeight}
            >
              {title}
            </sc.ArticleTitle>
          </sc.TitleWrapper>
          <sc.ArticleSubtitle
            data-cy="CardItemAction-ArticleSubtitle"
            width={subtitleWidth}
            fontSize={subtitleFontSize}
            lineHeight={subtitleLineHeight}
          >
            {subtitle}
          </sc.ArticleSubtitle>
        </sc.Article>
        {buttonText && (
          <sc.ButtonWrapper align={textAlign} className="Button-Wrapper">
            {actionResource.type === CardActionTypeOfAction.SYSTEM_COMMAND ? (
              <sc.Button
                data-cy="CardItemAction-Button"
                shouldHaveBorder={!backgroundImage}
                color={etBlack}
                cardSize={cardSize}
                onClick={() => alert("Add function later")}
              >
                <span>{buttonText}</span>
              </sc.Button>
            ) : actionResource.type === CardActionTypeOfAction.URL_INTERNAL ? (
              <Link
                href={actionResource.nextjsAsPath}
                onClick={(e) => {
                  e.preventDefault();
                  props.driver.onCardLinkClicked();
                }}
              >
                <sc.Button
                  data-cy="CardItemAction-Button"
                  shouldHaveBorder={!backgroundImage}
                  color={etBlack}
                  cardSize={cardSize}
                >
                  <span>{buttonText}</span>
                </sc.Button>
              </Link>
            ) : actionResource.type === CardActionTypeOfAction.URL_EXTERNAL ? (
              <Link
                href={actionResource.url}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  props.driver.onCardLinkClicked();
                }}
              >
                <sc.Button
                  data-cy="CardItemAction-Button"
                  shouldHaveBorder={!backgroundImage}
                  color={etBlack}
                  cardSize={cardSize}
                >
                  <span>{buttonText}</span>
                </sc.Button>
              </Link>
            ) : (
              <></>
            )}
          </sc.ButtonWrapper>
        )}
      </sc.Container>
    </CardItemLayout>
  );
});
