import styled, { css } from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBlue,
  etDark,
  etGrayBold,
  etRegentGray,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";

export namespace sc {
  export const Container = styled.div<{ isFixed: boolean }>`
    display: flex;
    width: 100%;
    margin: 0;
    padding: 19px 0;
    background-color: ${etSolitudeLight};
    z-index: ${zIndex.COOKIE_BANNER};

    ${(props) =>
      props.isFixed &&
      css`
        position: fixed;
        bottom: 0;
      `}

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
    }
    &.mLandscape {
      background-color: ${etBlack};
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      min-height: calc(176px + 85px + 1px);
      padding: 19px 0 26px 0;
      background-color: ${(props) => props.theme.themeStyle.defaultBackground};
    }
  `;
  export const CenterBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 0;
  `;
  export const Wrapper = styled.div`
    display: flex;
    @media (max-width: ${smallScreenUpperLimit}) {
      flex-direction: column;
    }
    @media (min-width: ${xLargeScreenLowerLimit}) {
      width: calc(328px * 4 + 24px * 3);
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      width: calc(328px * 3 + 24px * 2);
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      width: calc(328px * 2 + 24px);
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 328px;
    }
  `;
  export const CookiePolicy = styled.div`
    flex: 7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 985px;

    @media (max-width: ${largeScreenUpperLimit}) {
      max-width: 719px;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      max-width: 425px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      text-align: center;
    }
  `;
  export const Title = styled.h3`
    display: inline-block;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${etDark};
    margin: 0;
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
      text-align: center;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultLargeTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const Content = styled.p`
    display: inline-block;
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    color: ${etDark};

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const ContentLinkWrapper = styled.span`
    display: inline-block;
  `;
  export const ContentLink = styled.a`
    color: ${etDark};
    text-decoration: underline;
    display: initial;

    :hover {
      text-decoration: underline;
      color: ${etBlue};
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${etSolitudeLight};
    }
    &.mLandscape {
      color: ${etSolitudeLight};
    }
  `;
  export const Control = styled.div`
    flex: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: ${smallScreenUpperLimit}) {
      justify-content: flex-start;
      margin-top: 1rem;
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      justify-content: center;
    }
  `;
  export const Setting = styled.a`
    margin-left: 12px;

    border-radius: 20px;
    min-height: 40px;
    height: 40px;
    width: 110px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    font-stretch: condensed;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${etRegentGray};
    color: ${etRegentGray};
    background-color: ${etWhite};
    &:hover {
      text-decoration: none;
      color: ${etGrayBold};
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
      background-color: ${(props) => props.theme.themeStyle.defaultBackground};
    }
  `;
}
