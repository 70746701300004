import { observer } from "mobx-react-lite";
import React from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import sc from "./AlertMessage.styled";

export enum AlertType {
  Danger,
}

const alertTypeClass = {
  [AlertType.Danger]: "alert-danger",
};

type Props = {
  type?: AlertType;
  children: React.ReactNode;
};

export default observer((props: Props) => {
  const type = props.type === undefined ? AlertType.Danger : props.type;

  const { t } = useTranslation("ToastMessage");

  return (
    <sc.Container
      data-cy="AlertMessage-Content"
      role="alert"
      className={"alert " + alertTypeClass[type]}
    >
      {typeof props.children === "string" ? t(props.children) : props.children}
    </sc.Container>
  );
});
