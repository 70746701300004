import { observer } from "mobx-react-lite";
import React from "react";

import { etBrightLightBlue, etWhite } from "../../shared/colors";
import { CardItemMetaData } from "../CardItem/CardItem";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardItemMusicFooter.styled";

export interface CardItemMusicFooterActionDriver {
  onStarCard(): void;
  metadata: CardItemMetaData | null;
  cardRepostIdUpdate(): void;
  toggleStarActive(): void;
  toggleShareActive(): void;
  openContextMenu(): void;
  closeContextMenu(): void;
}

interface IProps {
  driver: CardItemMusicFooterActionDriver;
}
export const CardItemMusicFooterAction = observer(
  function CardItemMusicFooterAction(props: IProps) {
    const { driver } = props;

    return (
      <sc.CardActionsContainer>
        <sc.CardActionStar
          data-cy="CardItemMusicFooter-CardActionStar"
          onClick={() => {
            driver.onStarCard();
          }}
        >
          <IconSvg
            dataCy="CardItemMusicFooterAction-StarIcon"
            icon="carditem-star-v2"
            pointer
            padding="0"
            width="20px"
            height="20px"
            color={driver.metadata?.starred ? etBrightLightBlue : etWhite}
            hoverColor={etBrightLightBlue}
          />
        </sc.CardActionStar>
      </sc.CardActionsContainer>
    );
  },
);
