/* eslint-disable import/no-default-export */
import { observer } from "mobx-react-lite";
import React from "react";
import { scroller } from "react-scroll";

import { etWhite } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardDeckHeaderActions.styled";

export interface CardDeckHeaderActionsDriver {
  readonly shareActive: boolean;
  readonly following: boolean;
  readonly currentUser: boolean;

  onToggleFollowing(b: boolean): void;
  toggleShareActive(): void;
}

interface IProps {
  driver: CardDeckHeaderActionsDriver;
}

export default observer(function CardDeckHeaderActions(props: IProps) {
  const { t: tGeneral } = useTranslation("General");

  const { driver } = props;
  return (
    <sc.Container>
      {!driver.currentUser && (
        <sc.Item
          data-cy="CardDeckHeaderActions-Follow"
          onClick={() => {
            driver.onToggleFollowing(!driver.following);
          }}
        >
          <IconSvg
            icon="checkmark"
            width="30px"
            height="30px"
            padding="0"
            color={etWhite}
            pointer
          />
          {driver.following
            ? tGeneral("general.unfollow")
            : tGeneral("general.follow")}
        </sc.Item>
      )}

      {/* <sc.Item>
        <IconSvg
          icon="save"
          width="26px"
          height="18px"
          padding="0 4px"
          color={etWhite}
          pointer={true}
        />
        Save
      </sc.Item> */}

      <sc.Item
        data-cy="CardDeckHeaderActions-Share"
        onClick={() => {
          driver.toggleShareActive();
          scroller.scrollTo("cardHeaderShare", {
            duration: 500,
            smooth: true,
            offset: -(screen ? screen.height - 150 : 350),
          });
        }}
      >
        <IconSvg
          icon="share"
          width="26px"
          height="18px"
          padding="0 4px"
          color={etWhite}
          pointer
        />
        {tGeneral("general.share")}
      </sc.Item>
    </sc.Container>
  );
});
