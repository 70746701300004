import React, { ReactNode } from "react";

import { ModalType } from "../../stores/ModalStore";
import { Button } from "../Button/Button";
import { LayoutModal } from "../LayoutModal/LayoutModal";
import sc from "./ModalCardReport.styled";

interface Driver {
  openModal(modalTypes: ModalType): void;
}

interface IProps {
  driver: Driver;
  renderModalWrapper(children: ReactNode): ReactNode;
}

export const ModalCardReportLayout = function (props: IProps) {
  const { driver } = props;
  return (
    <LayoutModal
      onClose={() => driver.openModal("")}
      renderModalWrapper={props.renderModalWrapper}
      renderModalContent={() => (
        <>
          <sc.Header>
            <sc.HeaderTitle data-cy="ModalReportCard-HeaderTitle">
              Report this Card
            </sc.HeaderTitle>
          </sc.Header>
          <sc.Body data-cy="ModalReportCard-BodyText">
            <sc.Paragraph>
              If you believe this card violates the EarthToday Terms &amp;
              Conditions, principles or guidelines, and should be adjusted or
              removed, please let us know.
            </sc.Paragraph>
            <sc.Contact data-cy="ModalReportCard-Contact">
              <div>Contact us directly by emailing</div>

              <sc.ReportLink
                data-cy="ModalReportCard-Email"
                href="mailto:support@earthtoday.com"
              >
                support@earthtoday.com
              </sc.ReportLink>
            </sc.Contact>
          </sc.Body>
        </>
      )}
    />
  );
};
