import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { OverlayDriver } from "./LayoutError";
import sc from "./LayoutError.styled";

interface IProps {
  overlayDriver: OverlayDriver;
}

export const LayoutErrorDefault = observer(function LayoutErrorDefault(
  props: IProps,
) {
  return (
    <sc.BackGround
      isOverlayActive={props.overlayDriver.isOverlayActive}
      className="visual-reg"
      data-cy="LayoutErrorDefault"
    >
      <sc.Container>
        <sc.ErrorImg className="d-none d-md-block d-lg-block" />
        <sc.ErrorInfo>
          <sc.ErrorTitle>Houston, we got a problem.</sc.ErrorTitle>
          <sc.ErrorText>We lost connection with Earth</sc.ErrorText>
          <Link href="/" passHref className="btn btn-primary">
            Return Home
          </Link>
        </sc.ErrorInfo>
      </sc.Container>
    </sc.BackGround>
  );
});
