import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mobileMaxHeight,
} from "../../shared/breakpoints";
import { etBlack, etSolitudeLight } from "../../shared/colors";

namespace sc {
  export const PullTop = styled.div`
    margin-top: -24px;
    width: 100%;
  `;

  export const Container = styled.div<{
    isOverlayActive: boolean;
    isCardJoiningOpen: boolean;
    isMenuAppOpen: boolean;
  }>`
    position: ${(props) =>
      props.isCardJoiningOpen || props.isMenuAppOpen
        ? "relative"
        : props.isOverlayActive
        ? "fixed"
        : "relative"};
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${etSolitudeLight};
    width: 100%;
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.defaultLayoutBackground};
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
    }
    &.mLandscape {
      background-color: ${etBlack};
    }
  `;
}

export default sc;
