import { NotificationResponseDto } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { NotificationItem } from "../NotificationItem/NotificationItem";
import { TheLoader } from "../TheLoader/TheLoader";
import sc from "./TheNavbarNotification.styled";

export interface TheNavbarNotificationDriver {
  notifications: NotificationResponseDto[];
  isLoadingNotification: boolean;
  isLoadingReply: boolean;
  isInvitationAccepted: boolean | null;
  isInvitationReplied: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  onMount(): void;
  showNotificationsToggle(): void;
  onMarkNotificationRead(notification: NotificationResponseDto): void;
  formatLatestTime(
    fromDate: Date,
    notification: NotificationResponseDto,
  ): string;
  turnMenuOff(): void;
  handleReplyGroupInvitation(
    b: boolean,
    notification: NotificationResponseDto,
  ): void;
  getGiftCollectedNotificationTitle(notification: NotificationResponseDto): {
    key: string;
    vars: {
      uonCount: number;
    };
  };
  getDeckAutoplayedNotificationTitle(
    notification: NotificationResponseDto,
  ): ReactNode;
}

interface IProps {
  driver: TheNavbarNotificationDriver;
}

const bodyOverflowHidden = () => {
  document.body.style.overflow = "hidden";
};

const bodyOverflowHidddenAuto = () => {
  document.body.style.overflow = "hidden auto";
};

export const TheNavbarNotification = observer(function TheNavbarNotification(
  props: IProps,
) {
  const { driver } = props;
  return (
    <>
      <sc.NotificationOverLay
        onClick={() => {
          driver.showNotificationsToggle();
        }}
      />
      <sc.Notification
        className={["visual-reg", driver.mLandscape ? "mLandscape" : ""].join(
          " ",
        )}
        onMouseEnter={bodyOverflowHidden}
        onMouseLeave={bodyOverflowHidddenAuto}
        onTouchStart={bodyOverflowHidden}
        onTouchMove={bodyOverflowHidden}
        onTouchEnd={bodyOverflowHidddenAuto}
      >
        {driver.notifications && driver.notifications.length > 0 ? (
          driver.notifications.map((notification: NotificationResponseDto) => {
            return (
              <NotificationItem
                key={notification.id}
                bodyOverflowAuto={bodyOverflowHidddenAuto}
                notification={notification}
                driver={driver}
              />
            );
          })
        ) : props.driver.isLoadingNotification ? (
          <TheLoader />
        ) : (
          <sc.NotificationEmpty data-cy="TheNavbarNotification-NotificationEmpty">
            No recent activities
          </sc.NotificationEmpty>
        )}
      </sc.Notification>
    </>
  );
});
