import styled from "styled-components";

import errorBgX1 from "../../assets/img/error-bg-x1.jpg";
import errorBgX2 from "../../assets/img/error-bg-x2.jpg";
import errorBgX3 from "../../assets/img/error-bg-x3.jpg";
import errorImagePng from "../../assets/img/error-image.png";
import errorImageWebp from "../../assets/img/error-image.webp";
import unauthorizedErrorImg from "../../assets/svgs/bad-request-error.svg";
import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenLowerLimit,
  smallScreenUpperLimit,
  xmediumScreenLowerLimit,
} from "../../shared/breakpoints";
import { etBlack, etSolitudeLight } from "../../shared/colors";

const MOBILE_COOKIE_POLICY_HEIGHT = "221px";
const MEDIUM_SCREEN_COOKIE_POLICY_HEIGHT = "179px";
const FIXED_HEIGHT_TO_SHOW_MENU_AND_NAV_BAR_TOP = "calc(496px + 64px + 48px)";

namespace sc {
  export const PreloadErrorImage = styled.div`
    max-height: 0;
    max-width: 0;

    ${(props) =>
      props.theme.isWebpSupported
        ? `background-image: url(${errorImageWebp});`
        : `background-image: url(${errorImagePng});`}
  `;
  export const PreloadErrorImageBackground = styled.div`
    max-height: 0;
    max-width: 0;
    background-image: url(${errorBgX3});

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url(${errorBgX2});
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      background-image: url(${errorBgX2});
    }

    @media (max-width: ${smallScreenLowerLimit}) {
      background-image: url(${errorBgX1});
    }
  `;

  export const PreloadBadRequestErrorImage = styled.div`
    background: url(${unauthorizedErrorImg});
    width: 0;
    height: 0;
    max-width: 0;
    max-height: 0;
  `;
  export const WrapFooter = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.themeStyle.theFooterBackgroundColor};
    }
  `;
  export const Container = styled.div<{
    shouldFixViewport: boolean;
    shouldShowCookiePolicy: boolean;
    isIntroVideoOpen: boolean;
    maxHeightOfNewCardMenuItem?: string;
  }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 100vw;
    max-width: 100vw;
    height: 100%;
    background-color: ${etSolitudeLight};

    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      overflow-y: ${(props) => (props.shouldFixViewport ? "hidden" : "unset")};
      max-height: ${(props) =>
        props.shouldFixViewport
          ? props.shouldShowCookiePolicy
            ? `calc(${FIXED_HEIGHT_TO_SHOW_MENU_AND_NAV_BAR_TOP} + ${MEDIUM_SCREEN_COOKIE_POLICY_HEIGHT})`
            : FIXED_HEIGHT_TO_SHOW_MENU_AND_NAV_BAR_TOP
          : "100%"};
      ${(props) => props.isIntroVideoOpen && `max-height: 100vh;`}
    }

    // mobile
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.isMobileDevice &&
        props.theme.themeStyle.defaultLayoutBackground};
      overflow-y: ${(props) => (props.shouldFixViewport ? "hidden" : "unset")};
      max-height: ${(props) =>
        props.shouldFixViewport
          ? props.shouldShowCookiePolicy
            ? `calc(100vh + ${MOBILE_COOKIE_POLICY_HEIGHT} + 40px)` // 40px: gap causes by minimizing address bar when scrolling down
            : "100vh"
          : "100%"};
      ${(props) =>
        props.maxHeightOfNewCardMenuItem &&
        `max-height: calc(100vh + ${props.maxHeightOfNewCardMenuItem})`}
      ${(props) => props.isIntroVideoOpen && `max-height: 100vh;`}
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
      overflow-y: ${(props) => (props.shouldFixViewport ? "hidden" : "unset")};
      max-height: ${(props) =>
        props.shouldFixViewport
          ? props.shouldShowCookiePolicy
            ? `calc(100vw + ${MOBILE_COOKIE_POLICY_HEIGHT})`
            : "100vw"
          : "100%"};
      ${(props) => props.isIntroVideoOpen && `max-height: 100vh;`}
    }
    &.mLandscape {
      background-color: ${etBlack};
      overflow-y: ${(props) => (props.shouldFixViewport ? "hidden" : "unset")};
      max-height: ${(props) =>
        props.shouldFixViewport
          ? props.shouldShowCookiePolicy
            ? `calc(100vw + ${MOBILE_COOKIE_POLICY_HEIGHT})`
            : "100vw"
          : "100%"};
      ${(props) => props.isIntroVideoOpen && `max-height: 100vh;`}
    }
  `;

  export const ChildrenWrapper = styled.div`
    min-height: calc(100vh - 64px - 283px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media (max-width: ${mediumScreenUpperLimit}) {
      min-height: calc(100vh - 64px - 450px);
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: calc(100vh - 64px - 702px);
    }
  `;
}

export default sc;
