import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etGray,
  etLicoriceBlue,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";

namespace sc {
  export const SearchResultContainer = styled.div<{
    display: string;
    userLoggedIn: boolean;
  }>`
    display: ${(props) => props.display};
    flex-direction: column;
    background-color: ${etWhite};
    padding-top: 12px;
    @media (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      border-radius: 0 0 4px;
    }

    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      max-height: 500px;
      background-color: ${(props) => props.theme.themeStyle.defaultBackground};
      width: 327px;
      max-width: 327px;
    }

    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      max-height: 500px;
      background-color: ${etBlack};
    }
    &.mLandscape {
      max-height: 500px;
      background-color: ${etBlack};
    }
  `;
  export const TabContainer = styled.div`
    display: flex;
    justify-content: flex-start;
  `;
  export const Tab = styled.div`
    user-select: none;
    flex: 1;
    margin-right: 0.75rem;
    text-align: center;
    border: 1px solid ${etGray};
    border-radius: 1.5rem;
    color: ${etGray};
    cursor: pointer;
    &.active {
      color: ${etWhite};
      background-color: ${etGray};
    }
    font-family: "Roboto Condensed";
    font-size: 14px;
    font-weight: normal;
    height: 32px;
    letter-spacing: 0px;
    line-height: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      border-color: ${(props) =>
        props.theme.themeStyle.notificationButtonBorder};
      &.active {
        color: ${(props) =>
          props.theme.themeStyle.notificationButtonActiveColor};
        background-color: ${(props) =>
          props.theme.themeStyle.notificationButtonActiveBackground};
      }
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      border-color: ${etSolitudeLight};
      &.active {
        color: ${etLicoriceBlue};
        background-color: ${etSolitudeLight};
      }
    }
    &.mLandscape {
      border-color: ${etSolitudeLight};
      &.active {
        color: ${etLicoriceBlue};
        background-color: ${etSolitudeLight};
      }
    }
  `;

  export const TabSmall = styled(Tab)`
    width: 53px;
    max-width: 53px;
  `;

  export const TabMedium = styled(Tab)`
    width: 68px;
    max-width: 68px;
  `;
  export const SearchResults = styled.div<{ userLoggedIn: boolean }>`
    display: grid;
    grid-template-columns: 275px 275px;
    grid-template-rows: auto;
    column-gap: 12px;
    row-gap: 12px;
    height: 100%;
    overflow: hidden;
    padding-top: 12px;
    @media (max-width: ${mediumScreenUpperLimit}) {
      top: ${(props) => (props.userLoggedIn ? "40px" : "50px")};
      width: 100%;
      grid-template-columns: ${(props) =>
        props.userLoggedIn ? "275px 275px" : "1fr"};
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      grid-template-columns: 1fr;
      grid-auto-rows: 64px;
      overflow: hidden auto;
      width: 327px;
      max-width: 327px;
      max-height: 456px;
    }
  `;
  export const SearchNoResult = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    flex: 1;
    justify-content: center;
    text-align: center;
  `;
  export const SearchNoResultText = styled.span`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export default sc;
