import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import sc from "./CardDeckOverviewOverLay.styled";

interface IProps {
  driver: { path: string[] };
}

export const CardDeckOverviewOverLay = observer(
  function CardDeckOverviewOverLay(props: IProps) {
    return (
      <>
        <sc.Overlay data-cy="CardDeckOverviewOverLay-Overlay" />
        <Link
          href="/[vanityName]/[deckName]"
          as={`/${props.driver.path.join("/")}`}
          passHref
          legacyBehavior
        >
          <sc.OverlayLink data-cy="CardDeckOverviewOverLay-OverlayLink" />
        </Link>
      </>
    );
  },
);
