import styled from "styled-components";

import { mediumScreenUpperLimit } from "../../shared/breakpoints";
import { etBlack, etBlue, etGrayLight, etWhite } from "../../shared/colors";

export namespace sc {
  export const Wrapper = styled.div`
    margin-right: 20px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-right: 12px;
    }
  `;
  export const Options = styled.div<{ display: "none" | "flex" }>`
    position: absolute;
    top: 0;
    left: 0;

    display: ${(props) => props.display};
    flex-flow: column;
  `;

  export const Item = styled.label<{ order?: number }>`
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    margin: 0;

    border-radius: 50%;
    background-color: ${etGrayLight};
    color: ${etBlack};

    font-size: 18px;
    font-family: Roboto Condensed;
    letter-spacing: 0.5px;
    font-weight: bold;

    cursor: pointer;
    position: relative;
    z-index: 1;

    ${(props) =>
      Number.isInteger(props.order) &&
      `
      order: ${props.order};
    `}

    :hover {
      background-color: ${etBlue};
      color: ${etWhite};
    }
  `;

  export const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    display: none;
  `;

  export const Container = styled.div`
    display: flex;
    flex-flow: column;
    position: relative;

    width: 40px;
    height: 40px;

    > input:checked ~ ${Options}, > input:checked ~ ${Overlay} {
      display: flex;
    }
  `;

  export const SwitcherOverlay = styled.div`
    background-color: ${etGrayLight};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 40px;
    height: calc(100% - 40px);
    z-index: 0;
  `;
}
