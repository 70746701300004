import { observer } from "mobx-react-lite";
import React from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import { UserProfileCard } from "./CardProfile";
import sc from "./CardProfile.styled";

interface IProps {
  driver: UserProfileCard;
}

export const CardProfileTitle = observer(function CardProfileTitle(
  props: IProps,
) {
  const { t } = useTranslation("ProfileCard");

  const renderSubTitle = () => {
    if (props.driver.profileTagOverride) {
      return (
        <sc.Tagline
          data-cy="CardProfile-ProfileTagOverride"
          isProfileTagOverride={props.driver.profileTagOverride}
        >
          {props.driver.profileTagOverride}
        </sc.Tagline>
      );
    }

    if (props.driver.tagline) {
      return (
        <sc.Tagline data-cy="CardProfile-Tagline">
          {props.driver.tagline}
        </sc.Tagline>
      );
    }

    if (props.driver.userSubTitle) {
      return (
        <sc.SubTitle data-cy="CardProfile-userType">
          {t(props.driver.userSubTitle)}
        </sc.SubTitle>
      );
    }

    return <></>;
  };
  return (
    <>
      <sc.Title
        data-cy="CardProfile-Name"
        isProfileTagOverride={props.driver.profileTagOverride}
      >
        {props.driver.fullName}
      </sc.Title>
      {renderSubTitle()}
    </>
  );
});
