import styled from "styled-components";

import {
  largeScreenLowerLimit,
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  xLargeScreenLowerLimit,
  xmediumScreenLowerLimit,
} from "../../shared/breakpoints";
import { etBlack, etGray, etWhite } from "../../shared/colors";

export namespace sc {
  export const LayoutTabsEtProfile = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: transparent;
    width: calc(328px * 4 + 24px * 3);
    margin-top: 24px;
    @media (max-width: ${xLargeScreenLowerLimit}) {
      width: calc(328px * 3 + 24px * 2);
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      width: calc(328px * 2 + 24px * 1);
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-left: 25%;
      margin-top: 12px;
      margin-bottom: -12px;
      display: grid;
      grid-template-columns: 80px 106px 128px;
      gap: 6px;
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      margin-left: 0;
      width: 328px;
      max-width: 328px;
      margin-left: -15px;
    }
  `;
  export const TabContainer = styled.div<{ isEtProfile: boolean }>`
    position: ${(props) => (props.isEtProfile ? "relative" : "absolute")};
    top: ${(props) => (props.isEtProfile ? "0px" : "26px")};
    left: ${(props) => (props.isEtProfile ? "0px" : "120px")};
    display: grid;
    grid-template-columns: repeat(6, auto);
    column-gap: 12px;
    z-index: 5;

    @media (min-width: ${xLargeScreenLowerLimit}) {
      ${(props) =>
        props.isEtProfile
          ? ""
          : "left: calc((100% - (328px * 4 + 24px * 3))/2);"}
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      ${(props) =>
        props.isEtProfile
          ? ""
          : "left: calc((100% - (328px * 3 + 24px * 2))/2);"}
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      left: ${(props) =>
        props.isEtProfile ? "0px" : "calc((100% - (328px * 2 + 24px))/2)"};
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      grid-template-columns: repeat(6, auto);
      column-gap: 6px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      left: ${(props) => (props.isEtProfile ? "0px" : "12px")};
      ${(props) =>
        props.isEtProfile
          ? `grid-template-columns: repeat(5, auto);`
          : `grid-template-columns: repeat(4, auto);`}

      grid-template-rows: repeat(2, auto);
      gap: 6px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);
    }
  `;

  export const Tab = styled.a<{
    width: string;
    height: string;
    isEtProfile: boolean;
    enableOneFlow?: boolean;
  }>`
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${(props) => (props.isEtProfile ? etGray : etWhite)};
    min-height: ${(props) => props.height};
    max-height: ${(props) => props.height};
    min-width: ${(props) => props.width};
    max-width: ${(props) => props.width};

    color: ${(props) => (props.isEtProfile ? etGray : etWhite)};
    font-family: Roboto;
    font-stretch: condensed;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    text-align: center;
    :hover {
      text-decoration: none;
      color: ${(props) => (props.enableOneFlow ? etBlack : etWhite)};
      background-color: ${(props) => (props.enableOneFlow ? etWhite : etBlack)};
      border: 1px solid ${etBlack};
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      ${(props) =>
        props.isEtProfile
          ? `:nth-child(2) {
            margin-left: -55px;
          }

          :hover {
            text-decoration: none;
            color: ${etBlack};
            background-color: ${etWhite};
            border: 1px solid ${etWhite};
          }
          `
          : ``}

      grid-template-rows: repeat(2, auto);
      gap: 6px;

      border: 1px solid ${etWhite};
      color: ${etWhite};
    }

    @media (max-width: ${mobileDeviceWidth}) {
      :nth-child(2) {
        margin-left: -11px;
      }
      :nth-child(3) {
        margin-left: -15px;
      }
      :nth-child(5) {
        margin-left: 0px;
      }
      :nth-child(6) {
        margin-left: 0px;
      }

      ${(props) =>
        props.isEtProfile
          ? `:nth-child(6) {
          margin-left: 0px;
          }
           :nth-child(2) {
          margin-left: -6px;
           }
          :nth-child(3) {
          margin-left: -29px;
          }
        `
          : ``}
    }
  `;
}
