/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */

import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { etGray, etGrayDark, etWhite } from "../../shared/colors";
import {
  CardSize,
  CardType,
  CardTypeRegular,
  MasterLinkType,
} from "../../shared/models/Card";
import { CardItemLocation } from "../CardItem/CardItem";
import { LayoutDriver } from "../CardItemArticle/CardItemArticle";
import { IconSvg } from "../IconSvg/IconSvg";
import scUpdate from "./CardSimpleContext_Update.styled";

type ColorOptions = {
  color: string;
  hoverColor: string;
  activeColor: string;
};

type BoxModelOptions = {
  margin: string;
};

export interface ContextMenuLayoutDriver {
  readonly isCardDrafting?: boolean;
  readonly adminOrOwner?: boolean;
  readonly contextMenuActive: boolean;
  readonly cardType: MasterLinkType;
  readonly contentType: CardTypeRegular;
  onIconMoreClicked(): void;
  closeContextMenu(): void;
}

interface IProps {
  driver: ContextMenuLayoutDriver;
  layoutDriver: LayoutDriver;
  location: CardItemLocation;
  isHeaderCard?: boolean;
  isWrapperCardRepost?: boolean;
  renderContextMenuItem(): ReactNode;
}

export const ContextMenuLayout = observer(function (props: IProps) {
  const {
    driver,
    layoutDriver,
    isHeaderCard,
    renderContextMenuItem,
    isWrapperCardRepost,
  } = props;
  let colorOptions: ColorOptions | null = null;
  let boxModelOptions: BoxModelOptions | null = null;
  let color = "";

  if (
    layoutDriver.cardSize !== CardSize.DOUBLE &&
    driver.cardType === MasterLinkType.ARTICLE
  ) {
    colorOptions = {
      color: etGray,
      hoverColor: etGrayDark,
      activeColor: etWhite,
    };
    boxModelOptions = { margin: "0" };
  }

  if (isHeaderCard) {
    colorOptions = {
      color: etGray,
      hoverColor: etGrayDark,
      activeColor: etWhite,
    };
  }

  if (driver.cardType === MasterLinkType.MUSIC) {
    colorOptions = {
      color: etWhite,
      hoverColor: etWhite,
      activeColor: etWhite,
    };
  }

  (() => {
    if (driver.contentType === CardType.DECK_REPOST && !isWrapperCardRepost) {
      return (color = etWhite);
    }
    if (colorOptions) {
      if (driver.contextMenuActive) {
        return (color = colorOptions.activeColor);
      }
      return (color = colorOptions.color);
    }
    if (driver.cardType === MasterLinkType.MUSIC) {
      return (color = etWhite);
    }
    if (driver.contextMenuActive) {
      return (color = etWhite);
    }
    return (color = etGray);
  })();

  return (
    <>
      {driver.contextMenuActive && (
        <scUpdate.Overlay
          onClick={driver.closeContextMenu}
          data-cy="CardSimpleContext-MenuContextLayout"
        />
      )}
      <scUpdate.Container
        contentType={driver.contentType}
        data-cy="CardSimpleContext-MenuContext"
        className="visual-reg"
        contextMenuActive={driver.contextMenuActive}
      >
        <scUpdate.ContextMenuContainer
          data-cy="CardSimpleContext-ContextMenuContainer"
          iconColor={driver.contextMenuActive ? etGrayDark : etGray}
        >
          <scUpdate.ContextMenuContent>
            <IconSvg
              icon="more"
              pointer
              width="18px"
              height="20px"
              padding="0px"
              margin={boxModelOptions ? boxModelOptions.margin : "0"}
              color={color}
              onClick={driver.onIconMoreClicked}
              hoverColor={
                driver.cardType === MasterLinkType.MUSIC ||
                (driver.contentType === CardType.DECK_REPOST &&
                  !isWrapperCardRepost)
                  ? etWhite
                  : colorOptions
                  ? colorOptions.hoverColor
                  : etGrayDark
              }
            />
            <scUpdate.ContextMenu
              className="visual-reg-contextMenu"
              data-cy="ContextMenuLayout-ContextMenu"
              display={driver.contextMenuActive ? "flex" : "none"}
            >
              {renderContextMenuItem()}
            </scUpdate.ContextMenu>
          </scUpdate.ContextMenuContent>
        </scUpdate.ContextMenuContainer>
      </scUpdate.Container>
    </>
  );
});
