/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable class-methods-use-this */
import { UserResponseDto } from "@earthtoday/contract";
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import Router from "next/router";
import QRCode from "qrcode";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { HomeApi } from "../../../shared/apis/HomeApi";
import { INavbarApi } from "../../../shared/apis/NavbarApi";
import { UnsubscribeFn } from "../../../shared/apis/UnsubscribeFn";
import { mobileDeviceWidthValue } from "../../../shared/breakpoints";
import { etBlack, etGreen } from "../../../shared/colors";
import {
  ACCESS_TOKEN_KEY,
  OVERLAY_CLOSED_AT_SESSION_ID,
  SESSION_ID_ON_RELOAD,
} from "../../../shared/constants";
import { ETLocalStorage } from "../../../shared/helpers/EtStorages";
import { translateAPIError } from "../../../shared/helpers/translateApiError";
import { User } from "../../../shared/models/User";
import { FeatureFlaggingData } from "../../../stores/FeatureFlaggingStore";
import { FlyingOverPresenter } from "../../../stores/FlyingOverPresenter/FlyingOverPresenter";
import { ITokenStore } from "../../../stores/TokenStore";
import { IUserSessionStore } from "../../ModalLogin/UserSessionStore";
import { QrCodeOverlayDriver } from "../../QrCodeOverlay/QrCodeOverlay";
import { ITheMessageStore } from "../../TheMessage/TheMessageStore";
import { TheNavbarStore } from "./TheNavbarStore";

export enum QrCodeOverlayScreenState {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export class QrCodeOverlayPresenter implements QrCodeOverlayDriver {
  constructor(
    private theNavbarStore: TheNavbarStore,
    private theMessageStore: ITheMessageStore,
    public navbarApi: INavbarApi,
    public userSessionStore: IUserSessionStore,
    private tokenStore: ITokenStore,
    private featureFlagging: FeatureFlaggingData,
    private homeApi: HomeApi,
  ) {
    makeObservable(this);
    this.flyingMapDriver = new FlyingOverPresenter();
  }

  @observable state: QrCodeOverlayScreenState =
    QrCodeOverlayScreenState.PRIMARY;

  @observable protectedCount: number | null = null;
  @observable isProtectedCountFetched: boolean = false;

  @action.bound onUserSessionStateUpdated = flow(function* onMount(
    this: QrCodeOverlayPresenter,
    pageName: string,
    didInit: boolean,
    asPath: string,
  ) {
    const overlayCloseAtSessionId = ETLocalStorage.getItem(
      OVERLAY_CLOSED_AT_SESSION_ID,
    );
    if (!!overlayCloseAtSessionId) return;

    if (asPath === "/earthtoday/partnerships") {
      this.onCloseOverlay();
      return;
    }

    const isUserLoggedIn = !!ETLocalStorage.getItem(ACCESS_TOKEN_KEY);
    const isEnabledQrCode = this.featureFlagging.flags.enableLoginWithQRCode;
    const isFaqDeck = asPath.startsWith("/earthtoday/faq_earthtoday");

    if (
      !didInit ||
      !isEnabledQrCode ||
      isUserLoggedIn ||
      pageName === "GenericCollectPage" ||
      pageName === "WelcomePage" ||
      asPath === "/earthtoday/partnerships" ||
      isFaqDeck
    ) {
      return;
    }
    this.state = QrCodeOverlayScreenState.PRIMARY;

    const uonCount = yield* toFlowGeneratorFunction(
      this.homeApi.fetchProtectedCount,
    )();
    this.protectedCount = uonCount;
    this.isProtectedCountFetched = true;

    this.onOpenOverlay();
  });

  @observable shouldShowOverlay: boolean = false;
  @action.bound onOpenOverlay = flow(function* (this: QrCodeOverlayPresenter) {
    this.shouldShowOverlay = true;
    document.body.style.overflowY = "hidden";
  });

  flyingMapDriver: FlyingOverPresenter;

  @observable shouldRenderVideoPlayer: boolean = false;
  @action.bound onWatchVideoButtonClicked(): void {
    this.shouldRenderVideoPlayer = true;
  }
  @action.bound onVideoOverlayClicked(): void {
    this.shouldRenderVideoPlayer = false;
  }

  @action.bound onLoginForPartnerClicked(isUnitTest?: boolean): void {
    this.onCreateQrCode(isUnitTest);
    this.state = QrCodeOverlayScreenState.SECONDARY;
  }

  get browsingSessionId(): string {
    return ETLocalStorage.getItem(SESSION_ID_ON_RELOAD) || "";
  }

  @action.bound onCreateQrCode = flow(function* onCreateQrCode(
    this: QrCodeOverlayPresenter,
    isUnitTest?: boolean,
  ) {
    this.shouldShowOverlay = true;

    if (this.isQrCodeExpired) {
      yield* toFlowGeneratorFunction(this.getQRCodeData)();
      if (!isUnitTest) {
        this.createQrCode(); // prevent error "document is not defined" in unit test
      }
    }

    if (!this.isQrCodeExpired && !!this.qrUuid) {
      this.subscribeQrCode(this.qrUuid);
    }
    document.body.style.overflowY = "hidden";
  });
  @action.bound onLoginButtonClicked = flow(function* onLoginButtonClicked(
    this: QrCodeOverlayPresenter,
    isUnitTest?: boolean,
  ) {
    this.state = QrCodeOverlayScreenState.SECONDARY;
    this.shouldShowOverlay = true;
    this.onCreateQrCode(isUnitTest);
  });

  @observable qrCodeUrl: string = "";
  @action.bound createQrCode(): void {
    const canvasSize = 304;
    const canvas = document.createElement("canvas");
    canvas.width = canvasSize;
    canvas.height = canvasSize;
    QRCode.toCanvas(canvas, this.qrUuid, {
      errorCorrectionLevel: "L",
      margin: 4,
      width: canvasSize,
      color: {
        dark: etBlack,
        light: etGreen,
      },
    });

    this.qrCodeUrl = canvas.toDataURL("image/jpeg");
  }

  @observable qrUuid: string = "";
  @observable expireTime: Date | null = null;
  @computed get isQrCodeExpired(): boolean {
    if (!this.expireTime) {
      return true;
    }
    return this.now.getTime() > this.expireTime.getTime();
  }

  @observable isLoading: boolean = false;
  @action.bound getQRCodeData = flow(function* getQRCodeData(
    this: QrCodeOverlayPresenter,
  ) {
    try {
      this.isLoading = true;

      const createdQrCodeData = yield* toFlowGeneratorFunction(
        this.navbarApi.createQrCode,
      )();

      this.qrUuid = createdQrCodeData.data.qrUuid;
      this.expireTime = new Date(createdQrCodeData.data.expiredAt);
      this.subscribeQrCode(this.qrUuid);
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isLoading = false;
    }
  });

  @action.bound onReloadBtnClicked = flow(function* onReloadBtnClicked(
    this: QrCodeOverlayPresenter,
  ) {
    yield* toFlowGeneratorFunction(this.getQRCodeData)();
    this.createQrCode();
  });

  @action.bound onCloseOverlay(): void {
    this.shouldShowOverlay = false;
    ETLocalStorage.setItem(
      OVERLAY_CLOSED_AT_SESSION_ID,
      this.browsingSessionId,
    );

    this.unsubscribe();
    document.body.style.overflowY = "auto";
  }

  @action.bound onBackBtnClicked = flow(function* (
    this: QrCodeOverlayPresenter,
  ) {
    this.state = QrCodeOverlayScreenState.PRIMARY;

    if (!this.isProtectedCountFetched) {
      const uonCount = yield* toFlowGeneratorFunction(
        this.homeApi.fetchProtectedCount,
      )();
      this.protectedCount = uonCount;
      this.isProtectedCountFetched = true;
    }
  });

  @action.bound onLearnMoreBtnClicked(): void {
    // TODO: need to set enviroment var for this to work on staging
    Router.push("/earthtoday/faq_earthtoday/8521635716243755008", undefined, {
      shallow: true,
    });
    this.onCloseOverlay();
  }

  @action.bound onCloseButtonClicked(): void {
    this.onCloseOverlay();
  }

  @observable now: Date = new Date();
  @action.bound updateNow(date: Date): void {
    this.now = date;
  }

  @observable unsubscribeFns: UnsubscribeFn[] = [];
  @action.bound subscribeQrCode(uuid: string): void {
    this.unsubscribeFns.push(
      this.navbarApi.subscribeQrCode(
        uuid,
        (
          error,
          qrCodeActivated: {
            accessToken: string;
            refreshToken: string;
            user: UserResponseDto;
            status: "SUCCESS" | "EXPIRED";
          } | null,
        ) => {
          if (error) {
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (qrCodeActivated) {
              this.userSessionStore.setUser({
                ...qrCodeActivated.user,
                enableGiftLastUon: false, // TODO: add enableGiftLastUon to user in qrCodeActivated response
              } as User);
              this.tokenStore.setAccessToken(qrCodeActivated.accessToken);
              this.tokenStore.setRefreshToken(qrCodeActivated.refreshToken);
              this.theNavbarStore.onMount();
              this.resetState();
              this.unsubscribe();
            }
          });
        },
      ),
    );
  }

  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };

  resetState(): void {
    this.qrUuid = "";
    this.qrCodeUrl = "";
    this.expireTime = null;
    this.shouldShowOverlay = false;
    document.body.style.overflowY = "auto";
  }
}
