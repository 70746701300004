export enum FlagKey {
  ENABLE_EARTHTODAY_CHANGE_PLEDGE_PHOTO = "f-3372-enable-change-pldge-photo",
  ENABLE_CHARITY_PROFILE_LEARN_MORE_BTN = "f-3372-enable-charity-profile-learn-more-btn",
  ENABLE_GIVE_UON_CARD = "f-9048-enable-give-button",
  ENABLE_UON_COLLECT_CODE_FLOW = "f-8945-enable-collect-code-flow",
  ENABLE_NEW_HOMEPAGE = "f-10613-enable-new-homepage",

  ENABLE_MEMBER_GETS_MEMBER_FEATURE = "f-10098-enable-member-get-feature",
  EXCLUDED_NPO_LOGO = "f-excluded-NPO-logo",
  COUNTER_FLAP_EFFECT_DURATION = "f-10747-counter-flap-effect-duration",
  ENABLE_CHARITIES_TAB = "f-10534-create-charities-deck-page",
  ENABLE_SHARE_BTN_ON_CARD_SYSTEM = "f-10533-create-brands-deck-page-share-button",
  LEADERBOARD_EFFECT_DURATION = "f-animation-leaderboard",
  ENABLE_FIRST_CONTENT_CARD_IN_HOME_PAGE = "f-10904-first-content-card-in-home-page",
  ENABLE_PLAY_PAGE = "f-11955-play-page",
  ENABLE_CARD_ONBOARDING = "f-14928-onboarding-cards",
  ENABLE_AUTO_PLAY_DECK = "f-12665-autoplay-basics",
  ENABLE_EMAIL_GIVE_BUTTON = "f-10790-enable-email-give-button",
  ENABLE_NPO_GROUP_ROLE = "f-12851-enable-npo-role",
  ENABLE_CHARITY_GROUP_ROLE = "f-12424-enable-charity-role",
  ENABLE_WHOLESALE_UON_ORDER = "f-13383-enable-wholesale-uon-order",
  ENABLE_WEBSOCKET_CARD_EVENTS = "f-14035-websocket-card-events",
  COUNTER_FLIP_EFFECT_SETTIMEOUT = "us-15621-counter-flip-effect-settimout",
  COUNTER_FLIP_EFFECT_TIMEREROLL = "us-15621-counter-flip-effect-timereroll",
  ENABLE_DECK_SHORTCUTS = "f-14961-deck-shortcuts",
  ENABLE_NEW_PROFILE_PAGE = "f-14920-14921-profile-page",
  ENABLE_NEW_COLLECT_PAGE = "f-16958-new-collect-page",
  ENABLE_NEW_ET_PROFILE = "f-16958-new-et-profile",
  ENABLE_ONE_FLOW = "f-20634-one-flow",
  ENABLE_ALL_SUBSCRIPTION_CARD = "f-24481-all-subscription-card",
  VANITY_NAME_HIDE_SUBSCRIPTION_CARD = "f-24481-vanity-name-hide-subscription-card",
  ENABLE_API_KEY = "f-27589-api-key-ui",
  ENABLE_NEW_HOME_HEADER_CARD = "f-28666-update-the-home-header-card",
  ENABLE_NEW_SEARCH_PANEL = "f-28630-move-search-bar-to-center",
  ENABLE_LANGUAGE_SWITCHER = "f-28752-language-switcher",
  ENABLE_2023MAY_CTA_PROTECT_AND_SUBSCRIBE_MODAL = "f-29026-update-cta-protect-and-subscribe-modal",
  ENABLE_CREATE_ACTION_CARD = "f-29252-create-action-card",
  ENABLE_CREATE_INFO_CARD = "f-29646-create-info-card",
  ENABLE_COLLECTIBLE_UON = "f-29628-uon-collectable-cards",
  ENABLE_SORT_CARDS_IN_DECK = "f-30583-sort-card-in-deck",
  ENABLE_VIEW_GALLERY = "f-29908-view-a-users-gallery-of-collectibles",
  ENABLE_MULTI_CURRENCY = "f-29044-Implement-multi-currency-payments",
  ENABLE_GLOBAL_COUNTER_HEADER_CARD_IN_PROFILE_PAGE = "f-31229-enable-global-counter-header-card-in-profile-page",
  ENABLE_NEW_CARD_MENU_ITEM_FOR_MOBILE = "f-31229-enable-new-card-menu-item-for-mobile",
  UON_EARTH_LANDING_PAGE_MODE = "f-31251-uon-earth-landing-page-mode",
  UON_EARTH_LANDING_PAGE_DOWNLOAD_APP_BUTTONS = "f-31251-uon-earth-landing-page-download-buttons",
  ENABLE_SET_BACKGROUND_IMAGE_FOR_DECK = "f-31538-set-background-image-for-decks",
  ENABLE_SET_SYSTEM_DECK = "f-31541-set-deck-as-system-deck",
  ENABLE_COOKIE_BANNER_AT_BOTTOM = "f-31800-cookie-banner",
  ENABLE_LOGIN_WITH_QR_CODE = "f-31887-web-login-by-scanning-qr-code",
  ENABLE_GROUP = "f-7677-display-group-tab",
  GRID_LAND_ZOOM_THRESHOLD = "f-32994-grid-land-zoom-threshold",
  GRID_MARINE_ZOOM_THRESHOLD = "f-32994-grid-marine-zoom-threshold",
  ENABLE_UPDATED_DECK_CARDS_DESIGN = "f-32956-update-deck-cards-design",
  ENABLE_LANDING_PAGE_MOBILE_WEB = "f-33103-landing-page-mobile-web",
  ENABLE_RESERVE_DEFAULT_UON_COLLECTIBLE = "f-33548-uon-collectibles",
}
