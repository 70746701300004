import { observer } from "mobx-react-lite";
import { Element, scroller } from "react-scroll";
import Slider from "react-slick";

import { etGrayBlue, etWhite } from "../../../shared/colors";
import { utmSourceValue } from "../../../shared/env";
import { getUonLabel } from "../../../shared/helpers/protect-page/getUonLabel";
import { protectflowStarted } from "../../../shared/snowplow";
import { useTranslation } from "../../../shared/translate/NextI18next";
import { Currency, UonSize } from "../../../stores/DonateStore/DonateStore";
import { Button } from "../../Button/Button";
import { CardSquareTransactionV202208 } from "../../CardSquareTransactionV202208/CardSquareTransactionV202208";
import { DangerouslyHTMLRenderer } from "../../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import scLayoutProtect from "../../LayoutProtect/LayoutProtect.styled";
import { ModalReceipt } from "../../ModalReceipt/ModalReceipt";
import sc from "./SectionTwoV202208.styled";
import { WhereYourMoneyGoes } from "./WhereYourMoneyGoes";

export const SamplePrevArrow = (props) => {
  return (
    <sc.Left onClick={props.onClick} data-cy="SamplePrevArrow">
      <img src={require("../../../assets/img/arrow-left.png")} alt="" />
    </sc.Left>
  );
};

export const SampleNextArrow = (props) => {
  return (
    <sc.Right onClick={props.onClick} data-cy="SampleNextArrow">
      <img src={require("../../../assets/img/arrow-right.png")} alt="" />
    </sc.Right>
  );
};

const UONs = [
  {
    uonReserveSize: 1,
    price: "€1.20",
    isEndWithOne: true,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
  {
    uonReserveSize: 5,
    price: "€6.00",
    isEndWithOne: false,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
  {
    uonReserveSize: 10,
    price: "€12.00",
    isEndWithOne: false,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
  {
    uonReserveSize: 20,
    price: "€24.00",
    isEndWithOne: false,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
  {
    uonReserveSize: 50,
    price: "€60.00",
    isEndWithOne: false,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
  {
    uonReserveSize: 100,
    price: "€120.00",
    isEndWithOne: false,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
  {
    uonReserveSize: 500,
    price: "€600.00",
    isEndWithOne: false,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
  {
    uonReserveSize: 1000,
    price: "€1200.00",
    isEndWithOne: false,
    point: {
      lat: 8.247222,
      lng: 71.72861,
    },
  },
];

const receiptDriver = {
  natureProtectionNumber: "1.00",
  operationalCost: "0.16",
  vat: "0.04",
  totalPaid: "1.20",
  currency: Currency.EUR,
};

export interface SectionTwoV202208Driver {
  readonly isSectionTwoSubSectionActivated: boolean;
  toggleSectionTwoSubSection(): void;
  updateUonSize(size: UonSize, dataCy?: string): void;
}

interface IProps {
  driver: SectionTwoV202208Driver;
}

export const SectionTwoV202208 = observer((props: IProps) => {
  const { t } = useTranslation("Protect");
  const settings = {
    swipeToSlide: true,
    variableWidth: true,
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    appendDots: (dots) => {
      return <sc.NavBottom>{dots}</sc.NavBottom>;
    },
    initialSlide: 1,
  };

  return (
    <scLayoutProtect.Section
      id="sectionTwo"
      data-cy="SectionTwoV202208"
      className="visual-reg"
    >
      <sc.Wrapper>
        <sc.Container>
          <sc.Head>
            <scLayoutProtect.Title data-cy="SectionTwoV202208-Title">
              <DangerouslyHTMLRenderer>
                {t("protect.section-two-v202208.title")}
              </DangerouslyHTMLRenderer>
            </scLayoutProtect.Title>
            <sc.SubTitle data-cy="SectionTwoV202208-SubTitle">
              {t("protect.section-two-v202208.sub-title")}
            </sc.SubTitle>
          </sc.Head>
          <sc.SliderWrapper>
            <sc.Slider>
              <Slider {...settings}>
                {UONs.map((uon) => (
                  <sc.Item key={uon.price} data-cy="SectionTwoSlide-Item">
                    <CardSquareTransactionV202208
                      key={uon.uonReserveSize}
                      driver={uon}
                      updateUonSize={(dataCy: string) =>
                        props.driver.updateUonSize(uon.uonReserveSize, dataCy)
                      }
                    />
                    <sc.ReserveLabel
                      data-cy={`SectionTwoSlide-UONReserveSize${uon.uonReserveSize}`}
                    >
                      {getUonLabel(uon.uonReserveSize)} m<sup>2</sup>
                    </sc.ReserveLabel>
                    <sc.InfoLabel data-cy="SectionTwoSlide-InfoLabel">
                      <DangerouslyHTMLRenderer>
                        {t("protect.section-two-v202208.label", {
                          uonCount: uon.uonReserveSize,
                        })}
                      </DangerouslyHTMLRenderer>
                    </sc.InfoLabel>
                    <sc.PriceLabel
                      data-cy={`SectionTwoSlide-UONPrice${uon.price}`}
                    >
                      {uon.price}
                    </sc.PriceLabel>
                    <sc.Button>
                      <scLayoutProtect.ButtonLinkPrimaryBuy
                        data-cy={`SectionTwoSlide-ProtectBtn${uon.uonReserveSize}`}
                        data-track-id={`protectPage_fold-section2_btn-${uon.uonReserveSize}uon`}
                        onClick={(event) => {
                          event.preventDefault();
                          props.driver.updateUonSize(
                            uon.uonReserveSize,
                            `SectionTwoSlide-ProtectBtn${uon.uonReserveSize}`,
                          );
                          protectflowStarted(event);
                        }}
                      >
                        {t("protect.section-two-v202208.protect-btn")}
                      </scLayoutProtect.ButtonLinkPrimaryBuy>
                    </sc.Button>
                  </sc.Item>
                ))}
              </Slider>
            </sc.Slider>
          </sc.SliderWrapper>
          <sc.FinancialButton
            data-cy="SectionTwo-FinancialButton"
            href={`https://financials.earthtoday.com/?${utmSourceValue}`}
            target={"_blank"}
          >
            <>{t("protect.section-two-v202208.financial-btn-update")}</>
          </sc.FinancialButton>
          <sc.DescribeWrapper>
            <sc.Describe data-cy="SectionTwo-Describe">
              {t("protect.section-two-v202208.desc")}
            </sc.Describe>
          </sc.DescribeWrapper>
          <sc.DescribeWrapper>
            <sc.ReceiptSection>
              <sc.ModalReceiptWrapper>
                <ModalReceipt driver={receiptDriver} />
              </sc.ModalReceiptWrapper>
              <sc.ReceiptDescribeWrapper>
                <sc.DescribeText data-cy="SectionTwo-ReceiptDescription">
                  <DangerouslyHTMLRenderer>
                    {t("protect.section-two-v202208.receipt-desc")}
                  </DangerouslyHTMLRenderer>
                </sc.DescribeText>
                <sc.DescribeSubText data-cy="SectionTwo-ReceiptDescriptionBy">
                  <DangerouslyHTMLRenderer>
                    {t("protect.section-two-v202208.receipt-desc-sub")}
                  </DangerouslyHTMLRenderer>
                </sc.DescribeSubText>
                <sc.ButtonWrapper>
                  <Button
                    driver={{
                      dataCy: "SectionTwo-LearnMore",
                      type: "transparent",
                      round: true,
                      width: "170px",
                      height: "40px",
                      textTransform: "uppercase",
                      fontSize: "16px",
                      fontStretch: "condensed",
                      borderColor: etGrayBlue,
                      bgColor: props.driver.isSectionTwoSubSectionActivated
                        ? etGrayBlue
                        : undefined,
                      color: props.driver.isSectionTwoSubSectionActivated
                        ? etWhite
                        : undefined,
                      onClick: () => {
                        props.driver.toggleSectionTwoSubSection();
                        if (props.driver.isSectionTwoSubSectionActivated) {
                          scroller.scrollTo("whereYourMoneyGoesSection", {
                            duration: 500,
                            smooth: true,
                          });
                        }
                      },
                    }}
                  >
                    {props.driver.isSectionTwoSubSectionActivated
                      ? t("protect.section-two-v202208.close")
                      : t("protect.section-two-v202208.business-model")}
                  </Button>
                </sc.ButtonWrapper>
              </sc.ReceiptDescribeWrapper>
            </sc.ReceiptSection>
          </sc.DescribeWrapper>
        </sc.Container>
        <sc.DescribeWrapper>
          {props.driver.isSectionTwoSubSectionActivated && (
            <Element name="whereYourMoneyGoesSection">
              <WhereYourMoneyGoes driver={props.driver} />
            </Element>
          )}
        </sc.DescribeWrapper>
      </sc.Wrapper>
    </scLayoutProtect.Section>
  );
});
