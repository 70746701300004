import styled from "styled-components";

import {
  largeScreenLowerLimit,
  mediumScreenUpperLimitValue,
  xLargeScreenLowerLimit,
  xmediumScreenLowerLimit,
} from "../../../shared/breakpoints";
import {
  etGray,
  etGrayBold,
  etLicoriceBlue,
  etNevadaGrey,
  etSolitudeLight,
  etWhite,
} from "../../../shared/colors";

namespace sc {
  export const Wrapper = styled.div<{ expendLearnMore?: boolean }>`
    display: flex;
    padding: 100px 0px 50px 0;
    background-color: ${etSolitudeLight};

    @media (max-width: ${largeScreenLowerLimit}) {
      padding-top: 16px;
    }

    ${(props) =>
      props.expendLearnMore !== undefined &&
      props.expendLearnMore === false &&
      `
      max-height: 809px;
      @media (max-width: ${mediumScreenUpperLimitValue}) {  
        max-height: 833px;
      }

      @media (max-width: ${xmediumScreenLowerLimit}) {  
        max-height: 748px;
      }
    `}

    ${(props) =>
      props.expendLearnMore !== undefined &&
      props.expendLearnMore === true &&
      `
      max-height: 1413px;
      @media (max-width: ${mediumScreenUpperLimitValue}) {  
        max-height: 1382px;
      }

      @media (max-width: ${xmediumScreenLowerLimit}) {  
        max-height: 1251px;
      }
    `}
  `;
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `;

  export const NatureNeedsHalf = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 58px 50px 37px;
    background-color: ${etSolitudeLight};

    @media (max-width: ${xLargeScreenLowerLimit}) {
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      padding-top: 16px;
      padding-bottom: 42px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      padding-top: 27px;
      padding-bottom: 50px;
    }
  `;

  export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    min-height: 588px;
    max-height: 588px;
    max-width: 1396px;
    min-width: 1396px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      justify-content: space-between;
      max-width: 1032px;
      min-width: 1032px;
      min-height: 564px;
      max-height: 564px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      flex-flow: column-reverse;
      min-height: 709px;
      max-height: 709px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex-flow: column-reverse;
      min-height: 604px;
      max-height: 604px;
    }
  `;

  export const CardRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 504px;
    max-height: 504px;
    max-width: 1407px;
    min-width: 1407px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      max-width: 1046px;
      min-width: 1046px;
      > a:last-child {
        display: none;
      }
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      justify-content: space-between;
      min-height: 504px;
      max-height: 504px;
      max-width: calc(334px * 2 + 23px);
      min-width: calc(334px * 2 + 23px);

      > a:last-child,
      a:nth-child(3) {
        display: none;
      }
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      justify-content: center;
      min-height: 504px;
      max-height: 504px;
      max-width: 334px;
      min-width: 334px;

      > a:nth-child(2n),
      a:nth-child(3) {
        display: none;
      }

      > a:first-child {
        visibility: visible;
      }
    }
  `;

  export const Title = styled.h1`
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.5px;
    width: 100%;
    text-align: left;
    margin: 0;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      font-size: 40px;
      line-height: 45px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      font-size: 25px;
      line-height: 30px;
    }
  `;

  export const SubTitle = styled.div`
    color: ${etLicoriceBlue};
    font-family: "Roboto Condensed";
    font-size: 24px;
    line-height: 29px;
    font-weight: normal;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 27px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      font-size: 16px;
      line-height: 19px;
    }
  `;

  export const Description = styled.p`
    color: ${etNevadaGrey};
    font-family: Roboto;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    letter-spacing: 0px;
    max-width: 688px;
    min-height: 120px;
    max-height: 120px;
    margin-bottom: 0;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      min-height: 140px;
      max-height: 140px;
      min-width: 469px;
      max-width: 469px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      font-size: 16px;
      line-height: 24px;
      min-height: 120px;
      max-height: 120px;
      min-width: 469px;
      max-width: 469px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      font-size: 16px;
      line-height: 24px;
      min-height: 168px;
      max-height: 168px;
      min-width: 328px;
      max-width: 328px;
    }
  `;

  export const Left = styled.div`
    min-height: 318px;
    max-height: 318px;
    min-width: 688px;
    max-width: 688px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      flex: 1;
      min-width: 469px;
      max-width: 469px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      flex: 1;
      margin-left: 0;
      margin-top: 8px;
      min-width: 469px;
      max-width: 469px;
      min-height: 296px;
      max-height: 296px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      flex: 1;
      margin-left: 0;
      margin-top: 4px;
      min-width: 328px;
      max-width: 328px;
      min-height: 326px;
      max-height: 326px;
    }
  `;

  export const Right = styled.div`
    min-width: 534px;
    max-width: 534px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      min-width: 512px;
      max-width: 512px;
      min-height: 564px;
      max-height: 564px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      min-width: 367px;
      max-width: 367px;
      min-height: 405px;
      max-height: 405px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      min-width: 249px;
      max-width: 249px;
      min-height: 274px;
      max-height: 274px;
    }
  `;

  export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    min-height: 40px;
    max-height: 40px;
    margin: 32px 0 0;
  `;

  export const Button = styled.button`
    min-height: 40px;
    max-height: 40px;
    min-width: 150px;
    max-width: 150px;
    border-radius: 20px;
    border: 1px solid ${etGray};
    cursor: pointer;
  `;

  export const LearnMoreBtn = styled(Button)`
    border-color: ${etGray};
    background-color: transparent;
    color: ${etGray};
    font-family: "Roboto Condensed";
    text-transform: uppercase;

    &:hover {
      color: ${etGrayBold};
    }
  `;

  export const CloseBtn = styled(Button)`
    border-color: ${etGrayBold};
    background-color: ${etGrayBold};
    color: ${etWhite};
    font-family: "Roboto Condensed";

    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const ImageWrapper = styled.div`
    position: relative;
  `;

  export const Image = styled.div<{ bg: string }>`
    background-size: contain;
    min-width: 534px;
    min-height: 588px;
    max-width: 534px;
    max-height: 588px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      min-width: 512px;
      max-width: 512px;
      min-height: 564px;
      max-height: 564px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      min-width: 367px;
      max-width: 367px;
      min-height: 405px;
      max-height: 405px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      min-width: 249px;
      max-width: 249px;
      min-height: 274px;
      max-height: 274px;
    }
  `;

  export const LineWrapper = styled.div`
    position: absolute;
    top: 68px;
    left: 54px;
    min-width: 224.5px;
    min-height: 440px;
    overflow: hidden;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      top: 67px;
      left: 52px;
      min-width: 215px;
      min-height: 420px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      top: 48px;
      left: 37px;
      min-width: 154px;
      min-height: 305px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      top: 32px;
      left: 25.5px;
      min-width: 104.5px;
      min-height: 205px;
    }
  `;

  export const Line = styled.div<{ bg: string; rotateDeg: number }>`
    background-image: url(${(props) => props.bg});
    background-repeat: no-repeat;
    background-position: left;
    background-size: 52% 100%;

    transform: rotate(${(props) => props.rotateDeg + 180}deg);

    position: absolute;
    min-width: 440px;
    min-height: 440px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      min-width: 420px;
      min-height: 420px;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      min-width: 302px;
      min-height: 302px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      min-width: 205px;
      min-height: 205px;
    }
  `;

  export const ExpandContainer = styled.div`
    overflow: hidden;
  `;

  export const ExpandContract = styled.div<{
    expand?: boolean;
    disableAnimation: boolean;
  }>`
    margin-top: ${(props) => (props.expand ? "0" : "-100%")};
    ${(props) =>
      !props.disableAnimation &&
      `
      -moz-transition: linear 0.75s;
      -ms-transition: linear 0.75s;
      -o-transition: linear 0.75s;
      -webkit-transition: linear 0.75s;
      transition: linear 0.75s;
    `}

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 0 0 100px;
    background-color: ${etSolitudeLight};

    &.expanded {
      margin-top: 0;
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      padding-bottom: 45px;
    }

    @media (max-width: ${xmediumScreenLowerLimit}) {
      margin-top: ${(props) => (props.expand ? "0" : "-503px")};
      padding-bottom: 0;
    }
  `;

  export const StaticCardImg = styled.a<{ bg: string }>`
    background: url(${(props) => props.bg}) center center no-repeat;
    background-size: 334px 504px; // static image include shadow

    // fix problem with image being blurry after scaling
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

    height: 504px;
    width: 334px;
    max-height: 504px;
    max-width: 334px;
  `;
}

export default sc;
