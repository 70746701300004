import { observer } from "mobx-react-lite";
import React from "react";

import { etBrightLightBlue, etHeatherBlue } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import { CardItemMetaData } from "./CardItem";
import { sc } from "./CardItemFooter.styled";

export interface CardItemMusicFooterActionDriver {
  readonly metadata: CardItemMetaData | null;
  starActive: boolean;
  cardType?: string;
  cardRepostIdUpdate(): void;
  toggleStarActive(): void;
  toggleShareActive(): void;
  openContextMenu(): void;
  closeContextMenu(): void;
  onStarCard(): void;
}

interface IProps {
  driver: CardItemMusicFooterActionDriver;
}

export const CardItemFooterAction = observer(function CardItemFooterAction(
  props: IProps,
) {
  const { driver } = props;
  return (
    <sc.CardActionsContainer
      data-cy="CardItemFooter-CardAction"
      onClick={() => {
        driver.onStarCard();
      }}
    >
      <sc.CardActions>
        <IconSvg
          dataCy="CardAction-StarIcon"
          icon="carditem-star-v2"
          pointer
          padding="0"
          width="20px"
          height="20px"
          color={driver.metadata?.starred ? etBrightLightBlue : etHeatherBlue}
          hoverColor={etBrightLightBlue}
        />
      </sc.CardActions>
    </sc.CardActionsContainer>
  );
});
