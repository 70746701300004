/* eslint-disable @typescript-eslint/no-loop-func */
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useRef, useState } from "react";

import { getCounterEffect } from "../../shared/helpers/getCounterEffect";

interface IProps {
  protectCount: number;
  renderChildren: (counter: number) => ReactNode;

  flags: {
    counterFlipEffectSetInterval: number;
    counterFlipEffectTimeReroll: number;
  };
}
export const CounterFlipEffect = observer((props: IProps) => {
  const { protectCount, renderChildren, flags } = props;
  const [counter, setCounter] = useState(protectCount);
  const initTimeWaitEffect = useRef(Date.now());
  const initCounter = useRef(protectCount);
  const [shouldUseFlipEffect, setShouldUseFlipEffect] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const isUseFlipEffect =
        initCounter.current === protectCount &&
        Date.now() - initTimeWaitEffect.current >=
          flags.counterFlipEffectSetInterval;
      setShouldUseFlipEffect(isUseFlipEffect);
    }, 1000);
    return () => clearInterval(interval);
  }, [protectCount]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    const timeoutArray: ReturnType<typeof setTimeout>[] = [];
    if (shouldUseFlipEffect) {
      interval = setInterval(() => {
        let ms = 0;
        for (let i = 0; i < flags.counterFlipEffectTimeReroll; i++) {
          timeoutArray.push(
            setTimeout(() => {
              setCounter(getCounterEffect(protectCount));
            }, ms),
            setTimeout(() => {
              setCounter(protectCount);
            }, (ms += 500)),
          );
          ms += 500;
        }
      }, flags.counterFlipEffectSetInterval);
    } else {
      setCounter(protectCount);
      initCounter.current = protectCount;
    }

    return () => {
      clearInterval(interval);
      for (const timeout of timeoutArray) {
        clearTimeout(timeout);
      }
    };
  }, [protectCount, shouldUseFlipEffect]);

  return <>{renderChildren(counter)}</>;
});
