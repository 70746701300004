import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etIceBlue,
  etWhite,
} from "../../shared/colors";
import { theLoaderSc } from "../TheLoader/TheLoader.styled";

namespace sc {
  export const SearchContainer = styled.div<{ width?: string }>`
    position: relative;
    display: flex;
    justify-content: center;
    ${(props) => props.width && `min-width: ${props.width}`}
    margin: 0 12px;

    @media (max-width: ${smallScreenUpperLimit}) {
      position: static;
    }
  `;
  export const SearchSubmit = styled.label<{
    searchInputFocus: boolean;
    mLandscape?: boolean;
  }>`
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-right: 22px;
    cursor: pointer;
    @media (max-width: ${smallScreenUpperLimit}) {
      display: flex;
      justify-content: center;
      z-index: 1;
      margin-right: 10px;
    }
    > div > svg {
      @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
        fill: ${(props) =>
          props.searchInputFocus
            ? props.theme.themeStyle.searchIconActiveColor
            : props.theme.themeStyle.searchIconColor};
      }
      // to detect mobile landscape
      @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
        fill: ${(props) =>
          props.searchInputFocus ? etBrightLightBlue : etWhite};
      }
      fill: ${(props) =>
        props.mLandscape
          ? props.searchInputFocus
            ? etBrightLightBlue
            : etWhite
          : "unset"};
    }
  `;
  export const Searching = styled.div`
    background-image: url(${require("../../assets/svgs/et-com-logo.svg")});
    position: absolute;
    right: 0;
    content: "";
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.125rem;
    animation: ${theLoaderSc.loaderRotate} 3s linear infinite;
  `;

  export const SearchReserve = styled.div<{
    userLoggedIn: boolean;
    width?: string;
  }>`
    display: block;
    padding: 12px 0;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: ${(props) => (props.width ? props.width! : "450px")};
    background: ${etWhite};
    border-radius: 0 0 4px;

    @media (max-width: ${smallScreenUpperLimit}) {
      position: fixed;
      top: 63px;
      width: calc(100% - 19px);
      margin: 0 auto;
      left: -8px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      top: ${(props) => (props.userLoggedIn ? "40px" : "50px")};
      width: 400px;
    }
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      position: absolute;
      width: 100vw;
      left: -9px;
      top: 64px;
      background: ${(props) => props.theme.themeStyle.defaultBackground};
      width: 351px;
      max-width: 351px;
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background: ${etBlack};
    }
    &.mLandscape {
      background: ${etBlack};
    }
  `;

  export const FormGroup = styled.div<{
    borderColor: string;
    width?: string;
  }>`
    position: relative;
    > div {
      position: absolute;
      top: 11px;
      right: 18px;
    }
    > input {
      border-radius: 20px;
      border: 1px solid ${(props) => props.borderColor};
      height: 40px;
      max-height: 40px;
      width: ${(props) => (props.width ? props.width! : "450px")};
      padding: 12px 16px;
      flex: 1;
      min-width: 0;
      color: ${etBlack};
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 20px;
      background-color: ${etIceBlue};
      @media (max-width: ${mediumScreenUpperLimit}) {
        width: 400px;
      }
      @media (max-width: ${smallScreenUpperLimit}) {
        width: 100%;
      }
      @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
        border-color: ${(props) =>
          props.theme.themeStyle.theNavbarSearchInputBorderColor};
      }
      // to detect mobile landscape
      @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
        border-color: ${etBlack};
      }
      &.mLandscape {
        border-color: ${etBlack};
      }
    }
  `;

  export const SearchContainerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  `;

  export const SearchResultsWrapper = styled.div<{ shouldShowResult: boolean }>`
    display: ${(props) => (props.shouldShowResult ? "block" : "none")};
    position: absolute;
    background-color: ${etWhite};
    padding: 12px;
    min-width: 450px;
    box-shadow: 0px 16px 18px 0px rgba(42, 54, 66, 0.2);
    border-radius: 0 0 8px 8px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    width: 586px;
    max-width: 586px;
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background: ${etBlack};
    }
  `;
}

export default sc;
