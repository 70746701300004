import styled from "styled-components";

import {
  etBlack,
  etDark,
  etNevadaGrey,
  etSolitude,
  etWhite,
} from "../../shared/colors";
import { MasterLinkType } from "../../shared/models/Card";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const Container = styled.div`
    font-family: Roboto, sans-serif;
    height: 496px;
    padding-bottom: 0;
    background-color: ${etWhite};
    color: ${etBlack};
    .card-footer {
      > div > a {
        color: ${etBlack};
      }
    }
  `;
  export const TextContent = styled.div`
    flex: 1 1 0%;
    height: 78px;
    padding: 0px 12px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `;
  export const Text = styled.div<{ isCardDraftVideo: boolean }>`
    background-color: ${(props) =>
      props.isCardDraftVideo ? etBlack : etWhite};
    color: ${(props) => (props.isCardDraftVideo ? etWhite : etDark)};
    font-family: Roboto Slab;
    min-height: 94px;
    height: 100px;
    width: 304px;
    padding: 9px 0 12px 0;
    display: flex;
    flex-direction: column;
  `;
  export const Wrapper = styled.div`
    position: relative;
    margin: 0px 12px;
    height: 347px;
    width: 304px;
    border-bottom: 1px solid rgb(177, 185, 193);
  `;
  export const Header = styled.div<{ iconColor: string }>`
    border: none;
    display: flex;
    padding: 0 12px 0 12px;
    align-items: center;
    border-radius: 8px 8px 0 0;
    height: 44px;

    > div > svg {
      height: 18px;
      width: 19px;
      > g > g {
        stroke: ${(props) => props.iconColor};
        fill: ${(props) => props.iconColor};
      }
    }
  `;
  export const Logo = styled.div`
    width: 20px;
    height: 20px;
  `;
  export const Title = styled.div<{ isEmptyCategories: boolean }>`
    margin-left: ${(props) => (props.isEmptyCategories ? "19px" : "0")};
    cursor: pointer;
    font-family: Roboto;
    font-weight: 400;
    font-stretch: condensed;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 0;
    padding: 0 5px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${etNevadaGrey};
  `;
  export const ImgWrapper = styled.div<{ cardImg: string }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    height: 204px;
    width: 304px;
    background-image: url(${(props) => props.cardImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `;
  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 15;
  `;

  export const CardLink = styled.a`
    width: calc(100% - 48px);
    position: absolute;
    bottom: 12px;
    left: 12px;
    color: ${etWhite};
    flex: 1;
    z-index: 2;
    font-size: 12px;
    white-space: nowrap;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 14px;
    &:hover {
      text-decoration: none;
      color: ${etWhite};
    }
  `;
  export const RemovedCardBody = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    flex: 3;
    color: ${etBlack};
    background-color: ${etSolitude};
    padding: 16px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    max-height: 328px;
    min-height: 128px;
    font-weight: 600;
    > b {
      font-size: 10px;
      padding: 16px 0;
    }
  `;

  export const Footer = styled.div<{ cardType: MasterLinkType }>`
    padding: ${(props) =>
      props.cardType === "music" ? "15px 0 " : "15px 12px"};
    display: flex;
    flex-direction: column;
    height: 148px;
  `;

  export const CardActions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  `;

  export const NoDeckSelected = styled(Title)<{ isEmptyCategories: boolean }>`
    margin-left: ${(props) => (props.isEmptyCategories ? "19px" : "0")};
    pointer-events: none;
  `;

  export const CardCurator = styled.div<{ cardType: MasterLinkType }>`
    font-size: 12px;
    font-family: Roboto;
    font-stretch: condensed;
    font-weight: 400;
    height: 14px;
    letter-spacing: 0px;
    color: ${(props) => (props.cardType === "music" ? etWhite : etNevadaGrey)};
  `;
  export const WapperCuratorLink = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
  `;
  export const CardCuratorLink = styled.a`
    color: inherit;
    font-weight: 700;
    text-transform: capitalize;
    font-weight: bold;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  `;

  export const CardCuratorComment = styled.div`
    height: 61px;
    width: 301px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-align: left;
    margin-top: 8px;
  `;
  export const WapperContext = styled.div`
    width: 32px;
    height: 44px;
  `;
  export const IconPlayContainer = styled.div`
    position: absolute;
    width: 74px;
    height: 51px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${zIndex.CARDITEM_LINK};
  `;
}

export default sc;
