import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { Grid, Renderer } from "../Grid/Grid";
import sc from "./LayoutDeckDetail.styled";

interface IProps {
  renderHeader(): Renderer;
  renderCardProfile(): Renderer;
  renderCards(): Renderer[];
  renderSwitcherBanner?(): ReactNode;
  renderSystemPageTabs?(): ReactNode;
  renderOverlaySystemPage?(): ReactNode;
  isOverlayActive: boolean;
  driverMenu: { isCardJoiningOpen: boolean; isMenuAppOpen: boolean };
  mLandscape?: boolean; // to customize snapshot in visual test
}

export const LayoutDeckDetail = observer(function LayoutDeckDetail(
  props: IProps,
) {
  return (
    <sc.Container
      className={props.mLandscape ? "mLandscape" : ""}
      isOverlayActive={props.isOverlayActive}
      isCardJoiningOpen={props.driverMenu.isCardJoiningOpen}
      isMenuAppOpen={props.driverMenu.isMenuAppOpen}
    >
      {props.renderSwitcherBanner ? props.renderSwitcherBanner() : null}
      {props.renderSystemPageTabs ? props.renderSystemPageTabs() : null}
      {props.renderOverlaySystemPage ? props.renderOverlaySystemPage() : null}
      <Grid
        renderer={[
          props.renderHeader(),
          props.renderCardProfile(),
          ...props.renderCards(),
        ]}
      />
    </sc.Container>
  );
});
