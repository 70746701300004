import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { LayoutModal } from "../LayoutModal/LayoutModal";
import sc from "./ModalDeckReport.styled";

interface Driver {
  onClose(): void;
}

interface IProps {
  driver: Driver;
  renderModalWrapper(children: ReactNode): ReactNode;
}

export const ModalDeckReportLayout = observer((props: IProps) => {
  const { driver } = props;
  return (
    <LayoutModal
      onClose={() => driver.onClose()}
      renderModalWrapper={props.renderModalWrapper}
      renderModalContent={() => (
        <>
          <sc.Header>
            <sc.HeaderTitle data-cy="ModalReportDeck-HeaderTitle">
              Report this Deck
            </sc.HeaderTitle>
          </sc.Header>
          <sc.Body data-cy="ModalReportDeck-BodyText">
            <sc.Paragraph>
              If you believe this deck violates the EarthToday Terms &amp;
              Conditions, principles or guidelines, and should be adjusted or
              removed, please let us know.
            </sc.Paragraph>
            <sc.Contact data-cy="ModalReportDeck-Contact">
              <div>Contact us directly by emailing</div>
              <sc.ReportLink
                data-cy="ModalReportDeck-Email"
                href="mailto:support@earthtoday.com"
              >
                support@earthtoday.com
              </sc.ReportLink>
            </sc.Contact>
          </sc.Body>
        </>
      )}
    />
  );
});
