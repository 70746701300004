import React, { ReactNode } from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import { ModalType } from "../../stores/ModalStore";
import { LayoutModal } from "../LayoutModal/LayoutModal";
import sc from "./ModalProfileReport.styled";

interface Driver {
  openModal(modalTypes: ModalType): void;
}

interface IProps {
  driver: Driver;
  renderModalWrapper(children: ReactNode): ReactNode;
}

export const ModalProfileReportLayout = function (props: IProps) {
  const { driver } = props;

  const { t } = useTranslation("ProfileCard");

  return (
    <LayoutModal
      onClose={() => driver.openModal("")}
      renderModalWrapper={props.renderModalWrapper}
      renderModalContent={() => (
        <>
          <sc.Header>
            <sc.HeaderTitle data-cy="ModalReportProfile-HeaderTitle">
              {t("profilecard.button.report")}
            </sc.HeaderTitle>
          </sc.Header>
          <sc.Body data-cy="ModalReportProfile-BodyText">
            <sc.Paragraph>{t("profilecard.report-text")}</sc.Paragraph>
            <sc.Contact data-cy="ModalReportProfile-Contact">
              <div>{t("profilecard.contact-text")}</div>
              <sc.ReportLink
                data-cy="ModalReportProfile-Email"
                href="mailto:support@earthtoday.com"
              >
                support@earthtoday.com
              </sc.ReportLink>
            </sc.Contact>
          </sc.Body>
        </>
      )}
    />
  );
};
