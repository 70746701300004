import styled from "styled-components";

import {
  largeScreenLowerLimit,
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  xLargeScreenLowerLimit,
} from "../../../shared/breakpoints";
import { etBlack, etSolitudeLight } from "../../../shared/colors";

namespace sc {
  export const Container = styled.div`
    min-height: 835px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    background-color: ${etSolitudeLight};
  `;

  export const SectionTitle = styled.h2`
    color: ${etBlack};
    font-family: Roboto;
    font-size: 60px;
    font-weight: 300;
    height: 65px;
    letter-spacing: -0.5px;
    line-height: 65px;

    > span > b {
      font-weight: 700;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      font-family: Roboto;
      font-size: 25px;
      font-weight: 300;
      height: 30px;
      letter-spacing: -0.5px;
      line-height: 30px;
    }
  `;

  export const SectionSubTitle = styled.p`
    color: ${etBlack};
    font-family: Roboto Condensed;
    font-size: 24px;
    font-weight: normal;
    height: 29px;
    letter-spacing: 0px;
    line-height: 29px;
    text-transform: uppercase;

    @media (max-width: ${mediumScreenLowerLimit}) {
      font-family: Roboto Condensed;
      font-size: 16px;
      font-weight: normal;
      height: 19px;
      letter-spacing: 0px;
      line-height: 19px;
      text-transform: uppercase;
    }
  `;

  export const TitleWrapper = styled.div`
    margin-top: 50px;
    padding: 0 8px;
    width: 100%;
    max-width: calc(328px * 4 + 24px * 3);

    @media (max-width: ${largeScreenUpperLimit}) {
      max-width: calc(328px * 3 + 24px * 2);
    }
    @media (max-width: ${largeScreenLowerLimit}) {
      max-width: calc(328px * 2 + 24px * 1);
    }
  `;

  export const CustomGrid = styled.div`
    display: grid;
    min-height: 520px;
    position: relative;
    grid-template-columns: repeat(auto-fill, 328px);
    grid-auto-rows: minmax(496px, auto);
    grid-gap: 24px;
    justify-content: center;
    padding: 24px 0;
    width: 100%;
    max-width: ${xLargeScreenLowerLimit};
  `;
}

export { sc };
