import { observer } from "mobx-react-lite";
import React from "react";

import { etWhite } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { Button } from "../Button/Button";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./TheNavBarCreateCollectibleButton.styled";

export interface TheNavbarCreateCollectibleButtonDriver {
  readonly userLoggedIn: boolean;
  onCreateCollectableCardMenuItemClicked(): void;
}

interface IProps {
  driver: TheNavbarCreateCollectibleButtonDriver;
}

export const TheNavbarCreateCollectibleButton = observer(
  function TheNavbarCreateCollectibleButton(props: IProps) {
    const { t: tGeneral } = useTranslation("General");

    const renderButton = () => {
      if (!props.driver.userLoggedIn) {
        return null;
      }

      return (
        <sc.Container className="navbar-nav">
          <Button
            driver={{
              type: "primary",
              dataCy: "TheNavbarLinkItems-createCollectibleButton",
              width: "113px",
              fontStretch: "condensed",
              margin: "0 21px 0 0",
              round: true,
              mobileWidth: "70px",
              color: etWhite,
              fontWeight: "normal",
              fontFamily: "Roboto Condensed",
              onClick: () =>
                props.driver.onCreateCollectableCardMenuItemClicked(),
            }}
          >
            <IconSvg
              icon="add"
              color={etWhite}
              pointer
              width="14px"
              height="14px"
              padding="0"
              margin="0 6.5px 0 0"
            />{" "}
            {tGeneral("general.nav-top-create-collectible-card")}
          </Button>
        </sc.Container>
      );
    };

    return <>{renderButton()}</>;
  },
);
