export const getSocialNetworkShareLink = (
  socialNetworkName: string,
  shareUrl: string,
  title?: string,
  imageId?: string,
) => {
  switch (socialNetworkName) {
    case "facebook": {
      return `https://facebook.com/sharer/sharer.php?u=${shareUrl}`;
    }

    case "twitter": {
      return `https://twitter.com/share?url=<${shareUrl}>`;
    }

    case "whatsapp": {
      return `https:///wa.me/?text=${shareUrl}`;
    }

    case "pinterest": {
      return `https://in.pinterest.com/pin/create/button/?url=${shareUrl}&description=${title}&media=${imageId}?width=1440`;
    }

    case "linkedin": {
      return `https://linkedin.com/sharing/share-offsite/?url=${shareUrl}`;
    }

    default: {
      return "";
    }
  }
};
