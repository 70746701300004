import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { Button } from "../Button/Button";
import { sc } from "./CookiePolicy.styled";

export interface Driver {
  shouldShowCookieBanner: boolean;
  isCookieConsentRegion: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  onAcceptAllCookies(): void;
}

interface IProps {
  cookiePolicyRef?: React.RefObject<HTMLDivElement>;
  driver: Driver;
  flags: {
    enableCookieBannerAtBottom: boolean;
  };
}

export const CookiePolicy = observer((props: IProps) => {
  const { driver, flags } = props;

  return driver.shouldShowCookieBanner ? (
    <sc.Container
      ref={props.cookiePolicyRef}
      isFixed={flags.enableCookieBannerAtBottom}
      className={["visual-reg", driver.mLandscape ? "mLandscape" : ""].join(
        " ",
      )}
      data-cy="Cookie-Policies"
    >
      <sc.CenterBox className="container">
        <sc.Wrapper>
          <sc.CookiePolicy>
            <sc.Title className={driver.mLandscape ? "mLandscape" : ""}>
              Cookie Policy
            </sc.Title>
            <sc.Content className={driver.mLandscape ? "mLandscape" : ""}>
              We place cookies in order to remember your settings and to enrich
              your experience with social media integrations such as the viewing
              of embedded videos. You can read more about our use of cookies in
              our&nbsp;
              <sc.ContentLinkWrapper>
                <Link
                  href="/policies/[param]"
                  as="/policies/cookie-policy"
                  passHref
                  legacyBehavior
                >
                  <sc.ContentLink
                    className={driver.mLandscape ? "mLandscape" : ""}
                  >
                    Cookie Policy
                  </sc.ContentLink>
                </Link>
                &nbsp;
              </sc.ContentLinkWrapper>
              and change your settings in our&nbsp;
              <sc.ContentLinkWrapper>
                <Link
                  href="/policies/[param]"
                  as="/policies/cookie-policy"
                  passHref
                  legacyBehavior
                >
                  <sc.ContentLink
                    className={driver.mLandscape ? "mLandscape" : ""}
                  >
                    Cookie Settings
                  </sc.ContentLink>
                </Link>
                .&nbsp;
              </sc.ContentLinkWrapper>
              Please note that our&nbsp;
              <sc.ContentLinkWrapper>
                <Link
                  href="/policies/[param]"
                  as="/policies/terms-of-service"
                  passHref
                  legacyBehavior
                >
                  <sc.ContentLink
                    className={driver.mLandscape ? "mLandscape" : ""}
                  >
                    disclaimer
                  </sc.ContentLink>
                </Link>
                &nbsp;
              </sc.ContentLinkWrapper>
              applies to your use of the EarthToday website
            </sc.Content>
          </sc.CookiePolicy>
          <sc.Control>
            <Button
              driver={{
                dataCy: "AcceptCookie-Button",
                type: "primary",
                width: "110px",
                fontStretch: "condensed",
                fontSize: "16px",
                textTransform: "uppercase",
                round: true,
                onClick: driver.onAcceptAllCookies,
              }}
            >
              Accept
            </Button>
            <Link
              href="/policies/[param]"
              as="/policies/cookie-policy"
              passHref
              legacyBehavior
            >
              <sc.Setting>Settings</sc.Setting>
            </Link>
          </sc.Control>
        </sc.Wrapper>
      </sc.CenterBox>
    </sc.Container>
  ) : (
    <></>
  );
});
