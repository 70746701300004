import { KeyboardEvent } from "react";

export function isValidNumberInput(e: KeyboardEvent<HTMLInputElement>) {
  const validKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "Delete",
  ];

  if (validKeys.includes(e.key)) {
    return true;
  }
  e.preventDefault();
  return false;
}
