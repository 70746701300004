import styled from "styled-components";

import {
  etBlack,
  etBlue,
  etBrightLightBlue,
  etRed,
  etRegentGray,
  etSolitudeLight,
  etVoteRed,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

export namespace sc {
  export const ContextMenuItemBlue = styled.span`
    display: flex;
    color: ${etRegentGray};
    font-family: Roboto;
    font-stretch: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0;

    > a {
      color: ${etRegentGray};
      margin-left: 12px;
      text-decoration: none;
    }
  `;

  export const ContextMenuItemRed = styled.span<{ location?: string }>`
    color: ${etVoteRed};
    font-family: Roboto;
    font-stretch: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0;
    margin-left: ${(props) => (props.location === "home" ? "12px" : "15px")};
  `;

  export const ContextMenuRow = styled.div<{
    hoverColor?: string;
  }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 190px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid ${etSolitudeLight};
    white-space: nowrap;
    color: ${etBlack};
    > a {
      color: ${etBlack};
      text-decoration: none;
    }

    :hover {
      background-color: ${transparentize(etBrightLightBlue, 0.1)};
      border-color: ${transparentize(etBrightLightBlue, 0.1)};
    }

    :hover > ${ContextMenuItemBlue}, :hover a {
      color: ${etBlue};
    }

    :hover > ${ContextMenuItemRed} {
      color: ${etRed};
    }

    :hover > ${IconSvgContainer} {
      fill: ${(props) => props.hoverColor || etBlue};
    }

    :hover > svg {
      fill: ${(props) => props.hoverColor || etBlue};
    }

    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
  `;
  export const MenuItemText = styled.p`
    margin: 0 0 0 12px;
  `;
}
