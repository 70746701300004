import styled from "styled-components";

import {
  etBlack,
  etBrightLightBlue,
  etBrightTurquoise,
  etGray,
  etGreenSquare,
  etHeatherBlue,
  etLicoriceBlue,
  etNevadaGrey,
  etScarletRed,
  etStormGray,
  etVoteRed,
  etWhite,
} from "../../shared/colors";
import { UPTOBILLION } from "../../shared/constants";
import { getColorProtected } from "../../shared/helpers/getColorProtected";
import {
  CARD_PROFILE_IMAGE_HEIGHT,
  CARD_PROFILE_IMAGE_WIDTH,
} from "../../stores/UserModel";

namespace sc {
  export const LinkProfile = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
  `;
  export const LinkProfileSwitchToken = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
  `;
  export const ProtectedNumber = styled.span`
    position: absolute;
    top: 52px;
    left: 20px;
    z-index: 6;
    height: 32px;
    width: 32px;
    font-size: 12px;
    color: ${etStormGray};
    padding-top: 2px;
    display: flex;
    justify-content: center;
  `;
  export const SquareProtected = styled.span<{ protected: number }>`
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 5;
    height: 32px;
    width: 32px;
    background-color: ${(props) =>
      props.protected > UPTOBILLION
        ? "transparent"
        : getColorProtected(props.protected)};
    transform: rotate(45deg);
    border: 1px solid ${(props) => getColorProtected(props.protected)};
    ::before {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      width: 45px;
      background-color: transparent;
      border-top: 1px solid ${(props) => getColorProtected(props.protected)};
      transform: translate(-8px, 14px);
    }
    ::after {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      height: 45px;
      background-color: transparent;
      border-left: 1px solid ${(props) => getColorProtected(props.protected)};
      transform: translate(14px, -9px);
    }
  `;
  export const CircleProtected = styled.span<{ protected: number }>`
    position: absolute;
    top: 13px;
    left: 13px;
    z-index: 4;
    height: 46px;
    width: 46px;
    overflow: hidden;
    background-color: ${etWhite};
    border-radius: 50%;
    border: 1px solid ${(props) => getColorProtected(props.protected)};
    ::after {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      width: 46px;
      background-color: transparent;
      border-bottom: 1px solid ${(props) => getColorProtected(props.protected)};
      transform: translate(-1px, 21px);
    }
  `;
  export const UonReserveSizeUnit = styled.span<{ protected: number }>`
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 6;
    height: 32px;
    width: 32px;
    background-color: ${(props) =>
      props.protected > UPTOBILLION ? "transparent" : etWhite};
    border: ${(props) => (props.protected > UPTOBILLION ? "1px" : "2px")} solid
      ${(props) => getColorProtected(props.protected)};
    color: ${(props) => getColorProtected(props.protected)};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    ::before {
      content: "";
      display: ${(props) =>
        props.protected > UPTOBILLION ? " block" : "none"};
      height: 44px;
      background-color: transparent;
      border-right: 1px solid ${(props) => getColorProtected(props.protected)};
      transform: translate(0px, 7px);
    }
  `;
  export const M2Logo = styled.div<{ display: "none" | "block" }>`
    font-family: Roboto;
    font-weight: bold;
    font-size: 15px;
    line-height: 35px;
    margin-bottom: -8px;
    margin-right: 5px;
    display: ${(props) => props.display};
    text-transform: lowercase;
  `;
  export const Metric = styled.sup`
    position: absolute;
    top: 15px;
    font-size: 9px;
  `;
  export const IconContainer = styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
  `;
  export const Container = styled.div`
    position: relative;
    overflow: hidden;
    color: ${etBlack};

    width: 328px;
    height: 496px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    background-color: ${etWhite};
  `;
  export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 3px 12px 24px 12px;
  `;
  export const CardHeaderImgWrapper = styled.div`
    height: 154px;
    width: 100%;
    margin-top: 22px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  `;
  export const CardContentWrapper = styled.div`
    height: 208px;
  `;
  export const CardHeaderImg = styled.div<{
    src: string;
    pointer: boolean;
    zIndex?: number;
  }>`
    width: ${CARD_PROFILE_IMAGE_WIDTH}px;
    height: ${CARD_PROFILE_IMAGE_HEIGHT}px;
    border-radius: 50%;

    background-image: url(${(props) => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    cursor: ${(props) => (props.pointer ? "pointer" : "default")};
    z-index: ${(props) => props.zIndex || 0};
  `;
  export const CardHeaderImgCrossair = styled(CardHeaderImg)<{ src: string }>`
    background-position: center;
    z-index: 10;
  `;
  export const Title = styled.div<{ isProfileTagOverride?: string }>`
    font-family: Roboto Condensed, sans-serif;
    min-height: ${(props) => (props.isProfileTagOverride ? "unset" : "31px")};
    max-height: ${(props) => (props.isProfileTagOverride ? "unset" : "54px")};
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${etLicoriceBlue};
  `;
  export const SubTitle = styled.div`
    margin-top: 8px;

    height: 11px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${etGray};

    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

  export const Tagline = styled.div<{ isProfileTagOverride?: string }>`
    margin-top: ${(props) => (props.isProfileTagOverride ? "5px" : "unset")};
    height: 11px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${etGray};

    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

  export const UserType = styled.div`
    background-color: #f4fcf1;
    color: #498c31;
    height: 1rem;
    font-size: 0.5rem;
    line-height: 1rem;
    letter-spacing: 0.8px;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
    text-transform: uppercase;
    font-weight: 700;
  `;

  export const M2Wrapper = styled.div`
    flex: 1;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `;
  export const M2 = styled.img`
    width: 30px;
    height: 30px;
  `;
  export const Protected = styled.div`
    position: absolute;
    bottom: 54px;
    margin-bottom: 13px;

    height: 26px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${etGreenSquare};
  `;
  export const UploadImageDescription = styled.div`
    width: 100%;
    height: 97px;
    padding: 0 28px;
    margin: 18px 0;

    color: ${etNevadaGrey};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 20px;
  `;
  export const Bio = styled.div`
    width: 100%;
    margin: 18px 0;

    height: 97px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: ${etNevadaGrey};

    position: relative;
  `;
  export const BioOpenLink = styled.a`
    font-weight: 500;
    cursor: pointer;
    color: ${etLicoriceBlue};

    &:hover {
      color: ${etBrightLightBlue};
      text-decoration: none;
    }
  `;

  export const Stats = styled.div`
    position: absolute;
    bottom: 93px;
    margin: 0 auto 9px auto;

    height: 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  `;
  export const DecksCount = styled.span`
    color: ${etScarletRed};
  `;
  export const FollowersCount = styled.span`
    color: ${etBrightLightBlue};
  `;
  export const Hyphen = styled.span`
    margin: 0 5px;
    color: ${etGray};
  `;
  export const Footer = styled.div`
    position: absolute;
    bottom: 24px;
    z-index: 10;
    margin: 0 auto 0 auto;
    height: 32px;
    display: flex;

    > a {
      width: 33px;
      height: 32px;
      border-radius: 50%;
      border: solid 1px ${etHeatherBlue};

      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      margin-right: 13px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
          0px 2px 3px 0px rgba(0, 0, 0, 0.1);
      }
    }
  `;

  export const ContextWrapper = styled.div<{ contextMenuActive: boolean }>`
    position: absolute;
    z-index: ${(props) => (props.contextMenuActive ? "17" : "16")};
    top: 6px;
    right: 20px;
    width: 30px;
    height: 50px;
  `;
  export const OverlayContextMenu = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${etBlack};
    opacity: 0.6;
    z-index: 12;
  `;
  export const Joined = styled.p`
    color: ${etGray};
    font-family: Roboto;
    font-stretch: condensed;
    font-weight: normal;
    font-size: 10px;
    height: 20px;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 0;
  `;
  export const UserCounts = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: condensed;
    height: 20px;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    width: 100%;
  `;
  export const DeckCounts = styled.span`
    color: ${etVoteRed};
  `;
  export const FollowerCounts = styled.span`
    color: ${etBrightTurquoise};
  `;

  export const UnpublishContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: absolute;
    bottom: 8px;
    z-index: 2;
  `;

  export const UnpublishedMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${etNevadaGrey};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    height: 33px;
    width: 290px;
  `;
  export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
  `;

  export const SwitchTokenContainer = styled.div`
    position: absolute;
    left: 24px;
    bottom: 24px;
    z-index: 2;
  `;
}

export default sc;
