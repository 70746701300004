/* eslint-disable no-plusplus */
import mapboxgl, { Map } from "mapbox-gl";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import FlagPole from "../../assets/img/flagpole.png";
import M2Blue from "../../assets/svgs/m2-green.svg";
import { snapToGrid } from "../../shared/helpers/ww";
import { random, sample } from "../../shared/lodash";
import {
  FlightPathItem,
  GeoData,
} from "../../stores/FlyingOverPresenter/FlyingOverPresenter";
import { namesList } from "./Names";

mapboxgl.accessToken =
  "pk.eyJ1Ijoiam9yaXNlYXJ0aHRvZGF5IiwiYSI6ImNrMjFoeGY5aDFhZ2IzZXVnY25nbmNlNWIifQ.bsFpboBSyiwLSgOALI2YXg";

const generateData = (): GeoData[] => {
  const possibleNames: string[] = namesList;
  const possibleAmounts = [
    "9 m2",
    "16 m2",
    "4 m2",
    "50 m2",
    "25 m2",
    "6532 m2",
    "101 m2",
    "100 m2",
  ];

  const left: number = -71.74363177843762;

  const top: number = -8.23413452443151;

  const right: number = -71.70709958537184;

  const bottom: number = -8.267195201886807;

  return possibleNames.map((name) => ({
    type: "Feature",
    properties: {
      description: name,
      amount: sample(possibleAmounts),
      type: "current",
    },
    geometry: {
      type: "Point",
      coordinates: (() => {
        return snapToGrid(random(left, right, true), random(top, bottom, true));
      })(),
    },
  }));
};

export interface FlyingMapDriver {
  initialCamera: FlightPathItem;
  nextCamera: FlightPathItem;
  checkFly(): void;
}
interface IProps {
  driver: FlyingMapDriver;
}
export const FlyingMap = observer(function FlyingMap(props: IProps) {
  const { checkFly, nextCamera, initialCamera } = props.driver;

  const mapContainerRef = useRef(null);
  const mapRef = useRef<Map | null>(null);

  const m2Image = new Image();
  m2Image.src = M2Blue;
  const flagPole = new Image();
  flagPole.src = FlagPole;

  useEffect(() => {
    const mapBoxData = generateData();

    const mapBox = new mapboxgl.Map({
      container: mapContainerRef.current || "",
      interactive: true,
      center: initialCamera.center,
      zoom: 18,
      pitch: 50,
      bearing: initialCamera.bearing,
      style: "mapbox://styles/mapbox/satellite-v9",
      scrollZoom: false,
      doubleClickZoom: false,
      dragRotate: false,
      touchZoomRotate: false,
      dragPan: false,
    });
    mapRef.current = mapBox;

    mapBox.on("style.load", () => {
      mapBox.addSource("data", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: mapBoxData,
        },
      });

      mapBox.addImage("m2Icon", m2Image);
      mapBox.addImage("flagPole", flagPole);

      mapBox.addLayer({
        id: "marker",
        type: "symbol",
        source: "data",
        layout: {
          "icon-image": "m2Icon",
          "icon-allow-overlap": true,
          "icon-pitch-alignment": "map",
          "icon-size": 0.5,
        },
      });
      mapBox.addLayer({
        id: "flagpoles",
        type: "symbol",
        source: "data",
        layout: {
          "icon-image": "flagPole",
          "icon-allow-overlap": true,
          "icon-size": 0.5,
          "icon-offset": [24, -48],
          "text-padding": 70,
          "text-field": ["get", "description"],
          "text-anchor": "bottom",
          "text-offset": [0, -3],
          "text-optional": true,
        },
        paint: {
          "text-color": "#FFFFFF",
        },
      });
    });

    mapBox.on("moveend", () => {
      checkFly();
    });

    return () => mapBox.remove();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.easeTo({
        bearing: nextCamera.bearing,
        center: nextCamera.center,
        duration: nextCamera.flightDuration,
        easing: (t) => t,
        animate: true,
        essential: true,
      });
    }
  }, [nextCamera]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{ width: "100%", height: "105%" }}
        ref={mapContainerRef}
        className="map-container"
      />
    </div>
  );
});
