/* eslint-disable react/jsx-no-target-blank */
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import { etBrightLightBlue, etNevadaGrey } from "../../shared/colors";
import { branchIOHost, utmSourceValue } from "../../shared/env";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./TheFooter.styled";

export interface FooterDriver {
  readonly isOverlayActive: boolean;
  mLandscape?: boolean; // to customize snapshot in visual test
  openOverlay(): void;
}
export interface TheFooterProps {
  driver: FooterDriver;
  driverMenu: {
    readonly isCardJoiningOpen: boolean;
    readonly isMenuAppOpen: boolean;
    readonly currentYear: number;
  };
}

export const TheFooter = observer(function TheFooter(props: TheFooterProps) {
  const { t } = useTranslation("Footer");
  const { driver, driverMenu } = props;

  return (
    <sc.Container
      isOverlayActive={driver.isOverlayActive}
      isCardJoiningOpen={driverMenu.isCardJoiningOpen}
      isMenuAppOpen={driverMenu.isMenuAppOpen}
      className={["visual-reg", driver.mLandscape ? "mLandscape" : ""].join(
        " ",
      )}
      data-cy="TheFooter-Container"
    >
      <sc.FooterWrapper className={driver.mLandscape ? "mLandscape" : ""}>
        <sc.WrapperFooterTop>
          <sc.LogoContainer data-cy="Footer-LogoContainer">
            <sc.Logo data-cy="Footer-Logo" />
            <sc.LogoText
              data-cy="Footer-LogoText"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {t("footer.copyright")} &copy; {driverMenu.currentYear}
            </sc.LogoText>
          </sc.LogoContainer>
          <sc.PoliciesContainer data-cy="Footer-PoliciesContainer">
            <sc.Policies>
              <sc.Title
                data-cy="Footer-Partnerships"
                className={driver.mLandscape ? "mLandscape" : ""}
              >
                {t("footer.partnerships")}
              </sc.Title>
              <Link href="/brands" as="/brands" passHref legacyBehavior>
                <sc.PolicyLink
                  data-cy="Footer-Brands"
                  className={driver.mLandscape ? "mLandscape" : ""}
                >
                  {t("footer.brands")}
                </sc.PolicyLink>
              </Link>
              <sc.PolicyLink
                href={branchIOHost + "/uonearth/protect"}
                data-cy="Footer-NPO"
                className={driver.mLandscape ? "mLandscape" : ""}
              >
                NPOs
              </sc.PolicyLink>
              <sc.PolicyLink
                href={`https://uon.org/?${utmSourceValue}`}
                target="_blank"
                data-cy="Footer-UnionOfNature"
                className={driver.mLandscape ? "mLandscape" : ""}
              >
                {t("footer.union-of-nature")}
              </sc.PolicyLink>
            </sc.Policies>
          </sc.PoliciesContainer>
          <sc.MoreEarthTodayContainer data-cy="Footer-PoliciesContainer">
            <sc.Title
              data-cy="Footer-MoreEarthToday"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              EarthToday
            </sc.Title>
            <sc.TextField
              href={`https://about.earthtoday.com/?${utmSourceValue}`}
              target="_blank"
              data-cy="Footer-About"
            >
              {t("footer.about-update")}
            </sc.TextField>
            <sc.TextField
              data-cy="Footer-Protect"
              href={branchIOHost + "/uonearth/protect"}
            >
              {t("footer.protect")}
            </sc.TextField>
            <sc.TextField
              href={`https://curation.earthtoday.com/?${utmSourceValue}`}
              target="_blank"
              data-cy="Footer-Financial-Curation"
            >
              {t("footer.curation")}
            </sc.TextField>
            <sc.TextField
              href={`https://financials.earthtoday.com/?${utmSourceValue}`}
              data-cy="Footer-FinancialStatement"
              target="_blank"
            >
              {t("footer.financial")}
            </sc.TextField>
          </sc.MoreEarthTodayContainer>
          <sc.Contact data-cy="Footer-Contact">
            <sc.Title
              data-cy="Footer-ContactUs"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {t("footer.contact")}
            </sc.Title>
            <sc.TextField
              data-cy="Footer-SupportCenter"
              href={`http://earthtoday.info/faq?${utmSourceValue}`}
              target="_blank"
            >
              {t("footer.faq")}
            </sc.TextField>
            <sc.TextField
              data-cy="Footer-Press"
              href={`https://press.earthtoday.com/?${utmSourceValue}`}
              target="_blank"
            >
              {t("footer.press")}
            </sc.TextField>
            <sc.SocialContacts
              data-cy="support-SocialContacts"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              <sc.LogoV2
                data-cy="Footer-Facebook"
                title="Facebook"
                href="https://www.facebook.com/earthtoday/"
                target="_blank"
              >
                <IconSvg
                  icon="facebook-logo"
                  pointer
                  width="20px"
                  height="20px"
                  padding="0"
                  color={etNevadaGrey}
                  hoverColor={etBrightLightBlue}
                />
              </sc.LogoV2>
              <sc.LogoV2
                data-cy="Footer-Instagram"
                title="Instagram"
                href="https://www.instagram.com/joinearthtoday/"
                target="_blank"
              >
                <IconSvg
                  icon="instagram-v2"
                  pointer
                  width="20px"
                  height="20px"
                  padding="0"
                  color={etNevadaGrey}
                  hoverColor={etBrightLightBlue}
                />
              </sc.LogoV2>
              <sc.LogoV2
                data-cy="Footer-X"
                title="X"
                href="https://twitter.com/JoinEarthToday"
                target="_blank"
              >
                <IconSvg
                  icon="x-v2"
                  pointer
                  width="20px"
                  height="20px"
                  padding="0"
                  color={etNevadaGrey}
                  hoverColor={etBrightLightBlue}
                />
              </sc.LogoV2>
            </sc.SocialContacts>
          </sc.Contact>
        </sc.WrapperFooterTop>
        <sc.PoliciesContainerBottom data-cy="Footer-PoliciesContainer">
          <Link
            href="/policies/[param]"
            as="/policies/disclaimer"
            passHref
            legacyBehavior
          >
            <sc.PolicyLinkBottom
              data-cy="Footer-Disclaimer"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {t("footer.disclaimer")}
            </sc.PolicyLinkBottom>
          </Link>
          <span>|</span>
          <Link
            href="/policies/[param]"
            as="/policies/terms-and-conditions"
            passHref
            legacyBehavior
          >
            <sc.PolicyLinkBottom
              data-cy="Footer-TermOfService"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {t("footer.terms-and-condition")}
            </sc.PolicyLinkBottom>
          </Link>
          <span>|</span>
          <Link
            href="/policies/[param]"
            as="/policies/privacy"
            passHref
            legacyBehavior
          >
            <sc.PolicyLinkBottom
              data-cy="Footer-PrivacyPolicy"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {t("footer.privacy-policy")}
            </sc.PolicyLinkBottom>
          </Link>
          <span>|</span>
          <Link
            href="/policies/[param]"
            as="/policies/cookie-policy"
            passHref
            legacyBehavior
          >
            <sc.PolicyLinkBottom
              data-cy="Footer-CookieSettings"
              className={driver.mLandscape ? "mLandscape" : ""}
            >
              {t("footer.cookie-policy")}
            </sc.PolicyLinkBottom>
          </Link>
        </sc.PoliciesContainerBottom>
      </sc.FooterWrapper>
    </sc.Container>
  );
});
