import { observer } from "mobx-react-lite";
import React from "react";

import { etLicoriceBlue, etRegentGray, etWhite } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./ButtonCircleSocial.styled";

export interface ButtonCircleSocialDriver {
  readonly icon: string;
  readonly iconColor: string;
  readonly iconHeight: string;
  readonly iconWidth: string;
  readonly dataCy: string;

  readonly title?: string;
  readonly disabled?: boolean;
  readonly height?: string;
  readonly width?: string;
  readonly label?: string;
  readonly mobileHeight?: string;
  readonly mobileWidth?: string;
  readonly backgroundColor?: string;
  readonly hoverBackgroundColor?: string;
  readonly hoverBorderColor?: string;
  readonly hoverIconColor?: string;
  readonly borderColor?: string;
  readonly borderWidth?: string;
  readonly margin?: string;

  readonly labelColor?: string;
  readonly dataTrackId?: string;

  onClick?(): void;
}

interface IProps {
  driver: ButtonCircleSocialDriver;
  dataBody?: string;
  dataSubject?: string;
}

export const ButtonCircleSocial = observer((props: IProps) => {
  const { driver } = props;
  const {
    title = "",
    disabled = false,
    height = "32px",
    width = "32px",
    backgroundColor = etWhite,
    hoverBackgroundColor = backgroundColor,
    hoverIconColor = driver.iconColor,
    hoverBorderColor = etRegentGray,
    borderWidth = "0",
    mobileHeight = height,
    mobileWidth = width,
    borderColor = etRegentGray,
    margin = "0",
    onClick = () => {},
    label = "",
    labelColor = etLicoriceBlue,
    dataTrackId,
  } = driver;

  return (
    <sc.Button
      className="visual-reg"
      title={title}
      data-cy={driver.dataCy}
      data-track-id={driver.dataTrackId}
      onClick={onClick}
      disabled={disabled}
      height={height}
      width={width}
      hoverIconColor={hoverIconColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderWidth={borderWidth}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverBorderColor={hoverBorderColor}
      mobileHeight={mobileHeight}
      mobileWidth={mobileWidth}
      margin={margin}
      data-body={props.dataBody}
      data-subject={props.dataSubject}
    >
      <IconSvg
        icon={driver.icon}
        width={driver.iconHeight}
        height={driver.iconHeight}
        color={driver.iconColor}
        padding="0"
        pointer={!disabled}
      />
      {label ? (
        <sc.ButtonLabel labelColor={labelColor}>{label}</sc.ButtonLabel>
      ) : null}
    </sc.Button>
  );
});
