import { observer } from "mobx-react-lite";
import Link from "next/link";
import React, { ReactNode } from "react";

import { etBlack } from "../../shared/colors";
import { DECK_NAME_MAX_LENGTH_SINGLE_SIZE } from "../../shared/constants";
import { getValidHttpUrl } from "../../shared/helpers/getValidHttpUrl";
import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";
import { MasterLinkType, MetaData } from "../../shared/models/Card";
import { CardBaseContentType } from "../../stores/CardBaseModel";
import { ICardItemContent } from "../CardItem/CardItem";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardItemDraft.styled";
import { CardItemDraftFooter } from "./CardItemDraftFooter";
import { CardItemDraftMusic } from "./CardItemDraftMusic";

export type CardItemDraftDriver = {
  readonly cardType: MasterLinkType;
  readonly contentType: CardBaseContentType;
  readonly isCardValidToPublish: boolean;
  readonly id: string;
  readonly metadata: MetaData;
  readonly vanityName: string;
  readonly curatorName: string;
  readonly comment: string;
  readonly deckPathString: string;
  readonly categoryPrinciple: string;
  readonly deckName: string;
  readonly previewImage: string;
  readonly contextMenuActive: boolean;
  readonly categoryColor: string;
  readonly updatedDate: string;
  readonly adminOrOwner: boolean;
  readonly cardDraftMusicPreviewImageBackground: string;
  readonly isCardDrafting: true;
  readonly cardContent: ICardItemContent | null;
  onOpenModalCardEdit(): void;
  onOpenModalCardEdit(): void;
  onEditCard(): void;
};
interface IProps {
  driver: CardItemDraftDriver;
  renderMenuContext(): ReactNode;
}

export const CardItemDraft = observer(function CardItemDraft(props: IProps) {
  return (
    <>
      {props.driver.cardType === "music" ? (
        <sc.Container
          className="card shadow visual-reg"
          data-cy="CardSimpleTypeDraft-container"
        >
          <CardItemDraftMusic
            driver={props.driver}
            renderMenuContext={props.renderMenuContext}
            renderDraftFooter={() => (
              <CardItemDraftFooter driver={props.driver} />
            )}
          />
        </sc.Container>
      ) : (
        <sc.Container
          className="card shadow visual-reg"
          data-cy="CardSimpleTypeDraft-container"
        >
          {props.driver.contextMenuActive && <sc.BlackOverlay />}
          <sc.Header
            iconColor={props.driver.categoryColor}
            data-icon={`cat-${props.driver.categoryPrinciple}`}
          >
            {props.driver.categoryPrinciple ? (
              <IconSvg
                icon={`cat-${props.driver.categoryPrinciple}`}
                width="19px"
                height="18px"
                padding="0"
                color={props.driver.categoryColor}
              />
            ) : undefined}
            {props.driver.deckPathString ? (
              <Link
                href="/[vanityName]/[deckName]"
                as={props.driver.deckPathString}
                passHref
                legacyBehavior
              >
                <sc.Title
                  title={props.driver.deckName}
                  isEmptyCategories={props.driver.categoryPrinciple === ""}
                >
                  #
                  {truncateWithEllipsis(
                    props.driver.deckName,
                    DECK_NAME_MAX_LENGTH_SINGLE_SIZE,
                  )}
                </sc.Title>
              </Link>
            ) : (
              <sc.NoDeckSelected
                isEmptyCategories={props.driver.categoryPrinciple === ""}
              >
                #NoDeckselected
              </sc.NoDeckSelected>
            )}
            <sc.WapperContext> {props.renderMenuContext()}</sc.WapperContext>
          </sc.Header>
          {props.driver.cardContent && props.driver.cardContent.content?.url ? (
            <sc.Wrapper>
              <sc.ImgWrapper cardImg={props.driver.previewImage}>
                {props.driver.cardType === "video" && (
                  <sc.IconPlayContainer>
                    <IconSvg
                      opacity={0.7}
                      icon="play"
                      width="74px"
                      height="51px"
                      padding="0"
                      pointer
                      color={etBlack}
                      position="absolute"
                    />
                  </sc.IconPlayContainer>
                )}

                <sc.CardLink
                  href={getValidHttpUrl(props.driver.cardContent.content.url)}
                  target="_blank"
                >
                  {props.driver.cardContent.content?.source?.name}
                </sc.CardLink>
              </sc.ImgWrapper>
              <sc.Text
                title={props.driver.cardContent.content.title}
                isCardDraftVideo={props.driver.cardType === "video"}
              >
                <sc.TextContent>
                  {props.driver.cardContent.content.title}
                </sc.TextContent>
              </sc.Text>
            </sc.Wrapper>
          ) : (
            <sc.RemovedCardBody data-cy="RemovedCardBody">
              <b>NO LONGER AVAILABLE</b>
              <span>This card was deleted by its original curator</span>
            </sc.RemovedCardBody>
          )}
          <CardItemDraftFooter driver={props.driver} />
        </sc.Container>
      )}
    </>
  );
});
