import { observer } from "mobx-react-lite";
import dynamic from "next/dynamic";

import { urlVimeoSectionThree } from "../../../shared/env";
import { getVideoId } from "../../../shared/helpers/getVideoId";
import { useTranslation } from "../../../shared/translate/NextI18next";
import { DangerouslyHTMLRenderer } from "../../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { sc } from "./SectionThreeV202208.styled";

const VimeoPlayer = dynamic(() => import("react-player/vimeo"), { ssr: false });

export interface SectionThreeV202208Driver {
  isAutoPlayVimeo: boolean;
  isShowAdditionalText: boolean;
  setIsShowAdditionalText(): void;
}
interface IProps {
  driver: SectionThreeV202208Driver;
}

export const SectionThreeV202208 = observer(function SectionThreeV202208(
  props: IProps,
) {
  const { t } = useTranslation("Protect");
  const { driver } = props;
  const videoId = getVideoId(urlVimeoSectionThree);
  const configVimeo = {
    playerOptions: {
      autoplay: driver.isAutoPlayVimeo,
      loop: true,
      quality: "1080p",
    },
  };

  return (
    <sc.Container className="visual-reg">
      <sc.Overlay />
      <sc.WrapContent isShowAdditionalText={props.driver.isShowAdditionalText}>
        <sc.Title data-cy="SectionThreeV202208-Title">
          <DangerouslyHTMLRenderer>
            {t("protect.section-three-v202208.title")}
          </DangerouslyHTMLRenderer>
        </sc.Title>
        <sc.SubTitle data-cy="SectionThreeV202208-SubTitle">
          {t("protect.section-three-v202208.sub-title")}
        </sc.SubTitle>
        <sc.Content data-cy="SectionThreeV202208-Content">
          {t("protect.section-three-v202208.content")}
        </sc.Content>
        <sc.HowProtect
          onClick={() => driver.setIsShowAdditionalText()}
          hide={!driver.isShowAdditionalText}
          data-cy="SectionThreeV202208-HowProtectBtn"
        >
          {t("protect.section-three-v202208.how-protect")}
        </sc.HowProtect>
        {driver.isShowAdditionalText && (
          <sc.Additional data-cy="SectionThreeV202208-Addittional">
            {t("protect.section-three-v202208.additional")}
          </sc.Additional>
        )}
      </sc.WrapContent>
      <sc.Background data-cy="SectionThreeV202208-Background">
        <div className="visual-reg-pass">
          <VimeoPlayer
            video={videoId}
            url={urlVimeoSectionThree}
            muted
            config={configVimeo}
          />
        </div>
      </sc.Background>
    </sc.Container>
  );
});
