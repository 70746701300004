import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import Link from "next/link";

import {
  CARD_MUCSIC_WHY_COMMENT_LIMIT,
  DATE_FORMAT_MMMM_DD_YYYY,
} from "../../shared/constants";
import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";
import { MasterLinkType } from "../../shared/models/Card";
import { Button } from "../Button/Button";
import { HydratedDateWrapper } from "../HydratedDateWrapper/HydratedDateWrapper";
import sc from "./CardItemDraft.styled";

interface Driver {
  isCardValidToPublish: boolean;
  id: string;
  curatorName: string;
  vanityName: string;
  comment: string;
  deckName: string;
  cardType: MasterLinkType;
  updatedDate: string;
  onOpenModalCardEdit(): void;
  onEditCard(): void;
}
interface IProps {
  driver: Driver;
}

export const CardItemDraftFooter = observer(function CardItemDraftFooter(
  props: IProps,
) {
  const {
    isCardValidToPublish,
    comment,
    curatorName,
    vanityName,
    cardType,
    updatedDate,
    onOpenModalCardEdit,
    onEditCard,
  } = props.driver;
  return (
    <sc.Footer cardType={cardType}>
      <sc.CardCurator cardType={cardType}>
        <sc.WapperCuratorLink>
          By{" "}
          <Link
            href="/[vanityName]"
            as={`/${vanityName}`}
            passHref
            legacyBehavior
          >
            <sc.CardCuratorLink data-cy="CardItemDraft-CuratorLink">
              {curatorName}
            </sc.CardCuratorLink>
          </Link>
          &nbsp;-&nbsp;
          <HydratedDateWrapper>
            {format(new Date(updatedDate), DATE_FORMAT_MMMM_DD_YYYY).replaceAll(
              ",",
              "",
            )}
          </HydratedDateWrapper>
        </sc.WapperCuratorLink>
        {comment && (
          <sc.CardCuratorComment data-cy="CardItemDraft-CuratorComment">
            {truncateWithEllipsis(comment, CARD_MUCSIC_WHY_COMMENT_LIMIT)}
          </sc.CardCuratorComment>
        )}
      </sc.CardCurator>
      <sc.CardActions>
        <Button
          driver={{
            dataCy: "CardItemDraft-EditBtn",
            fontSize: "16px",
            width: "146px",
            type: "outline-light",
            onClick: onOpenModalCardEdit,
          }}
        >
          Edit
        </Button>
        <Button
          driver={{
            dataCy: "CardItemDraft-PublishBtn",
            type: "primary",
            disabled: !isCardValidToPublish,
            fontSize: "16px",
            width: "146px",
            onClick: onEditCard,
          }}
        >
          Publish Card
        </Button>
      </sc.CardActions>
    </sc.Footer>
  );
});
