/* eslint-disable react/jsx-wrap-multilines */
import { observer } from "mobx-react-lite";
import React from "react";

import { UserProfileCard } from "./CardProfile";
import sc from "./CardProfile.styled";

interface IProps {
  driver: UserProfileCard;
}

export const CardProfileBio = observer((props: IProps) => {
  const { driver } = props;
  return (
    <sc.Bio data-cy="CardProfile-Bio" title={driver.bio}>
      {driver.bio}
    </sc.Bio>
  );
});
