import styled from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  smallScreenLowerLimit,
  xLargeScreenLowerLimit,
  xmediumScreenLowerLimit,
} from "../../../shared/breakpoints";
import {
  etGrayBlue,
  etGrayBold,
  etHeatherBlue,
  etLicoriceBlue,
  etNevadaGrey,
  etRegentGray,
  etSolitudeBlue,
} from "../../../shared/colors";
import transparentize from "../../../shared/helpers/transparentize";

namespace sc {
  export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 50px;
    background-color: ${etSolitudeBlue};

    @media (max-width: ${mediumScreenUpperLimit}) {
      padding: 100px;
      max-height: unset;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      padding: 95px 0 151px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      padding: 95px 0 0;
    }
  `;

  export const Head = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    > label {
      text-align: center;
    }
  `;

  export const SubTitle = styled.label`
    font-family: Roboto Condensed;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 34px;
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 20px;
    }
    @media (max-width: ${xmediumScreenLowerLimit}) {
      text-align: center;
      padding: 0 10px;
      margin-bottom: 17px;
    }
  `;

  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 1390px;
  `;

  export const Arrow = styled.div`
    position: absolute;
    top: 130px;
    width: 40px;

    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      top: 114px;
      width: 30px;
      height: 100px;
    }
    @media (max-width: 376px) {
      top: 140px;
      width: 15px;
      height: 50px;
    }
  `;

  export const Left = styled(Arrow)`
    left: -80px;
    @media (max-width: ${mediumScreenLowerLimit}) {
      left: -45px;
    }
    @media (max-width: 376px) {
      left: -8px;
    }
  `;

  export const Right = styled(Arrow)`
    right: -80px;
    @media (max-width: ${mediumScreenLowerLimit}) {
      right: -45px;
    }
    @media (max-width: 376px) {
      right: -8px;
    }
  `;

  export const SliderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100vw;
    min-height: 700px;
    max-height: 700px;
    overflow: hidden;
  `;

  export const Slider = styled.div`
    width: 101%;
    min-height: 649px;
    max-height: 649px;
    @media (min-width: ${smallScreenLowerLimit}) {
      max-width: 352px;
      padding: 0;
    }
    @media (min-width: ${mediumScreenLowerLimit}) {
      max-width: 343px;
      padding: 0;
    }
    @media (min-width: ${xmediumScreenLowerLimit}) {
      max-width: 544px;
      padding: 0 100px;
    }
    @media (min-width: 1024px) {
      max-width: 805px;
      padding: 0 50px;
    }
    @media (min-width: ${xLargeScreenLowerLimit}) {
      max-width: 1056px;
      padding: 0;
    }
    @media (min-width: 1600px) {
      max-width: 1398px;
      padding: 0;
    }
  `;

  export const Item = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 12px;
    label {
      display: flex;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      padding: 0 12px;
    }
  `;

  export const ReserveLabel = styled.label`
    color: ${etLicoriceBlue};
    font-size: 40px;
    font-family: Roboto Condensed;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 45px;

    text-align: left;

    margin-top: 43px;
    margin-bottom: 0;

    sup {
      line-height: 40px;
      font-size: 50%;
    }
  `;

  export const InfoLabel = styled.label`
    width: 322px;
    color: ${etNevadaGrey};
    font-size: 20px;
    font-family: Roboto;
    letter-spacing: -0.5px;
    line-height: 30px;
    text-align: left;

    margin-bottom: 0;
  `;

  export const PriceLabel = styled.label`
    color: ${etLicoriceBlue};
    font-size: 24px;
    font-family: Roboto;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: left;

    margin: 17px 0 36px 0;
  `;

  export const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      min-width: 100px;
    }
  `;
  export const NavBottom = styled.ul`
    display: flex;
    bottom: -48px;
    background: ${etHeatherBlue};
    border-radius: 0px;
    height: 2px;
    width: 100%;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: flex;
    }
  `;
  export const FinancialButton = styled.a`
    color: ${etRegentGray};
    background: transparent;
    border: 1px solid ${etRegentGray};
    border-radius: 23px;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 6px 12px;
    min-height: 40px;
    max-height: 40px;
    min-width: 210px;
    max-width: 210px;
    text-transform: uppercase;
    font-family: Roboto Condensed;
    font-style: normal;
    font-size: 16px;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      text-decoration: none;
      color: ${etRegentGray};
    }

    @media (max-width: ${mobileDeviceWidth}) {
      margin-bottom: 0;
    }
  `;
  export const DescribeWrapper = styled.div`
    width: 100%;

    @media (min-width: 1024px) {
      max-width: 805px;
      padding: 0 50px;
    }
    @media (min-width: ${xLargeScreenLowerLimit}) {
      max-width: 1056px;
      padding: 0;
    }
    @media (min-width: 1600px) {
      max-width: 1398px;
      padding: 0;
    }
  `;
  export const Describe = styled.p`
    border-top: 1px solid ${transparentize(etGrayBold, 0.3)};
    text-align: center;
    margin: 0;
    padding: 100px 125px;
    font-family: Roboto;
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -1px;
    line-height: 70px;

    @media (max-width: 1600px) {
      font-size: 40px;
      line-height: 45px;
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      padding: 50px 30px;
    }
    @media (max-width: ${mobileDeviceWidth}) {
      border: none;
      font-size: 25px;
      line-height: 30px;
    }
  `;
  export const ReceiptSection = styled.div`
    width: 100%;
    margin: 100px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: ${mediumScreenUpperLimit}) {
      flex-direction: column;
      margin-top: 51px;
      margin-bottom: 0;
    }
    @media (min-width: ${xLargeScreenLowerLimit}) {
      max-width: 1056px;
    }
    @media (min-width: 1600px) {
      max-width: 1398px;
      justify-content: center;
    }
  `;
  export const ModalReceiptWrapper = styled.div`
    width: 328px;
    max-width: 328px;
  `;
  export const ReceiptDescribeWrapper = styled.div`
    margin-left: 192px;
    padding: 92px 0;

    @media (max-width: ${largeScreenUpperLimit}) {
      margin-left: 50px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      padding-bottom: 51px;
    }
  `;
  export const DescribeText = styled.p`
    font-family: Roboto Slab;
    font-size: 35px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: -1px;
    height: 193px;
    max-height: 193px;
    width: 510px;
    max-width: 510px;
    margin: 0;

    & > span > span {
      font-weight: bold;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      height: 193px;
      max-height: 193px;
      width: 375px;
      max-width: 375px;
      font-size: 27px;
      padding: 0px 15px;
      line-height: 36px;
    }
  `;
  export const DescribeSubText = styled.p`
    color: ${etGrayBlue};
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: normal;
    height: 19px;
    max-height: 19px;

    & > span > span {
      font-weight: bold;
    }
    @media (max-width: ${mobileDeviceWidth}) {
      padding: 0px 15px;
      font-size: 14px;
    }
  `;
  export const ButtonWrapper = styled.div`
    margin-top: 36px;
    @media (max-width: ${mobileDeviceWidth}) {
      padding: 0px 15px;
    }
  `;
}

export default sc;
