import styled from "styled-components";

import {
  largeScreenLowerLimit,
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  mobileMaxHeight,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import { etBlack, etGray, etWhite } from "../../shared/colors";

export namespace sc {
  export const WrapperTabs = styled.div<{ height: "69px" | "70px" }>`
    position: relative;
    display: flex;
    justify-content: flex-start;
    position: unset;

    margin: 24px auto 0px auto;

    @media (min-width: ${xLargeScreenLowerLimit}) {
      width: calc(328px * 4 + 24px * 3);
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      width: calc(328px * 3 + 24px * 2);
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      padding: 0 12px;

      width: calc(328px * 2 + 24px);
      height: 32px;
      max-height: 732px;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      height: ${(props) => props.height};
      max-height: ${(props) => props.height};
    }

    @media (max-width: ${mobileDeviceWidth}) {
      justify-content: start;
    }
  `;

  export const Container = styled.div`
    width: fit-content;
    display: grid;
    grid-gap: 12px;
    grid-auto-flow: column;

    background-color: transparent;

    @media (max-width: ${largeScreenLowerLimit}) {
      align-items: flex-start;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      justify-content: flex-start;
      grid-gap: 6px;
    }
  `;

  export const Tab = styled.a<{
    isActive: boolean;
    bgColor: string;
    isDisplayNone: boolean;
  }>`
    width: fit-content;
    white-space: nowrap;
    cursor: pointer;
    min-height: 32px;
    max-height: 32px;

    padding: 0px 12px;

    color: ${(props) => (props.isActive ? etWhite : etGray)};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: condensed;
    text-align: center;
    line-height: 30px;
    letter-spacing: 0px;

    background-color: ${(props) => (props.isActive ? etBlack : "transparent")};
    border: 1px solid ${(props) => (props.isActive ? etBlack : etGray)};
    border-radius: 4px;

    display: ${(props) => (props.isDisplayNone ? "none" : "flex")};
    align-items: center;
    justify-content: center;

    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      color: ${(props) => (props.isActive ? etBlack : etWhite)};
      background-color: ${(props) =>
        props.isActive ? etWhite : "transparent"};
      border: 1px solid ${(props) => (props.isActive ? etWhite : "")};
    }

    &.channel-tab-active {
      color: ${(props) => props.color};
      text-transform: uppercase;
      background-color: ${(props) => props.bgColor};
      border: 1px solid ${(props) => props.bgColor};
    }

    // override hover styles on mobile
    &:hover {
      color: ${(props) => (props.isActive ? etWhite : etGray)};
      text-decoration: none;
    }

    /* - hover styles that only fire on desktop
       - also prevent mobile devices emulate hovering when the user performs an inconvenient long tap */
    @media (hover: hover) {
      &:hover {
        color: ${etWhite};
        background-color: ${etBlack};
        text-decoration: none;
        border: 1px solid ${etBlack};
      }
    }

    &[href="/about"] {
      @media (max-width: ${mediumScreenLowerLimit}) {
        height: 32px;
        max-height: 32px;
        width: 128px;
        max-width: 128px;
      }
    }

    &:last-child {
      @media (max-width: ${mediumScreenLowerLimit}) {
        width: 111px;
        max-width: 111px;
      }
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      border: 1px solid ${etWhite};
      color: ${etWhite};
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      color: ${(props) => (props.isActive ? etBlack : etWhite)};
      background-color: ${(props) =>
        props.isActive ? etWhite : "transparent"};
      &:hover {
        color: ${etBlack};
        text-decoration: none;
        background-color: ${etWhite};
      }
    }
  `;

  export const TabsCardIntro = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 6px;
    width: fit-content;
    background-color: transparent;

    @media (max-width: ${mediumScreenLowerLimit}) {
      min-height: 70px;
      align-items: start;
    }
  `;
  export const WrapperTabsCardIntro = styled.div`
    margin-top: 24px;

    width: calc(328px * 4 + 24px * 3);
    @media (max-width: ${xLargeScreenLowerLimit}) {
      width: calc(328px * 3 + 24px * 2);
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      width: calc(328px * 2 + 24px * 1);
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      padding: 0 12px;
    }

    @media (max-width: ${mobileDeviceWidth}) {
      margin-top: 12px;
    }
  `;
}
