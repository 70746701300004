import styled from "styled-components";

import earthImg from "../../assets/img/download-app-card-background.png";
import {
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  smallScreenBalanceLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etGray,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import zIndex from "../../shared/zIndex";

export namespace sc {
  export const FixedContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: ${zIndex.QR_OVERLAY};
  `;

  export const BackGroundMask = styled.div`
    position: absolute;
    width: 100%;
    height: 850px;

    pointer-events: none;
    background-color: ${etGray}; // incase bg not loading fast enough
    background-image: url(${require("../../assets/img/kunal-shinde.png")});
    background-size: cover;
    background-position: center;

    mask: url(${require("../../assets/img/qr-code-bg-mask.png")});
    mask-position: bottom center;
    mask-size: cover;
    mask-repeat: no-repeat;

    z-index: 2;

    @media screen and (max-height: 890px) {
      height: calc(100vh - 100px);
    }

    @media screen and (max-width: ${mediumScreenUpperLimit}) {
      height: 80%;
    }
  `;

  export const BgOverlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${transparentize(etBlack, 0.5)};
  `;

  export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 850px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-height: 890px) {
      height: calc(100vh - 40px);
    }

    @media screen and (max-width: ${mediumScreenUpperLimit}) {
      height: 80%;
    }
  `;

  export const CloseBtnContainer = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 870px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 3;

    display: grid;
    place-items: center;

    @media screen and (max-height: 890px) {
      top: calc(100vh - 80px);
    }

    @media screen and (max-width: ${mediumScreenUpperLimit}) {
      width: 80px;
      height: 80px;
      bottom: -88px;
      top: calc(80% + 20px);
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      width: 70px;
      height: 70px;
      bottom: -78px;
    }
  `;

  export const CloseBtnWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${etBrightLightBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: ${mediumScreenUpperLimit}) {
      width: 35px;
      height: 35px;

      & > div {
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        max-width: 14px;
        max-height: 14px;
      }
    }
  `;

  export const PageOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(3px);
    z-index: 1;
  `;

  export const Content = styled.div`
    position: absolute;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    z-index: 3;

    @media screen and (max-width: 1535px) {
      width: 70%;
    }

    @media screen and (max-width: ${largeScreenUpperLimit}) {
      width: 100%;
      padding: 0 40px;
    }

    @media screen and (max-width: ${mediumScreenUpperLimit}) {
      width: 100%;
      padding: 0 10px;
      gap: 20px;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      position: relative;
      height: 100%;
      top: 0;
      left: 0;
      transform: none;
      flex-direction: column;
      align-items: center;
    }
  `;

  export const QrCodeCard = styled.div`
    position: relative;
    width: 328px;
    height: 496px;
    padding: 12px;
    border-radius: 8px;
    background-image: url(${require("../../assets/img/download-qrcode-bg.png")});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: ${"970px"}) {
      scale: 0.8;
    }
    @media screen and (max-width: ${smallScreenUpperLimit}) {
      scale: 0.8;
      margin-top: calc((100vh - 990px) / 2);
    }
  `;

  export const QrCodeExpiredOverlay = styled.div`
    width: 304px;
    height: 304px;
    position: absolute;
    top: 12px;
    left: 12px;
    background-color: ${transparentize(etBlack, 0.6)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      width: 180px;
      height: 180px;
    }
  `;
  export const ExpiredDes = styled.p`
    font-family: "Roboto Slab";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    color: ${etWhite};

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      font-size: 10px;
    }
  `;

  export const ReloadButon = styled.button`
    width: 134px;
    height: 40px;
    text-align: center;
    padding: 0 24px;
    background-color: ${etBrightLightBlue};
    border: none;
    border-radius: 20px;

    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: ${etWhite};

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      font-size: 10px;
      width: 80px;
      height: 30px;
    }
  `;

  export const LoaderContainer = styled.div`
    width: 304px;
    height: 304px;

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      width: 180px;
      height: 180px;
    }
  `;

  export const DownloadAppContainer = styled.div`
    width: 100%;
    flex: 1;
    padding: 24px 0px 12px;
  `;

  export const DownloadDes = styled.p`
    width: 100%;
    font-family: Roboto;
    font-size: 34px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 45px;
    letter-spacing: normal;
    text-align: center;
    color: ${etWhite};
    margin: 0;
  `;
  export const DownloadDes2 = styled.p`
    width: 100%;
    font-family: Roboto Condensed;
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;
    color: ${etWhite};
    margin: 0;
    margin-top: 2px;
    text-transform: uppercase;
  `;

  export const DownloadAppButtonsWrapper = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 22px;
  `;

  export const GuideBlock = styled.div`
    flex: 1;
    height: auto;
    color: ${etWhite};
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      margin-top: -170px;
    }
  `;

  export const GuideTitle = styled.p`
    max-width: 670px;
    font-family: "Roboto Slab";
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: -0.5px;
    text-align: left;
    color: ${etWhite};
    margin-bottom: 40px;

    @media screen and (max-width: ${largeScreenUpperLimit}) {
      font-size: 45px;
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 800px) {
      font-size: 40px;
      margin-bottom: 28px;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      text-align: center;
      font-size: 18px;
      margin-bottom: 0;
    }
  `;

  export const MobileQrCode = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  `;

  export const MobileQrCodeWrapper = styled.div`
    position: relative;
    width: 204px;
    height: 204px;
    padding: 12px;
  `;

  export const GuildStepsBlock = styled.div`
    max-width: 670px;
    height: fit-content;
  `;

  export const GuideStep = styled.p`
    font-family: Roboto;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    color: ${etWhite};
    margin-bottom: 4px;

    & > div {
      display: inline-block;
      margin-left: 7px;
    }

    @media screen and (max-width: ${largeScreenUpperLimit}) {
      font-size: 25px;
      margin-bottom: 0;
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 800px) {
      font-size: 22px;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      font-size: 14px;
      margin: 0;
      text-align: center;
    }
  `;

  export const HaveAccount = styled.p`
    max-width: 670px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    text-align: left;
    color: ${etWhite};
    margin: 0;
    margin-bottom: 10px;

    @media screen and (max-width: ${largeScreenUpperLimit}) {
      font-size: 16px;
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 800px) {
      font-size: 14px;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      font-size: 12px;
      margin-top: 3px;
      text-align: center;
      letter-spacing: 0;
    }
  `;

  export const StepImage = styled.img`
    display: inline-block;
    width: 32px;
    height: 32px;
  `;

  export const AppLink = styled.a`
    color: ${etWhite};
    text-decoration: underline;

    :focus {
      color: ${etWhite};
    }
  `;

  export const LearnMoreWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `;

  export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 20px;

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      justify-content: center;
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      flex-direction: column;
    }
  `;
  export const BackBtn = styled.button`
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    border: 1px solid ${etWhite};
    border-radius: 20px;
    padding: 0 24px;

    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: ${etWhite};
    text-transform: uppercase;

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      width: fit-content;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      margin-top: 10px;
      font-size: 10px;
      height: 28px;
      line-height: 28px;
    }
  `;

  export const LearnMore = styled(BackBtn)``;
  export const DownloadAppCard = styled.div`
    position: relative;
    width: 328px;
    height: 496px;
    min-width: 328px;
    min-height: 496px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-image: url(${earthImg});
    background-size: cover;
    color: ${etWhite};

    @media screen and (max-width: 970px) {
      scale: 0.8;
    }
    @media screen and (max-width: ${smallScreenUpperLimit}) {
      scale: 0.8;
      margin-top: calc((100vh - 790px) / 2);
    }
    @media screen and (max-width: ${smallScreenBalanceLimit}) {
      scale: 0.6;
    }
  `;

  export const AboutBlock = styled.div`
    flex: 1;
    height: fit-content;
    color: ${etWhite};
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      margin-top: -170px;
    }
  `;

  export const AboutTitle = styled.p`
    font-family: "Roboto Slab";
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: -0.5px;
    text-align: left;
    color: ${etWhite};
    margin-bottom: 40px;

    @media screen and (max-width: ${largeScreenUpperLimit}) {
      font-size: 45px;
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      font-size: 40px;
      margin-bottom: 28px;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      text-align: center;
      font-size: 16px;
      margin-bottom: 0;
      letter-spacing: 0;
    }
  `;

  export const AboutDesBlock = styled.div`
    max-width: 670px;
    height: fit-content;
  `;

  export const AboutDes = styled.p`
    font-family: Roboto;
    font-size: 30px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    text-align: left;
    color: ${etWhite};
    margin-bottom: 17px;

    & > img {
      margin-left: 7px;
    }

    @media screen and (max-width: ${largeScreenUpperLimit}) {
      font-size: 25px;
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      font-size: 22px;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      font-size: 12px;
      margin: 0;
      margin-top: 3px;
      text-align: center;
      letter-spacing: 0;
    }
  `;

  export const CounterBlock = styled.div`
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
  `;
  export const StartProtecting = styled.p`
    margin: 0;
    margin-top: 30px;
    font-family: Roboto;
    font-size: 34px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    line-height: 40px;
  `;

  export const CounterContainer = styled.div``;
  export const CounterWrapper = styled.div`
    margin-left: 3px;
    display: flex;
    justify-content: center;
    // 32px is wanted height, 57px is the height of counter which is difficult to change
    scale: ${32 / 57};

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-top: 17px;
    }
  `;

  export const CounterMeterLogo = styled.img`
    height: 57px;
    width: 57px;
    margin-left: -2px;

    z-index: 1;
  `;

  export const CounterDescription = styled.p`
    margin: 10px 0 0 0;
    padding: 0;
    font-family: "Roboto Condensed";
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
  `;

  export const DownloadAppBlock = styled.div`
    width: 100%;
    padding: 38px 12px 12px;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
  `;

  export const DownloadApp = styled.p`
    margin: 0 0 10px 0;
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    width: 100%;
    text-align: center;
  `;

  export const DownloadAppButtons = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  `;

  export const MoviePlayer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
  `;
  export const MovieWrapper = styled.div`
    width: 90vw;
    height: calc(90vw * 759 / 1350);
  `;

  export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      justify-content: center;
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      flex-direction: column;
    }
  `;

  export const WatchVideo = styled.button`
    height: 40px;
    text-align: center;
    padding: 0 24px;
    border-radius: 25px;
    background-color: ${etBrightLightBlue};
    border: none;
    border-radius: 20px;

    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: ${etWhite};
    text-transform: uppercase;
    margin-top: 20px;
    width: fit-content;

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      width: fit-content;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      margin-top: 10px;
      font-size: 10px;
      height: 28px;
      line-height: 28px;
    }
  `;

  export const VisitEarth = styled(WatchVideo)`
    border: 1px solid ${etWhite};
    background-color: transparent;

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      margin-top: 0;
    }
  `;
  export const BecomePartner = styled.a`
    height: 40px;
    text-align: center;
    padding: 0 24px;
    border-radius: 25px;
    background-color: transparent;
    border: 1px solid ${etWhite};
    border-radius: 20px;

    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: ${etWhite};
    text-transform: uppercase;
    margin-top: 20px;
    width: fit-content;

    :hover {
      text-decoration: none;
      color: ${etWhite};
    }

    @media only screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: 910px) {
      width: fit-content;
      margin-top: 0;
    }

    @media screen and (max-width: ${smallScreenUpperLimit}) {
      margin-top: 10px;
      font-size: 10px;
      height: 28px;
      line-height: 28px;
      padding: 0 20px;
    }
  `;
}
