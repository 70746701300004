import { observer } from "mobx-react-lite";
import { TFunction } from "next-i18next";
import React from "react";

import { etWhite } from "../../shared/colors";
import {
  getLogoSystemPage,
  getSubtitleSystemPageNewTemplate,
  getTextColorSystemPage,
  getTitleSystemPage,
} from "../../shared/helpers/getItemSystemPage";
import useWindowSize from "../../shared/helpers/useWindowSize";
import { useTranslation } from "../../shared/translate/NextI18next";
import {
  IWhiteBannerInfo,
  NamePage,
  NameSystemPage,
} from "../../stores/OverlaySystemPagePresenter";
import { CardIntroSystemDeck } from "../CardIntroSystemDeck/CardIntroSystemDeck";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./CardIntro.styled";

export interface CardIntroDriver {
  readonly systemPage: NameSystemPage;
  readonly isSystemDeck: boolean;
  readonly isVisibleIntroCard: boolean;
  readonly isHomePage: boolean;
  readonly isChannelPage: boolean;
  readonly flagCharitiesTab: boolean;
  readonly whiteBannerInfo: IWhiteBannerInfo;
  openModalDonate(): void;
  openModalSignUp(): void;
  openModalCreateCard(): void;
  closeCardIntro(page: string): void;
}
interface IPropsCardIntro {
  driver: CardIntroDriver;
}

export const CardIntro = observer(function TodayOverlay(
  props: IPropsCardIntro,
) {
  const { driver } = props;
  const widthDevice = useWindowSize().width;

  const { t } = useTranslation("SystemPageTabs");

  if (
    driver.systemPage === NamePage.AboutEarthToday ||
    !driver.isVisibleIntroCard ||
    (!driver.isSystemDeck && !driver.isChannelPage)
  ) {
    return null;
  }

  if (driver.isSystemDeck && !driver.isChannelPage) {
    return <CardIntroSystemDeck driver={driver} />;
  }

  return (
    <sc.Container
      className="visual-reg"
      data-cy="CardIntro-Container"
      systemPage={driver.systemPage || ""}
      textColor={getTextColorSystemPage(driver.systemPage || "")}
    >
      <sc.CloseButton
        data-cy="CardIntro-CloseBtn"
        color={getTextColorSystemPage(driver.systemPage || "")}
        onClick={() => driver.closeCardIntro(`${driver.systemPage}`)}
      >
        <IconSvg icon="close" pointer height="16px" width="16px" padding="0" />
      </sc.CloseButton>
      <sc.WrapperContent>
        <sc.WrapperLogo data-cy="CardIntro-WrapperLogo">
          <IconSvg
            icon={getLogoSystemPage(driver.systemPage || "", widthDevice)}
            pointer={false}
            padding="0"
            width="100%"
            height="100%"
            color={etWhite}
          />
        </sc.WrapperLogo>
        <sc.Content>
          <sc.Title data-cy="CardIntro-Title">
            <span>
              {t(`systempagetabs.${driver.systemPage}`) || driver.systemPage}
            </span>
            {getTitleSystemPage(driver.systemPage || "", t)}
          </sc.Title>
          <sc.Subtitle
            data-cy="CardIntro-Subtitle"
            isDeckNature={driver.systemPage === NamePage.Nature}
          >
            {getSubtitleSystemPageNewTemplate(driver.systemPage || "", t)}
          </sc.Subtitle>
          <sc.WrapperButton>
            <sc.LearnMoreButton
              data-cy="CardIntro-LearnMoreButton"
              href={"#"}
              onClick={(e) => {
                e.preventDefault();
                driver.openModalCreateCard();
              }}
            >
              <span>{t("systempagetabs.start-curating")}</span>
            </sc.LearnMoreButton>
          </sc.WrapperButton>
        </sc.Content>
      </sc.WrapperContent>
    </sc.Container>
  );
});
