import styled from "styled-components";

import { etBlack, etWhite } from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const Container = styled.div`
    position: relative;
    margin-left: auto;
    user-select: none;
    z-index: 11;
    width: 100%;
    height: 100%;
  `;
  export const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    cursor: default;
  `;
  export const ContextMenuContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 7px;
    width: 100%;
    height: 100%;
    line-height: 20px;
    font-size: 20px;
    cursor: pointer;
  `;
  export const ContextMenu = styled.div<{
    display: string;
  }>`
    position: absolute;
    display: ${(props) => props.display};
    flex-direction: column;
    align-items: flex-start;
    top: 24px;
    right: 0;
    padding: 12px;
    z-index: ${zIndex.CONTEXT};
    background-color: ${etWhite};
    border-radius: 8px;
    box-shadow: 0px 0px 12px ${transparentize(etBlack, 0.175)};
  `;
}

export default sc;
