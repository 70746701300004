import { observer } from "mobx-react-lite";

import { etGray } from "../../shared/colors";
import { CardSize } from "../../shared/models/Card";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./ContextMenuCardResize.styled";

interface IProps {
  cardSize: CardSize;
  isPinHeaderButtonDisabled: boolean;
  onCardSizeChanged(size: CardSize): void;
  isHoverHeader?: boolean;
}

export const ContextMenuCardResize = observer(function ContextMenuCardResize(
  props: IProps,
) {
  const { t } = useTranslation("CardItemAction");

  return (
    <sc.CardSizeContainer>
      <sc.BlockTitle data-cy="CardItemPromotionContextMenu-CardSize">
        {t("context-menu.card-size.title")}
      </sc.BlockTitle>
      <sc.CardSizeItems>
        <sc.CardSizeItem
          disabled={false}
          data-cy="CardItemPromotionContextMenu-CardSizeSingle"
          onClick={() => props.onCardSizeChanged(CardSize.SINGLE)}
        >
          <sc.IconBorder
            disabled={false}
            shouldChangeIconColorWhenHover={true}
            className={props.cardSize === CardSize.SINGLE ? "active" : ""}
          >
            <IconSvg
              icon="single-card"
              width="32px"
              height="32px"
              padding="0"
              color={etGray}
              pointer
            />
          </sc.IconBorder>
          <sc.CardSizeItemText>
            {t("context-menu.card-size.single")}
          </sc.CardSizeItemText>
        </sc.CardSizeItem>
        <sc.CardSizeItem
          disabled={true}
          data-cy="CardItemPromotionContextMenu-CardSizeDouble"
        >
          <sc.IconBorder disabled={true} shouldChangeIconColorWhenHover={true}>
            <IconSvg
              icon="double-card"
              width="32px"
              height="32px"
              padding="0"
              color={etGray}
            />
          </sc.IconBorder>
          <sc.CardSizeItemText>
            {t("context-menu.card-size.double")}
          </sc.CardSizeItemText>
        </sc.CardSizeItem>
        <sc.CardSizeItem
          disabled={true}
          data-cy="CardItemPromotionContextMenu-CardSizeTriple"
        >
          <sc.IconBorder disabled={true} shouldChangeIconColorWhenHover={true}>
            <IconSvg
              icon="triple-card"
              width="32px"
              height="32px"
              padding="0"
              color={etGray}
              fillColor={etGray}
            />
          </sc.IconBorder>
          <sc.CardSizeItemText>
            {t("context-menu.card-size.triple")}
          </sc.CardSizeItemText>
        </sc.CardSizeItem>
        <sc.CardSizeItem
          disabled={props.isPinHeaderButtonDisabled}
          data-cy="CardItemPromotionContextMenu-CardSizeHeader"
          onClick={() =>
            !props.isPinHeaderButtonDisabled &&
            props.onCardSizeChanged(CardSize.HEADER)
          }
        >
          <sc.IconBorder
            shouldChangeIconColorWhenHover={true}
            disabled={props.isPinHeaderButtonDisabled}
            className={
              props.cardSize === CardSize.HEADER || props.isHoverHeader
                ? "active"
                : ""
            }
          >
            <IconSvg
              icon="header-card"
              width="32px"
              height="32px"
              padding="0"
              color={etGray}
              fillColor={etGray}
              pointer
            />
          </sc.IconBorder>
          <sc.CardSizeItemText>
            {t("context-menu.card-size.header")}
          </sc.CardSizeItemText>
        </sc.CardSizeItem>
      </sc.CardSizeItems>
    </sc.CardSizeContainer>
  );
});
