import { observer } from "mobx-react-lite";
import React from "react";

import { etGray, etGrayLight } from "../../shared/colors";
import { IconSvg } from "../IconSvg/IconSvg";
import scUpdate from "./CardSimpleContext_Update.styled";

interface IProps {
  isValid: boolean;
  onPublish(): void;
}

export const ContextMenuCardPublish = observer(function (props: IProps) {
  const rederContexMenuPublish = () =>
    props.isValid ? (
      <>
        <IconSvg
          icon="publish"
          color={etGray}
          pointer={true}
          width="13px"
          height="15px"
          padding="0"
        />
        <scUpdate.ContextMenuItemBluePublish>
          Publish this Card
        </scUpdate.ContextMenuItemBluePublish>
      </>
    ) : (
      <>
        <IconSvg
          icon="publish"
          color={etGrayLight}
          pointer={true}
          width="13px"
          height="15px"
          padding="0"
        />
        <scUpdate.ContextMenuItemGrayLight>
          Publish this Card
        </scUpdate.ContextMenuItemGrayLight>
      </>
    );
  return props.isValid ? (
    <scUpdate.ContextMenuRowPublish
      data-cy="CardMenuItem-Publish"
      isValid={props.isValid}
      onClick={props.onPublish}
    >
      {rederContexMenuPublish()}
    </scUpdate.ContextMenuRowPublish>
  ) : (
    <scUpdate.ContextMenuRowPublish
      data-cy="CardMenuItem-Publish"
      isValid={props.isValid}
    >
      {rederContexMenuPublish()}
    </scUpdate.ContextMenuRowPublish>
  );
});
