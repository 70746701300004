import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { ETReactProps } from "../../../typings/types";
import sc from "./PageLayoutDefault.styled";

export interface IPageLayoutDefaultDriver {
  readonly shouldFixViewport: boolean;
  readonly shouldShowCookiePolicy: boolean;
  readonly isIntroVideoOpen: boolean;
  readonly maxHeightOfNewCardMenuItem?: string;
  mLandscape?: boolean; // to customize snapshot in visual test
}

interface IProps extends ETReactProps {
  renderTop(): ReactNode;
  renderBottom(): ReactNode;
  renderMenu(): ReactNode;
  renderCardJoining(): ReactNode;
  renderCardBlank(): ReactNode;
  driver: IPageLayoutDefaultDriver;
}

export const PageLayoutDefault = observer((props: IProps) => {
  const {
    shouldFixViewport,
    shouldShowCookiePolicy,
    maxHeightOfNewCardMenuItem,
    isIntroVideoOpen,
    mLandscape,
  } = props.driver;
  const {
    children,
    renderTop,
    renderMenu,
    renderCardJoining,
    renderCardBlank,
    renderBottom,
  } = props;

  return (
    <sc.Container
      className={["visual-reg", mLandscape ? "mLandscape" : ""].join(" ")}
      shouldFixViewport={shouldFixViewport}
      shouldShowCookiePolicy={shouldShowCookiePolicy}
      maxHeightOfNewCardMenuItem={maxHeightOfNewCardMenuItem}
      isIntroVideoOpen={isIntroVideoOpen}
    >
      {renderTop()}
      {renderMenu()}
      {renderCardJoining()}
      {renderCardBlank()}
      <sc.ChildrenWrapper>{children}</sc.ChildrenWrapper>

      <sc.WrapFooter className="visual-reg-footer">
        {renderBottom()}
      </sc.WrapFooter>
      <sc.PreloadErrorImage />
      <sc.PreloadErrorImageBackground />
      <sc.PreloadBadRequestErrorImage />
    </sc.Container>
  );
});
