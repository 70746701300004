/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { ReactNode } from "react";

import {
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import CardItemLayout, { LayoutDriver } from "../CardItemLayout/CardItemLayout";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardItemInfo.styled";

export interface CardItemInfoDriver {
  contentType: CardType.INFO;
  id: string;
  isEnableOpenCardView: boolean;

  categoryPrinciple: string;
  deckNameWithHashTag: string;
  deckPath: string;
  isContextMenuActive: boolean;
  onContextMenuEllipsisClicked(): void;
  onContextMenuFullscreenOverlayClicked(): void;

  title: string;
  subtitle?: string;
  backgroundImage?: string;
  backgroundColor: string;
  textAlign: CardTextAlignment;
  textColor: CardTextColor;
}

interface IProps {
  driver: CardItemInfoDriver;
  layoutDriver: LayoutDriver;
  renderMenuContext(): ReactNode;
}

export const CardItemInfo = observer(function CardItemInfo(props: IProps) {
  const {
    id,
    isEnableOpenCardView,
    categoryPrinciple,
    deckNameWithHashTag,
    deckPath,
    textAlign,
    backgroundImage,
    backgroundColor,
    title,
    subtitle,
    textColor,
    isContextMenuActive,
    onContextMenuEllipsisClicked,
    onContextMenuFullscreenOverlayClicked,
  } = props.driver;
  const { cardSize } = props.layoutDriver;

  const titleWidth =
    textAlign === CardTextAlignment.CENTER || cardSize === CardSize.SINGLE
      ? "100%"
      : cardSize === CardSize.DOUBLE
      ? "502px"
      : "654px";
  const titleFontSize =
    cardSize === CardSize.TRIPLE
      ? "50px"
      : cardSize === CardSize.DOUBLE
      ? "40px"
      : "30px";
  const titleLineHeight =
    cardSize === CardSize.TRIPLE
      ? "55px"
      : cardSize === CardSize.DOUBLE
      ? "45px"
      : "35px";

  const subtitleWidth =
    textAlign === CardTextAlignment.CENTER || cardSize === CardSize.SINGLE
      ? "100%"
      : cardSize === CardSize.DOUBLE
      ? "556px"
      : "779px";
  const subtitleFontSize =
    cardSize === CardSize.TRIPLE
      ? "32px"
      : cardSize === CardSize.DOUBLE
      ? "24px"
      : "20px";
  const subtitleLineHeight =
    cardSize === CardSize.TRIPLE
      ? "37px"
      : cardSize === CardSize.DOUBLE
      ? "29px"
      : "25px";

  let articlePadding;
  if (textAlign === CardTextAlignment.CENTER) {
    articlePadding =
      cardSize === CardSize.SINGLE
        ? "12px"
        : cardSize === CardSize.DOUBLE
        ? "21px"
        : "76px";
  } else if (textAlign === CardTextAlignment.LEFT) {
    articlePadding =
      cardSize === CardSize.SINGLE
        ? "12px"
        : cardSize === CardSize.DOUBLE
        ? "24px"
        : "48px";
  }

  return (
    <CardItemLayout layoutDriver={props.layoutDriver}>
      <sc.Container
        data-cy="CardItemInfo-Container"
        cardSize={cardSize}
        color={textColor}
        bgImage={backgroundImage}
        align={textAlign}
        bgColor={backgroundColor}
      >
        {isContextMenuActive && (
          <>
            <sc.ContextMenuFullScreenLayer
              onClick={onContextMenuFullscreenOverlayClicked}
            />
            <sc.CardOverLay />
            <sc.ContextMenuWrapper>
              {props.renderMenuContext()}
            </sc.ContextMenuWrapper>
          </>
        )}
        {isEnableOpenCardView && (
          <Link
            href="/[vanityName]/[deckName]/[cardId]"
            as={`${deckPath}/${id}`}
            passHref
            legacyBehavior
          >
            <sc.CardLink data-cy="CardItemAction-CardOverlay" />
          </Link>
        )}
        <sc.CardHeader data-cy="CardItemInfo-CardHeader">
          <sc.CardHeaderLeft>
            <sc.IconWrapper>
              <IconSvg
                data-cy="CardItemArticle-Icon"
                icon={`${categoryPrinciple.toLowerCase()}`}
                width="19px"
                height="18px"
                padding="0"
                color={textColor}
                fillColor={textColor}
              />
            </sc.IconWrapper>
            <Link
              href="/[vanityName]/[deckName]"
              as={deckPath}
              passHref
              legacyBehavior
            >
              <sc.DeckName data-cy="CardItemInfo-DeckName" color={textColor}>
                {deckNameWithHashTag}
              </sc.DeckName>
            </Link>
          </sc.CardHeaderLeft>
          <sc.CardHeaderRight
            data-cy="CardItemInfo-OpenContextMenuButton"
            onClick={onContextMenuEllipsisClicked}
          >
            <IconSvg
              icon="more"
              pointer
              width="20px"
              height="20px"
              padding="0"
              color={textColor}
            />
          </sc.CardHeaderRight>
        </sc.CardHeader>
        <sc.Article
          textAlign={textAlign}
          padding={articlePadding}
          className="Article-Content"
        >
          <sc.TitleWrapper width={titleWidth}>
            <sc.ArticleTitle
              data-cy="CardItemInfo-ArticleTitle"
              fontSize={titleFontSize}
              lineHeight={titleLineHeight}
            >
              {title}
            </sc.ArticleTitle>
          </sc.TitleWrapper>
          {subtitle && (
            <sc.ArticleSubtitle
              data-cy="CardItemInfo-ArticleSubtitle"
              width={subtitleWidth}
              fontSize={subtitleFontSize}
              lineHeight={subtitleLineHeight}
            >
              {subtitle}
            </sc.ArticleSubtitle>
          )}
        </sc.Article>
      </sc.Container>
    </CardItemLayout>
  );
});
