import { TFunction } from "next-i18next";

import { NamePage } from "../../stores/OverlaySystemPagePresenter";
import { SystemDeckParam } from "../../stores/SystemDeckStore";
import { mediumScreenUpperLimitValue } from "../breakpoints";
import {
  namePageCreationTooltip,
  namePageKeyTooltip,
  namePageNatureTooltip,
  namePagePeopleTooltip,
  namePageSpiritTooltip,
  namePageWorldTooltip,
} from "../env";
import {
  etBlack,
  etBlue,
  etGray,
  etGrayBold,
  etGreen,
  etOrange,
  etPurpleUonCard,
  etRed,
  etWhite,
  etYellow,
} from "./../colors";

export const getLogoSystemPage = (
  namePage: string,
  widthDevice: number,
): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return widthDevice < mediumScreenUpperLimitValue
        ? "keys-logo-mobile"
        : "keys-logo";
    }

    case NamePage.Nature: {
      return widthDevice < mediumScreenUpperLimitValue
        ? "nature-logo-mobile"
        : "nature-logo";
    }

    case NamePage.Spirit: {
      return widthDevice < mediumScreenUpperLimitValue
        ? "spirit-logo-mobile"
        : "spirit-logo";
    }

    case NamePage.World: {
      return widthDevice < mediumScreenUpperLimitValue
        ? "world-logo-mobile"
        : "world-logo";
    }

    case NamePage.Creation: {
      return widthDevice < mediumScreenUpperLimitValue
        ? "creation-logo-mobile"
        : "creation-logo";
    }

    case NamePage.People: {
      return widthDevice < mediumScreenUpperLimitValue
        ? "people-logo-mobile"
        : "people-logo";
    }
    default: {
      return "";
    }
  }
};

export const getBGImgCardIntro = (
  namePage: string,
  isWebpSupported: boolean,
): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/keys.webp")
        : require("../../assets/imgCardIntro/keys.jpg");
    }

    case NamePage.Nature: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/nature.webp")
        : require("../../assets/imgCardIntro/nature.jpg");
    }

    case NamePage.Spirit: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/spirit.webp")
        : require("../../assets/imgCardIntro/spirit.jpg");
    }

    case NamePage.World: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/world.webp")
        : require("../../assets/imgCardIntro/world.jpg");
    }
    case NamePage.Creation: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/creation.webp")
        : require("../../assets/imgCardIntro/creation.jpg");
    }

    case NamePage.People: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/people.webp")
        : require("../../assets/imgCardIntro/people.jpg");
    }
    default: {
      return "";
    }
  }
};

export const getBGImgCardIntro1col = (
  namePage: string,
  isWebpSupported: boolean,
): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/keys-1col.webp")
        : require("../../assets/imgCardIntro/keys-1col.jpg");
    }

    case NamePage.Nature: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/nature-1col.webp")
        : require("../../assets/imgCardIntro/nature-1col.jpg");
    }

    case NamePage.Spirit: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/spirit-1col.webp")
        : require("../../assets/imgCardIntro/spirit-1col.jpg");
    }

    case NamePage.World: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/world-1col.webp")
        : require("../../assets/imgCardIntro/world-1col.jpg");
    }
    case NamePage.Creation: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/creation-1col.webp")
        : require("../../assets/imgCardIntro/creation-1col.jpg");
    }

    case NamePage.People: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/people-1col.webp")
        : require("../../assets/imgCardIntro/people-1col.jpg");
    }
    default: {
      return "";
    }
  }
};

export const getBGImgCardIntro2col = (
  namePage: string,
  isWebpSupported: boolean,
): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/keys-2col.webp")
        : require("../../assets/imgCardIntro/keys-2col.jpg");
    }

    case NamePage.Nature: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/nature-2col.webp")
        : require("../../assets/imgCardIntro/nature-2col.jpg");
    }

    case NamePage.Spirit: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/spirit-2col.webp")
        : require("../../assets/imgCardIntro/spirit-2col.jpg");
    }

    case NamePage.World: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/world-2col.webp")
        : require("../../assets/imgCardIntro/world-2col.jpg");
    }
    case NamePage.Creation: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/creation-2col.webp")
        : require("../../assets/imgCardIntro/creation-2col.jpg");
    }

    case NamePage.People: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/people-2col.webp")
        : require("../../assets/imgCardIntro/people-2col.jpg");
    }
    default: {
      return "";
    }
  }
};

export const getBGImgCardIntro3col = (
  namePage: string,
  isWebpSupported: boolean,
): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/keys-3col.webp")
        : require("../../assets/imgCardIntro/keys-3col.jpg");
    }

    case NamePage.Nature: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/nature-3col.webp")
        : require("../../assets/imgCardIntro/nature-3col.jpg");
    }

    case NamePage.Spirit: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/spirit-3col.webp")
        : require("../../assets/imgCardIntro/spirit-3col.jpg");
    }

    case NamePage.World: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/world-3col.webp")
        : require("../../assets/imgCardIntro/world-3col.jpg");
    }
    case NamePage.Creation: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/creation-3col.webp")
        : require("../../assets/imgCardIntro/creation-3col.jpg");
    }

    case NamePage.People: {
      return isWebpSupported
        ? require("../../assets/imgCardIntro/people-3col.webp")
        : require("../../assets/imgCardIntro/people-3col.jpg");
    }
    default: {
      return "";
    }
  }
};

export const getSubtitleSystemPage = (
  namePage: string,
  t: TFunction,
): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return t("systempagetabs.keys-channel.hash-tags") || "";
    }
    case NamePage.Nature: {
      return t("systempagetabs.nature-channel.hash-tags") || "";
    }
    case NamePage.Spirit: {
      return t("systempagetabs.spirit-channel.hash-tags") || "";
    }
    case NamePage.World: {
      return t("systempagetabs.world-channel.hash-tags") || "";
    }
    case NamePage.Creation: {
      return t("systempagetabs.creation-channel.hash-tags") || "";
    }
    case NamePage.People: {
      return t("systempagetabs.people-channel.hash-tags") || "";
    }
    default: {
      return "";
    }
  }
};

export const getSubtitleSystemPageNewTemplate = (
  namePage: string,
  t: TFunction,
): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return t("systempagetabs.keys-channel.subtitle") || "";
    }
    case NamePage.Nature: {
      return t("systempagetabs.nature-channel.subtitle") || "";
    }
    case NamePage.Spirit: {
      return t("systempagetabs.spirit-channel.subtitle") || "";
    }
    case NamePage.World: {
      return t("systempagetabs.world-channel.subtitle") || "";
    }
    case NamePage.Creation: {
      return t("systempagetabs.creation-channel.subtitle") || "";
    }
    case NamePage.People: {
      return t("systempagetabs.people-channel.subtitle") || "";
    }
    default: {
      return "";
    }
  }
};

export const getTitleSystemPage = (namePage: string, t: TFunction): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return t("systempagetabs.keys-channel.title") || namePageKeyTooltip;
    }
    case NamePage.Nature: {
      return t("systempagetabs.nature-channel.title") || namePageNatureTooltip;
    }
    case NamePage.Spirit: {
      return t("systempagetabs.spirit-channel.title") || namePageSpiritTooltip;
    }
    case NamePage.World: {
      return t("systempagetabs.world-channel.title") || namePageWorldTooltip;
    }
    case NamePage.Creation: {
      return (
        t("systempagetabs.creation-channel.title") || namePageCreationTooltip
      );
    }
    case NamePage.People: {
      return t("systempagetabs.people-channel.title") || namePagePeopleTooltip;
    }

    default: {
      return "";
    }
  }
};

export const getBgColorSystemPage = (namePage: string): string => {
  switch (namePage) {
    case NamePage.Keys: {
      return etBlue;
    }

    case NamePage.Nature: {
      return etGreen;
    }

    case NamePage.Spirit: {
      return etPurpleUonCard;
    }

    case NamePage.World: {
      return etYellow;
    }

    case NamePage.Creation: {
      return etOrange;
    }

    case NamePage.People: {
      return etRed;
    }
    default: {
      return "";
    }
  }
};

export const getTextColorSystemPage = (namePage: string): string => {
  switch (namePage) {
    case NamePage.Today: {
      return etGray;
    }
    case NamePage.Keys: {
      return etWhite;
    }
    case NamePage.Nature: {
      return etBlack;
    }
    case NamePage.Spirit: {
      return etWhite;
    }
    case NamePage.World: {
      return etBlack;
    }
    case NamePage.Creation: {
      return etBlack;
    }
    case NamePage.People: {
      return etWhite;
    }

    case NamePage.AboutEarthToday: {
      return etGrayBold;
    }
    case SystemDeckParam.BRAND: {
      return etGrayBold;
    }
    case SystemDeckParam.PROTECTOR: {
      return etGrayBold;
    }

    case SystemDeckParam.NPO: {
      return etGrayBold;
    }

    default: {
      return "";
    }
  }
};
