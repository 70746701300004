import styled from "styled-components";

import { etDark, etGrayLight } from "../../shared/colors";

namespace sc {
  export const Header = styled.div`
    position: relative;
    padding: 0;
  `;
  export const HeaderTitle = styled.h2`
    font-family: Roboto;
    font-weight: 500;
    font-stretch: normal;
    font-size: 20px;
    height: 65px;
    letter-spacing: 0px;
    color: ${etDark};
    margin-bottom: 35px;
    border-bottom: 1px solid ${etGrayLight};
  `;
  export const Body = styled.div`
    border: 2px solid #a1d2eb;
    border-radius: 8px;
    padding: 16px;
    font-family: Roboto;
    font-weight: 400;
    font-stretch: normal;
    font-size: 14px;
    letter-spacing: 0px;
    height: 169px;
    line-height: 20px;
    color: ${etDark};
  `;
  export const Logo = styled.div`
    position: relative;
    display: block;
    height: 40px;
    width: 160px;
    background-image: url(${require("../../assets/img/logo-2x.png")});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  `;
}

export default sc;
