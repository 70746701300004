/* eslint-disable no-return-assign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  adminContract,
  cardContract,
  deckContract,
  imageContract,
  oneFlowContract,
  paymentContract,
  promotionContract,
  qrCodeContract,
  reserveContract,
  uonContract,
  userContract,
} from "@earthtoday/contract";
import { initClient } from "@ts-rest/core";
import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  Method,
} from "axios";

import { getAPIBaseUrl, getAPIRootBaseUrl } from "../shared/env";
import { ETSessionStorage } from "../shared/helpers/EtStorages";
import { isBrowser } from "../shared/helpers/isBrowser";
import { TermsNotAcceptedError } from "../shared/helpers/termsNotAcceptedError";
import {
  ErrorAPIType,
  getAPIErrorType,
  isAxiosError,
} from "../shared/helpers/translateApiError";
import { wait } from "../shared/helpers/wait";
import { Logger } from "../shared/models/Logger";
import { RootStore } from "./rootStore";
import { TokenStore } from "./TokenStore";

const { CancelToken } = Axios;

const MAX_RETRY = 3;

export class TokenInterceptorStore {
  static IGNORED_PATHS = ["/users/me/logins/token/refresh"];

  private logger: Logger;
  private canceler: Canceler[] = [];

  public tsRestClient = {
    image: initClient(imageContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    card: initClient(cardContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    admin: initClient(adminContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    user: initClient(userContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    uon: initClient(uonContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    oneFlow: initClient(oneFlowContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    payment: initClient(paymentContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    promotion: initClient(promotionContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    deck: initClient(deckContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    qrCode: initClient(qrCodeContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
    reserveContract: initClient(reserveContract, {
      baseUrl: getAPIRootBaseUrl(),
      baseHeaders: {},
      api: this.tsRestApi.bind(this),
    }),
  };
  constructor(private rootStore: RootStore, private tokenService: TokenStore) {
    this.logger = rootStore.logger.child({ container: this.constructor.name });
  }

  private shouldInsertToken(requestUrl: string): boolean {
    if (
      !this.rootStore.userSessionStore.user &&
      !this.tokenService.getAccessToken()
    ) {
      return false;
    }

    return !TokenInterceptorStore.IGNORED_PATHS.some((path) =>
      requestUrl.endsWith(path),
    );
  }

  public async getToken(): Promise<string> {
    const token = this.tokenService.getAccessToken();

    // anon user
    if (!token) {
      return "";
    }

    if (!TokenStore.isExpired(token)) {
      // Use existing token for request
      return token;
    }

    let isMocking = false;
    if (isBrowser() && ETSessionStorage.getItem("mockOptions")) {
      isMocking = true;
    }
    if (isMocking) {
      return token;
    }

    this.logger.info("token expired");
    // Refresh expired token
    let newToken = "";
    try {
      newToken = await this.tokenService.refreshToken(this.logger);
    } catch (error: any) {
      this.logger.info({ err: error }, "refresh error");

      if (error.name === "NoRefreshTokenError") {
        this.tokenService.removeAccessToken();
      }
    }

    return newToken;
  }

  public reset(): void {
    this.tokenService.removeAccessToken();
    this.tokenService.removeRefreshToken();
  }

  public cancel(): void {
    if (this.canceler.length > 0) {
      for (const c of this.canceler) c();
      this.canceler.length = 0;
    }
  }

  public isCancelableUrl = (url: string): boolean => {
    const excludeUrls = ["/users/me", "/v1/cards"];
    for (const excludeUrl of excludeUrls) {
      if (url.endsWith(excludeUrl)) {
        return false;
      }
    }

    return true;
  };

  public async callWithoutExtraConfigs<T = any, R = AxiosResponse<T>>(
    request: AxiosRequestConfig,
  ): Promise<any> {
    const resp = await Axios(request);
    return resp as any;
  }

  public async call<T = any, R = AxiosResponse<T>>(
    request: AxiosRequestConfig,
    tryCount = 0,
  ): Promise<R> {
    const logger = this.logger.child({
      tryCount,
      request: `${request.method || "GET"} ${request.url?.trim()}`,
    });

    logger.info("intercepting request");

    if (this.isCancelableUrl(request.url || "")) {
      request.cancelToken = new CancelToken((c) => this.canceler.push(c));
    } else {
      console.log(`request.url`, request.url);
    }

    let isMocking = false;

    if (isBrowser()) {
      if (ETSessionStorage.getItem("mockOptions")) {
        isMocking = true;
      }
    } else if (this.rootStore.mockStore.mockApiBaseUrl) {
      request.url = `${
        this.rootStore.mockStore.mockApiBaseUrl
      }/v1${request.url?.replace(getAPIBaseUrl(), "")}`;
      isMocking = true;
    }

    if (!this.shouldInsertToken(request.url || "")) {
      request.headers = { ...request.headers };

      if (isMocking) {
        request.headers.mockoptions = isBrowser()
          ? (ETSessionStorage.getItem("mockOptions") as string)
          : this.rootStore.mockStore.mockOptions;
      }

      return (await Axios(request)) as any;
    }
    const doCall = async (token: string) => {
      request.headers = { ...request.headers };

      if (token) {
        request.headers.Authorization = token;
      }

      if (isMocking) {
        request.headers.mockoptions = ETSessionStorage.getItem(
          "mockOptions",
        ) as string;
      }

      try {
        const resp = await Axios(request);

        const respHeaders: Record<string, string> = {};
        for (const [key, value] of Object.entries(resp.headers)) {
          respHeaders[key] = value.toString();
        }

        this.tokenService.setTokensFromResponse({ headers: respHeaders });

        return resp as any;
      } catch (error) {
        if (!isAxiosError(error) || !error.response) {
          throw error;
        }

        const resp: AxiosResponse = error.response;

        if (resp.status !== 403) {
          throw error;
        }

        const errorType = getAPIErrorType(error);

        if (errorType === ErrorAPIType.TermsNotAccepted) {
          throw new TermsNotAcceptedError(
            "You must accept the terms and conditions",
          );
        }

        // eslint-disable-next-line no-param-reassign
        tryCount += 1;
        if (tryCount >= MAX_RETRY) {
          this.tokenService.removeAccessToken();
          this.tokenService.removeRefreshToken();
          throw error;
        }

        if (errorType === ErrorAPIType.Blacklisted) {
          // refresh
          await this.tokenService.removeAccessToken();
          await this.tokenService.refreshToken(logger);
          await wait(500);
          logger.info("Blacklisted. call retry(b) count: ", tryCount);
          return await this.call(request, tryCount);
        }

        if (errorType === ErrorAPIType.IncorrectPassword) {
          // refresh
          await this.tokenService.removeAccessToken();
          await this.tokenService.refreshToken(logger);
          throw error;
        }

        if (errorType === ErrorAPIType.AuthenticationFailed) {
          this.tokenService.removeAccessToken();
          this.tokenService.removeRefreshToken();
          throw error;
        }

        if (
          errorType === ErrorAPIType.UserEmailNotVerified ||
          errorType === ErrorAPIType.MaximumNumberOfItemsCreatedError
        ) {
          throw error;
        }

        if (errorType === ErrorAPIType.GenericApplicationError) {
          throw error;
        }

        // retry
        this.tokenService.removeAccessToken();
        this.tokenService.removeRefreshToken();

        await wait(500);
        logger.info("call retry(b) count: ", tryCount);
        return await this.call(request, tryCount);
      }
    };

    const token = this.tokenService.getAccessToken();

    if (!TokenStore.isExpired(token)) {
      // Use existing token for request
      return doCall(token);
    }

    if (isMocking) {
      return doCall(token);
    }

    this.logger.info("token expired");
    // Refresh expired token
    let newToken = "";
    try {
      newToken = await this.tokenService.refreshToken(logger);
    } catch (error: any) {
      this.logger.info({ err: error }, "refresh error");

      if (error.name === "NoRefreshTokenError") {
        this.tokenService.removeAccessToken();
      }
    }

    return doCall(newToken);
  }

  private async tsRestApi({ path, method, headers, body }) {
    try {
      const result = await this.call({
        method: method as Method,
        url: path,
        headers,
        data: body,
      });

      const respHeaders: Record<string, string> = {};
      for (const [key, value] of Object.entries(result.headers)) {
        respHeaders[key] = value.toString();
      }
      this.tokenService.setTokensFromResponse({ headers: respHeaders });

      const responseHeaders = new Headers();
      for (const [key, value] of Object.entries(result.headers)) {
        responseHeaders.set(
          key,
          Array.isArray(value) ? value.join(",") : value,
        );
      }

      return {
        status: result.status,
        body: result.data,
        headers: responseHeaders,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error_: Error | AxiosError | any) {
      if (isAxiosError(error_)) {
        const error = error_ as AxiosError;
        const response = error.response as AxiosResponse;

        if (!response) throw error;

        const responseHeaders = new Headers();
        for (const [key, value] of Object.entries(response.headers)) {
          responseHeaders.set(
            key,
            Array.isArray(value) ? value.join(",") : value,
          );
        }

        return {
          status: response.status,
          body: response.data,
          headers: responseHeaders,
          response,
          isAxiosError: true,
        };
      }
      throw error_;
    }
  }
}
