import { observer } from "mobx-react-lite";
import React from "react";

import { etBrightLightBlue, etGray } from "../../shared/colors";
import { GroupProfileType } from "../../shared/models/User";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ModalType } from "../../stores/ModalStore";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./ContextMenuRow.styled";

export interface ContextMenuRowReportDriver {
  readonly isAdminEarthToday: boolean;
  readonly isGroupProfile: boolean;
  readonly enableNpoRole: boolean;

  readonly enableCharityRole: boolean;

  applyGroupRole(groupProfileType: GroupProfileType): void;

  unpublishGroup(): void;
}

interface IProps {
  driver?: ContextMenuRowReportDriver;
  openModal(modalType: ModalType): void;
}

export const ContextMenuRowReport = observer((props: IProps) => {
  const { driver } = props;

  const { t } = useTranslation("ProfileCard");

  return (
    <sc.ContextMenuRowReport>
      <sc.ContextMenuRowReportItem
        data-cy="CardProfileContext-Report"
        onClick={() => props.openModal("profileReport")}
        hoverColor={etBrightLightBlue}
      >
        <IconSvg
          icon="report"
          color={etGray}
          pointer={true}
          width="19px"
          height="19px"
          padding="0"
        />
        <sc.ContextMenuItemBlue>
          {t("profilecard.button.report")}
        </sc.ContextMenuItemBlue>
      </sc.ContextMenuRowReportItem>
      {driver?.isAdminEarthToday && driver?.isGroupProfile ? (
        <>
          <sc.ContextMenuRowReportItem
            data-cy="CardProfile-SettingMenu-ApplyBrandRole"
            hoverColor={etBrightLightBlue}
            onClick={() => driver.applyGroupRole(GroupProfileType.BRAND)}
          >
            <IconSvg
              width="18px"
              height="18px"
              padding="0"
              icon="edit-card"
              color={etGray}
              pointer
            />
            <sc.ContextMenuItemBlue>
              {t("profilecard.button.apply-brand")}
            </sc.ContextMenuItemBlue>
          </sc.ContextMenuRowReportItem>
          <sc.ContextMenuRowReportItem
            data-cy="CardProfile-SettingMenu-ApplyNPORole"
            hoverColor={etBrightLightBlue}
            onClick={() => {
              if (driver.enableNpoRole) {
                driver.applyGroupRole(GroupProfileType.NPO);
              }
            }}
          >
            <IconSvg
              width="18px"
              height="18px"
              padding="0"
              icon="edit-card"
              color={etGray}
              pointer
            />
            <sc.ContextMenuItemBlue>
              {t("profilecard.button.apply-npo")}
            </sc.ContextMenuItemBlue>
          </sc.ContextMenuRowReportItem>
          <sc.ContextMenuRowReportItem
            data-cy="CardProfile-SettingMenu-ApplyCharityRole"
            hoverColor={etBrightLightBlue}
            onClick={() => {
              if (driver.enableCharityRole) {
                driver.applyGroupRole(GroupProfileType.CHARITY);
              }
            }}
          >
            <IconSvg
              width="18px"
              height="18px"
              padding="0"
              icon="edit-card"
              color={etGray}
              pointer
            />
            <sc.ContextMenuItemBlue>
              {t("profilecard.button.apply-charity")}
            </sc.ContextMenuItemBlue>
          </sc.ContextMenuRowReportItem>
          <sc.ContextMenuRowReportItem
            data-cy="CardProfile-SettingMenu-UnpublishGroup"
            hoverColor={etBrightLightBlue}
            onClick={() => driver && driver.unpublishGroup()}
          >
            <IconSvg
              width="18px"
              height="18px"
              padding="0"
              icon="edit-card"
              color={etGray}
              pointer
            />
            <sc.ContextMenuItemBlue>
              {t("profilecard.button.unpublish-group")}
            </sc.ContextMenuItemBlue>
          </sc.ContextMenuRowReportItem>
        </>
      ) : undefined}
    </sc.ContextMenuRowReport>
  );
});
