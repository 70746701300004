import styled from "styled-components";

import {
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
} from "../../shared/breakpoints";
import { etWhite } from "../../shared/colors";

namespace sc {
  export const Blank = styled.div`
    display: flex;
    width: calc(328px - 32px);
    height: calc(496px - 32px);
    background-color: ${etWhite};
    border-radius: 8px;
    @media screen and (min-width: ${mediumScreenLowerLimit}) {
      display: none;
    }
    @media screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      background-color: transparent;
    }
  `;

  export const Container = styled.div<{ bgColor?: string }>`
    display: flex;
    width: 328px;
    min-height: 496px; // to display correctly on safari
    height: 496px;
    margin-top: 24px;
    padding: 16px;
    background-color: ${(props) => props.bgColor || etWhite};
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    @media screen and (min-width: ${largeScreenLowerLimit}) {
      display: none;
    }

    @media screen and (min-width: ${mediumScreenLowerLimit}) and (max-width: ${mediumScreenUpperLimit}) {
      visibility: hidden;
      // to detect mobile landscape
      @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
        visibility: visible;
        display: flex;
        height: 496px;
        min-height: 496px;
        width: 680px;
        background-color: ${(props) => props.bgColor || etWhite};
      }
      &.mLandscape {
        visibility: visible;
        display: flex;
        height: 496px;
        min-height: 496px;
        width: 680px;
        background-color: ${(props) => props.bgColor || etWhite};
      }
    }
  `;
}

export default sc;
