import styled from "styled-components";

import { largeScreenUpperLimit } from "../../../shared/breakpoints";
import {
  etBlack,
  etGray,
  etGrayBlue,
  etNevadaGrey,
  etWhite,
} from "../../../shared/colors";

namespace sc {
  export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background-color: ${etWhite};
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    min-height: 513px;
    margin-bottom: 51px;

    @media (max-width: ${largeScreenUpperLimit}) {
      flex-direction: column;
    }
    @media (max-width: 1024px) {
      margin-left: 32px;
      margin-right: 32px;
    }
  `;

  export const XButtonWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    z-index: 5;
  `;

  export const Img = styled.img`
    height: 278px;
    width: 310px;
    margin-left: 196px;
    margin-right: 197px;

    @media (max-width: 1600px) {
      margin-left: 105px;
      margin-right: 105px;
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      height: 237px;
      width: 264px;
      margin-left: 32px;
      margin-right: 32px;
      margin-top: 50px;
      margin-bottom: 50.5px;
    }
  `;

  export const BodyContent = styled.div`
    min-height: 310px;

    @media (max-width: ${largeScreenUpperLimit}) {
      padding: 32px;
    }
  `;
  export const Title = styled.h3`
    color: ${etBlack};
    font-family: Roboto Slab;
    font-size: 35px;
    font-weight: 300;
    line-height: 45px;
    margin-bottom: 31.5px;
    @media (max-width: 1600px) {
      line-height: 40px;
      font-size: 30px;
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      line-height: 30px;
      font-size: 20px;
    }
  `;
  export const Description = styled.div`
    color: ${etNevadaGrey};
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 30px;
    max-width: 545px;

    margin-right: 136px;
    margin-bottom: 30px;

    @media (max-width: 1600px) {
      font-size: 18px;
      line-height: 28px;
      margin-right: 140px;
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      margin-right: unset;
      line-height: 24px;
      font-size: 16px;
    }
  `;
  export const LearnMoreBtn = styled.button`
    background-color: ${etWhite};
    margin-top: 35.5px;
    border-radius: 20px;
    border: 1px solid ${etGray};
    height: 40px;
    width: 150px;

    color: ${etGray};
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
  `;
  export const CuratorInfo = styled.div`
    color: ${etGrayBlue};
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: normal;
    height: 19px;
    letter-spacing: 0px;
  `;
}

export { sc };
