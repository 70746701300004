import { observer } from "mobx-react-lite";
import React from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import { useRootStore } from "../../stores/rootStore";
import { LayoutModal } from "../LayoutModal/LayoutModal";
import { ModalDelete } from "../ModalDelete/ModalDelete";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";

export const ModalDeleteCard = observer(() => {
  const rootStore = useRootStore();
  const store = rootStore.modalDeleteCardStore;

  const { t } = useTranslation("ModalDeleteCard");

  return (
    <LayoutModal
      renderModalWrapper={(children) => (
        <ModalWrapper
          onClose={() => rootStore.modalStore.openModal("")}
          modalTypes="cardDelete"
        >
          {children}
        </ModalWrapper>
      )}
      renderModalContent={() => (
        <ModalDelete
          driver={store}
          title={t("modaldeletecard.title")}
          description={t("modaldeletecard.description")}
          deleteBtnText={t("modaldeletecard.button.delete")}
        />
      )}
      onClose={() => rootStore.modalStore.openModal("")}
    />
  );
});
