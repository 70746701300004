import { observer } from "mobx-react-lite";
import Link from "next/link";

import { urlCardTheEarthFlag } from "../../../shared/env";
import { protectPageSectionOneNumberAnimation } from "../../../shared/helpers/protectPageSectionOneNumberAnimation";
import { useTranslation } from "../../../shared/translate/NextI18next";
import { Currency } from "../../../stores/DonateStore/DonateStore";
import { ILeaderboardModel } from "../../../stores/LeaderBoardModel";
import { CounterFlipEffect } from "../../CounterFlipEffect/CounterFlipEffect";
import { Leaderboard } from "../../Leaderboard/Leaderboard";
import { TheLoader } from "../../TheLoader/TheLoader";
import { M2Icon } from "../../UonCountNumberAnimation/M2Icon";
import { UonCountNumberLocation } from "../../UonCountNumberAnimation/StylesUonCountNumberAnimation";
import { UonCountNumberAnimation } from "../../UonCountNumberAnimation/UonCountNumberAnimation";
import { sc } from "./SectionOneV202208.styled";

export interface IPropsSectionOneBodyV202208 {
  leaderboard: ILeaderboardModel;
  stateDriver: {
    loading: boolean;
  };
  driver: {
    protectCount: number;
    lastNameProtector: string;
    isGroupUnpublished: boolean;
    currency: Currency;
    openSubscribeOneFlowModal(dataCy: string): void;
  };
  flags: {
    counterFlapEffectDuration: number;
    counterFlipEffectSetInterval: number;
    counterFlipEffectTimeReroll: number;
    enableOneFlow: boolean;
  };
  noAnimationCounter?: boolean;
}

export const SectionOneBodyV202208 = observer(
  function SectionOneV202208WithEnableOneFlowFlag(
    props: IPropsSectionOneBodyV202208,
  ) {
    const { t } = useTranslation("Protect");

    return (
      <sc.Container>
        <sc.ColLeft data-cy="SectionOneBodyV202208-ColLeft">
          <sc.EarthFlag
            href={urlCardTheEarthFlag}
            data-cy="SectionOneBodyV202208-EarthFlag"
          />
          <sc.LastNameProtector data-cy="SectionOneBodyV202208-LastNameProtector">
            {props.driver.lastNameProtector}
          </sc.LastNameProtector>
          {!props.stateDriver.loading && (
            <CounterFlipEffect
              protectCount={props.driver.protectCount}
              renderChildren={(counter: number) => {
                return (
                  <sc.CountsWrapper data-cy="SectionOneBodyV202208-CountsWrapper">
                    {props.driver.protectCount === null ? (
                      <TheLoader className="visual-reg-remove" />
                    ) : (
                      <>
                        {protectPageSectionOneNumberAnimation(counter).map(
                          (count, i) => (
                            <UonCountNumberAnimation
                              dataCy={"SectionOneBodyV202208-CountNumber"}
                              noAnimationCounter={props.noAnimationCounter}
                              flags={props.flags}
                              countNumbers={count.countNumbers}
                              lastNumber={count.lastNumber}
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${count.lastNumber}${i}`}
                              location={UonCountNumberLocation.NewProtectPage}
                            />
                          ),
                        )}
                      </>
                    )}
                    <M2Icon
                      location={UonCountNumberLocation.NewProtectPage}
                      enableOneFlow={props.flags.enableOneFlow}
                    />
                  </sc.CountsWrapper>
                );
              }}
              flags={props.flags}
            />
          )}
          <sc.LabelProtect data-cy="SectionOneBodyV202208-LabelProtect">
            {props.driver.currency === Currency.USD
              ? t("protect.section-one-v202208.label.usd-title")
              : t("protect.section-one-v202208.label.euro-title")}
          </sc.LabelProtect>

          <sc.Buttons>
            {props.flags.enableOneFlow && !props.driver.isGroupUnpublished ? (
              <sc.StartProtectButton
                data-cy="SectionOneBodyV202208-StartProtecting"
                onClick={() => {
                  props.driver.openSubscribeOneFlowModal(
                    "SectionOneBodyWithEnableOneFlow-StartProtecting",
                  );
                }}
              >
                {t("protect.section-one-v202208.start-protecting")}
              </sc.StartProtectButton>
            ) : (
              <Link passHref href="#sectionTwo" scroll legacyBehavior>
                <sc.StartProtectButton data-cy="SectionOneBodyV202208-StartProtecting">
                  {t("protect.section-one-v202208.start-protecting")}
                </sc.StartProtectButton>
              </Link>
            )}

            <sc.FinancialStatementButton
              data-cy="SectionOneBodyV202208-FinancialStatementButton"
              href="https://financials.earthtoday.com"
              target="_blank"
            >
              <>{t("protect.section-one-v202208.financial-btn-update")}</>
            </sc.FinancialStatementButton>
          </sc.Buttons>
        </sc.ColLeft>
        <sc.ColRight id="leaderBoard" data-cy="SectionOneBodyV202208-ColRight">
          <sc.Leaderboard>
            <Leaderboard
              driver={props.leaderboard}
              stateDriver={props.stateDriver}
            />
          </sc.Leaderboard>
        </sc.ColRight>
      </sc.Container>
    );
  },
);
