import React from "react";

import { TheLoader } from "../TheLoader/TheLoader";
import sc from "./PageLoader.styled";

interface IProps {
  forcedCenterMiddle?: boolean;
}

export const PageLoader = (props: IProps): JSX.Element => {
  return (
    <sc.Container data-cy="PageLoader">
      <TheLoader forcedCenterMiddle={props.forcedCenterMiddle} />
    </sc.Container>
  );
};
